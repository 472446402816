import StarRating from "components/StarRating/StarRating";
import { MatColors } from "helpers/color";
import { calculateAverage } from "helpers/number";
import { _generateFTLineChart } from "./lineChart";
import { _generateFTMixedChart } from "./mixedChart";
import { _generateFTPolarChart } from "./polarChart";
import { _generateFTRadarChart } from "./radarChart";
import { _generateFTScatterChart } from "./scatterChart";

//TODO adeguare i titoli con i18n

export const generateDepartmentCharts = () => {
	return {
		resources: {},
		radarChart: _generateFTRadarChart(),
		scatterChart: _generateFTScatterChart(),
		polarChart: {
			'rating': _generateFTPolarChart(),
			'hard_skill': _generateFTPolarChart(),
			'soft_skill': _generateFTPolarChart(),
			'mood': _generateFTPolarChart(),
			'hr': _generateFTPolarChart(),
		},
		linearChart: {
			'rating': _generateFTLineChart(),
			'hard_skill': _generateFTLineChart(),
			'soft_skill': _generateFTLineChart(),
			'mood': _generateFTLineChart(),
			'hr': _generateFTLineChart(),
		},
		mixedChart: _generateFTMixedChart(),
	};
};

export const populateDepartmentCharts = (charts, data) => {
	if (data == null || data.length == 0) {
		return null;
	}

	// radarChart
	let radarChart = charts.radarChart;
	radarChart.series = [{
		name: 'Valore medio del team',
		data: [
			calculateAverage(data, 'rating_value'),
			calculateAverage(data, 'hard_skill_value'),
			calculateAverage(data, 'soft_skill_value'),
			calculateAverage(data, 'hr_value'),
			calculateAverage(data, 'mood_value'),
		]
	}];
	radarChart.description = 'departments.item.contents.evaluations.radarChart';

	// scatterChart
	const scatterChart = charts.scatterChart;
	scatterChart.description = 'departments.item.contents.evaluations.scatterChart';

	//polarChart
	const polarChart = charts.polarChart;
	polarChart['rating'].description = 'departments.item.contents.evaluations.polarChart.rating';
	polarChart['hard_skill'].description = 'departments.item.contents.evaluations.polarChart.hard_skill';
	polarChart['soft_skill'].description = 'departments.item.contents.evaluations.polarChart.soft_skill';
	polarChart['mood'].description = 'departments.item.contents.evaluations.polarChart.mood';
	polarChart['hr'].description = 'departments.item.contents.evaluations.polarChart.hr';

	// linearChart
	const linearChart = charts.linearChart;
	linearChart['rating'].description = 'departments.item.contents.evaluations.linearChart.rating';
	linearChart['hard_skill'].description = 'departments.item.contents.evaluations.linearChart.hard_skill';
	linearChart['soft_skill'].description = 'departments.item.contents.evaluations.linearChart.soft_skill';
	linearChart['mood'].description = 'departments.item.contents.evaluations.linearChart.mood';
	linearChart['hr'].description = 'departments.item.contents.evaluations.linearChart.hr';

	//lista di tutte le risorse con al suo interno ogni grafico per ogni parametro
	const resources = charts.resources;

	//Foreach per il popolamento delle series
	data.forEach((value, index) => {
		value.data = new Date(value.data);

		//se non esiste la risorsa la inserisco
		if (resources[value.idRisorsa] == null) {
			resources[value.idRisorsa] = {
				id: value.idRisorsa,
				title: value.nome + ' ' + value.cognome,
				avatar: {
					initials: value.nome.charAt(0) + value.cognome.charAt(0),
					image: value.foto
				},
				charts: {
					'rating': _generateFTMixedChart(),
					'hard_skill': _generateFTMixedChart(),
					'soft_skill': _generateFTMixedChart(),
					'mood': _generateFTMixedChart(),
					'hr': _generateFTMixedChart(),
				},
				isActive: Object.keys(resources).length == 0 //metto selezionato la prima risorsa
			};


			//SCATTERS
			scatterChart.series.push({
				name: value.nome + ' ' + value.cognome,
				type: 'scatter',
				data: [[0, 0]],
				strokeColors: 'black',
				hardIdx: 0,
				hardSum: 0,
				softIdx: 0,
				softSum: 0
			});
			scatterChart.options.fill.image.src.push(value.foto);
			scatterChart.options.fill.type.push('image');
			scatterChart.options.colors.push(MatColors.BlueGrey['500']);

		}

		//mostro le stelline
		if (resources[value.idRisorsa].subtitle == null || value.rating_value != null) {
			resources[value.idRisorsa].subtitle = <StarRating actual={value.rating_value || 0} total="5" disableAnimation={true} justify='start' showLabel={true} />;
		}

		//risorse rating
		resources[value.idRisorsa].charts.rating.description = 'departments.item.contents.evaluations.resources.rating';
		resources[value.idRisorsa].charts.rating.series[0].data.push(value.rating_value);
		resources[value.idRisorsa].charts.rating.series[1].data.push(value.variance_avg_rating);
		resources[value.idRisorsa].charts.rating.options.labels.push(value.data.getTime());
		resources[value.idRisorsa].charts.rating.options.colors = [MatColors.Amber['400'], MatColors.Amber['600'], MatColors.Amber['800']];
		resources[value.idRisorsa].charts.rating.options.yaxis.min = resources[value.idRisorsa].charts.rating.options.yaxis.min > value.variance_avg_rating ? value.variance_avg_rating : resources[value.idRisorsa].charts.rating.options.yaxis.min;


		//risorse hard_skill
		resources[value.idRisorsa].charts.hard_skill.description = 'departments.item.contents.evaluations.resources.rating';
		resources[value.idRisorsa].charts.hard_skill.series[0].data.push(value.hard_skill_value);
		resources[value.idRisorsa].charts.hard_skill.series[1].data.push(value.variance_avg_hard_skill);
		resources[value.idRisorsa].charts.hard_skill.options.labels.push(value.data.getTime());
		resources[value.idRisorsa].charts.hard_skill.options.colors = [MatColors.Red['400'], MatColors.Red['600'], MatColors.Red['800']];
		resources[value.idRisorsa].charts.hard_skill.options.yaxis.min = resources[value.idRisorsa].charts.hard_skill.options.yaxis.min > value.variance_avg_hard_skill ? value.variance_avg_hard_skill : resources[value.idRisorsa].charts.hard_skill.options.yaxis.min;


		//risorse soft_skill
		resources[value.idRisorsa].charts.soft_skill.description = 'departments.item.contents.evaluations.resources.rating';
		resources[value.idRisorsa].charts.soft_skill.series[0].data.push(value.soft_skill_value);
		resources[value.idRisorsa].charts.soft_skill.series[1].data.push(value.variance_avg_soft_skill);
		resources[value.idRisorsa].charts.soft_skill.options.labels.push(value.data.getTime());
		resources[value.idRisorsa].charts.soft_skill.options.colors = [MatColors.Blue['400'], MatColors.Blue['600'], MatColors.Blue['800']];
		resources[value.idRisorsa].charts.soft_skill.options.yaxis.min = resources[value.idRisorsa].charts.soft_skill.options.yaxis.min > value.variance_avg_soft_skill ? value.variance_avg_soft_skill : resources[value.idRisorsa].charts.soft_skill.options.yaxis.min;


		//risorse mood
		resources[value.idRisorsa].charts.mood.description = 'departments.item.contents.evaluations.resources.rating';
		resources[value.idRisorsa].charts.mood.series[0].data.push(value.mood_value);
		resources[value.idRisorsa].charts.mood.series[1].data.push(value.variance_avg_mood);
		resources[value.idRisorsa].charts.mood.options.labels.push(value.data.getTime());
		resources[value.idRisorsa].charts.mood.options.colors = [MatColors.Green['400'], MatColors.Green['600'], MatColors.Green['800']];
		resources[value.idRisorsa].charts.mood.options.yaxis.min = resources[value.idRisorsa].charts.mood.options.yaxis.min > value.variance_avg_mood ? value.variance_avg_mood : resources[value.idRisorsa].charts.mood.options.yaxis.min;


		//risorse hr
		resources[value.idRisorsa].charts.hr.description = 'departments.item.contents.evaluations.resources.rating';
		resources[value.idRisorsa].charts.hr.series[0].data.push(value.hr_value);
		resources[value.idRisorsa].charts.hr.series[1].data.push(value.variance_avg_hr);
		resources[value.idRisorsa].charts.hr.options.labels.push(value.data.getTime());
		resources[value.idRisorsa].charts.hr.options.colors = [MatColors.Cyan['400'], MatColors.Cyan['600'], MatColors.Cyan['800']];
		resources[value.idRisorsa].charts.hr.options.yaxis.min = resources[value.idRisorsa].charts.hr.options.yaxis.min > value.variance_avg_hr ? value.variance_avg_hr : resources[value.idRisorsa].charts.hr.options.yaxis.min;



		//SCATTERS CHART
		scatterChart.series.forEach(serie => {
			if (serie.name == value.nome + ' ' + value.cognome) {
				if (value.hard_skill_value != null) { //aggiorno i valori di media e indice per hardskills
					serie.hardSum += parseFloat(value.hard_skill_value);
					serie.hardIdx++;
				}
				if (value.soft_skill_value != null) { //aggiorno i valori di media e indice per softkills
					serie.softSum += parseFloat(value.soft_skill_value);
					serie.softIdx++;
				}

				//aggiorno il dataset
				serie.data[0][1] = (serie.softIdx > 0 ? (serie.softSum / serie.softIdx).toFixed(2) : 0);
				serie.data[0][0] = (serie.hardIdx > 0 ? (serie.hardSum / serie.hardIdx).toFixed(2) : 0);
			}
		});


		// LINEAR CHART
		linearChart['rating'].series[0].data.push({ x: value.data.getTime(), y: value.rating_value });
		// linearChart['rating'].series[1].data.push({ x: value.data.getTime(), y: value.avg_rating_team });
		linearChart['rating'].options.colors = [MatColors.Amber['200'], MatColors.Amber['500']];

		linearChart['hard_skill'].series[0].data.push({ x: value.data.getTime(), y: value.hard_skill_value });
		// linearChart['hard_skill'].series[1].data.push({ x: value.data.getTime(), y: value.avg_hard_skill_team });
		linearChart['hard_skill'].options.colors = [MatColors.Red['200'], MatColors.Red['500']];

		linearChart['soft_skill'].series[0].data.push({ x: value.data.getTime(), y: value.soft_skill_value });
		// linearChart['soft_skill'].series[1].data.push({ x: value.data.getTime(), y: value.avg_soft_skill_team });
		linearChart['soft_skill'].options.colors = [MatColors.Blue['200'], MatColors.Blue['500']];

		linearChart['mood'].series[0].data.push({ x: value.data.getTime(), y: value.mood_value });
		// linearChart['mood'].series[1].data.push({ x: value.data.getTime(), y: value.avg_mood_team });
		linearChart['mood'].options.colors = [MatColors.Green['200'], MatColors.Green['500']];


		linearChart['hr'].series[0].data.push({ x: value.data.getTime(), y: value.hr_value });
		// linearChart['hr'].series[1].data.push({ x: value.data.getTime(), y: value.avg_hr_team });
		linearChart['hr'].options.colors = [MatColors.Cyan['200'], MatColors.Cyan['500']];

		if (index < 12) {
			linearChart['rating'].series[1].data.push({ x: value.data.getTime(), y: value.avg_rating_team });
			linearChart['hard_skill'].series[1].data.push({ x: value.data.getTime(), y: value.avg_hard_skill_team });
			linearChart['soft_skill'].series[1].data.push({ x: value.data.getTime(), y: value.avg_soft_skill_team });
			linearChart['mood'].series[1].data.push({ x: value.data.getTime(), y: value.avg_mood_team });
			linearChart['hr'].series[1].data.push({ x: value.data.getTime(), y: value.avg_hr_team });
		}


		//POLAR CHART
		if (index < 12) {

			//rating
			let ratingValue = 0;
			if (value.sigma_rating != null && value.avg_rating_team != null) {
				ratingValue = ((1 - (parseFloat(value.sigma_rating) / parseFloat(value.avg_rating_team))) * 100).toFixed(2);
				polarChart['rating'].series.unshift(ratingValue);
			} else {
				polarChart['rating'].series.unshift(0);

			}

			//hard skills
			let hard_skillValue = 0;
			if (value.sigma_hard_skill != null && value.avg_hard_skill_team != null) {
				hard_skillValue = ((1 - (parseFloat(value.sigma_hard_skill) / parseFloat(value.avg_hard_skill_team))) * 100).toFixed(2);
				polarChart['hard_skill'].series.unshift(hard_skillValue);

			} else {
				polarChart['hard_skill'].series.unshift(0);

			}

			//soft skills
			let soft_skillValue = 0;
			if (value.sigma_soft_skill != null && value.avg_soft_skill_team != null) {
				soft_skillValue = ((1 - (parseFloat(value.sigma_soft_skill) / parseFloat(value.avg_soft_skill_team))) * 100).toFixed(2);
				polarChart['soft_skill'].series.unshift(soft_skillValue);

			} else {
				polarChart['soft_skill'].series.unshift(0);

			}

			//mood
			let moodValue = 0;
			if (value.sigma_mood != null && value.avg_mood_team != null) {
				moodValue = ((1 - (parseFloat(value.sigma_mood) / parseFloat(value.avg_mood_team))) * 100).toFixed(2);
				polarChart['mood'].series.unshift(moodValue);

			} else {
				polarChart['mood'].series.unshift(0);

			}

			//hr
			let hrValue = 0;
			if (value.sigma_hr != null && value.avg_hr_team != null) {
				hrValue = ((1 - (parseFloat(value.sigma_hr) / parseFloat(value.avg_hr_team))) * 100).toFixed(2);
				polarChart['hr'].series.unshift(hrValue);

			} else {
				polarChart['hr'].series.unshift(0);

			}

			//imposto la data alla mezzanotte del primo del mese
			let monthYear = value.data;
			monthYear.setDate(1);
			monthYear.setHours(0);
			monthYear.setMinutes(0);
			monthYear.setMilliseconds(0);
			monthYear = monthYear.toLocaleDateString('it-IT', { year: 'numeric', month: 'long' });

			polarChart['rating'].options.labels.unshift(monthYear);
			polarChart['rating'].options.theme.monochrome.color = MatColors.Amber['500'];

			polarChart['hard_skill'].options.labels.unshift(monthYear);
			polarChart['hard_skill'].options.theme.monochrome.color = '#D7263D';

			polarChart['soft_skill'].options.labels.unshift(monthYear);
			polarChart['soft_skill'].options.theme.monochrome.color = '#008FFB';

			polarChart['mood'].options.labels.unshift(monthYear);
			polarChart['mood'].options.theme.monochrome.color = '#4CAF50';

			polarChart['hr'].options.labels.unshift(monthYear);
			polarChart['hr'].options.theme.monochrome.color = '#4ECDC4';
		}

	});

	return charts;

};

export const datasetTypologies = [
	{
		id: 'rating',
		name: 'Rating',
		isActive: true,
		color: MatColors.Amber['500'],
		backgroundColor: MatColors.Amber['200'],
	},
	{
		id: 'hard_skill',
		name: 'Hard Skill',
		isActive: false,
		color: MatColors.Red['500'],
		backgroundColor: MatColors.Red['200'],
	},
	{
		id: 'soft_skill',
		name: 'Soft Skill',
		isActive: false,
		color: MatColors.Blue['500'],
		backgroundColor: MatColors.Blue['200'],
	},
	{
		id: 'mood',
		name: 'Mood',
		isActive: false,
		color: MatColors.Green['500'],
		backgroundColor: MatColors.Green['200'],
	},
	{
		id: 'hr',
		name: 'HR',
		isActive: false,
		color: MatColors.Cyan['500'],
		backgroundColor: MatColors.Cyan['200'],
	}
];