import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import NotificationsMenu from './NotificationsMenu';
import Avatar from '../Avatar/Avatar';
import ProfileMenu from './ProfileMenu';
import Tooltip from '../Tooltip/Tooltip';
import { OffCanvas, OffCanvasButton, OffCanvasContent, OffCanvasPortal } from '../Bootstrap/OffCanvas';
import BadgeMenu from '../Badge/BadgeMenu';
import { Await, Link, useLoaderData } from 'react-router-dom';
import BadgeCount from '../Badge/BadgeCount';
import MotionComponent from 'components/Motion/MotionComponent';

import './Nav.scss';
import SearchMenu from './SearchMenu';

const FooterNav = () => {
	const { user, lastAccess, permissions } = useAuth();
	const { t } = useTranslation();

	const { badges } = useLoaderData();

	const handleSidenav = () => {
		let sidebar = document.getElementById('sidenav');
		sidebar && sidebar.classList.toggle('show') && sidebar.classList.remove('navbar-short');
		localStorage.removeItem('navbar-short');
	}

	return (
		<>
			<nav className="navbar bg-white w-100 border-top p-0">
				<div className='row w-100 g-0 align-items-center text-center'>
					<div className='col d-lg-none'>
						<MotionComponent as={'button'} whileTap={{ scale: 0.8 }} className='btn btn-sm' onClick={handleSidenav}>
							<Tooltip title={t('components:nav.menu')} >
								<i className='mdi mdi-menu mdi-18px' />
							</Tooltip>
						</MotionComponent>
					</div>
					<div className='col'>
						<MotionComponent as={Link} whileTap={{ scale: 0.8 }} className='btn btn-sm' to='/'>
							<Tooltip title={t('components:nav.dashboard')} >
								<i className='mdi mdi-home-outline mdi-18px' />
							</Tooltip>
						</MotionComponent>
					</div>
					<div className='col d-none d-lg-block'>
						<OffCanvas>
							<OffCanvasButton target='ocSearch'>
								<Tooltip title={t('components:nav.search')} >
									<i className='mdi mdi-magnify mdi-18px' />
								</Tooltip>
							</OffCanvasButton>
							<OffCanvasPortal>
								<OffCanvasContent id='ocSearch' height={100}>
									<Suspense>
										<SearchMenu />
									</Suspense>
								</OffCanvasContent>
							</OffCanvasPortal>
						</OffCanvas>
					</div>
					<div className='col'>
						<OffCanvas>
							<OffCanvasButton target='ocBadge'>
								<Tooltip title={t('components:nav.badge')}>
									<span className='fw-semibold h5'>
										<Suspense fallback={'00:00'}>
											<Await resolve={badges} children={(response) => <>
												{localStorage.setItem('badges', response?.data?.data)}
												<BadgeCount badges={response?.data?.data} />
											</>
											} />
										</Suspense>
									</span>
								</Tooltip>
							</OffCanvasButton>
							<OffCanvasPortal>
								<OffCanvasContent id='ocBadge'>
									<Await resolve={badges} children={(response) => <BadgeMenu badges={response?.data?.data} />} />
								</OffCanvasContent>
							</OffCanvasPortal>
						</OffCanvas>
					</div>
					<div className='col'>
						<OffCanvas>
							<OffCanvasButton target='ocNotifications'>
								<Tooltip title={t('components:nav.notifications')} >
									<i className={(user?.notificationsCount ? 'notification-badge ' : '') + 'mdi mdi-bell-outline mdi-18px'} />
								</Tooltip>
							</OffCanvasButton>
							<OffCanvasPortal>
								<OffCanvasContent id='ocNotifications'>
									<Suspense>
										<NotificationsMenu count={user?.notificationsCount} />
									</Suspense>
								</OffCanvasContent>
							</OffCanvasPortal>
						</OffCanvas>
					</div>
					<div className='col'>
						<OffCanvas>
							<OffCanvasButton target='ocProfile'>
								<Avatar item={user?.avatar} className='avatar-sm' />
							</OffCanvasButton>
							<OffCanvasPortal>
								<OffCanvasContent id='ocProfile'>
									<div className='row g-0 align-items-center justify-content-center'>
										<div className='col-12 col-lg-6 p-2'>
											<ProfileMenu user={user} lastAccess={lastAccess} permissions={permissions} />
										</div>
									</div>
								</OffCanvasContent>
							</OffCanvasPortal>
						</OffCanvas>
					</div>
				</div>
				{/* <div className='row g-0 align-items-center d-none d-lg-flex'> */}
				{/* BADGE //nonbadge */}
				{/* </div> */}
			</nav>
		</>
	)
}

export default FooterNav