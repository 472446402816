import React, { useState } from 'react'
import { Outlet, useLoaderData } from 'react-router-dom';
import ItemAwait from 'components/Item/ItemAwait';
import ItemHeader from 'components/Item/ItemHeader';
import ItemContent from 'components/Item/ItemContent';
import ItemFooter from 'components/Item/ItemFooter';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';

const Supplier = () => {
	const { supplier } = useLoaderData();
	const [item, setItem] = useState();

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			setItem(response.data);
		}
	}

	return (
		<ItemAwait resolvePromise={supplier} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
			<ItemHeader
				avatar={item?.avatar}
				title={item?.fullname}
				subtitle={item?.addresses && item?.addresses[0] ? item.addresses[0].fulladdress : ''}
			/>
			<ItemContent>
				<Outlet context={[item]} />
			</ItemContent>
			<ItemFooter />
		</ItemAwait>
	)
}

export default Supplier