import { urlReplace } from "helpers/url";
import Api from "./Api";

export default class TimesheetService {
	constructor(api) {
		this.api = api || new Api();
	}

	async getAll(params) {
		return this.api.get('/secure/timesheets', params);
	}

	async getAllMe(params) {
		return this.api.get('/secure/timesheets/my', params);
	}

	async getAllByDepartment(id, params) {
		if (id != null) {
			return this.api.get('/secure/timesheets/departments', params);
		} else {
			return this.api.get(urlReplace('/secure/timesheets/departments/{id}', { id: id }), params);
		}
	}

	async getById(id, params) {
		return this.api.get(urlReplace('/secure/timesheet/{id}', { id: id }), params);
	}
}
