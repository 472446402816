import React from 'react'
import Item from 'components/Item/Item'
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard'

const SupplierForm = () => {

	let professionalWorker = '/new-supplier/index.php?tipology=1';
	let business = '/new-supplier/index.php?tipology=2';

	return (
		<Item>
			<div className='d-flex flex-column h-100 justify-content-center text-body-secondary text-center align-items-center'>
				<div><i className='mdi mdi-account-hard-hat-outline mdi-72px'></i></div>
				<h4 className='m-0'>Aggiungi un nuovo fornitore</h4>
				<div className='fw-semibold mb-5'>Clicca su uno dei link qui di seguito oppure copialo per condividerlo più facilmente.</div>

				<div className='row g-0 align-items-center'>
					<div className='col-12 col-lg-6 mb-3 pe-lg-2'>
						<div className='p-2'>
							<strong>Aggiungi Professionista</strong>
						</div>
						<div className='row g-0 align-items-center'>
							<div className='col-12 pb-2'>
								<a className='btn btn-sm bg-primary bg-opacity-10 text-primary w-100' href={professionalWorker} target='_blank'>Nuovo Professionista</a>
							</div>
							<div className='col pe-2'>
								<input type='text' className='form-control form-control-sm' value={'https://alcodesk.it' + professionalWorker} readOnly onFocus={(e) => e.target.select()} />
							</div>
							<div className='col-auto'>
								<CopyToClipboard value={'https://alcodesk.it' + professionalWorker} />
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 mb-3 ps-lg-2'>
						<div className='p-2'>
							<strong>Aggiungi Impresa</strong>
						</div>
						<div className='row g-0 align-items-center'>
							<div className='col-12 pb-2'>
								<a className='btn btn-sm bg-primary bg-opacity-10 text-primary w-100' href={business} target='_blank'>Nuova Impresa</a>
							</div>
							<div className='col pe-2'>
								<input type='text' className='form-control form-control-sm' value={'https://alcodesk.it' + business} readOnly onFocus={(e) => e.target.select()} />
							</div>
							<div className='col-auto'>
								<CopyToClipboard value={'https://alcodesk.it' + business} />
							</div>
						</div>
					</div>
				</div>


				{/* <a href="/new-supplier/index.php" target='_blank'>Redirect su pagina pubblica</a> */}
			</div>
		</Item>
	)
}

export default SupplierForm