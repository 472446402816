import React from 'react'
import { useOutletContext } from 'react-router-dom';
import DetailView from 'components/DetailView/DetailView';
import { Trans, useTranslation } from 'react-i18next';
import Document from 'components/Document/Document';

const SupplierInfo = () => {
	const [item] = useOutletContext();

	const supplierAttributes = [
		"businessName",
		"vatNumber:text",
		"uniqueSDICode:text",
		"fiscalCode",
		"activityStartDate:date",
		"employees",
		{
			label: 'notes',
			cssClass: 'col-12 border-bottom p-2',
			value: 'notes',
		}
	];

	const jobsAttribute = [
		"description:text",
		"amount:currency",
		"date:date"
	];



	return (
		item != null ? (
			<>
				{/* info fornitore */}
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='suppliers.item.contents.info.title' />{item.typology == '1' ? ' (Professionista)' : ' (Impresa)'}</h6>
						<div className='text-muted'><Trans i18nKey='suppliers.item.contents.info.subtitle' /></div>
					</div>
					<DetailView item={item} attributes={supplierAttributes} options={{
						transPrefix: 'models:supplier.',
						cssClass: 'col-12 col-md-6 border-bottom p-2'
					}} />
				</div>

				{/* qualifiche e z1 */}
				<div className='row g-0'>
					<DetailView
						item={item}
						attributes={[{
							label: 'models:supplier.qualifications',
							cssClass: 'col-12 col-md-6 border-bottom p-2',
							value: () => {
								return (
									<ul className={item.qualifications != null && item.qualifications.length > 0 ? '' : 'list-unstyled'}>
										{item.qualifications != null && item.qualifications.length > 0 ? (item.qualifications?.map((q, idx) => (
											<li key={idx}>{q.qualification.categoryId} - {q.qualification.description}</li>
										))) : (<li className='text-center text-body-tertiary small'>-</li>)}
									</ul>
								)
							},
							formatter: 'raw'
						},
						{
							label: 'models:supplier.construction_works',
							cssClass: 'col-12 col-md-6 border-bottom p-2',
							value: () => {
								return (
									<ul className={item.construction_works != null && item.construction_works.length > 0 ? '' : 'list-unstyled'}>
										{item.construction_works != null && item.construction_works.length > 0 ? (item.construction_works?.map((cw, idx) => (
											<li key={idx}>{cw.construction_work.categoryId} - {cw.construction_work.name}</li>
										))) : (<li className='text-center text-body-tertiary small'>-</li>)}
									</ul>
								)
							},
							formatter: 'raw'
						}]}
					/>
				</div>

				{/* incarichi svolti */}
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='models:supplier.jobs.title' /></h6>
					</div>
					<div className='col-12'>
						{item.jobs != null && item.jobs.length > 0 ? (item.jobs?.map((job, idx) => (
							<div key={idx} className='row g-0 align-items-center'>
								<DetailView
									item={job}
									attributes={[{
										label: 'construction_work',
										formatter: 'raw',
										value: job.construction_work.category + ' ' + job.construction_work.categoryId,
									}, ...jobsAttribute]}
									options={{
										transPrefix: 'models:supplier.jobs.',
										cssClass: 'col-12 col-lg-3 border-bottom p-2'
									}} />
							</div>
						))) : (<div className='text-center text-body-tertiary small'>-</div>)}
					</div>
				</div>

				{/* fatturato */}
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='models:supplier.sales.title' /></h6>
					</div>
					<div className='col-12'>
						{item.sales != null && item.sales.length > 0 ? (item.sales?.map((sale, idx) => (
							<div key={idx} className='row g-0 align-items-center'>
								<DetailView
									item={sale}
									attributes={['year:text', 'amount:currency']}
									options={{
										transPrefix: 'models:supplier.sales.',
										cssClass: 'col-12 col-md-6 border-bottom p-2',
										numberFormat: {
											currency: sale.currency
										}
									}} />
							</div>
						))) : (<div className='text-center text-body-tertiary small'>-</div>)}
					</div>
				</div>


				{/* documenti */}
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='models:supplier.documents' /></h6>
					</div>
					<div className='col-12'>
						<ul className="list-group">
							{item.documents != null && item.documents.length > 0 ? (item.documents?.map((document, idx) => (
								<li key={idx} className='list-group-item list-group-item-action'>
									<Document item={document} />
								</li>
							))) : (<div className='text-center text-body-tertiary small'>-</div>)}
						</ul>
					</div>
				</div>

				{/* sedi */}
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='models:supplier.addresses' /></h6>
					</div>
					<div className='col-12'>
						{item.addresses != null && item.addresses.length > 0 ? (item.addresses?.map((address, idx) => (
							<div key={idx} className='row g-0 align-items-center'>
								<DetailView
									item={address}
									attributes={[{
										label: 'models:address.typeOptions.' + address.type,
										cssClass: 'col-12 col-lg-4 border-bottom p-2',
										value: 'fulladdress'
									}]}
								/>
								{address.contacts?.map((contact, key) => (
									<DetailView
										key={key}
										item={contact}
										attributes={[{
											label: 'models:contact.typeOptions.' + contact.type,
											cssClass: 'col-12 col-md-6 col-lg border-bottom p-2',
											value: 'value'
										}]}
									/>
								))}
							</div>
						))) : (<div className='text-center text-body-tertiary small'>-</div>)}
					</div>
				</div>


				{/* referenti */}
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='models:supplier.references' /></h6>
					</div>
					<div className='col-12'>
						{item.references != null && item.references.length > 0 ? (item.references?.map((reference, idx) => (
							<div key={idx} className='row g-0 align-items-center'>
								<DetailView
									item={reference}
									attributes={[{
										label: reference.role,
										cssClass: 'col-12 col-lg-4 border-bottom p-2',
										value: 'fullname'
									}]}
								/>
								{reference.contacts?.map((contact, key) => (
									<DetailView
										key={key}
										item={contact}
										attributes={[{
											label: 'models:contact.typeOptions.' + contact.type,
											cssClass: 'col-12 col-md-6 col-lg border-bottom p-2',
											value: 'value'
										}]}
									/>
								))}
							</div>
						))) : (<div className='text-center text-body-tertiary small'>-</div>)}
					</div>
				</div>
			</>
		) : null
	)
}

export default SupplierInfo