import React, { createContext, useContext, useMemo, useState } from 'react'

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

	const [theme, setTheme] = useState(localStorage.getItem('theme'));
	theme && document.documentElement.setAttribute("data-bs-theme", theme);

	const toggleTheme = (th) => {
		if (th) {
			localStorage.setItem('theme', th);
			document.documentElement.setAttribute("data-bs-theme", th);
		} else {
			localStorage.removeItem('theme');
			document.documentElement.removeAttribute("data-bs-theme");
		}
		setTheme(th);
	};

	const value = useMemo(() => ({ theme, toggleTheme }), [theme]);

	return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export const useTheme = () => {
	return useContext(ThemeContext);
};