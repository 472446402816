import DetailView from 'components/DetailView/DetailView';
import Persona from 'components/Persona/Persona';
import React from 'react'
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

const ResourceInfo = () => {
	const [item] = useOutletContext();

	const profileAttributes = [
		"id",
		"codiceFiscale:text",
		"dataNascita:date",
		"age",
		{
			label: 'genere.label',
			value: () => <Trans i18nKey={'models:resource.genere.value.' + item.genere} />,
			formatter: 'raw'
		},
		"titoloStudio",
		"cellAziendale",
		"cellPersonale",
		"mailAziendale",
		"mailPersonale",
		"areaInterna",
		"funzioneTeam",
		"circle",
		"matricolaDipendente",
	];
	if (item?.iscrittoAlbo == '1') {
		profileAttributes.push("numIscrizioneAlbo", "dataIscrizioneAlbo:date");
	}

	return (
		<div className='row g-0 align-items-center'>
			{item && (
				<div className='col-12 my-2'>
					<Persona resource={item} showContact={false} />
				</div>
			)}
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='resources.item.contents.info.title' /></h6>
			</div>
			{item && <DetailView item={item} attributes={profileAttributes} options={{
				transPrefix: 'models:resource.',
				cssClass: 'col-12 col-md-6 border-bottom p-2'
			}} />}
		</div>
	)
}

export default ResourceInfo