import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React from 'react'

const ItemContent = ({ ...props }) => {
    props.overflow = props.overflow || { x: "hidden" };
    props.height = props.height || { height: 'calc(100% - 64px)' };
    props.hasOverlay = 'boolean' === typeof props.hasOverlay ? props.hasOverlay : true;

    return (
        props.children ? (props.hasOverlay ? (
            <OverlayScrollbarsComponent
                options={{
                    showNativeOverlaidScrollbars: false,
                    overflow: props.overflow,
                    scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
                }}
                defer
                style={props.height}
            >
                {props.children}
            </OverlayScrollbarsComponent>
        ) : props.children) : null
    )
}

export default ItemContent