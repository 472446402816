import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from "react-hook-form";

const Form = ({ ...props }) => {

	props.name = props.name || 'form-' + Date.now();
	props.method = props.method || 'post';
	props.enctype = props.enctype;

	const methods = useForm();

	const onSubmit = (data, ref) => {
		props.onSubmit && props.onSubmit(Object.entries(data).reduce((d, e) => (d.append(...e), d), new FormData()));

		ref.target.classList.add('was-validated');


		return true;
	};
	const onError = (inputs, ref) => {
		console.log(inputs, ref);

		ref.target.classList.add('was-validated');

		if (inputs != null) {
			let elm = document.getElementById(Object.keys(inputs)[0]);
			elm && elm.focus() && elm.scrollIntoView();
		}

	}
	//spostare il focus sull'errore
	// mostrare errrori
	//mostrare tost

	return (
		<FormProvider {...methods} >
			<form className="needs-validation" noValidate method={props.method} onSubmit={methods.handleSubmit(onSubmit, onError)} name={props.name} id={props.name} {...props.enctype} >
				{props.error && (<div className='bg-danger bg-opacity-10 text-danger mb-3 rounded p-2'>{props.error}</div>)}
				{
					React.Children.map(props.children, (child, idx) => {
						return React.isValidElement(child) ? React.cloneElement(child, { errors: methods.formState.errors, isSubmitting: methods.formState.isSubmitting, key: idx }) : child;
					})
				}

			</form>
		</FormProvider>
	)
}

export default Form