import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { isString } from 'helpers/commons';

export const PlainField = ({ ...props }) => {
	props.type = props.type || 'text';
	return (
		<div className={props.type != null && props.type != 'hidden' ? (props.cssClass != null ? props.cssClass + ' p-2' : 'p-2') : 'w-auto'}>
			<div className="mb-3">
				{props.label != null ? (<label id={props.id + '_label'} htmlFor={props.id} className="form-label fw-semibold" dangerouslySetInnerHTML={{ __html: props.label }} />) : null}
				<PlainInput {...props} />
			</div>
		</div>
	)
}

export const PlainInput = ({ ...props }) => {
	const { control } = useFormContext();

	if (props.type == 'checkbox' || props.type == 'radio') {
		props.originalValue = props.originalValue || props.value;
		props.checked = props.originalValue == '1';
		props.value = '1';
	}
	props.fieldType = props.fieldType || 'plain';
	props.attribute = props.attribute || {};
	props.dataAttribute = props.dataAttribute || {};

	return (
		<Controller
			control={control}
			name={props.formName + (props.attribute.multiple ? '[]' : '')}
			defaultValue={props.value}
			rules={{
				required: props.attribute.required || null,
				min: props.attribute.min || null,
				minLength: props.attribute.minLength || null,
				max: props.attribute.max || null,
				maxLength: props.attribute.maxLength || null,
				validate: props.attribute.validate || null,
				pattern: props.attribute.pattern || null
			}}
			render={({
				field: { onChange, onBlur, value, name, ref },
				fieldState: { invalid, isTouched, isDirty, error },
			}) =>
				props.inputGroup != null ? (
					<>
						<div className="input-group has-validation z-0">
							{props.inputGroup.before != null ? (<span className="input-group-text" dangerouslySetInnerHTML={{ __html: props.inputGroup.before }} />) : null}
							<InnerInput name={name} onChange={onChange} value={value} {...props} ref={ref} />
							{props.inputGroup.after != null ?
								isString(props.inputGroup.after) ?
									(<span className="input-group-text" dangerouslySetInnerHTML={{ __html: props.inputGroup.after }} />)
									: <span className="input-group-text">{props.inputGroup.after}</span>
								: null
							}

							<div className='invalid-feedback'>
								{error != null && <Trans i18nKey={'components:formFields.' + props.fieldType + '.error.' + error.type} values={props.attribute} />}
							</div>

						</div>
						{props.hint != null ? (<div id={props.id + '_hint'} className="form-text">{props.hint}</div>) : null}
					</>
				) : (
					<>
						<InnerInput name={name} onChange={onChange} value={value} {...props} ref={ref} />
						<div className='invalid-feedback'>
							{error != null && <Trans i18nKey={'components:formFields.' + props.fieldType + '.error.' + error.type} values={props.attribute} />}
						</div>
						{props.hint != null ? (<div id={props.id + '_hint'} className="form-text">{props.hint}</div>) : null}
					</>
				)

			}
		/>
	)
}

const InnerInput = forwardRef(({ onChange, name, value, ...props }, ref) => {
	return (<input
		type={props.type}

		name={name}
		ref={ref}
		onChange={onChange}

		id={props.id}
		className={props.inputClass != null ? props.inputClass : 'form-control'}
		placeholder={props.placeholder}
		defaultValue={value}
		defaultChecked={props.checked}
		aria-label={props.id}
		aria-describedby={props.hint != null ? (props.id + '_hint') : null}
		{...props.dataAttribute}
		{...props.attribute}

	/>)
})