import { defer } from "react-router-dom"
import AssetService from "../../services/AssetService"
import Cars from "pages/assets/cars/Cars"
import Furnishings from "pages/assets/furnishings/Furnishings"
import Guesthouses from "pages/assets/guesthouses/Guesthouses"
import Devices from "../../pages/assets/devices/Devices"
import Device from "../../pages/assets/devices/item/Device"
import DeviceEmpty from "../../pages/assets/devices/item/DeviceEmpty"
import Furniture from "pages/assets/furnishings/item/Furniture"
import FurnitureEmpty from "pages/assets/furnishings/item/FurnitureEmpty"

const service = new AssetService();
const AssetRoutes = [
	{
		path: "/assets/devices",
		handle: {
			title: 'assets.devices.title',
			crumb: null, //Breadcrumbs
		},
		loader: async ({ request }) => {
			const url = new URL(request.url);
			const search = url.searchParams.get("search");

			let queryParams = { perPage: 25, category: 'devices' };
			if (search) { queryParams.search = search; }

			return defer({ devices: service.getAll(queryParams) });
		},
		children: [
			{
				index: true,
				path: "",
				element: <DeviceEmpty />,
			},
			{
				path: "/assets/devices/:id",
				loader: async ({ params }) => {
					return defer({ device: service.getById(params.id) });
				},
				element: <Device />,
			},
		],
		element: <Devices />,
		future: {
			v7_normalizeFormMethod: true,
		},
	},
	{
		path: "/assets/cars",
		handle: {
			title: 'assets.cars.title',
			crumb: null, //Breadcrumbs
		},
		element: <Cars />,
		future: {
			v7_normalizeFormMethod: true,
		},
	},
	{
		path: "/assets/guesthouses",
		handle: {
			title: 'assets.guesthouses.title',
			crumb: null, //Breadcrumbs
		},
		element: <Guesthouses />,
		future: {
			v7_normalizeFormMethod: true,
		},
	},
	{
		path: "/assets/furnishings",
		handle: {
			title: 'assets.furnishings.title',
			crumb: null, //Breadcrumbs
		},
		loader: async ({ request }) => {
			const url = new URL(request.url);
			const search = url.searchParams.get("search");

			let queryParams = { perPage: 25, category: 'furnishings' };
			if (search) { queryParams.search = search; }

			return defer({ furnishings: service.getAll(queryParams) });
		},
		children: [
			{
				index: true,
				path: "",
				element: <FurnitureEmpty />,
			},
			{
				path: "/assets/furnishings/:id",
				loader: async ({ params }) => {
					return defer({ furniture: service.getById(params.id) });
				},
				element: <Furniture />,
			},
		],
		element: <Furnishings />,
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]

export default AssetRoutes