import { defer } from "react-router-dom";
import TenderRoutes from "./projects/TenderRoutes";
import WorkRoutes from "./projects/WorkRoutes";
import ProjectService from "services/ProjectService";
import Projects from "pages/projects/projects/Projects";
import ProjectEmpty from "pages/projects/projects/item/ProjectEmpty";
import Project from "pages/projects/projects/item/Project";
import { Error } from "components/Error/Error";
import ProjectInfo from "pages/projects/projects/item/info/ProjectInfo";

const ProjectRoutes = [
	...WorkRoutes,
	...TenderRoutes,

	{
		path: "/projects",
		element: <Projects />,
		loader: async ({ request }) => {
			const service = new ProjectService();
			return defer({ projects: service.getAll(new URL(request.url).search) });
		},
		handle: {
			title: 'projects.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				path: "",
				element: <ProjectEmpty />,
			},
			{
				path: "/projects/:id",
				element: <Project />,
				loader: ({ params }) => {
					const service = new ProjectService();
					return defer({ project: service.getById(params.id) });
				},
				handle: {
					crumb: null, //Breadcrumbs
				},
				children: [
					{
						index: true,
						path: "",
						element: <ProjectInfo />,
					}
				],
				errorElement: <Error />,
			},
		],
		future: {
			v7_normalizeFormMethod: true,
		},
	}
]

export default ProjectRoutes