export function generateBrushChart(data) {
	if (data == null || data.length == 0) {
		return null;
	}
	let maxLength = 12;// data.length > 12 ? data.length : 12; //data.length; 
	let series1 = [
		{ name: 'Ratings', data: new Array(maxLength).fill(null) },
		{ name: 'Hard Skills', data: new Array(maxLength).fill(null) },
		{ name: 'Soft Skills', data: new Array(maxLength).fill(null) },
		{ name: 'Mood', data: new Array(maxLength).fill(null) },
		{ name: 'HR', data: new Array(maxLength).fill(null) }
	];
	let series2 = [{ name: 'Surveys', data: new Array(maxLength).fill(0) }];

	let firstDate = new Date(data[0].data);
	firstDate.setDate(1);
	firstDate.getMinutes(0);
	firstDate.setHours(0);

	let labels = Array.from({ length: maxLength }, (item, i) => {
		let date = new Date(firstDate);
		date.setMonth(i);
		return date.getTime();
	});

	data.forEach(row => {
		let month = new Date(row.data).getMonth();
		series1[0].data[month] = row.rating_value;
		series1[1].data[month] = row.hard_skill_value;
		series1[2].data[month] = row.soft_skill_value;
		series1[3].data[month] = row.mood_value;
		series1[4].data[month] = row.hr_value;

		series2[0].data[month] = row.surveys;
	});

	let colors = ["#ffc107", "#ff5722", "#2196f3", "#4caf50", "#00bcd4"];

	let options1 = {
		title: {
			text: 'Storico delle valutazioni (' + firstDate.getFullYear() + ')',
			align: 'center',
			style: {
				color: '#000',
			}
		},
		chart: {
			id: "chart1",
			type: "area",
			height: 250,
			foreColor: "#ccc",
			toolbar: {
				autoSelected: "pan",
				show: false
			},
			events: {
				mounted: function (chartContext, config) {
					chartContext.hideSeries('Hard Skills');
					chartContext.hideSeries('Soft Skills');
					chartContext.hideSeries('Mood');
					chartContext.hideSeries('HR');
				},
			}
		},
		colors: colors,
		stroke: {
			width: 2
		},
		grid: {
			borderColor: "#555",
			clipMarkers: false,
			yaxis: {
				lines: {
					show: false
				}
			}
		},
		dataLabels: {
			enabled: false
		},
		fill: {
			gradient: {
				enabled: true,
				opacityFrom: 0.55,
				opacityTo: 0
			}
		},
		markers: {
			size: 2,
			colors: colors,
			strokeColor: colors,
			// strokeWidth: 2
		},
		tooltip: {
			x: {
				// format: "MMM yyyy",
				formatter: function (value) {
					return new Date(value).toLocaleString('it-IT', { month: 'long', year: 'numeric' })
				}
			}
		},
		xaxis: {
			type: "datetime",
			formatter: function (value, timestamp, opts) {
				return opts.dateFormatter(new Date(timestamp)).format("MMM yyyy");
			}
		},
		yaxis: [
			{ min: 0, max: 5, tickAmount: 5, seriesName: 'Ratings', showAlways: true, decimalsInFloat: 2 },
			{ min: 0, max: 5, tickAmount: 5, seriesName: 'Hard Skills', show: false, decimalsInFloat: 2 },
			{ min: 0, max: 5, tickAmount: 5, seriesName: 'Soft Skills', show: false, decimalsInFloat: 2 },
			{ min: 0, max: 5, tickAmount: 5, seriesName: 'Mood', show: false, decimalsInFloat: 2 },
			{ min: 0, max: 5, tickAmount: 5, seriesName: 'HR', show: false, decimalsInFloat: 2 },
		],
		labels: labels,
	};

	let options2 = {
		chart: {
			id: "chart2",
			height: 130,
			type: "bar",
			foreColor: "#ccc",
			brush: {
				target: "chart1",
				enabled: true
			},
			selection: {
				enabled: true,
				fill: {
					color: "#fff",
					opacity: 0.4
				},
				xaxis: {
					min: labels[0],
					max: labels[labels.length - 1],
				}
			}
		},
		colors: ["#4695a2"],
		stroke: {
			width: 2
		},
		xaxis: {
			type: "datetime",
			formatter: function (value, timestamp, opts) {
				return opts.dateFormatter(new Date(timestamp)).format("MMM yyyy");
			},
			tooltip: {
				enabled: false
			}
		},
		yaxis: {
			tickAmount: 2,
			decimalsInFloat: 2
		},
		labels: labels,
		legend: {
			show: false,
		}
	};

	return [
		{ id: 'resource-evaluations-chart-2', type: 'area', series: series1, options: options1 },
		{ id: 'resource-evaluations-chart-3', type: 'bar', series: series2, options: options2 }
	];
};


export function generateRadarChart(selected, current, idx = '1') {
	if (selected == null || current == null) {
		return null;
	}

	let labels = [
		'Valutazione del ' + new Date(selected.datasets[selected.datasets.length - 1].data).getFullYear(),
		'Ultima valutazione (' + new Date(current.datasets[current.datasets.length - 1].data).toLocaleString('it-IT', { month: 'short', year: 'numeric' }) + ')'
	];

	let data = { 0: [], 1: [] };
	data[0].push(selected.rating_avg);
	data[0].push(selected.hard_skill_avg);
	data[0].push(selected.soft_skill_avg);
	data[0].push(selected.hr_avg);
	data[0].push(selected.mood_avg);

	data[1].push(current.datasets[current.datasets.length - 1].rating_value);
	data[1].push(current.datasets[current.datasets.length - 1].hard_skill_value);
	data[1].push(current.datasets[current.datasets.length - 1].soft_skill_value);
	data[1].push(current.datasets[current.datasets.length - 1].hr_value);
	data[1].push(current.datasets[current.datasets.length - 1].mood_value);

	let series = [
		{
			name: labels[0],
			data: data[0]
		},
		{
			name: labels[1],
			data: data[1]
		}
	];

	let colors = ['#4695a2', '#ff9800'];

	let options = {
		chart: {
			height: 350,
			type: 'radar',
			toolbar: {
				show: false,
				tools: {
					download: false
				},
			},
		},
		dataLabels: {
			enabled: false
		},
		plotOptions: {
			radar: {
				size: 140,
				polygons: {
					strokeColors: '#e9e9e9',
					fill: {
						colors: ['#f8f8f8', '#fff']
					}
				}
			}
		},
		colors: colors,
		tooltip: {
			y: {
				formatter: function (val) {
					return val != null ? val.toFixed(2) : 'Nessun Valore';
				}
			}
		},
		xaxis: {
			categories: ['Rating', 'Hard skills', 'Soft skills', 'HR', 'Mood']
		},
		yaxis: {
			min: 0, max: 5, tickAmount: 5, show: true,
			labels: {
				formatter: function (val, i) {
					if (i % 2 === 0) {
						return val
					} else {
						return ''
					}
				}
			}
		}
	};

	return {
		id: 'resource-evaluations-chart-' + idx,
		type: 'radar',
		series: series,
		options: options
	};
};