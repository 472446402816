import i18next from "i18next";

export const hoursReport = (data, workname, filename) => {
	let series = [
		{
			type: 'bar',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.hours.labels.baseline_hours'),
			data: []
		},
		{
			type: 'bar',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.hours.labels.actual_hours'),
			data: []
		},
		{
			type: 'bar',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.hours.labels.replanned_hours'),
			data: []
		},
		{
			type: 'line',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.hours.labels.baseline_hours_comulate'),
			data: []
		},
		{
			type: 'line',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.hours.labels.replanned_hours_comulate'),
			data: []
		},
	];

	let options = {
		chart: {
			type: 'bar',
			stacked: false,
			toolbar: {
				export: {
					csv: {
						filename: i18next.t('views:works.item.contents.schedules.reports.charts.hours.filename', { workname: workname, filename: filename }),
						columnDelimiter: ';'
					}
				}
			}
		},
		labels: [],
		title: {
			text: i18next.t("views:works.item.contents.schedules.reports.charts.hours.title"),
			align: 'center',
			style: {
				fontSize: '11px',
				fontWeight: '600',
				color: '#212529',
			}
		},
		yaxis: [
			{
				show: true,
				showAlways: true,
				showForNullSeries: true,
				title: {
					text: i18next.t('views:works.item.contents.schedules.reports.charts.hours.yaxis'),
					style: {
						fontSize: '11px',
						fontWeight: '600',
						color: '#212529',
					}
				},
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: true,
				showAlways: true,
				showForNullSeries: true,
				title: {
					text: i18next.t('views:works.item.contents.schedules.reports.charts.hours.yaxis'),
					style: {
						fontSize: '11px',
						fontWeight: '600',
						color: '#212529',
					}
				},
				opposite: true,
				min: 0,
				max: 0,
			},
		],
		// xaxis: {
		// 	type: 'category'
		// },
		stroke: {
			width: 2,
			show: true,
			colors: [
				'transparent', //BASLINE
				'transparent', //ACTUAL HOUR
				'transparent', //REPLANED HOUR
				'rgba(96,125,139,1)', //BASLINE CUMULATIVA
				'rgba(67,160,71,1)' //COMULATIVA ACTUAL
			]
		},
		colors: [
			'rgba(96,125,139,0.6)', //BASLINE
			'rgba(67,160,71,0.6)', //ACTUAL HOUR
			'rgba(67,71,160,0.6)', //REPLANED HOUR
			'rgba(96,125,139,1)', //BASLINE CUMULATIVA
			'rgba(67,160,71,1)', //COMULATIVA ACTUAL
		],

		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '75%',
				endingShape: 'rounded'
			}
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0
		},
		tooltip: {
			'shared': true,
			'intersect': false,
		}
	}

	data.forEach((item) => {
		//popolo le ordinate
		options.yaxis[0].max = Math.max(parseFloat(options.yaxis[0].max), parseFloat(item.baselineHour));
		options.yaxis[1].max = Math.max(parseFloat(options.yaxis[1].max), parseFloat(item.actualHour));
		options.yaxis[2].max = Math.max(parseFloat(options.yaxis[2].max), parseFloat(item.replannedHour));
		options.yaxis[3].max = Math.max(parseFloat(options.yaxis[3].max), parseFloat(item.baselineHourCumulate), parseFloat(item.actualReplHourCumulate));
		options.yaxis[4].max = Math.max(parseFloat(options.yaxis[4].max), parseFloat(item.baselineHourCumulate), parseFloat(item.actualReplHourCumulate));

		//label
		let key = options.labels.indexOf(item.month + '-' + item.year) > -1;
		if (key == -1) {
			options.labels.push(item.month + '-' + item.year);
		}

		//popolo la serie
		series[0].data.push(item.baselineHour || 0);
		series[1].data.push(item.actualHour || 0);
		series[2].data.push(item.replannedHour || 0);
		series[3].data.push(item.baselineHourCumulate || 0);
		series[4].data.push(item.actualReplHourCumulate || 0);


	});

	options.yaxis[0].max += (options.yaxis[0].max / 100) * 10;
	options.yaxis[1].max += (options.yaxis[1].max / 100) * 10;
	options.yaxis[2].max += (options.yaxis[2].max / 100) * 10;
	options.yaxis[3].max += (options.yaxis[3].max / 100) * 10;
	options.yaxis[4].max += (options.yaxis[4].max / 100) * 10;

	return {
		id: 'hour-chart-1',
		type: 'bar',
		series: series,
		options: options
	};

}

export const costReport = (data, workname, filename) => {
	let series = [
		{
			type: 'bar',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.baseline_total_cost_cumulate'),
			data: []
		},
		{
			type: 'bar',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.actual_total_cost'),
			data: []
		},
		{
			type: 'bar',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.baseline_revenue'),
			data: []
		},
		{
			type: 'bar',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.actual_revenue'),
			data: []
		},
		{
			type: 'bar',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.replanned_total_cost'),
			data: []
		},
		{
			type: 'bar',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.replanned_revenue'),
			data: []
		},
		{
			type: 'line',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.baseline_total_cost_cumulate'),
			data: []
		},
		{
			type: 'line',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.actual_replanned_total_cost_cumulate'),
			data: []
		},
		{
			type: 'line',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.baseline_revenue_cumulate'),
			data: []
		},
		{
			type: 'line',
			name: i18next.t('views:works.item.contents.schedules.reports.charts.cost.labels.actual_replanned_revenue_cumulate'),
			data: []
		},
	];

	let options = {
		chart: {
			type: 'bar',
			stacked: false,
			toolbar: {
				export: {
					csv: {
						filename: i18next.t('views:works.item.contents.schedules.reports.charts.cost.filename', { workname: workname, filename: filename }),
						columnDelimiter: ';'
					}
				}
			}
		},
		labels: [],
		title: {
			text: i18next.t("views:works.item.contents.schedules.reports.charts.cost.title"),
			align: 'center',
			style: {
				fontSize: '11px',
				fontWeight: '600',
				color: '#212529',
			}
		},
		yaxis: [
			{
				show: true,
				showAlways: true,
				showForNullSeries: true,
				title: {
					text: i18next.t('views:works.item.contents.schedules.reports.charts.cost.yaxis'),
					style: {
						fontSize: '11px',
						fontWeight: '600',
						color: '#212529',
					}
				},
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: false,
				min: 0,
				max: 0,
			},
			{
				show: true,
				showAlways: true,
				showForNullSeries: true,
				title: {
					text: i18next.t('views:works.item.contents.schedules.reports.charts.cost.yaxis'),
					style: {
						fontSize: '11px',
						fontWeight: '600',
						color: '#212529',
					}
				},
				opposite: true,
				min: 0,
				max: 0,
			},
		],
		// xaxis: {
		// 	type: 'category'
		// },
		stroke: {
			width: 2,
			show: true,
			colors: [
				'transparent', // Baseline Total Cost
				'transparent', // Actual Hour Cost
				'transparent', // Baseline Revenue
				'transparent', // Actual Revenue
				'transparent', // Replanned Total Cost
				'transparent', // Replanned Revenue
				'rgba(86,177,202,1)', // Baseline Total Cost Cumulata
				'rgba(247,150,70,1)', // Actual Replanned Total Cost Cumulata
				'rgba(155,187,89,1)', // Baseline Revenue Cumulata
				'rgba(128,100,162,1)' // Actual Replanned Revenue Cumulata
			]
		},
		colors: [
			'rgba(86,177,202,0.6)', // Baseline Total Cost
			'rgba(247,150,70,1)', // Actual Hour Cost
			'rgba(155,187,89,0.6)', // Baseline Revenue
			'rgba(128,100,162,1)', // Actual Revenue
			'rgba(100,129,80,0.6)', // Replanned Total Cost
			'rgba(190,800,130,1)', // Replanned Revenue
			'rgba(86,177,202,0.6)', // Baseline Total Cost Cumulata
			'rgba(247,150,70,1)', // Actual Replanned Total Cost Cumulata
			'rgba(155,187,89,0.6)', // Baseline Revenue Cumulata
			'rgba(128,100,162,1)', // Actual Replanned Revenue Cumulata
		],

		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '75%',
				endingShape: 'rounded'
			}
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0
		},
		tooltip: {
			'shared': true,
			'intersect': false,
		}
	}

	data.forEach((item) => {
		//popolo le ordinate
		options.yaxis[0].max = Math.max(parseFloat(options.yaxis[0].max), parseFloat(item.baselineTotalCost));
		options.yaxis[1].max = Math.max(parseFloat(options.yaxis[1].max), parseFloat(item.actualTotalCost));
		options.yaxis[2].max = Math.max(parseFloat(options.yaxis[2].max), parseFloat(item.baselineRevenue));
		options.yaxis[3].max = Math.max(parseFloat(options.yaxis[3].max), parseFloat(item.actualRevenue));
		options.yaxis[4].max = Math.max(parseFloat(options.yaxis[4].max), parseFloat(item.replannedTotalCost));
		options.yaxis[5].max = Math.max(parseFloat(options.yaxis[5].max), parseFloat(item.replannedRevenue));

		options.yaxis[6].max = Math.max(parseFloat(options.yaxis[6].max), parseFloat(item.baselineTotalCostCumulate), parseFloat(item.actualReplTotalCostCumulate), parseFloat(item.baselineRevenueCumulate), parseFloat(item.actualReplRevenueCumulate));
		options.yaxis[7].max = Math.max(parseFloat(options.yaxis[7].max), parseFloat(item.baselineTotalCostCumulate), parseFloat(item.actualReplTotalCostCumulate), parseFloat(item.baselineRevenueCumulate), parseFloat(item.actualReplRevenueCumulate));
		options.yaxis[8].max = Math.max(parseFloat(options.yaxis[8].max), parseFloat(item.baselineTotalCostCumulate), parseFloat(item.actualReplTotalCostCumulate), parseFloat(item.baselineRevenueCumulate), parseFloat(item.actualReplRevenueCumulate));
		options.yaxis[9].max = Math.max(parseFloat(options.yaxis[9].max), parseFloat(item.baselineTotalCostCumulate), parseFloat(item.actualReplTotalCostCumulate), parseFloat(item.baselineRevenueCumulate), parseFloat(item.actualReplRevenueCumulate));


		//label
		let key = options.labels.indexOf(item.month + '-' + item.year) > -1;
		if (key == -1) {
			options.labels.push(item.month + '-' + item.year);
		}

		//popolo la serie
		series[0].data.push(item.baselineTotalCost || 0);
		series[1].data.push(item.actualTotalCost || 0);
		series[2].data.push(item.baselineRevenue || 0);
		series[3].data.push(item.actualRevenue || 0);
		series[4].data.push(item.replannedTotalCost || 0);
		series[5].data.push(item.replannedRevenue || 0);
		series[6].data.push(item.baselineTotalCostCumulate || 0);
		series[7].data.push(item.actualReplTotalCostCumulate || 0);
		series[8].data.push(item.baselineRevenueCumulate || 0);
		series[9].data.push(item.actualReplRevenueCumulate || 0);

	});

	options.yaxis[0].max += (options.yaxis[0].max / 100) * 10;
	options.yaxis[1].max += (options.yaxis[1].max / 100) * 10;
	options.yaxis[2].max += (options.yaxis[2].max / 100) * 10;
	options.yaxis[3].max += (options.yaxis[3].max / 100) * 10;
	options.yaxis[4].max += (options.yaxis[4].max / 100) * 10;
	options.yaxis[5].max += (options.yaxis[5].max / 100) * 10;
	options.yaxis[6].max += (options.yaxis[6].max / 100) * 10;
	options.yaxis[7].max += (options.yaxis[7].max / 100) * 10;
	options.yaxis[8].max += (options.yaxis[8].max / 100) * 10;
	options.yaxis[9].max += (options.yaxis[9].max / 100) * 10;

	return {
		id: 'cost-chart-1',
		type: 'bar',
		series: series,
		options: options
	};

}