import it from "apexcharts/dist/locales/it.json"

/**
 * Genero le impostazioni per il grafico con ApexChart
 * @param {int} id 
 * @param {string} type 
 * @param {array} series 
 * @param {object} options 
 * @returns 
 */
export const generateChart = (id, type, series, options) => {
    let chart = {
        wigth: options.chart != null && options.chart.wigth ? options.chart.wigth : 500,
        height: options.chart != null && options.chart.height ? options.chart.height : 400,
        series: [],
        options: {
            chart: {
                zoom: { enabled: false },
                locales: [it],
                defaultLocale: 'it',
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    },
                },
            },
            xaxis: {},
            yaxis: { labels: { show: false } },
            labels: [],
            dataLabels: {
                bar: {
                    distributed: true,
                    horizontal: false,
                    barHeight: "75%",
                    dataLabels: { position: "bottom" }
                }
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    horizontal: false,
                    barHeight: "75%",
                    dataLabels: { position: "bottom" }
                }
            },
            stroke: {},
            states: {},
            tooltip: {},
            title: {},
            legend: {},
            noData: {
                text: 'Dati non sufficienti per visualizzare il grafico'
            }
        }
    };

    chart.id = id;
    chart.type = type || null;
    chart.series = series || [];

    Object.assign(options.chart, chart.options.chart); //make deep copy of chart properties
    Object.assign(chart.options, options);

    if (chart.options.chart.id == null) {
        chart.options.chart.id = id;
    }

    return chart;
};