import { createBrowserRouter } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import LegacyRoutes from './LegacyRoutes';
import ProtectedRoutes from './ProtectedRoutes';

const Routes = createBrowserRouter([
	...PublicRoutes, ...ProtectedRoutes, ...LegacyRoutes
],
	{
		future: {
			v7_normalizeFormMethod: true,
		}
	}
);

export default Routes