import { ReactGrid } from '@silevis/reactgrid';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { useEffect, useState } from 'react';

const textCell = (text, className = "", style) => ({ type: "text", text, className, style });

const numberCell = (value, className = "", style) => ({
	type: "number",
	value,
	className,
	style,
	format: new Intl.NumberFormat("it-IT", { style: 'unit', unit: 'hour' }),
});

const nonEditable = (cell) => ({
	...cell,
	nonEditable: true
});


const getColumns = () => [
	{ columnId: "n", width: 30, resizable: true },
	{ columnId: "idWbs", width: 200 },
	{ columnId: "idProfile", width: 200 },
	{ columnId: "01", width: 120 },
	{ columnId: "02", width: 120 },
	{ columnId: "03", width: 120 },
	{ columnId: "04", width: 120 },
	{ columnId: "05", width: 120 },
	{ columnId: "06", width: 120 },
	{ columnId: "07", width: 120 },
	{ columnId: "08", width: 120 },
	{ columnId: "09", width: 120 },
	{ columnId: "10", width: 120 },
	{ columnId: "11", width: 120 },
	{ columnId: "12", width: 120 },
	{ columnId: "total", width: 150 },
];

const headerRow = {
	rowId: "header",
	height: 40,
	cells: [
		{ type: "header", text: "N°", className: 'justify-content-strat fw-bold' },
		{ type: "header", text: "Wbs", className: 'justify-content-strat fw-bold' },
		{ type: "header", text: "profile", className: 'justify-content-strat fw-bold' },
		// { type: "header", text: "month.1", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.2", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.3", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.4", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.5", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.6", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.7", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.8", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.9", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.10", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.11", className: 'justify-content-center fw-bold' },
		// { type: "header", text: "month.12", className: 'justify-content-center fw-bold' },


		{ type: "header", text: "Gennaio 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Febbraio 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Marzo 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Aprile 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Maggio 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Giugno 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Luglio 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Agosto 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Settembre 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Ottobre 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Novembre 2024", className: 'justify-content-center fw-bold' },
		{ type: "header", text: "Dicembre 2024", className: 'justify-content-center fw-bold' },

		{ type: "header", text: "total", className: 'justify-content-end fw-bold' }
	]
};

const totalRow = (addItem) => {
	return {
		rowId: "total",
		height: 30,
		cells: [
			{ type: "header", text: '+', className: '', onClick: () => addItem() },
			{
				type: "header", text: 'Total',
				colspan: 2,
				className: 'fw-bold'
			},
			{ type: 'header', text: '' },
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
			nonEditable(numberCell(0, "fw-bold disabled")),
		]
	};
};

const getRows = (items = [], addItem) => [
	headerRow,
	...items.map((plan, idx) => ({
		rowId: plan.id,
		height: 30,
		cells: [
			{ type: "header", text: (idx + 1).toString() },
			{
				type: "dropdown",
				values: [
					{ label: 'Sviluppo', value: '0' },
					{ label: 'Progettazione', value: '12' },
					{ label: 'Collaudo', value: '13' },
					{ label: 'Test', value: '14' },
				],
				selectedValue: plan.wbs,
				inputValue: plan.idWbs,
				isOpen: plan.isWbsOpen,
				// isDisabled: false,
			},
			{
				type: "dropdown",
				values: [
					{ label: 'Sviluppatore Senior', value: '0' },
					{ label: 'Sviluppatore Junior', value: '2' },
					{ label: 'Sistemista Senior', value: '3' },
					{ label: 'Operatore help desk', value: '4' },
				],
				selectedValue: plan.profile,
				inputValue: plan.idProfile,
				isOpen: plan.isProfileOpen,
				// isDisabled: false,
			},
			numberCell(0),
			numberCell(0),
			numberCell(0),
			numberCell(0),
			numberCell(0),
			numberCell(0),
			numberCell(0),
			numberCell(0),
			numberCell(0),
			numberCell(0),
			numberCell(0),
			numberCell(0),
			nonEditable(numberCell(0, "fw-bold disabled")),
		]
	})),
	totalRow(addItem)
];

const applyChanges = (changes, oldItems) => {
	changes.forEach((change) => {
		const item = oldItems.find((i) => i.id === change.rowId);

		if (change.type === "dropdown") {
			if (change.previousCell.isOpen !== change.newCell.isOpen) {
				item['isWbsOpen'] = change.columnId == 'idWbs' ? change.newCell.isOpen : null;
				item['isProfileOpen'] = change.columnId == 'idProfile' ? change.newCell.isOpen : null;

				if (change.previousCell.selectedValue !== change.newCell.selectedValue) {
					// setSelectedValue(change.newCell.selectedValue);
					item[change.columnId] = change.newCell.selectedValue;
					item['wbs'] = change.columnId == 'idWbs' ? change.newCell.inputValue : null;
					item['profile'] = change.columnId == 'idProfile' ? change.newCell.inputValue : null;
				}
			}
		} else {
			item[change.columnId] = change.newCell.text;
		}

	});
	return [...oldItems];
};

const Profiles = ({ data }) => {
	const [items, setItems] = useState(data);
	const [columns, setColumns] = useState(getColumns());

	const addItem = () => {
		setItems([...items, { id: 0, idWbs: "0", idProfile: "0", profile: "", wbs: "", isWbsOpen: false, isProfileOpen: false }]);
	}

	const [rows, setRows] = useState(getRows(items, addItem));

	useEffect(() => {
		setRows(getRows(items, addItem))
	}, [items])


	const handleChanges = (changes) => {
		setItems((oldItems) => { return applyChanges(changes, oldItems) });
	};

	// const handleChanges = (changes) => {
	// 	changes.forEach((change) => {
	// 		if (change.type === "dropdown") {
	// 			if (change.previousCell.isOpen !== change.newCell.isOpen) {
	// 				//   setIsDropdownOpened(change.newCell.isOpen);
	// 				if (change.previousCell.selectedValue !== change.newCell.selectedValue) {
	// 					// setSelectedValue(change.newCell.selectedValue);
	// 					setItems((oldItems) =>{
	// 						oldItems.find((item) =>{
	// 							item.
	// 						})
	// 					});
	// 				}
	// 			}
	// 		}
	// 	});
	// };

	const simpleHandleContextMenu = (
		selectedRowIds,
		selectedColIds,
		selectionMode,
		menuOptions
	) => {
		return menuOptions;
	}

	const handleColumnResize = (ci, width) => {
		setColumns((prevColumns) => {
			const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
			const resizedColumn = prevColumns[columnIndex];
			const updatedColumn = { ...resizedColumn, width };
			prevColumns[columnIndex] = updatedColumn;
			return [...prevColumns];
		});
	}

	return (
		<>
			<OverlayScrollbarsComponent
				options={{
					showNativeOverlaidScrollbars: false,
					overflow: { x: 'scroll', y: 'scroll' },
					scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
				}}
				defer
				style={{ height: '100%' }}
			>
				<ReactGrid
					rows={rows}
					columns={columns}
					onCellsChanged={handleChanges}
					onContextMenu={simpleHandleContextMenu}
					onColumnResized={handleColumnResize}
					stickyLeftColumns={1}
					stickyRightColumns={1}
					stickyTopRows={1}
					enableRowSelection
					enableColumnSelection
					enableFillHandle
				// enableFullWidthHeader
				/>
			</OverlayScrollbarsComponent>
		</>
	)
}

export default Profiles