import { defer } from "react-router-dom";
import Api from "pages/documentations/api/Api";
import Wiki from "pages/documentations/wiki/Wiki";
import Procedures from "pages/documentations/procedures/Procedures";
import ProcedureService from "services/ProcedureService";

const service = new ProcedureService();
const DocumentationRoutes = [
	{
		path: "/documentations/wiki",
		element: <Wiki />,
		handle: {
			title: 'wiki.title',
			crumb: null, //Breadcrumbs
		},
		future: {
			v7_normalizeFormMethod: true,
		},
	},
	{
		path: "/documentations/api",
		element: <Api />,
		handle: {
			title: 'api.title',
			crumb: null, //Breadcrumbs
		},
		future: {
			v7_normalizeFormMethod: true,
		},
	},
	{
		path: "/documentations/procedures",
		element: <Procedures />,
		loader: async ({ request }) => {
			return defer({ procedures: service.getAll(new URL(request.url).search) });
		},
		handle: {
			title: 'procedures.title',
			crumb: null, //Breadcrumbs
		},
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]

export default DocumentationRoutes