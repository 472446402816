import React from 'react'
import { Circle, LayersControl, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'helpers/leaflet-icon';
import 'leaflet/dist/leaflet.css';
import { Trans } from 'react-i18next';
import Moment from 'react-moment';
import DetailView from 'components/DetailView/DetailView';

const BadgeMap = ({ badge, ...props }) => {

	const userIcon = new L.icon.glyph({
		prefix: 'mdi',
		glyph: 'account'
	});

	const locationIcon = new L.icon.glyph({
		prefix: 'mdi',
		glyph: 'home-city'
	});

	const onMapReady = (map) => {
		setTimeout(() => {
			map.invalidateSize();
		}, 0);
	}

	const attributes = [
		'data:date-short',
		'entrata',
		'uscita',
		'worktime',
		{
			label: 'location',
			value: 'location.fulladdress',
			cssClass: 'col-12 col-lg-8 border-bottom p-2'
		},
		{
			label: 'distance.label',
			value: () => badge.distance ? <Trans i18nKey='models:badge.distance.value' values={{ distance: badge.distance, precision: badge.precisione }} /> : '-',
			formatter: 'raw',
			cssClass: 'col-12 col-lg-4 border-bottom p-2'
		},
		{
			label: 'quality.label',
			value: () => <><Trans i18nKey={'models:badge.quality.value.' + badge.quality} />  ({badge.connessione})</>,
			formatter: 'raw',
			cssClass: 'col-12 col-lg-6 border-bottom p-2'
		},
		{
			label: 'ip',
			value: 'ip',
			cssClass: 'col-12 col-lg-6 border-bottom p-2'
		},
		{
			label: 'userAgent',
			value: 'userAgent',
			cssClass: 'col-12 p-2'
		},
	]

	return (
		<div className='row g-0 align-items-center'>
			<DetailView item={badge} attributes={attributes} options={{
				transPrefix: 'models:badge.',
				cssClass: 'col-12 col-md-6 col-lg-3 border-bottom p-2'
			}} />
			<div className='col-12 p-2'>
				<MapContainer
					center={[badge.lat, badge.lng]}
					zoom={18}
					scrollWheelZoom={false}
					whenReady={(e) => onMapReady(e.target)}
					style={{ height: '300px' }}
					className='border rounded'

				>
					<TileLayer
						attribution='© MapTile - © OpenStreetMap'
						url="https://api.maptiler.com/maps/pastel/{z}/{x}/{y}@2x.png?key=5mjCKW6x09xxz4euArRP"
						tileSize={512}
						zoomOffset={-1}
						minZoom={1}
						crossOrigin={true}
					/>
					<LayersControl position="topright">
						<LayersControl.Overlay name='User' checked={true}>
							<Marker name='User' position={[badge.lat, badge.lng]} icon={userIcon}>
								<Popup>
									Questa posizione è stata registrata alle : {badge.entrata}
								</Popup>
							</Marker>
						</LayersControl.Overlay>
						<LayersControl.Overlay name='Circle' checked={true}>
							<Circle center={[badge.lat, badge.lng, badge.precisione]} pathOptions={{ fillColor: '#2c80cc', fillOpacity: 0.5, weight: 1, color: 'blue' }} radius={badge.precisione} />
						</LayersControl.Overlay>
						{badge?.location && (
							<LayersControl.Overlay name='Location' checked={true}>
								<Marker position={[badge.location.lat, badge.location.lng]} icon={locationIcon}>
									<Popup>
										{badge?.location?.fulladdress}
									</Popup>
								</Marker>
							</LayersControl.Overlay>
						)}
					</LayersControl>
				</MapContainer>
			</div>
		</div>
	)
}

export default BadgeMap