import Avatar from 'components/Avatar/Avatar';
import BadgeItem from 'components/Badge/BadgeItem';
import ListAwait from 'components/List/ListAwait';
import moment from 'moment';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useLoaderData, useOutletContext } from 'react-router-dom';

const ProfileBadges = () => {
	const [item] = useOutletContext(); //user
	let { badges } = useLoaderData();

	const [items, setItems] = useState();
	const [hasMore, setHasMore] = useState(false);

	const handleLoad = (response) => {
		setItems(oldItems => [...oldItems, ...response.data]);
		setHasMore(response.next_page_url);
	};

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (items == null || items.length == 0) {
			setItems(response.data.data);
			setHasMore(response.data.next_page_url);
		}
	}

	let date;
	const DateDivider = ({ badgeDate }) => {
		let currDate = moment(badgeDate).format('DD/MM/YYYY');
		if (currDate != date) {
			date = currDate;
			return (<li className='list-group-item bg-light p-1 text-center'><span className='fw-semibold'>{currDate}</span></li>)
		}
		return null;
	}

	return (
		<div className='row g-0 align-items-center'>
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='profile.item.contents.badges.title' /></h6>
				<div className='text-body-ternary'><Trans i18nKey='profile.item.contents.badges.subtitle' /></div>
			</div>

			<div className='col-12'>
				<div className='bg-warning-subtle rounded p-2 text-warning mb-2 text-center'>
					<Trans i18nKey='profile.item.contents.badges.alert' />
				</div>
			</div>

			<div className='col-12 rounded border'>
				<ListAwait resolvePromise={badges} hasResolved={resolvedPromise} loadMore={handleLoad} hasMore={hasMore}>
					{items != null && items.length > 0 ? (items?.map((badge, idx) => (
						<React.Fragment key={idx}>
							<DateDivider badgeDate={badge.data} />
							<BadgeItem badge={badge} />
						</React.Fragment>
					))) : (<div className='text-center text-body-tertiary small'>
						<Trans i18nKey='profile.item.contents.badges.no_badge' />
					</div>)}
				</ListAwait>
			</div>
		</div >
	)
}

export default ProfileBadges