import React, { createContext, forwardRef, useMemo, useState } from 'react'
import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useClick,
	useDismiss,
	useRole,
	useInteractions,
	useMergeRefs,
	Placement,
	FloatingPortal,
	FloatingFocusManager,
	useId,
	useHover,
	safePolygon
} from "@floating-ui/react";
import { motion } from 'framer-motion';

export function usePopover({ initialOpen = false, placement = "bottom", modal, open: controlledOpen, onOpenChange: setControlledOpen }) {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
	const [labelId, setLabelId] = useState();
	const [descriptionId, setDescriptionId] = useState();

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data = useFloating({
		placement, open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(5),
			flip({
				crossAxis: placement.includes("-"),
				fallbackAxisSideDirection: "end",
				padding: 5
			}),
			shift({ padding: 5 })
		]
	});

	const context = data.context;

	//TODO: fare in modo che o si usi il click o l'hover

	const click = useClick(context, {
		enabled: controlledOpen == null
	});

	const hover = useHover(context, {
		handleClose: safePolygon({
			requireIntent: false,
		}),
	});

	const dismiss = useDismiss(context);
	const role = useRole(context);

	const interactions = useInteractions([click, dismiss, role, hover]);

	return useMemo(() => ({
		open,
		setOpen,
		...interactions,
		...data,
		modal,
		labelId,
		descriptionId,
		setLabelId,
		setDescriptionId
	}),
		[open, setOpen, interactions, data, modal, labelId, descriptionId]
	);
}

const PopoverContext = createContext(null);

export const usePopoverContext = () => {
	const context = React.useContext(PopoverContext);

	if (context == null) {
		throw new Error("Popover components must be wrapped in <Popover />");
	}

	return context;
};

export const Popover = ({ children, modal = false, ...restOptions }) => {
	// This can accept any props as options, e.g. `placement`,
	// or other positioning options.
	const popover = usePopover({ modal, ...restOptions });
	return (
		<PopoverContext.Provider value={popover}>
			{children}
		</PopoverContext.Provider>
	);
}

export const PopoverButton = forwardRef(({ children, asChild = false, ...props }, propRef) => {
	const context = usePopoverContext();
	const childrenRef = children.ref;
	const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

	// `asChild` allows the user to pass any element as the anchor
	if (asChild && React.isValidElement(children)) {
		return React.cloneElement(children, context.getReferenceProps({ ref, ...props, ...children.props, "data-state": context.open ? "open" : "closed" }));
	}

	return (
		<motion.button ref={ref} type="button" whileTap={{ scale: 0.8 }}
			// The user can style the trigger based on the state
			data-state={context.open ? "open" : "closed"}
			{...context.getReferenceProps(props)}
		>
			{children}
		</motion.button>
	);
});

export const PopoverContent = forwardRef(({ style, ...props }, propRef) => {
	const { context: floatingContext, ...context } = usePopoverContext();
	const ref = useMergeRefs([context.refs.setFloating, propRef]);

	if (!floatingContext.open) return null;

	return (
		<FloatingPortal>
			<FloatingFocusManager context={floatingContext} modal={context.modal}>
				<div
					ref={ref}
					style={{ ...context.floatingStyles, ...style }}
					aria-labelledby={context.labelId}
					aria-describedby={context.descriptionId}
					{...context.getFloatingProps(props)}
				>
					{props.children}
				</div>
			</FloatingFocusManager>
		</FloatingPortal >
	);
});

export const PopoverHeader = (props) => {
	return (<div className='popover-header'>{props.children}</div>)
}

export const PopoverBody = (props) => {
	return (<div className='popover-body'>{props.children}</div>)
}