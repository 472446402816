import React, { useMemo } from 'react';
import MicrosoftService from 'services/external/MicrosoftService';
import EventService from 'services/EventService';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Item from 'components/Item/Item';
import ItemContent from 'components/Item/ItemContent';
import Calendar from 'components/Calendar/Calendar';
import EventInfo from './EventInfo';

const MeCalendar = (props) => {
	let { date } = useParams();

	const colorsBg = {
		'HOLIDAY': 'rgba(var(--bs-danger-rgb), var(--fc-bg-event-opacity))',
		'FESTIVITY': 'rgba(var(--bs-danger-rgb), var(--fc-bg-event-opacity))',
		'CORPORATE': 'rgba(var(--bs-success-rgb), var(--fc-bg-event-opacity))'
	}

	//convert to event
	const convertToEvents = (data) => {
		let eventList = [];
		data?.value?.forEach(event => {
			eventList.push({
				id: event.id,
				title: event.subject,
				start: moment(event.start.dateTime).toDate(),
				end: moment(event.end.dateTime).toDate(),
				backgroundColor: 'var(--bs-primary)',
				extendedProps: event,
				classNames: ['bg-primary-subtle', 'text-primary']
			})
		});
		return eventList;
	}

	//convert to background event
	const convertToBgEvents = (items) => {
		let events = [];
		items?.forEach((item) => {
			events.push({
				id: item.id,
				title: item.titolo,
				start: moment(item.dataStart).utcOffset(item.dataStart).startOf('day').toDate(),
				end: moment(item.dataEnd).utcOffset(item.dataEnd).endOf('day').toDate(),
				display: 'background',
				allDay: true,
				backgroundColor: colorsBg[item.tipologia],
				textColor: colorsBg[item.tipologia],
			})
		})
		return events;
	}

	//per evitare che faccia troppe chiamate
	const calendar = useMemo(() => {
		const fetchEvents = (fetchInfo, successCallback, failureCallback) => {
			const msService = new MicrosoftService();
			msService.getUserEvents(fetchInfo)
				.then(response => {
					successCallback(convertToEvents(response.data));
				})
				.catch((error) => {
					failureCallback(error);
				});
		}

		const fetchBgEvents = (fetchInfo, successCallback, failureCallback) => {
			const eventService = new EventService();
			eventService.getAll({
				filters: { tipologia: ['HOLIDAY', 'FESTIVITY', 'CORPORATE'] },
				...fetchInfo
			})
				.then(response => response.data)
				.then(response => {
					successCallback(convertToBgEvents(response.data));
				})
				.catch((error) => {
					failureCallback(error);
				});
		}

		const eventSources = [
			{
				events: fetchEvents,
				failure: function () {
					console.log('ERROR trying to fetch events');
				},
				lazyFetching: true,
			},
			{
				events: fetchBgEvents,
				failure: function () {
					console.log('ERROR trying to fetch bg events');
				},
				lazyFetching: true,
			}
		];

		return (<Calendar
			eventSources={eventSources}
			eventInfo={(event) => <EventInfo item={event} />}
			initialDate={date && moment(date).isValid() ? moment(date).toDate() : null}
		/>)
	}, []);

	return (
		<Item>
			<ItemContent height={{ height: '100%' }}>
				<div className='row g-0 p-2'>
					<div className='col-12'>
						{calendar}
					</div>
					<div className='col-12 pt-2'>
						<div className='bg-secondary-subtle text-secondary p-2 rounded'><Trans i18nKey='views:calendars.meetings.hint' /></div>
					</div>
				</div>
			</ItemContent>
		</Item>
	)
}

export default MeCalendar