import Attendance from "pages/users/attendance/Attendance"

const AttendanceRoutes = [
	{
		path: "/attendance",
		element: <Attendance />,
		handle: {
			title: 'attendance.title',
			crumb: null, //Breadcrumbs
		},
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]

export default AttendanceRoutes