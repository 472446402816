import React, { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import Avatar from 'components/Avatar/Avatar';

const randomImage = {
	backgroundImage: 'url(\'' + process.env.REACT_APP_PUBLIC_URL + '/images/login/' + Math.floor((Math.random() * 7) + 1) + '.jpg\')',
	backgroundSize: 'cover',
	backgroundPosition: 'center center'
}


const Login = () => {
	const props = useOutletContext();
	const errorMessage = props.error;
	const [showPassword, setShowPassword] = useState(false);
	const [rememberUser, setRememberUser] = useState(localStorage.getItem('rememberUser') ? JSON.parse(localStorage.getItem('rememberUser')) : null);
	const { t } = useTranslation();

	// form validation rules 
	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.required(t('views:login.form.email.errors.required'))
			.email(t('views:login.form.email.errors.invalid')),
		password: Yup.string()
			.required(t('views:login.form.password.errors.required'))
			.min(6, t('views:login.form.password.errors.min'))
			.max(40, t('views:login.form.password.errors.max')),
	});

	// get functions to build form with useForm() hook
	const { register, handleSubmit, formState } = useForm({ resolver: yupResolver(validationSchema) });
	const { errors, isSubmitting } = formState;

	function onSubmit({ email, password, rememberme }) {
		return props.login(email, password, rememberme);
	}

	const showPasswordToggle = () => {
		setShowPassword(!showPassword);
	}

	return (
		<div className='row g-0 align-items-center flex-fill'>
			<div className='col-12 col-lg-6 col-xl-4 p-2 vh-100'>
				<div className='row g-0 h-100'>
					<div className='col-12 d-flex flex-column'>
						<div className='row g-0 align-items-center justify-content-center d-none d-lg-flex'>
							<div className='col-auto'>
								<div className='row g-0 align-items-center '>
									<div className='col-auto pe-1'>
										<img src={process.env.REACT_APP_LOGO} style={{ width: '48px' }} alt={process.env.REACT_APP_NAME} />
									</div>
									<div className='col lh-1 text-start'>
										<h1 className='m-0 h5'>{process.env.REACT_APP_NAME}</h1>
										<div className='text-body-tertiary'><small>{process.env.REACT_APP_SUB_NAME}</small></div>
									</div>
								</div>
							</div>
						</div>

						<div className='d-flex flex-fill justify-content-center align-items-center'>
							<div className='col-12 col-lg-10 col-xl-8'>
								<form onSubmit={handleSubmit(onSubmit)} noValidate className='needs-validation'>
									<div className='d-lg-none pb-4'>
										<img src={process.env.REACT_APP_LOGO} style={{ width: '200px' }} alt={process.env.REACT_APP_NAME} />
									</div>
									<div className='mb-3'>
										<h3 className='mb-0'><Trans i18nKey='views:login.title' values={{ context: (rememberUser ? 'alt' : null) }} /></h3>
										<div className='mb-0 '><Trans i18nKey='views:login.subtitle' /></div>
									</div>

									{errorMessage && (<div className='bg-danger bg-opacity-10 text-danger mb-3 rounded p-2'>{errorMessage}</div>)}

									{rememberUser ? (
										<div className='row g-0 align-items-center bg-white border cursor-pointer rounded mb-3' onClick={() => setRememberUser(null)}>
											<div className='border-end col-auto p-2 pe-1'>
												<i className='mdi mdi-chevron-left mdi-24px' />
											</div>
											<div className='col-auto p-2'>
												<Avatar item={rememberUser?.avatar} />
											</div>
											<div className='col lh-1 text-start'>
												<div><strong>{rememberUser?.name}</strong></div>
												<div><small className='text-body-secondary'>{rememberUser?.email}</small></div>
											</div>
											<input type='hidden' id='email' name='email' value={rememberUser?.email} {...register('email')} />
										</div>
									) : (
										<div className='input-group mb-3'>
											<span className='input-group-text bg-white'>
												<i className='mdi mdi-at' />
											</span>
											<div className='form-floating'>
												<input type='email' id='email' name='email' placeholder='Inserisci la tua E-mail' autoComplete='email' {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
												<label htmlFor='email' className='form-label'><Trans i18nKey='views:login.form.email.label' /></label>
												<div className="invalid-feedback">{errors.email?.message}</div>
											</div>
										</div>
									)}

									<div className='input-group mb-3'>
										<span className='input-group-text bg-white'>
											<i className='mdi mdi-key-variant' />
										</span>
										<div className='form-floating'>
											<input type={showPassword ? 'text' : 'password'} id='password' name='password' placeholder='Inserisci la tua Password' autoComplete='current-password' {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
											<label htmlFor='password' className='form-label'><Trans i18nKey='views:login.form.password.label' /></label>
											<div className="invalid-feedback">{errors.password?.message}</div>
										</div>
										<span className='input-group-text bg-white p-0'>
											<button type='button' className='btn h-100' onClick={showPasswordToggle}>
												<i className={showPassword ? 'mdi mdi-eye-off-outline' : 'mdi mdi-eye-outline'} />
											</button>
										</span>
									</div>
									<div className='mb-3'>
										<div className='form-check'>
											<input className='form-check-input' type='checkbox' value='1' id='rememberme' name='rememberme' defaultChecked={true} {...register('rememberme')} />
											<label className='form-check-label lh-1 text-start w-100' htmlFor='rememberme'>
												<span><Trans i18nKey='views:login.form.rememberme.label' /></span>
												<br />
												<small className='text-body-secondary'><Trans i18nKey='views:login.form.rememberme.desc' /></small>
											</label>
										</div>
									</div>
									<div className='mb-3'>
										<button disabled={isSubmitting} className='btn btn-primary text-white w-100'>
											{isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
											<Trans i18nKey='views:login.form.button' />
										</button>
									</div>
								</form>
							</div>
						</div>

						<div>
							<small className='lh-1 text-body-tertiary'><Trans i18nKey='views:login.footer' /></small>
						</div>
					</div>
				</div>
			</div>
			<div className='col d-none d-lg-block p-2 vh-100'>
				{/* <div className='rounded bg-primary h-100' style={randomImage}>&nbsp;</div> */}
				<div className='rounded bg-primary h-100 overflow-hidden position-relative'>
					<div id="bg-wrap">
						<svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
							<defs>
								<radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5"><animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(255, 0, 255, 1)"></stop><stop offset="100%" stopColor="rgba(255, 0, 255, 0)"></stop></radialGradient>
								<radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5"><animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(255, 255, 0, 1)"></stop><stop offset="100%" stopColor="rgba(255, 255, 0, 0)"></stop></radialGradient>
								<radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5"><animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(0, 255, 255, 1)"></stop><stop offset="100%" stopColor="rgba(0, 255, 255, 0)"></stop></radialGradient>
								<radialGradient id="Gradient4" cx="50%" cy="50%" fx="4.56417%" fy="50%" r=".5"><animate attributeName="fx" dur="23s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(0, 255, 0, 1)"></stop><stop offset="100%" stopColor="rgba(0, 255, 0, 0)"></stop></radialGradient>
								<radialGradient id="Gradient5" cx="50%" cy="50%" fx="2.65405%" fy="50%" r=".5"><animate attributeName="fx" dur="24.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(0,0,255, 1)"></stop><stop offset="100%" stopColor="rgba(0,0,255, 0)"></stop></radialGradient>
								<radialGradient id="Gradient6" cx="50%" cy="50%" fx="0.981338%" fy="50%" r=".5"><animate attributeName="fx" dur="25.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stopColor="rgba(255,0,0, 1)"></stop><stop offset="100%" stopColor="rgba(255,0,0, 0)"></stop></radialGradient>
							</defs>
							<rect x="13.744%" y="1.18473%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(334.41 50 50)"><animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="7s" repeatCount="indefinite"></animateTransform></rect>
							<rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(255.072 50 50)"><animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="12s" repeatCount="indefinite"></animateTransform>
							</rect>
							<rect x="9.00483%" y="14.5733%" width="100%" height="100%" fill="url(#Gradient3)" transform="rotate(139.903 50 50)"><animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite"></animate><animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="9s" repeatCount="indefinite"></animateTransform>
							</rect>
						</svg>
					</div>
					{/* <div className='position-absolute top-0 left-0 w-100 h-100 d-flex flex-column align-items-center justify-content-center text-white' style={{ textShadow: 'rgb(0 0 0 / 32%) 0px 0px 10px' }}>
						<img src={process.env.REACT_APP_LOGO_ALT} className='img-fluid' height='92' alt={process.env.REACT_APP_NAME} />
						<h4 className='display-4'>{process.env.REACT_APP_NAME}</h4>
						<div className='display-6'>Testo di prova ad effetto per alcodesk</div>
					</div> */}
				</div>
			</div>
		</div>
	);
}

export default Login