import Profile from "pages/profile/Profile";
import ProfileInfo from "pages/profile/item/info/ProfileInfo";
import ProfileSkills from "pages/profile/item/skills/ProfileSkills";
import ProfileDocuments from "pages/profile/item/documents/ProfileDocuments";
import ProfileBadges from "pages/profile/item/badges/ProfileBadges";
import ProfileAssets from "pages/profile/item/assets/ProfileAssets";
import ProfileProjects from "pages/profile/item/projects/ProfileProjects";
import ProfileSecure from "pages/profile/item/secure/ProfileSecure";
import { defer } from "react-router-dom";
import UserService from "services/UserService";
import moment from "moment";

const service = new UserService();
const ProfileRoutes = [
	{
		path: "/profile",
		element: <Profile />,
		handle: {
			title: 'profile.title',
			crumb: null, //Breadcrumbs
		},
		// loader: async () => {
		// const service = new PowerBiService();

		// return defer({ report: service.getMe() });
		// },
		children: [
			{
				index: true,
				path: "",
				handle: {
					title: 'profile.item.contents.info.title',
				},
				element: <ProfileInfo />,
			},
			{
				path: "/profile/secure",
				handle: {
					title: 'profile.item.contents.secure.title',
				},
				element: <ProfileSecure />,
			},
			{
				path: "/profile/projects",
				handle: {
					title: 'profile.item.contents.projects.title',
				},
				element: <ProfileProjects />,
			},
			{
				path: "/profile/assets",
				handle: {
					title: 'profile.item.contents.assets.title',
				},
				element: <ProfileAssets />,
			},
			{
				path: "/profile/badges",
				handle: {
					title: 'profile.item.contents.badges.title',
				},
				loader: async () => {
					let start = moment().subtract(30, 'days').format('YYYY-MM-DD');
					let end = moment().format('YYYY-MM-DD');

					return defer({ badges: service.getBadges(start, end) });
				},
				element: <ProfileBadges />,
			},
			{
				path: "/profile/documents",
				handle: {
					title: 'profile.item.contents.documents.title',
				},
				element: <ProfileDocuments />,
			},
			{
				path: "/profile/skills",
				handle: {
					title: 'profile.item.contents.skills.title',
				},
				element: <ProfileSkills />,
			},
		],
		future: {
			v7_normalizeFormMethod: true,
		},
	},
];

export default ProfileRoutes