import React, { useRef } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { motion } from 'framer-motion';

export const CollapseContent = ({ ...props }) => {
	props.id = props.id || ('coll' + Date.now());
	props.className = 'collapse ' + (props.className ? props.className : '');
	props.style = props.style || {};

	return (
		<div id={props.id} className={props.className} style={props.style} data-bs-parent={props.parentId}>
			<OverlayScrollbarsComponent
				options={{
					showNativeOverlaidScrollbars: false,
					overflow: { x: "hidden" },
					scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
				}}
				defer
				style={{ maxHeight: 'calc(100vh - 150px)' }}
			>
				{props.children}
			</OverlayScrollbarsComponent>
		</div >
	)
}

export const CollapseButton = ({ ...props }) => {
	const ref = useRef();

	props.id = props.id || ('coll' + Date.now());
	props.className = 'collapsed ' + (props.className ? props.className : '');
	props.style = props.style || {};

	return (
		<motion.button ref={ref} type="button" whileTap={{ scale: 0.8 }} className={props.className} style={props.style} data-bs-toggle='collapse' aria-expanded='false' data-bs-target={'#' + props.id} aria-controls={props.id}>
			{props.children}
		</motion.button>
	)
}

const Collapse = (props) => {
	return (
		<>{props.children}</>
	)
}

export default Collapse