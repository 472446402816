import React, { useState, useEffect } from 'react';
import TopicItem from './TopicItem';
import TopicDetails from './TopicDetails';
import TopicPlaceHolderList from './TopicPlaceHolderList';
import TopicService from 'services/TopicService';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Autoplay } from 'swiper/modules';
import { AnimatePresence } from 'framer-motion';

import './TopicList.scss';

const topicService = new TopicService();
const TopicList = ({ rowID, item }) => {
	const [cardDetails, setCardDetails] = useState(null);
	const [cardList, setCards] = useState([]);
	const [loading, setLoading] = useState(true);

	item.sliderSettings = {
		responsive: {
			350: {
				slidesPerView: 1
			},
			576: {
				slidesPerView: parseInt(item.slider.cardItemsSm || 2)
			},
			768: {
				slidesPerView: parseInt(item.slider.cardItemsMd || 3)
			},
			992: {
				slidesPerView: parseInt(item.slider.cardItemsLg || 4)
			},
			1200: {
				slidesPerView: parseInt(item.slider.cardItemsXl || 6)
			},
			1400: {
				slidesPerView: parseInt(item.slider.cardItemsXxl || 8)
			},
		}
	}

	const status = {
		class: '',
		icon: '',
	};

	//check if card status in hight, medium or low
	const rowStatus = () => {
		if (item.cardLow >= cardList.length) {
			status.class = 'bg-primary bg-opacity-100 text-white';
			status.icon = null;

		} else if (item.cardMedium >= cardList.length) {
			status.class = 'bg-warning bg-opacity-100 text-white';
			status.icon = 'mdi mdi-alert-circle-outline mdi-18px';

		} else if (item.cardHight >= cardList.length) {
			status.class = 'bg-warning bg-opacity-100 bg-blink shadow-blink text-white';
			status.icon = 'mdi mdi-alert-octagram-outline mdi-18px';

		} else {
			status.class = 'bg-danger bg-opacity-100 text-white';
			status.icon = 'mdi mdi-fire-circle mdi-18px';
		}
		return true;
	};

	useEffect(() => {
		topicService.getRequestById(rowID)
			.then((response) => {
				setTimeout(() => {
					setCards(response.data);
					setLoading(false);
				}, 250);
			});
	}, []);

	// off canvas function to show the data from child  to parent
	const handleCallback = (card) => {
		setCardDetails(card != null ? card : null);
	};

	return (
		item.emptyHidden && (cardList == null || cardList.length == 0) ? null : (
			<div className='topics'>
				<div className='p-2 mt-1 mt-md-2'>
					<div className='align-items-center g-0 row'>
						<div className='col'>
							<h5 className='text-black fw-semibold m-0'>{item.title || ''}</h5>
							{item.subtitle != null ? (<strong className='text-muted fw-semibold'>{item.subtitle}</strong>) : null}
						</div>
						<div className='align-items-center col-auto d-flex'>
							{item.showLength != 1 && cardList.length > 0 && rowStatus() ? (
								<div className={'badge rounded-pill ' + status.class}>
									{status != null && status.icon != null ? (<><i className={status.icon}></i>&nbsp;</>) : null}
									{cardList.length > 0 ? cardList.length : ''}
								</div>
							) : null}
						</div>
					</div>
					<div className='position-relative overflow-hidden rounded'>
						{loading ? (
							<TopicPlaceHolderList sizes={item.sliderSettings.responsive} />
						) : cardList != null && cardList.length > 0 ? (
							<Swiper
								slidesPerView={parseInt(item.slider.cardItemsXs || 3)}
								spaceBetween={8}
								loop={item.slider.loop}
								keyboard={{ enabled: true }}
								navigation={{ enabled: true }}
								breakpoints={item.sliderSettings.responsive}
								autoplay={{ delay: 2500, disableOnInteraction: false }}
								modules={(item.slider.autoplay ? [Keyboard, Navigation, Autoplay] : [Keyboard, Navigation])}
								className='swiper-container m-1 mt-3'
							>
								{cardList?.map((card, index) => (
									<SwiperSlide key={index}>
										<TopicItem item={card} height={item.slider.height} handleCallback={handleCallback} rowID={rowID} />
									</SwiperSlide>
								))}
							</Swiper>

						) : (
							<div className='animate__animated animate__fadeIn'>
								<div className='bg-secondary bg-opacity-10 text-center p-2 mt-2 rounded'>
									<i className='mdi mdi-card-bulleted-off-outline mdi-18px pe-2'></i>
									<span>{item.descIfEmpty}</span>
								</div>
							</div>
						)}

					</div>
				</div>
				<AnimatePresence>
					{cardDetails && <TopicDetails
						key={rowID}
						rowID={rowID}
						item={cardDetails}
						handleCallback={handleCallback}
					/>}
				</AnimatePresence>
			</div>
		)
	);
};

export default TopicList;