import React from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';

const TextAreaField = ({ ...props }) => {
	props.type = 'textarea';

	const { control } = useFormContext();

	return (
		<div className={props.type != null && props.type != 'hidden' ? (props.cssClass != null ? props.cssClass + ' p-2' : 'p-2') : 'w-auto'}>
			<div className="mb-3">
				{props.label != null ? (<label id={props.id + '_label'} htmlFor={props.id} className="form-label fw-semibold" dangerouslySetInnerHTML={{ __html: props.label }} />) : null}
				<Controller
					control={control}
					name={props.formName + (props.attribute.multiple ? '[]' : '')}
					defaultValue={props.value}
					rules={{
						required: props.attribute.required,
						min: props.attribute.min,
						minLength: props.attribute.minLength,
						max: props.attribute.max,
						maxLength: props.attribute.maxLength,
						validate: props.attribute.validate,
					}}
					render={({
						field: { onChange, onBlur, value, name, ref },
						fieldState: { invalid, isTouched, isDirty, error },
					}) => (
						<>
							<textarea
								id={props.id}
								name={name}
								defaultValue={value}
								ref={ref}
								onChange={onChange}

								type={props.type}
								className={props.inputClass != null ? props.inputClass : 'form-control'}
								placeholder={props.placeholder}
								aria-label={props.id}
								aria-describedby={props.hint != null ? (props.id + '_hint') : null}
								{...props.dataAttribute}
								{...props.attribute}
							>
							</textarea>
							<div className='invalid-feedback'>
								{error != null && <Trans i18nKey={'components:formFields.plain.error.' + error.type} values={props.attribute} />}
							</div>
							{props.hint != null ? (<div id={props.id + '_hint'} className="form-text">{props.hint}</div>) : null}
						</>
					)}
				/>
			</div>
		</div>
	)
}

export default TextAreaField