import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Loader from 'components/Loader/Loader';
import { generateChart } from './chartHelpers'


export const Chart = ({ id, type, series, options, ...props }) => {

	const chart = generateChart(id, type, series, options);

	return (<ChartContent chart={chart} {...props} />)

}

export const ChartContent = ({ chart, ...props }) => {

	let chartLoadingStyle = props.chartLoadingStyle || {
		backgroundSize: '94px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundImage: 'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill-opacity="0.05" d="M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64" /></svg>\')',
	};

	return chart ? (
		<div className={props?.className ? props.className : 'justify-content-center animate__animated animate__zoomIn'}>
			<ReactApexChart
				id={chart.id}
				className="mt-2 d-flex justify-content-center"
				series={chart.series}
				options={chart.options}
				type={chart.type}
				height={chart.height}
				style={{ minWidth: "50%" }}
			/>
		</div>
	) : (<Loader style={chartLoadingStyle} />)


}

export default Chart