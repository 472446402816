import React from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import Document from 'components/Document/Document';

const ProfileDocuments = () => {
	const [item] = useOutletContext();

	return (
		<div className='row g-0 align-items-center'>
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='profile.item.contents.documents.title' /></h6>
			</div>
			<div className='col-12'>
				<ul className="list-group">
					{item.documents != null && item.documents.length > 0 ? (item.documents?.map((document, idx) => (
						<li key={idx} className='list-group-item list-group-item-action'>
							<Document item={document} />
						</li>
					))) : (<div className='text-center text-body-tertiary small'>
						<Trans i18nKey='profile.item.contents.documents.no_document' />
					</div>)}
				</ul>
			</div>
		</div>
	)
}

export default ProfileDocuments