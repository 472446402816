import Api from "./Api";

export default class NavService {
	constructor(api) {
		this.api = api || new Api();
	}

	async getAll(params) {
		return this.api.get('/secure/navs', params);
	}

	async getAllMe(params) {
		return this.api.get('/secure/navs/me', params);
	}
}