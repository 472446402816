import React, { useState } from 'react'
import { NavLink, Outlet, useLoaderData } from 'react-router-dom';
import ItemAwait from 'components/Item/ItemAwait';
import ItemHeader from 'components/Item/ItemHeader';
import ItemContent from 'components/Item/ItemContent';
import ItemFooter from 'components/Item/ItemFooter';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';
import { Trans } from 'react-i18next';
import { DropDown, DropDownButton, DropDownContent, DropDownDivider, DropDownItem } from 'components/Bootstrap/DropDown';

const Tender = () => {
	const { tender } = useLoaderData();
	const [item, setItem] = useState();

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			setItem(response.data);
		}
	}

	return (
		<ItemAwait resolvePromise={tender} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
			<ItemHeader
				avatar={item?.avatar}
				title={item?.fullname}
				subtitle={item?.typology ? <Trans i18nKey={'models:tender.typology.value.' + item.typology} /> : null}
			>
				<DropDown>
					<DropDownButton>
						<i className='mdi mdi-dots-vertical' />
					</DropDownButton>
					<DropDownContent>
						<DropDownItem>
							<NavLink to='./edit' disabled>
								<i className='mdi mdi-pencil mdi-18px pe-1' />
								<Trans i18nKey='views:tenders.item.header.menu.edit' />
							</NavLink>
						</DropDownItem>
						<DropDownDivider />
						<DropDownItem>
							<NavLink to='./close' className='bg-danger-subtle text-danger' disabled>
								<i className='mdi mdi-cancel mdi-18px pe-1' />
								<Trans i18nKey='views:tenders.item.header.menu.close' />
							</NavLink>
						</DropDownItem>
					</DropDownContent>
				</DropDown>
			</ItemHeader>
			<ItemContent>
				<Outlet context={[item]} />
			</ItemContent>
			<ItemFooter />
		</ItemAwait>
	)
}

export default Tender