import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'assets/quill.scss';
import Tooltip from 'components/Tooltip/Tooltip';

const EditorField = ({ ...props }) => {
	props.type = 'hidden';

	const { t } = useTranslation();

	const { control } = useFormContext();

	props.dataAttribute = props.dataAttribute || {};

	function countLength(editor) {
		props.length = editor != null && editor.getHTML() != null ? editor.getHTML().length : 0;
	}

	return (
		<div className={props.cssClass != null ? props.cssClass + ' p-2' : 'p-2'}>
			<div className="mb-3">
				{props.label != null ? (<label id={props.id + '_label'} htmlFor={props.id} className="form-label fw-semibold">{props.label}</label>) : null}
				<Controller
					control={control}
					name={props.formName + (props.attribute.multiple ? '[]' : '')}
					defaultValue={props.value}
					rules={{
						required: props.attribute.required,
						min: props.attribute.min,
						minLength: props.attribute.minLength,
						max: props.attribute.max,
						maxLength: props.attribute.maxLength,
						validate: props.attribute.validate,
					}}
					render={({
						field: { onChange, onBlur, value, name, ref },
						fieldState: { invalid, isTouched, isDirty, error },
					}) => (
						<>
							<ReactQuill
								id={props.id}
								name={name}
								// ref={ref}
								ref={(el) => {
									el = ref;
									el != null && el.unprivilegedEditor != null && countLength(el.unprivilegedEditor);
								}}
								defaultValue={value}

								onChange={(value, delta, source, editor) => {
									props.attribute.maxLength && countLength(editor);
									onChange(value);
								}}
								onChangeSelection={(selection, source, editor) => {
									props.attribute.maxLength && countLength(editor);
								}}
								onFocus={(selection, source, editor) => {
									props.attribute.maxLength && countLength(editor);
								}}

								theme="snow"
								className={(props.inputClass != null ? props.inputClass : '') + (invalid ? ' is-invalid' : (isDirty && !invalid ? ' is-valid' : ''))}
								placeholder={props.placeholder}

								readOnly={props.attribute.readOnly}
								disabled={props.attribute.disabled}

								aria-label={props.id}
								aria-describedby={props.hint != null ? (props.id + '_hint') : null}
								{...props.dataAttribute}
								{...props.attribute}
							/>
							<div className={(error != null ? "d-block" : "") + " invalid-feedback"}>
								{error != null && <Trans i18nKey={'components:formFields.editor.error.' + error.type} values={props.attribute} />}
							</div>
							{props.hint != null ? (<div id={props.id + '_hint'} className="form-text d-flex">
								<div className='me-auto'>{props.hint}</div>
								{props.length != null && props.attribute.maxLength != null ? (
									<Tooltip title={t('components:formFields.editor.info')}>
										<div>{props.length || 0}/{props.attribute.maxLength} <i className='mdi mdi-information-variant-circle-outline' /></div>
									</Tooltip>
								) : null}
							</div>) : null}
						</>
					)}
				/>
			</div>
		</div>
	)
}

export default EditorField