import React from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { motion } from 'framer-motion';

export const DropDownContent = ({ ...props }) => {
	props.id = props.id || ('dd' + Date.now());
	props.className = 'dropdown-menu ' + props.className;
	props.style = props.style || {};

	return (
		<div id={props.id} className={props.className} style={props.style}>
			<OverlayScrollbarsComponent
				options={{
					showNativeOverlaidScrollbars: false,
					overflow: { x: "scroll", y: "scroll" },
					scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
				}}
				defer
				style={{ maxHeight: 'calc(100vh - 150px)' }}
			>
				{props.children}
			</OverlayScrollbarsComponent>
		</div >
	)
}

export const DropDownButton = ({ ...props }) => {
	return (
		<motion.button whileTap={{ scale: 0.8 }} className='btn' data-bs-toggle='dropdown' aria-expanded='false' {...props}>
			{props.children}
		</motion.button>
	)
}

export const DropDownItem = (props) => {
	return (<li>{React.cloneElement(props.children, { className: ('dropdown-item ' + (props.children?.props?.className ? props.children.props.className : '')) })}</li>);
}

export const DropDownDivider = () => {
	return (<li><hr className="dropdown-divider" /></li>);
}

export const DropDown = ({ ...props }) => {
	props.className = props.className || 'dropdown';
	return (
		<div className={props.className} {...props}>
			{props.children}
		</div>
	)
}