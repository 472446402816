// Generate Empty Line Chart for Funzione Team
export const _generateFTLineChart = () => {
	return {
		id: 'line-scatter-chart',
		type: 'line',
		series: [
			{ name: 'Valutazione Risorsa', type: 'scatter', data: [] },
			{ name: 'Andamento Dipartimento', type: 'line', data: [] },
		],
		options: {
			colors: [],
			chart: {
				height: 200,
				width: '100%',
				type: 'line',
			},
			fill: {
				opacity: [0.6, 0.8],
			},
			// grid: {
			//     padding: {
			//         right: 40
			//     }
			// },
			markers: {
				size: [5, 0]
			},
			tooltip: {
				shared: false,
				intersect: false,
				x: {
					format: 'MMM yyyy',
				}
			},
			legend: {
				show: false
			},
			yaxis: {
				type: 'numeric',
				tickAmount: 4,
				min: 1,
				max: 5,
				decimalsInFloat: 1
			},
			xaxis: {
				type: "datetime",
				format: 'MM yyyy',
				formatter: function (value, timestamp, opts) {
					return new Date(timestamp != null ? (timestamp) : null).toLocaleDateString('it-IT', { year: 'numeric', month: 'long' });
				}
			}
		}
	};
};