import i18next from "i18next";

export const areaChart = (data) => {

	let dataset = [], labels = [];

	if (data != null && data.length > 0) {
		dataset = data.map(i => i.totalHours);
		labels = data.map(i => new Date(i.date).getTime());
	}

	return {
		id: 'area-chart-1',
		type: 'area',
		series: [{
			name: i18next.t('views:works.item.contents.info.charts.area.series1'),
			data: dataset
		}],
		options: {
			title: {
				text: i18next.t('views:works.item.contents.info.charts.area.title'),
				align: 'center',
				style: {
					fontSize: '11px',
					fontWeight: '600',
					color: '#212529',
				}
			},
			chart: {
				id: 'area-chart-1',
				type: 'area',
				height: 180,
				toolbar: {
					autoSelected: "pan",
					show: false
				},
			},
			colors: ["var(--bs-primary)"],
			stroke: {
				width: 2
			},
			grid: {
				borderColor: "#555",
				clipMarkers: false,
				yaxis: {
					lines: {
						show: false
					}
				}
			},
			dataLabels: {
				enabled: false
			},
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					inverseColors: false,
					gradientToColors: ['#fff'],
					opacityFrom: 0.45,
					opacityTo: 0.05,
				},
			},
			markers: {
				size: 0,
			},
			xaxis: {
				type: 'datetime',
			},
			labels: labels,
		}
	}
};

export const radialBar = (data) => {
	let dataset = [], labels = [];

	if (data != null && data.length > 0) {
		dataset = data.map(i => i.count);
		labels = data.map(i => i18next.t('models:task.status.value.' + i.status, { count: 100 }));
	}

	return {
		id: 'radial-bar-chart-1',
		type: 'radialBar',
		series: dataset,
		options: {
			title: {
				text: i18next.t('views:works.item.contents.info.charts.radialBar.title'),
				align: 'center',
				style: {
					fontSize: '11px',
					fontWeight: '600',
					color: '#212529',
				}
			},
			chart: {
				height: 200,
				type: 'radialBar',
			},
			plotOptions: {
				radialBar: {
					offsetY: 0,
					startAngle: 0,
					endAngle: 270,
					hollow: {
						margin: 5,
						size: '40%',
						background: 'transparent',
						image: undefined,
					},
					dataLabels: {
						name: {
							show: false,
						},
						value: {
							show: false,
						}
					},
					barLabels: {
						enabled: true,
						useSeriesColors: true,
						offsetX: -8,
						fontSize: 'var(--bs-body-font-size)',
						formatter: function (seriesName, opts) {
							return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
						},
					},
				}
			},
			colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
			labels: labels,
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						show: false
					}
				}
			}]
		}
	}
};

export const progressChart = (percentage) => {
	return {
		id: 'progress-bar-chart-1',
		type: 'radialBar',
		series: [(percentage || 0)],
		options: {

			chart: {
				height: 200,
				type: 'radialBar',
				toolbar: {
					show: true
				}
			},
			plotOptions: {
				radialBar: {
					startAngle: -135,
					endAngle: 225,
					hollow: {
						margin: 0,
						size: '70%',
						background: '#fff',
						image: undefined,
						imageOffsetX: 0,
						imageOffsetY: 0,
						position: 'front',
						dropShadow: {
							enabled: true,
							top: 3,
							left: 0,
							blur: 4,
							opacity: 0.24
						}
					},
					track: {
						background: '#fff',
						strokeWidth: '67%',
						margin: 0, // margin is in pixels
						dropShadow: {
							enabled: true,
							top: -3,
							left: 0,
							blur: 4,
							opacity: 0.35
						}
					},

					dataLabels: {
						show: true,
						name: {
							offsetY: -15,
							show: true,
							fontSize: '11px',
							fontWeight: '600',
							color: '#212529',

						},
						value: {
							formatter: function (val) {
								return parseInt(val);
							},
							offsetY: 5,
							fontSize: '36px',
							fontWeight: '600',
							color: '#212529',
							show: true,
						}
					}
				}
			},
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'dark',
					type: 'horizontal',
					shadeIntensity: 0.5,
					gradientToColors: ['#ABE5A1'],
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 100]
				}
			},
			stroke: {
				lineCap: 'round'
			},
			labels: [i18next.t('models:work.pal')],
		}
	}
}