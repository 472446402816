import Api from "./Api";

export default class AuthServices {
	constructor(api) {
		this.api = api || new Api();
	}

	async login(email, password, rememberme) {
		return this.api.postJSON('/auth/login', { uid: email, password, rememberme });
	}

	async logout() {
		return this.api.postJSON('/auth/logout', {});
	}

	async me() {
		return this.api.postJSON('/auth/user-profile');
	}
}