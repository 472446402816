export const calculateAverage = (array, param) => {
    var total = 0;
    var count = 0;

    if (array != null && array.length > 0) {
        array.forEach(function (item) {
            if ((Object.prototype.toString.call(item) === '[object Array]' || Object.prototype.toString.call(item) === '[object Object]') && param != null && item[param] != null && !isNaN(item[param])) {
                total += parseFloat(item[param]);
                count++;

            } else if (item != null && !isNaN(item)) {
                total += parseFloat(item);
                count++;
            }
        });

        return count > 0 ? (total / count).toFixed(2) : 0;
    }

    return 0;
}