import { Error } from "components/Error/Error";
import Customers from "pages/users/customers/Customers";
import Customer from "pages/users/customers/item/Customer";
import CustomerEmpty from "pages/users/customers/item/CustomerEmpty";
import CustomerInfo from "pages/users/customers/item/info/CustomerInfo";
import { defer } from "react-router-dom";
import CustomerService from "services/CustomerService";

const service = new CustomerService();
const CustomerRoutes = [
	{
		path: "/customers",
		element: <Customers />,
		loader: async ({ request }) => {
			return defer({ customers: service.getAll(new URL(request.url).search) });
		},
		handle: {
			title: 'customers.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				path: "",
				element: <CustomerEmpty />,
			},
			{
				path: "/customers/:id",
				element: <Customer />,
				loader: ({ params }) => {
					return defer({ customer: service.getById(params.id) });
				},
				handle: {
					crumb: null, //Breadcrumbs
				},
				children: [
					{
						index: true,
						element: <CustomerInfo />,
					},
				],
				errorElement: <Error />,
			},
		],
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]

export default CustomerRoutes