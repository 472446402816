import { defer } from "react-router-dom";
import { Error } from "components/Error/Error";
import { PermissionsProvider } from "providers/PermissionsProvider";
import Timesheets from "pages/timesheets/Timesheets";
import ApprovalTimesheets from "pages/timesheets/approval/ApprovalTimesheets";
import ConsolidationTimesheets from "pages/timesheets/consolidation/ConsolidationTimesheets";
import TimesheetService from "services/TimesheetService";

const service = new TimesheetService();
const TimesheetRoutes = [
	{
		path: "/timesheets/:date?",
		element: <PermissionsProvider permissions={['VIEW_TIMESHEETS', 'EDIT_TIMESHEETS']}><Timesheets /></PermissionsProvider>,
		loader: async ({ params }) => {
			return defer({ timesheets: service.getAllMe({ date: (params.date || new Date().toISOString().split('T')[0]) }) });
		},
		handle: {
			title: 'timesheets.title',
			crumb: null, //Breadcrumbs
		},
		errorElement: <Error />,

		future: {
			v7_normalizeFormMethod: true,
		},
	},
	{
		path: "/approval-timesheets/:date?",
		element: <PermissionsProvider permissions={['APPROVE_TIMESHEETS']}><ApprovalTimesheets /></PermissionsProvider>,
		loader: async ({ request, params }) => {
			let searchParams = new URL(request.url).searchParams;
			return defer({
				timesheets: service.getAllByDepartment('', {
					date: (params.date || new Date().toISOString().split('T')[0]),
					search: searchParams.get("search")
				})
			});
		},
		handle: {
			title: 'timesheets.title',
			crumb: null, //Breadcrumbs
		},
		errorElement: <Error />,

		future: {
			v7_normalizeFormMethod: true,
		},
	},
	{
		path: "/consolidation-timesheets/:date?",
		element: <PermissionsProvider permissions={['CONSOLIDATE_TIMESHEETS']}><ConsolidationTimesheets /></PermissionsProvider>,
		loader: async ({ params }) => {
			return defer({ timesheets: service.getAll({ date: (params.date || new Date().toISOString().split('T')[0]) }) });
		},
		handle: {
			title: 'timesheets.title',
			crumb: null, //Breadcrumbs
		},

		//inserise children???
		errorElement: <Error />,

		future: {
			v7_normalizeFormMethod: true,
		},
	},

]

export default TimesheetRoutes