import Planning from "pages/planning/Planning"

const PlanningRoutes = [
	{
		path: "/planning",
		element: <Planning />,
		handle: {
			title: 'planning.title',
			crumb: null, //Breadcrumbs
		},
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]

export default PlanningRoutes