import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import ResourceService from 'services/ResourceService';
import StarRating from 'components/StarRating/StarRating';
import { generateBrushChart, generateRadarChart } from './resourceEvaluationCharts';
import Loader from 'components/Loader/Loader';
import Chart from 'components/Chart/Chart';

const resourceService = new ResourceService();
const ResourceEvaluations = () => {
    const [item] = useOutletContext();

    const [years, setYears] = useState([]);
    const [current, setCurrent] = useState(null);
    const [radarChart, setRadarChart] = useState(null);
    const [brushChart, setBrushChart] = useState([]);

    useEffect(() => {
        if (item?.id) {
            resourceService.getEvaluationsById(item.id)
                .then((response) => {
                    if (
                        response != null &&
                        response.data != null &&
                        response.data.length > 0
                    ) {
                        setYears(adeguateDatasetByYear(response.data));
                    } else {
                        setYears(null);
                    }
                });
        }
    }, [item]);

    useEffect(() => {
        if (years != null && years.length > 0) {
            let selectedYear = years.filter((year) => year.isSelected == true);
            selectedYear = selectedYear.length > 0 ? selectedYear : [years[0]]; //se non ci sono anni selezionati mostro il 1°

            let currentYear = years.filter((year) => year.id == (new Date()).getFullYear());
            currentYear = currentYear.length > 0 ? currentYear : [years[0]]; //se non ci sono anni selezionati mostro il 1°

            setRadarChart(generateRadarChart(selectedYear[0], currentYear[0], '1'));
            setBrushChart(generateBrushChart(selectedYear[0].datasets));
            setCurrent(currentYear[0].datasets[currentYear[0].datasets.length - 1]);
        }
    }, [years]);

    /**
     * handle on change year update is selected
     * @param {int} year 
     */
    const onYearChange = (year = (new Date()).getFullYear()) => {
        if (years != null && years.length > 0) {
            let _years = [...years];
            _years.forEach((item) => {
                item.isSelected = item.id == year;
            });
            setYears(_years);
        }
    };

    function adeguateDatasetByYear(data) {
        let years = [];
        let currentYear = (new Date()).getFullYear();
        if (data != null || data.length > 0) {
            data.forEach(row => {
                let year = new Date(row.data).getFullYear();
                let i = years.findIndex(y => y.id == year);

                //se non esiste lo creo
                if (i == -1) {
                    years.push({
                        id: year,
                        datasets: [],
                        isSelected: currentYear == year,
                        rating_avg: 0,
                        rating_length: 0,
                        hard_skill_avg: 0,
                        hard_skill_length: 0,
                        soft_skill_avg: 0,
                        soft_skill_length: 0,
                        mood_avg: 0,
                        mood_length: 0,
                        hr_avg: 0,
                        hr_length: 0,
                    });
                    i = years.length - 1;//adeguo l'indice
                }

                //aggiungo al dataset
                years[i].datasets.push(row);

                //sommo i vari dataset per preparazione all'avg
                //conteggio solo i valori non null
                if (row.rating_value != null) {
                    years[i].rating_avg += parseFloat(row.rating_value);
                    years[i].rating_length += 1;
                }

                if (row.hard_skill_value != null) {
                    years[i].hard_skill_avg += parseFloat(row.hard_skill_value);
                    years[i].hard_skill_length += 1;
                }

                if (row.soft_skill_value != null) {
                    years[i].soft_skill_avg += parseFloat(row.soft_skill_value);
                    years[i].soft_skill_length += 1;
                }

                if (row.mood_value != null) {
                    years[i].mood_avg += parseFloat(row.mood_value);
                    years[i].mood_length += 1;
                }

                if (row.hr_value != null) {
                    years[i].hr_avg += parseFloat(row.hr_value);
                    years[i].hr_length += 1;
                }

            });

            //creo avg
            years.forEach(year => {
                if (year.datasets.length > 0) {
                    year.rating_avg = year.rating_length > 0 ? (year.rating_avg / year.rating_length) : null;
                    year.hard_skill_avg = year.hard_skill_length > 0 ? (year.hard_skill_avg / year.hard_skill_length) : null;
                    year.soft_skill_avg = year.soft_skill_length > 0 ? (year.soft_skill_avg / year.soft_skill_length) : null;
                    year.mood_avg = year.mood_length > 0 ? (year.mood_avg / year.mood_length) : null;
                    year.hr_avg = year.hr_length > 0 ? (year.hr_avg / year.hr_length) : null;
                }
            });

            //sort in base all'anno desc
            years = years.reverse();
        }

        return years;
    };

    return (
        <>
            <div className='row g-4 m-0'>
                {years != null ? (
                    <>
                        <div className='col-12 col-xl-6'>
                            {radarChart && <Chart {...radarChart} />}
                        </div>
                        <div className='col-12 col-xl-6'>
                            {years != null && years.length > 0 && current != null ? (
                                <div className='justify-content-center  animate__animated animate__zoomIn'>
                                    <div className='overflow-auto p-2 px-lg-4' style={{ maxHeight: '350px' }}>
                                        <ul className="list-group text-center">
                                            {
                                                current != null ? (
                                                    <li className="list-group-item bg-warning bg-opacity-10">
                                                        <div className='row g-0 align-items-center'>
                                                            <div className='col-auto'>
                                                                <i className='mdi mdi-mdi mdi-trophy-award mdi-36px text-warning'></i>
                                                            </div>
                                                            <div className='col'>
                                                                <div className="fw-bold"><Trans i18nKey="resources.item.contents.evaluations.last_year" /> ({new Date(current.data).toLocaleString('it-IT', { month: 'short', year: 'numeric' })})</div>
                                                                <StarRating actual={current.rating_value} disableAnimation={true} showLabel={true} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                ) : null
                                            }
                                            {
                                                years?.map((year) => (
                                                    <li className={"list-group-item list-group-item-action cursor-pointer" + (year.isSelected ? ' bg-primary bg-opacity-10' : '')} onClick={() => onYearChange(year.id)}>
                                                        <div className='row g-0 align-items-center'>
                                                            <div className='col-auto'>
                                                                <i className={'mdi mdi-checkbox-marked-circle-outline mdi-36px ' + (year.isSelected ? 'text-primary' : 'opacity-0')}></i>
                                                            </div>
                                                            <div className='col'>
                                                                <div className="fw-bold"><Trans i18nKey="resources.item.contents.evaluations.year" /> {year.id}</div>
                                                                <StarRating actual={year.rating_avg} disableAnimation={true} showLabel={true} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            ) : (<Loader />)}
                        </div>
                        <div className='col-12'>
                            {brushChart?.map((chart, index) => (<Chart {...chart} key={index} />))}
                        </div>
                    </>
                ) : (
                    <div className='col-12 p-2'>
                        <div className='justify-content-center w-100 h-100 animate__animated animate__zoomIn'>
                            <div className='text-center lh-1'>
                                <div><i className='mdi mdi-chart-timeline-variant mdi-92px text-opacity-50 text-secondary'></i></div>
                                <div><span><Trans i18nKey="resources.item.contents.evaluations.empty" /></span></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className='mt-4 m-2'>
                <div className='bg-opacity-25 bg-primary p-2 rounded small'>
                    <strong><Trans i18nKey="resources.item.contents.evaluations.legenda.title" />:</strong>
                    <ul>
                        <li>
                            <strong><Trans i18nKey="resources.item.contents.evaluations.legenda.rating.title" />: </strong>
                            <span><Trans i18nKey="resources.item.contents.evaluations.legenda.rating.desc" /></span>
                        </li>
                        <li>
                            <strong><Trans i18nKey="resources.item.contents.evaluations.legenda.hard_skill.title" />: </strong>
                            <span><Trans i18nKey="resources.item.contents.evaluations.legenda.hard_skill.desc" /></span>
                        </li>
                        <li>
                            <strong><Trans i18nKey="resources.item.contents.evaluations.legenda.soft_skills.title" />: </strong>
                            <span><Trans i18nKey="resources.item.contents.evaluations.legenda.soft_skills.desc" /></span>
                        </li>
                        <li>
                            <strong><Trans i18nKey="resources.item.contents.evaluations.legenda.hr.title" />: </strong>
                            <span><Trans i18nKey="resources.item.contents.evaluations.legenda.hr.desc" /></span>
                        </li>
                        <li>
                            <strong><Trans i18nKey="resources.item.contents.evaluations.legenda.mood.title" />: </strong>
                            <span><Trans i18nKey="resources.item.contents.evaluations.legenda.mood.desc" /></span>
                        </li>
                        <li>
                            <strong><Trans i18nKey="resources.item.contents.evaluations.legenda.surveys.title" />: </strong>
                            <span><Trans i18nKey="resources.item.contents.evaluations.legenda.surveys.desc" /></span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ResourceEvaluations;