import Item from 'components/Item/Item';
import { Filters, FiltersGroup, FiltersItem } from 'components/Filters/Filters';
import Search from 'components/Search/Search';
import ListAwait from 'components/List/ListAwait';
import ListItem from 'components/List/ListItem';
import React, { useState } from 'react'
import { NavLink, Outlet, useLoaderData } from 'react-router-dom'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Trans, useTranslation } from 'react-i18next';
import { SplitScreen } from 'components/SplitScreen/SplitScreen';


const Works = () => {
    let { works } = useLoaderData();
    const [items, setItems] = useState();
    const [hasMore, setHasMore] = useState(false);

    const { t } = useTranslation();

    const handleLoad = (response) => {
        setItems(oldItems => [...oldItems, ...response.data]);
        setHasMore(response.next_page_url);
    };

    //callback function of Await react-router-dom
    const resolvedPromise = (response) => {
        if (items == null || items.length == 0) {
            setItems(response.data.data);
            setHasMore(response.data.next_page_url);
        }
    }

    return (
        <SplitScreen name="works-list" leftClass="flex-column h-100 w-100">
            <Item className="d-flex flex-column shadow-sm bg-white p-2 rounded w-100 h-100 position-relative">
                <div className='row g-2 align-items-center mb-2'>
                    <div className='col'>
                        <NavLink to={"./new"} end className='btn btn-info shadow-sm text-white d-block text-truncate' disabled><Trans i18nKey="works.new" /></NavLink>
                    </div>
                    <div className='col-auto'>
                        <button
                            className="btn border shadow-sm"
                            type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#works-filter" aria-controls="works-filter"
                            title='views:works.filters.title'>
                            <i className="mdi mdi-filter-variant"></i>
                        </button>
                    </div>
                </div>
                <div className='row g-2 align-items-center'>
                    <div className='col-12'>
                        <Search action="/works" />
                    </div>
                </div>
                <div className="offcanvas offcanvas-start position-absolute bg-white rounded border-0 w-100" data-bs-backdrop="false" tabIndex="-1" id="works-filter" aria-labelledby="works-filter-label">
                    <div className="offcanvas-header p-3 text-truncate">
                        <h5 className="offcanvas-title" id="works-filter-label"><Trans i18nKey="views:works.filters.title" /></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body p-2">
                        <OverlayScrollbarsComponent
                            options={{
                                showNativeOverlaidScrollbars: false,
                                overflow: { x: "hidden" },
                                scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
                            }}
                            defer
                            style={{ height: '100%' }}
                        >
                            <Filters action="/works">
                                <FiltersGroup label='views:works.filters.status.title' type="radio">
                                    <FiltersItem value="" name="filters[status]" label='views:works.filters.status.all' />
                                    <FiltersItem value="C" name="filters[status]" label='views:works.filters.status.active' />
                                    <FiltersItem value="K" name="filters[status]" label='views:works.filters.status.closed' />
                                    <FiltersItem value="S" name="filters[status]" label='views:works.filters.status.suspended' />
                                </FiltersGroup>
                                <FiltersGroup label='views:works.filters.cdc.title' type="radio">
                                    <FiltersItem value="CDC-INTERNO" name="filters[businessSector]" label='views:works.filters.cdc.local' />
                                    <FiltersItem value="!CDC-INTERNO" name="filters[businessSector]" label='views:works.filters.cdc.production' />
                                    <FiltersItem value="" name="filters[businessSector]" label='views:works.filters.cdc.all' />
                                </FiltersGroup>
                                <FiltersGroup label='views:works.filters.orders.title' type="radio">
                                    <FiltersItem value="ASC" name="orders[id]" label='views:works.filters.orders.idAsc' />
                                    <FiltersItem value="DESC" name="orders[id]" label='views:works.filters.orders.idDesc' />
                                    <FiltersItem value="ASC" name="orders[importo]" label='views:works.filters.orders.amountAsc' />
                                    <FiltersItem value="DESC" name="orders[importo]" label='views:works.filters.orders.amountDesc' />
                                    <FiltersItem value="ASC" name="orders[importoLavori]" label='views:works.filters.orders.workAmountAsc' />
                                    <FiltersItem value="DESC" name="orders[importoLavori]" label='views:works.filters.orders.workAmountDesc' />
                                </FiltersGroup>
                            </Filters>
                        </OverlayScrollbarsComponent>
                    </div>
                </div>
                <ListAwait resolvePromise={works} hasResolved={resolvedPromise} loadMore={handleLoad} hasMore={hasMore}>
                    {items?.map((item, idx) =>
                        <ListItem key={idx} item={item} />
                    )}
                </ListAwait>

            </Item>
            <Outlet />
        </SplitScreen>
    )
}

export default Works