import React from 'react'
import { PlainInput } from './PlainField';

const SwitchField = ({ ...props }) => {
	props.type = 'checkbox';
	props.invalidText = 'Questo campo è obbligatorio.';
	props.inputClass = 'form-check-input';

	//gestire checked

	return (
		<div className={props.type !== null && props.type != 'hidden' ? (props.cssClass !== null ? props.cssClass + ' p-2' : 'p-2') : 'w-auto'}>
			<div className="mb-3">
				<div className="form-check form-switch">
					{props.label != null ? (<label id={props.id + '_label'} htmlFor={props.id} className="form-check-label fw-semibold" dangerouslySetInnerHTML={{ __html: props.label }} />) : null}
					<PlainInput {...props} />
				</div>
			</div>
		</div>
	)
}

export default SwitchField