import React from 'react'
import { connect } from 'react-redux';
import * as auth from '../../store/actions/auth.action';
import { Outlet } from 'react-router-dom';

const Public = (props) => {

	return (
		<><Outlet context={props} /></>
	)
}


const mapStateToProps = state => {
	return {
		loggingIn: state.auth.loggingIn,
		user: state.auth.user,
		error: state.auth.error
	};
};

const mapDispatchToProps = dispatch => {
	return {
		login: (email, password, rememberme) => dispatch(auth.login(email, password, rememberme)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Public);