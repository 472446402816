import { urlReplace } from "helpers/url";
import axios from "axios";

export default class MicrosoftService {
	static url = process.env.REACT_APP_API_URL + '/secure/microsoft';

	constructor() {
		this.request = axios.create({
			baseURL: this.constructor.url, headers: {
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With, X-Requested-Type',
				'Access-Control-Allow-Credentials': 'true',
				'X-Requested-With': 'XMLHttpRequest',
				'X-Requested-Type': 'DTO',
				'credentials': 'same-origin',
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
			}
		});

		if (localStorage.getItem('token')) {
			const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
			if (token && token.access_token) {
				this.setAuth(token.access_token);
			}
		}
	}

	setAuth(token) {
		if (token && token.length > 0) {
			this.request.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		}

	}

	async getRooms(params) {
		return this.request.get('/rooms', params);
	}

	async getRoomsEvents(data) {
		return this.request.post('rooms-events', data);
	}

	async getUserEvents(data) {
		return this.request.post('user-events', data);
	}

	async getEvent(id, params) {
		return this.request.get(urlReplace('/event/{id}', { id: id }), params);
	}


}