import React, { useEffect } from 'react';
import Item from 'components/Item/Item';
import ItemContent from 'components/Item/ItemContent';
import { DropDown, DropDownButton, DropDownContent } from 'components/Bootstrap/DropDown';
import { Trans } from 'react-i18next';
import { WikiNav, WikiContent } from './Wiki_IT';
import ScrollToAnchor from '../../../components/ScrollToAnchor/ScrollToAnchor';
import { useLocation } from 'react-router-dom';
import Flag from 'react-world-flags';

const Wiki = () => {

	let location = useLocation();

	useEffect(() => {
		let activeLink;
		document.querySelectorAll('#wiki-nav .nav-link').forEach(link => {
			link.target != '_BLANK' && link.classList.remove('active');
			if (link.href.includes(location.hash)) {
				activeLink = link;
			}
		})

		activeLink && activeLink.classList.add('active');


	}, [location])

	return (
		<Item>
			<ScrollToAnchor />
			<ItemContent height={{ height: '100%' }}>
				<div className='row g-0'>
					<div className='col-12 col-lg-4 text-center pe-lg-2'>
						<div className='sticky-lg-top py-2 p-lg-2'>
							<div className='bg-warning-subtle rounded p-2 text-warning mb-2'>
								<Flag code='it' width='18' className='pe-1' /> Available only in Italian
							</div>
							<DropDown className='dropdown-center'>
								<DropDownButton className='dropdown-toggle w-100 btn btn-dark d-lg-none'>
									<Trans i18nKey='views:wiki.menu' />
								</DropDownButton>
								<DropDownContent id="wiki-nav" className='d-lg-block row w-100 border-0 g-0 p-1 bg-body-tertiary'>
									<WikiNav />
								</DropDownContent>
							</DropDown>
						</div>
					</div>
					<div className='col-12 col-lg-8 p-2'>
						<WikiContent />
					</div>
				</div>
			</ItemContent>
		</Item>
	)
}

export default Wiki