import React, { useState } from 'react'
import { NavLink, Outlet, useLoaderData } from 'react-router-dom';
import ItemAwait from 'components/Item/ItemAwait';
import ItemHeader from 'components/Item/ItemHeader';
import ItemNavDropDown from 'components/Item/ItemNavDropDown';
import ItemContent from 'components/Item/ItemContent';
import ItemFooter from 'components/Item/ItemFooter';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';
import { Trans } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';


const Department = () => {
	const { department } = useLoaderData();
	const [item, setItem] = useState();
	const { checkPermissions } = useAuth();
	const VIEW_EVALUATIONS = checkPermissions(['VIEW_EVALUATIONS']); //PERMISSION

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			setItem(response.data);
		}
	}

	return (
		<ItemAwait resolvePromise={department} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
			<ItemHeader
				avatar={item?.avatar}
				title={item?.name}
				subtitle={VIEW_EVALUATIONS && item != null && item.rating != null ? { html: item.rating } : null}
			/>

			{VIEW_EVALUATIONS ? (<ItemNavDropDown>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between" aria-current="page" to="." end>
					<span><Trans i18nKey="departments.item.nav.info" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between" aria-current="page" to={"./evaluations"} end>
					<span><Trans i18nKey="departments.item.nav.evaluations" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
			</ItemNavDropDown>) : null}
			<ItemContent>
				<Outlet context={[item]} />
			</ItemContent>
			<ItemFooter />
		</ItemAwait>
	)

}

export default Department