import React from 'react';
import { useAuth } from 'hooks/useAuth';
import { Trans, useTranslation } from 'react-i18next';
import Avatar from 'components/Avatar/Avatar';
import Tooltip from 'components/Tooltip/Tooltip';
import ProfileMenu from './ProfileMenu';
import NotificationsMenu from './NotificationsMenu';
import { DropDown, DropDownButton, DropDownContent } from 'components/Bootstrap/DropDown';
import Langs from './Langs';
import Themes from './Themes';

import './Nav.scss';

const Nav = () => {
	const { user, lastAccess, permissions } = useAuth();
	const { t } = useTranslation();

	return (
		<nav className='navbar navbar-expand-lg sticky-top w-100 bg-white border-bottom p-0'>
			<div className='container-fluid align-items-center d-flex flex-nowrap p-0'>
				<div className='d-flex justify-content-center text-truncate text-center text-lg-start flex-grow-1 flex-lg-grow-0'>
					<h6 className='m-0 p-2 py-lg-0'>{document.title}</h6>
				</div>
				<ul className='navbar-nav ms-auto flex-row justify-content-end align-items-center d-none d-lg-flex'>
					<li className='nav-item'>
						<Langs />
					</li>
					<li className='nav-item'>
						<Themes />
					</li>
					<li className='nav-item d-none d-lg-flex'>
						<DropDown>
							<DropDownButton>
								<Tooltip title={t('components:nav.moods')} >
									<i className='mdi mdi-emoticon-wink-outline mdi-18px' />
								</Tooltip>
							</DropDownButton>
							<DropDownContent className='dropdown-menu-end' style={{ width: '300px' }}>
								<div className='bg-warning-subtle text-warning rounded p-2 align-items-center justify-content-center d-flex mx-2'>
									<i className='mdi mdi-information-variant-circle-outline mdi-24px pe-1' />
									<Trans i18nKey='views:wip' />
								</div>
							</DropDownContent>
						</DropDown>
					</li>
					<li className='nav-item'>
						<DropDown>
							<DropDownButton>
								<Tooltip title={t('components:nav.notifications')} >
									<i className={(user?.notificationsCount ? 'notification-badge ' : '') + 'mdi mdi-bell-outline mdi-18px'} />
								</Tooltip>
							</DropDownButton>
							<DropDownContent className='dropdown-menu-end' style={{ width: '300px' }}>
								<NotificationsMenu count={user?.notificationsCount} />
							</DropDownContent>
						</DropDown>
					</li>
					<li className='nav-item'>
						<DropDown>
							<DropDownButton>
								<span className='align-items-center d-flex'>
									<Avatar item={user?.avatar} className='avatar-sm' />
									<span>{user?.nome}</span>
									<i className='mdi mdi-chevron-down' />
								</span>
							</DropDownButton>
							<DropDownContent className='dropdown-menu-end p-2' style={{ width: '250px' }}>
								<ProfileMenu user={user} lastAccess={lastAccess} permissions={permissions} />
							</DropDownContent>
						</DropDown>
					</li>
				</ul>
			</div>
		</nav>
	)
}

export default Nav