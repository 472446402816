import Departments from "pages/departments/Departments";
import Department from "pages/departments/item/Department";
import DepartmentEmpty from "pages/departments/item/DepartmentEmpty";
import DepartmentEvaluations from "pages/departments/item/evaluations/DepartmentEvaluations";
import DepartmentInfo from "pages/departments/item/info/DepartmentInfo";
import { Error } from "components/Error/Error";
import DepartmentService from "services/DepartmentService";
import { defer } from "react-router-dom";

const service = new DepartmentService();
const DepartmentRoutes = [
	{
		path: "/departments",
		element: <Departments />,
		loader: async ({ request }) => {
			const url = new URL(request.url);
			const search = url.searchParams.get("search");

			let queryParams = { perPage: 25 };
			if (search) { queryParams.search = search; }

			return defer({ departments: service.getAll(queryParams) });
		},
		handle: {
			title: 'departments.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				path: "",
				element: <DepartmentEmpty />,
			},
			{
				path: "/departments/:id",
				element: <Department />,
				loader: async ({ params }) => {
					return defer({ department: service.getById(params.id) });
				},
				handle: {
					crumb: null, //Breadcrumbs
				},
				children: [
					{
						index: true,
						element: <DepartmentInfo />,
					},
					{
						path: "/departments/:id/evaluations",
						element: <DepartmentEvaluations />,
						loader: ({ params }) => {
							return { evaluations: service.getEvalutaionsById(params.id) };
						},
						errorElement: <Error />,
					}
				],
				errorElement: <Error />,
			}
		],
		errorElement: <Error />,
	},
]

export default DepartmentRoutes