import React from 'react';
import { RouterProvider } from 'react-router-dom';
import Routes from 'routes/Routes';
import './App.scss';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import 'animate.css';
import 'moment/locale/it';
import 'moment/locale/es';
import { ThemeProvider } from 'providers/ThemeProvider';
import { LangProvider } from 'providers/LangProvider';

/**
 * TODO:
 * sistemare reducer
 * usare react router dom e creare non auth, auth, legacy, public
 * gestire la parte legacy con autoauth
 * controllo crfs sia react che laravel (almeno nell'auth)
 * logout anche da laravel quando ci si slogga su react
 * inserire nella login carousel
 * creare badge in-out
 * adattare css e js legacy solo all'occorrenza
 * usare il lazy per la parte secure
 */

const App = () => {
	return (
		<div className="App">
			<LangProvider>
				<ThemeProvider>
					<RouterProvider router={Routes} />
				</ThemeProvider>
			</LangProvider>
			<div id='portal-content'></div>
		</div>
	)
}

export default App;
