import React from 'react';
import { Trans } from 'react-i18next';
import ImportProjectPlan from './ImportProjectPlan';

const WorkSchedulesEmpty = () => {
	return (
		<div className='d-flex flex-column h-100 justify-content-center text-body-secondary text-center'>
			<div><i className='mdi mdi-file-tree-outline mdi-72px'></i></div>
			<h6><Trans i18nKey="works.item.contents.schedules.empty.title" /></h6>
			<div><Trans i18nKey="works.item.contents.schedules.empty.subtitle" /></div>
			<div className='mt-4'>
				<ImportProjectPlan />
			</div>
		</div>
	)
}

export default WorkSchedulesEmpty