import React, { useState } from 'react';
import { NavLink, Outlet, useLoaderData } from 'react-router-dom';
import { SplitScreen } from '../../../components/SplitScreen/SplitScreen';
import Item from '../../../components/Item/Item';
import ListAwait from '../../../components/List/ListAwait';
import ListItem from '../../../components/List/ListItem';
import Search from '../../../components/Search/Search';
import { Trans } from 'react-i18next';

const Furnishings = () => {
	let { furnishings } = useLoaderData();
	const [items, setItems] = useState();
	const [hasMore, setHasMore] = useState(false);

	const handleLoad = (response) => {
		setItems(oldItems => [...oldItems, ...response.data]);
		setHasMore(response.next_page_url);
	};

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (items == null || items.length == 0) {
			setItems(response.data.data);
			setHasMore(response.data.next_page_url);
		}
	}

	return (
		<SplitScreen name="assets-furnishings-list" leftClass="flex-column h-100 w-100">
			<Item>
				<div className='row g-2 align-items-center mb-2'>
					<div className='col-12'>
						<NavLink to="./new" end className='btn btn-info shadow-sm text-white d-block text-truncate disabled'><Trans i18nKey="assets.furnishings.new" /></NavLink>
					</div>
					<div className='col'>
						{/* <Filters /> */}
						<Search action="/assets/furnishings" />
					</div>
				</div>
				<ListAwait resolvePromise={furnishings} hasResolved={resolvedPromise} loadMore={handleLoad} hasMore={hasMore}>
					{items?.map((item, idx) => (
						<ListItem key={idx} item={item} />
					))}
				</ListAwait>
			</Item>
			<Outlet />
		</SplitScreen>
	)
}

export default Furnishings