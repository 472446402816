import React from 'react'
import SelectField from './fields/SelectField';
import PasswordField from './fields/PasswordField';
import EmailField from './fields/EmailField';
import TextField from './fields/TextField';
import TextAreaField from './fields/TextAreaField';
import TimeField from './fields/TimeField';
import DateField from './fields/DateField';
import HiddenFields from './fields/HiddenFields';
import FileField from './fields/FileField';
import LabelField from './fields/LabelField';
import CheckboxField from './fields/CheckboxField';
import RadioField from './fields/RadioField';
import SwitchField from './fields/SwitchField';
import PercentageField from './fields/PercentageField';
import NumberField from './fields/NumberField';
import CurrencyField from './fields/CurrencyField';
import EditorField from './fields/EditorField';
import FormSubmit from './FormSubmit';
import { isString } from 'helpers/commons';

const FormFields = ({ fields = [], item = {}, formName = 'form', wrap = true, ...props }) => {

	//Rimuovo le parentesi quadre dal formname per generare l'id
	let formId = formName.replace('[', '_');
	formId.replace(']', '');
	formId = formId.length > 0 ? formId + '_' : formId;

	const _buildFormField = (field, key) => {
		if (field == null || isString(field)) {
			return null;
		}

		field.id = formId + key;
		field.formName = formName.length > 0 ? (props.formName + '[' + props.id + ']') : key;

		//se presente l'ogetto da db, assegno il valore
		if (item[key] != null) {
			field.value = item[key];
		}

		switch (field.type) {
			case 'select': return <SelectField key={key} {...field} />;

			case 'textarea': return <TextAreaField key={key} {...field} />;

			case 'editor': return <EditorField key={key} {...field} />;

			case 'text': return <TextField key={key} {...field} />;
			case 'email': return <EmailField key={key} {...field} />;
			case 'password': return <PasswordField key={key} {...field} />;

			case 'date': return <DateField key={key} {...field} />;
			case 'time': return <TimeField key={key} {...field} />;

			case 'currency': return <CurrencyField key={key} {...field} />;
			case 'number': return <NumberField key={key} {...field} />;
			case 'percentage': return <PercentageField key={key} {...field} />;

			case 'switch': return <SwitchField key={key} {...field} />;
			case 'radio': return <RadioField key={key} {...field} />;
			case 'checkbox': return <CheckboxField key={key} {...field} />;

			case 'radio-group': return 'Plug In non creato';
			case 'checkbox-group': return 'Plug In non creato';

			case 'file': return <FileField key={key} {...field} />;

			case 'label': return <LabelField key={key} {...field} />;
			case 'hidden': return <HiddenFields key={key} {...field} />

			case 'submit': return (<div key={key} className='p-2'><FormSubmit {...field} /></div>);

			default:
				return null;
		}
	}

	return (
		<>
			{wrap ? (
				<div className="row g-0 mb-2">
					{Object.keys(fields)?.map((key) => _buildFormField(fields[key], key))}
				</div>
			) : Object.keys(fields)?.map((key) => _buildFormField(fields[key], key))
			}
		</>
	)
}

export default FormFields