import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { NavLink, Outlet, useLoaderData, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ItemNav from 'components/Item/ItemNav'
import WorkSchedulesEmpty from './WorkSchedulesEmpty';

const WorkSchedules = () => {
	const [item] = useOutletContext();
	let { projectPlanId } = useParams();

	const [projectPlan, setProjectPlan] = useState();

	useEffect(() => {
		if (item?.project_plans && item.project_plans.length != 0 && projectPlanId) {
			setProjectPlan(item?.project_plans.find(p => p.id == projectPlanId))
		}
	}, [item?.project_plans, projectPlanId])

	return projectPlan ? (
		<>
			<ItemNav className='nav-pills nav-fill bg-body-secondary align-items-center mb-2 mt-2'>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between p-1" aria-current="page" to="." end>
					<span><Trans i18nKey="works.item.contents.schedules.reports.title" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between p-1" aria-current="page" to="./wbs" end>
					<span><Trans i18nKey="works.item.contents.schedules.wbs.title" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between p-1" aria-current="page" to="./project-plan" end>
					<span><Trans i18nKey="works.item.contents.schedules.projectplan.title" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between p-1" aria-current="page" to="./milestones" end>
					<span><Trans i18nKey="works.item.contents.schedules.milestones.title" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
			</ItemNav>
			<div>
				<Outlet context={[item, projectPlan]} />
			</div>
		</>
	) : (
		<WorkSchedulesEmpty />
	)

}

export default WorkSchedules