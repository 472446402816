import { defer } from "react-router-dom";
import { Error } from "components/Error/Error";
import Works from "pages/projects/works/Works";
import Work from "pages/projects/works/item/Work";
import WorkEmpty from "pages/projects/works/item/WorkEmpty";
import WorkForm from "pages/projects/works/item/WorkForm";
import WorkInfo from "pages/projects/works/item/info/WorkInfo";

import WorkSchedules from "pages/projects/works/item/schedule/WorkSchedules";
import WorkReports from "pages/projects/works/item/schedule/reports/WorkReports";
import WorkWbs from "pages/projects/works/item/schedule/wbs/WorkWbs";
import WorkProjectPlan from "pages/projects/works/item/schedule/project-plan/WorkProjectPlan";
import WorkMilestones from "pages/projects/works/item/schedule/milestones/WorkMilestones";

import WorkService from "services/WorkService";
import ProjectPlanService from "services/ProjectPlanService";

const workService = new WorkService();
const projectPlanService = new ProjectPlanService();
const WorkRoutes = [
    {
        path: "/works",
        element: <Works />,
        loader: async ({ request }) => {
            return defer({ works: workService.getAll(new URL(request.url).search) });
        },
        handle: {
            title: 'works.title',
            crumb: null, //Breadcrumbs
        },
        children: [
            {
                index: true,
                path: "",
                element: <WorkEmpty />,
            },
            {
                path: "/works/:id",
                element: <Work />,
                loader: ({ params }) => {
                    return defer({ work: workService.getById(params.id) });
                },
                handle: {
                    crumb: null, //Breadcrumbs
                },
                children: [
                    {
                        index: true,
                        element: <WorkInfo />,
                    },
                    {
                        path: "/works/:id/schedules/:projectPlanId?",
                        element: <WorkSchedules />,
                        children: [
                            {
                                index: true,
                                loader: ({ params }) => {
                                    return defer({ reports: projectPlanService.getReportsById(params.projectPlanId) });
                                },
                                element: <WorkReports />,
                            },
                            {
                                path: "/works/:id/schedules/:projectPlanId/wbs",
                                element: <WorkWbs />,
                                loader: ({ params }) => {
                                    return defer({ wbs: projectPlanService.getAllWbsById(params.projectPlanId) });
                                },
                                errorElement: <Error />,
                            },
                            {
                                path: "/works/:id/schedules/:projectPlanId/project-plan",
                                element: <WorkProjectPlan />,
                                errorElement: <Error />,
                            },
                            {
                                path: "/works/:id/schedules/:projectPlanId/milestones",
                                element: <WorkMilestones />,
                                errorElement: <Error />,
                            },
                        ],
                        errorElement: <Error />,
                    }
                ],
                errorElement: <Error />,
            },
            {
                path: "/works/new",
                element: <WorkForm />,
            },
            {
                path: "/works/:id/edit",
                element: <WorkForm />,
                loader: ({ params }) => {
                    return defer({ work: workService.getById(params.id) });
                },
                errorElement: <Error />,
            },
        ],
        errorElement: <Error />,

        future: {
            v7_normalizeFormMethod: true,
        },
    },

    // {
    //     path: "/works/:id/planning-controlling/:ppId",
    //     element: <PPC />,
    //     loader: ({ params }) => {
    //         let projectPlan = (params.ppId !== null && params.ppId != 'latest' ? projectPlanService.getById(params.ppId) : projectPlanService.getLatestByTabId(params.id)).then((response) => response.data);

    //         return defer({
    //             data: workService.getById(params.id).then(async (response) => {
    //                 response.data.currentProjectPlan = await projectPlan;
    //                 return response;
    //             })
    //         });
    //     },
    //     handle: {
    //         crumb: null, //Breadcrumbs
    //     },
    //     children: [
    //         {
    //             index: true,
    //             element: <Report />,
    //         },
    //         {
    //             path: "/works/:id/planning-controlling/:ppId/wbs",
    //             element: <Wbs />,
    //             // loader: ({ params }) => {
    //             // return defer({ company: getProjectPlanWbs(params.id) });
    //             // },
    //             errorElement: <Error />,
    //         },
    //         {
    //             path: "/works/:id/planning-controlling/:ppId/project-plan",
    //             element: <ProjectPlan />,
    //             // loader: ({ params }) => {
    //             // return defer({ company: getProjectPlanProfiles(params.id) });
    //             // },
    //             errorElement: <Error />,
    //         },
    //         {
    //             path: "/works/:id/planning-controlling/:ppId/milestones",
    //             element: <Milestones />,
    //             // loader: ({ params }) => {
    //             // return defer({ company: getProjectPlanById(params.id) });
    //             // },
    //             errorElement: <Error />,
    //         },
    //     ],
    //     errorElement: <Error />,
    //     future: {
    //         v7_normalizeFormMethod: true,
    //     },
    // },
]

export default WorkRoutes