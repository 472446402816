import MotionComponent from 'components/Motion/MotionComponent';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

const ProfileSecure = () => {
	const [item] = useOutletContext();
	const { permissions } = useAuth();

	return (
		<>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='profile.item.contents.secure.edit_password' /></h6>
				</div>
				<div className='col-12'>
					<div className='bg-warning-subtle rounded p-2 text-warning mb-2 text-center'>
						<Trans i18nKey='views:wip' />
					</div>
				</div>
			</div>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='profile.item.contents.secure.edit_avatar' /></h6>
				</div>
				<div className='col-12'>
					<div className='bg-warning-subtle rounded p-2 text-warning mb-2 text-center'>
						<Trans i18nKey='views:wip' />
					</div>
				</div>
			</div>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='profile.item.contents.secure.permissions' /></h6>
				</div>
				<div className='col-12'>
					<div className='row'>{permissions.map((p, key) => (
						<div key={key} className='col-12 col-lg-4'>
							<i className='mdi mdi-checkbox-marked-circle-outline mdi-18px text-success pe-1' />
							<span>{p.nome}</span>
						</div>
					))}</div>
				</div>
			</div>
			<div className='row g-0 align-items-center text-center bg-danger text-danger bg-opacity-10 mt-3 rounded p-2'>
				<div className='col-12'>
					<h6 className='m-0'><Trans i18nKey='profile.item.contents.secure.disable.title' /></h6>
					<div className='text-body-ternary'><Trans i18nKey='profile.item.contents.secure.disable.subtitle' /></div>
				</div>
				<div className='col-12 pt-3'>
					<MotionComponent as='button' whileTap={{ scale: 0.8 }} className='btn btn-danger text-white' disabled>
						<i className='mdi mdi-account-off-outline mdi-18px' />
						<Trans i18nKey='profile.item.contents.secure.disable.button' />
					</MotionComponent>
				</div>
			</div>
		</>
	)
}

export default ProfileSecure