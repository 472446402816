import React from 'react'
import { Trans } from 'react-i18next'
import BadgeCount from './BadgeCount'
import BadgeItem from './BadgeItem'

const BadgeMenu = ({ badges }) => {

	return (
		<div className='h-100'>
			<div className='text-center'>
				<h1 className='display-2 fw-semibold m-0'>
					<BadgeCount badges={badges} format='hh:mm:ss' />
				</h1>
				{badges != null && badges.length > 0 ? (
					<>
						<h4 className='m-0'>{badges[badges.length]?.location?.nome}</h4>
						<h6 className='m-0 text-body-emphasis'>{badges[badges.length]?.location?.fulladdress}</h6>
					</>
				) : null}
			</div>

			<div className='p-1' style={{ height: 'calc(100% - 140px)' }}>
				<ul className='list-group shadow-sm' >
					{badges?.map((badge, idx) => (
						<BadgeItem key={idx} badge={badge} linkable={false} />
					))}
				</ul>
			</div>

			<div className='row g-0 align-items-center'>
				<div className='col-6 p-1'>
					<button className='btn bg-warning text-warning bg-opacity-25 w-100 text-center'>
						<i className='mdi mdi-pause-circle-outline mdi-18px me-1' />
						<span className='d-inline-block fs-6'><Trans i18nKey='components:badgeMenu.pause' /></span>
					</button>
				</div>
				<div className='col-6 p-1'>
					<button className='btn bg-danger text-danger bg-opacity-25 w-100 text-center'>
						<i className='mdi mdi-stop-circle-outline mdi-18px me-1' />
						<span className='d-inline-block fs-6'><Trans i18nKey='components:badgeMenu.stop' /></span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default BadgeMenu