// Generate Empty Mixed Chart for Funzione Team
export const _generateFTMixedChart = () => {
	return {
		id: 'mixed-chart',
		type: 'line',
		series: [
			{ name: 'Valutazione Risorsa', type: "line", data: [] },
			{ name: 'Scostamento', type: "column", data: [] }
		], options: {
			colors: [],
			chart: {
				height: 350,
				width: '100%',
				type: "line"
			},
			plotOptions: {
				bar: {
					colors: {
						ranges: [{
							from: 0,
							to: 5,
						}, {
							from: -5,
							to: 0,
							color: '#CF0000'
						}]
					},
					columnWidth: '75%',
				}
			},
			stroke: {
				width: [3, 0]
			},
			labels: [],
			yaxis: {
				type: 'numeric',
				decimalsInFloat: 1,
				min: 0,
				max: 5,
			},
			tooltip: {
				show: false,
				x: {
					format: 'MMM yyyy',
				}
			},
			legend: {
				show: false
			},
			grid: {
				padding: {
					right: 40
				}
			},
			xaxis: {
				type: "datetime",
				format: 'MM yyyy',
				formatter: function (value, timestamp, opts) {
					return new Date(timestamp != null ? (timestamp) : null).toLocaleDateString('it-IT', { year: 'numeric', month: 'long' });
				},
			},

		}
	};
};