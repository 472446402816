
import React, { createContext, useContext, useEffect } from 'react'
import { Trans } from 'react-i18next';
import { Form, useSearchParams } from 'react-router-dom'

export const FiltersContext = createContext();

export const FiltersGroupContext = createContext();

export const Filters = ({ ...props }) => {
	props.method = props.method || "get";
	props.view = props.view || "vertiacal-pills";
	return (

		<Form method={props.method} action={props.action}>
			<FiltersContext.Provider value={{ view: props.view }}>
				{props.children}
			</FiltersContext.Provider>
		</Form>
	)
}

export const FiltersGroup = ({ ...props }) => {
	props.type = props.type || "checkbox";
	return (
		<div className='mb-2'>
			<div><h6 className='text-truncate'><Trans i18nKey={props.label}>{props.label}</Trans></h6></div>
			<FiltersGroupContext.Provider value={{ type: props.type }}>
				<div className='flex-column'>{props.children}</div>
			</FiltersGroupContext.Provider>
		</div>
	)
}

export const FiltersItem = ({ ...props }) => {
	props.uuid = Date.now();
	const { view } = useContext(FiltersContext); //viste differenti lista, dropdown, pills
	const { type } = useContext(FiltersGroupContext); //tipologie differenti radio/checkbox

	let [searchParams] = useSearchParams();

	props.type = type || "checkbox";
	props.name = props.type === "checkbox" ? props.name + "[]" : props.name;
	props.isChecked = searchParams.has(props.name, props.value) || ((props.value == null || props.value.length == 0) && searchParams.has(props.name));

	return (
		<div className='pb-2'>
			<input
				type={props.type}
				className="btn-check"
				id={props.uuid}
				name={props.name}
				defaultValue={props.value}
				defaultChecked={props.isChecked}
				autoComplete="off"
				onChange={(e) => e.target.form.submit()}
			/>
			<label className={'btn text-start text-truncate d-block' + (props.isChecked ? ' bg-primary text-white shadow-sm' : ' btn-light')} htmlFor={props.uuid}>
				<Trans i18nKey={props.label}>{props.label}</Trans>
			</label>
		</div>
	)
}