import Reports from "pages/reports/Reports"
import { defer } from "react-router-dom";
import PowerBiService from "services/external/PowerBiService";

const ReportRoutes = [
	{
		path: "/reports/:type/:id",
		element: <Reports />,
		handle: {
			title: 'report.title',
			crumb: null, //Breadcrumbs
		},
		loader: async ({ params }) => {
			if (!params.type || !params.id) {
				console.log('parametro non valido');
			}

			const service = new PowerBiService();

			document.title = 'report.' + params.type;

			return defer({ report: service.getReportItem(params.id) });
		},
		future: {
			v7_normalizeFormMethod: true,
		},
	},
];


/*
Non serve più va passato il report id nella tabella tbl_nav

const reportTypes = {
	'control-tower': {
		'title': 'Control Tower',
		'reportId': '00302f76-d232-4ab2-b32b-a7729b11f88b',
		'url': 'https://app.powerbi.com/view?r=eyJrIjoiNGZlMDBhNzMtNDQ5NC00YmEyLTllNmEtMTQyNDhhYjUxNjU0IiwidCI6ImFjN2UyYjYxLWQ2YmMtNDAxMC04NWExLTBkMDRhOGJiNDliMCIsImMiOjl9',
	},
	'budget-hours': {
		'title': 'Budget Ore',
		'reportId': '1e91fd2f-5796-40cd-ace5-2a11fb628619',
		'url': 'https://app.powerbi.com/view?r=eyJrIjoiMDIzZThkMzQtOTNhMi00YThiLWI1MDYtOGQ5MmQzMTc1MjQ1IiwidCI6ImFjN2UyYjYxLWQ2YmMtNDAxMC04NWExLTBkMDRhOGJiNDliMCIsImMiOjl9&pageName=ReportSection%22',
	},
	'hours-analysis': {
		'title': 'Analisi Ore Commesse',
		'reportId': 'c2cc42dd-397f-4f54-a20c-198df2a91bba',
		'url': 'https://app.powerbi.com/view?r=eyJrIjoiNjk5ZTQzNjMtYjNmYi00MzhjLWIzYzEtYmFlMDAyNjYyZjYxIiwidCI6ImFjN2UyYjYxLWQ2YmMtNDAxMC04NWExLTBkMDRhOGJiNDliMCIsImMiOjl9&pageName=ReportSection%22',
	},
	'economic-analysis': {
		'title': 'Analisi Economica Commesse',
		'reportId': '73499c97-1d9e-4614-a8d4-2fee3517d073',
		'url': 'https://app.powerbi.com/view?r=eyJrIjoiZDI0NjExNmEtZWQxYS00YWYxLThiZTctYWQ0MmFjMjczM2M4IiwidCI6ImFjN2UyYjYxLWQ2YmMtNDAxMC04NWExLTBkMDRhOGJiNDliMCIsImMiOjl9&pageName=ReportSectioneb0d1603518362c97935%22',
	},
	'costs-revenues-analysis': {
		'title': 'Analisi Costi-Ricavi Commesse',
		'reportId': '967444cc-d0bc-4437-a29f-203c43dfe851',
		'url': 'https://app.powerbi.com/view?r=eyJrIjoiMDE1MmYwYTMtYmU1Yy00MTlhLWJlZDUtMWI5Nzg0OWMyODg3IiwidCI6ImFjN2UyYjYxLWQ2YmMtNDAxMC04NWExLTBkMDRhOGJiNDliMCIsImMiOjl9'
	},
	'task-report': {
		'title': 'Report Task & Planning',
		'reportId': 'ce0830c8-a48f-4138-8899-0892f0fad14a',
		'url': 'https://app.powerbi.com/view?r=eyJrIjoiODQ0MWZmYmItOWVjNC00NWVkLTg5NDYtODBkMDkxYzk4MTFhIiwidCI6ImFjN2UyYjYxLWQ2YmMtNDAxMC04NWExLTBkMDRhOGJiNDliMCIsImMiOjl9'
	},
}*/


export default ReportRoutes