import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';
import { useLang } from 'providers/LangProvider';
import Loader from 'components/Loader/Loader';
import { AnimatePresence, motion } from 'framer-motion';

import './Calendar.scss';
import { OffCanvas, OffCanvasContent } from 'components/Bootstrap/OffCanvas';

const Calendar = ({ eventSources, eventInfo = null, initialDate = null, datesSet = null, ...props }) => {
	const ref = useRef();
	const calendarLoading = useRef();
	let { lang } = useLang();
	const [event, setEvent] = useState();

	useLayoutEffect(() => {
		screenResize();
		window.addEventListener('resize', screenResize, {});

		return () => {
			window.removeEventListener('resize', screenResize);
		}
	}, []);

	useEffect(() => {
		ref.current?.getApi().on('loading', (isLoading) => {
			if (isLoading) {
				calendarLoading.current?.classList.add('d-block');
				calendarLoading.current?.classList.remove('d-none');
				return;
			} else {
				calendarLoading.current?.classList.remove('d-block');
				calendarLoading.current?.classList.add('d-none');
			}
		});
	}, [ref.current, calendarLoading.current]);

	const screenResize = () => {
		let view = window.innerWidth <= 1200 ? 'listMonth' : 'dayGridMonth';
		if (ref.current?.getApi().view != view) {
			ref.current.getApi().changeView(view);
		}
	};

	const RenderEventContent = (eventInfo) => {
		return (
			<motion.div layoutId={eventInfo.event.id}>
				<div className='fc-event-time pe-1'>
					<strong>{eventInfo.timeText}</strong>
				</div>
				<div className='text-wrap lh-1' title={eventInfo.event.extendedProps.description}>
					<div className='fc-event-title'>{eventInfo.event.title}</div>
					<div>{eventInfo.event.extendedProps.description}</div>
				</div>
			</motion.div>
		)
	};

	const RenderEventDetail = () => {
		return (
			<div className='event-details'>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.2, delay: 0.1 }}
					style={{ pointerEvents: 'auto' }}
					className='overlay'
					onClick={() => setEvent(null)}
				>

					<motion.div className={'event-wrap' + (!eventInfo ? ' event-wrap-default' : '')} layoutId={event.id} id={event.id} animate={{ transitionEnd: { transform: 'translate(-50%, -50%)' } }}>
						<div className='h-100 p-2 position-relative w-100'>{eventInfo ? eventInfo(event) : <div dangerouslySetInnerHTML={{ __html: event.innerHTML }} />}</div>
					</motion.div>
				</motion.div>
			</div>
		)
	}

	const calendarOptions = {
		plugins: [dayGridPlugin, listPlugin],
		headerToolbar: {
			start: 'title',
			end: 'today prev,next'
		},
		initialDate: initialDate,
		datesSet: datesSet,
		initialView: 'dayGridMonth',
		locales: allLocales,
		height: 'auto',
		contentHeight: 'auto',
		displayEventEnd: true,
		dayMaxEventRows: 4,
		eventContent: RenderEventContent,
	}

	const handleEventClick = ({ event, el }) => {
		setEvent(eventInfo ? event : el);
	};

	const fullCalendar = useMemo(() => (
		<FullCalendar
			ref={ref}
			eventSources={eventSources}
			eventClick={handleEventClick}
			locale={lang}
			{...calendarOptions}
		/>
	), [eventSources])

	return (
		<div className=' position-relative h-100'>
			{fullCalendar}
			<div ref={calendarLoading} className='position-absolute z-3 top-0 start-0 w-100 h-100 bg-white bg-opacity-50'>
				<Loader />
			</div>
			<AnimatePresence>
				{event && <RenderEventDetail />}
			</AnimatePresence>
		</div>
	)
}

export default Calendar