import React from 'react'
import { Trans } from 'react-i18next'
/**
 * Generate a document view element for view
 * @param {object} item document  
 * @param {object} options
 * @param {object} options.numberFormat view: Intl.NumberFormat options
 * @param {object} options.dateFormat view: Intl.DateTimeFormat options
 * @returns 
 */
const Document = ({ item, options = {} }) => {

	//TODO:
	//in base al mine-type mostro diverse opzioni
	//se è una foto uso un image shower
	//se è un pdf lo apro in un altra scheda
	//se è un qualsiasi altro file lo scarico

	options = Object.assign({
		numberFormat: {
			locale: 'it-IT',
			notation: "compact",
			style: "unit",
			unit: "kilobyte",
			unitDisplay: "narrow",
		},
		dateFormat: {
			locale: 'it-IT',
			dateStyle: 'short',
			timeZone: 'Europe/Rome'
		}
	}, options);


	item.size = Intl.NumberFormat(options.numberFormat.locale, options.numberFormat).format(item.size / 1024);

	item.start = item.start != null ? Intl.DateTimeFormat(options.dateFormat.locale, options.dateFormat).format(new Date(item.start)) : null;

	item.end = item.end != null ? Intl.DateTimeFormat(options.dateFormat.locale, options.dateFormat).format(new Date(item.end)) : null;

	if (item.mime_type.includes('image/')) {
		item.icon = <i className='mdi mdi-image-area text-body-tertiary mdi-24px' />
		item.button = <a href={process.env.REACT_APP_CDN_URL + (item.file.startsWith('/') ? '' : '/') + item.file} title={item.name} target='_Blank' className='btn shadow-sm text-primary border btn-sm px-3'>
			<Trans i18nKey='components:document.open' />
		</a>;
		// item.button = <button className='btn shadow-sm text-primary border btn-sm px-3' onClick={null}>
		// 	<Trans i18nKey='components:document.show' />
		// </button>;

	} else if (item.mime_type == 'application/pdf') {
		item.icon = <i className='mdi mdi-file-pdf-box text-body-tertiary mdi-24px' />
		item.button = <a href={process.env.REACT_APP_CDN_URL + (item.file.startsWith('/') ? '' : '/') + item.file} title={item.name} target='_Blank' className='btn shadow-sm text-primary border btn-sm px-3'>
			<Trans i18nKey='components:document.open' />
		</a>;
	} else {
		item.icon = <i className='mdi mdi-file-download-outline text-body-tertiary mdi-24px' />
		item.button = <a download={item.name} href={process.env.REACT_APP_CDN_URL + (item.file.startsWith('/') ? '' : '/') + item.file} title={item.name} target='_Blank' className='btn shadow-sm text-primary border btn-sm px-3'>
			<i className='mdi mdi-download mdi-14px' /> <Trans i18nKey='components:document.download' />
		</a>
	}

	return (
		<div className='row g-0 align-items-center'>
			<div className='col-auto'>
				{item.icon}
			</div>
			<div className='col ps-2 text-truncate lh-1'>
				<div className='text-truncate fw-semibold'>{item.name}.{item.extension}</div>
				<div className='text-truncate small'>{item.size}{item.end != null ? (' - ' + item.end) : ''}</div>
			</div>
			<div className='col-auto'>
				{item.button}
			</div>
		</div>
	)
}

export default Document