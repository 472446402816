import React from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import Avatar from 'components/Avatar/Avatar';
import DetailView from 'components/DetailView/DetailView';

const ProfileAssets = () => {
	const [item] = useOutletContext();

	return (
		<div className='row g-0 align-items-center'>
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='profile.item.contents.assets.title' /></h6>
			</div>
			<div className='col-12'>
				<ul className="list-group">
					{item.assets != null && item.assets.length > 0 ? (item.assets?.map((asset, idx) => (
						<li key={idx} className='list-group-item p-1'>
							<div key={idx} className='row g-0 align-items-stretch text-center text-lg-start'>

								<DetailView
									item={asset}
									attributes={[
										{
											formatter: 'raw',
											value: () => (<Avatar item={asset.avatar} />),
											cssClass: 'col-lg-auto p-2'
										},
										{
											label: 'typology.name',
											value: 'typology.name',
											cssClass: 'col-lg p-2'
										},
										"name",
										"start:date",
										{
											label: 'barCode',
											formatter: 'raw',
											value: () => ((asset.qrCode || asset.barCode) && <><i className="mdi mdi-barcode mdi-24px"></i>{asset.qrCode || asset.barCode}</>),
										},
										{
											label: 'assignment.label',
											value: () => asset.assignment && <Trans i18nKey={'models:asset.assignment.value.' + asset.assignment} />,
											formatter: 'raw',
										},
									]}
									options={{
										transPrefix: 'models:asset.',
										cssClass: 'col-12 col-lg p-2'
									}} />
							</div>
						</li>
					))) : (<div className='text-center text-body-tertiary small'>
						<Trans i18nKey='profile.item.contents.assets.no_asset' />
					</div>)}
				</ul>
			</div>
		</div>
	)
}

export default ProfileAssets