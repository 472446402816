import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

i18n
	.use(ChainedBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		load: 'languageOnly',
		locales: ['en', 'it', 'es'],
		fallbackLng: 'it',
		debug: process.env.REACT_APP_NODE_ENV != 'production',

		interpolation: {
			escapeValue: false,
		},

		ns: ['models', 'views', 'components'],
		defaultNS: 'views',

		backend: {
			backends: [LocalStorageBackend, HttpBackend],
			backendOptions: [],
			cacheHitMode: 'refreshAndUpdateStore',
		},

		react: {
			bindI18nStore: 'added', // this way, when the HttpBackend delivers new translations (thanks to refreshAndUpdateStore), the UI gets updated
		},

		whitelist: ['it', 'en', 'es'],
	});

export default i18n;