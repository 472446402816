import Item from 'components/Item/Item';
import React from 'react'
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const FurnitureEmpty = () => {
	return (
		<Item>
			<div className='d-flex flex-column h-100 justify-content-center text-body-secondary text-center'>
				<div><i className='mdi mdi mdi-dresser-outline mdi-72px'></i></div>
				<h6><Trans i18nKey="assets.furnishings.item.empty.title" /></h6>
				<div><Trans i18nKey="assets.furnishings.item.empty.subtitle" /></div>
				<div className='mt-4'>
					<NavLink className='btn bg-primary text-white shadow-sm disabled' aria-current="page" to={"./new"} end>
						<i className='mdi mdi-plus-outline me-1'></i>
						<Trans i18nKey="assets.furnishings.item.empty.button" />
					</NavLink>
				</div>
			</div>
		</Item>
	)
}

export default FurnitureEmpty