import React, { useState, useEffect } from "react";
import TopicList from "components/TopicList/TopicList";
import TopicSettings from "components/TopicList/settings/TopicSettings";
import TopicService from "services/TopicService";
import { Trans } from "react-i18next";
import Loader from "components/Loader/Loader";
import StarRating from "components/StarRating/StarRating";
import { useAuth } from "hooks/useAuth";

const topicService = new TopicService();
const Topics = () => {
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([]);

	const { user } = useAuth();

	useEffect(() => {
		topicService.getAllMe()
			.then((response) => {
				setItems(response.data);
				setLoading(false);
			});
	}, []);

	//update after sorting
	const handleCallBack = (items) => {

		let orderItems = [];
		items.forEach((item, idx) => {
			orderItems.push({
				'idTopic': item.id,
				'displayOrder': idx,
				'emptyHidden': item.emptyHidden ? 1 : 0,
			});
		});

		setLoading(true);
		topicService.updatePreference({ 'orderItems': orderItems })
			.then((response) => {
				setItems(items);
				setLoading(false);
			});
	}

	return (
		<>
			{!loading && items != null && items.length > 0 ? (
				<>
					<div className="ps-2">
						<div className="row g-0 lh-sm align-items-center">
							<div className="col text-start">
								{user?.latest_evaluation[0]?.rating_value && (<>
									<div><span className="text-body-secondary"><Trans i18nKey='views:dashboard.topics.evaluations' />:</span></div>
									<div><StarRating actual={user.latest_evaluation[0].rating_value} justify='start' disableAnimation={true} /></div>
								</>
								)}
							</div>
							<div className="col-auto pt-2 pt-md-0 text-end">
								{items != null & items.length > 0 ? (<TopicSettings items={items} handleCallBack={handleCallBack} />) : null}
							</div>
						</div>
					</div>
					{items?.map((item, index) => (
						<TopicList key={index} rowID={item.id} item={item} />
					))}
				</>
			) : !loading ? (
				<div className="p-2 rounded bg-secondary bg-opacity-10 text-secondary text-center">
					<i className="mdi mdi-emoticon-sad-outline mdi-36px"></i>
					<div><Trans i18nKey='views:dashboard.topics.no_stories' /></div>
				</div>
			) : (
				<Loader title={<Trans i18nKey='views:dashboard.topics.loading' />} />
			)}
		</>
	);
};

export default Topics;
