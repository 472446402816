import { Error } from "components/Error/Error";
import Resources from "pages/users/resources/Resources";
import Resource from "pages/users/resources/item/Resource";
import ResourceEmpty from "pages/users/resources/item/ResourceEmpty";
import ResourceAssets from "pages/users/resources/item/assets/ResourceAssets";
import ResourceBadges from "pages/users/resources/item/badges/ResourceBadges";
import ResourceContracts from "pages/users/resources/item/contracts/ResourceContracts";
import ResourceDocuments from "pages/users/resources/item/documents/ResourceDocuments";
import ResourceEvaluations from "pages/users/resources/item/evaluations/ResourceEvaluations";
import ResourceInfo from "pages/users/resources/item/info/ResourceInfo";
import ResourceProjects from "pages/users/resources/item/projects/ResourceProjects";
import ResourceSecure from "pages/users/resources/item/secure/ResourceSecure";
import ResourceSkills from "pages/users/resources/item/skills/ResourceSkills";
import { defer } from "react-router-dom";
import ResourceService from "services/ResourceService";

const service = new ResourceService();
const ResourceRoutes = [
	{
		path: "/resources",
		element: <Resources />,
		loader: async ({ request }) => {
			return defer({ resources: service.getAll(new URL(request.url).search) });
		},
		handle: {
			title: 'resources.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				path: "",
				element: <ResourceEmpty />,
			},
			{
				path: "/resources/:id",
				element: <Resource />,
				loader: ({ params }) => {
					return defer({ resource: service.getById(params.id, { showAll: true }) });
				},
				handle: {
					crumb: null, //Breadcrumbs
				},
				children: [
					{
						index: true,
						element: <ResourceInfo />,
					},
					{
						path: "/resources/:id/evaluations",
						element: <ResourceEvaluations />,
					},
					{
						path: "/resources/:id/secure",
						element: <ResourceSecure />,
					},
					{
						path: "/resources/:id/projects",
						element: <ResourceProjects />,
					},
					{
						path: "/resources/:id/assets",
						element: <ResourceAssets />,
					},
					{
						path: "/resources/:id/skills",
						element: <ResourceSkills />,
					},
					{
						path: "/resources/:id/badges",
						element: <ResourceBadges />,
					},
					{
						path: "/resources/:id/documents",
						element: <ResourceDocuments />,
					},
					{
						path: "/resources/:id/contracts",
						element: <ResourceContracts />,
					},
				],
				errorElement: <Error />,
			},
		],
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]

export default ResourceRoutes