import React, { Suspense, useLayoutEffect, useState } from 'react';
import { Await, NavLink, useAsyncValue, useLoaderData, useMatches } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import * as bootstrap from 'bootstrap'
import { Trans } from 'react-i18next';
import { Popover, PopoverBody, PopoverButton, PopoverContent, PopoverHeader } from 'components/Popover/Popover';
import Collapse, { CollapseButton, CollapseContent } from 'components/Bootstrap/Collapse';
import Tooltip from 'components/Tooltip/Tooltip';
import MotionComponent from 'components/Motion/MotionComponent';

import './SideNav.scss';
import Langs from './Langs';
import Themes from './Themes';

const SideNav = () => {
	const { nav } = useLoaderData();

	const [items, setItems] = useState([]);
	const [short, setShort] = useState(false);

	const screenResize = () => {
		if (window.innerWidth <= 1200) {
			setShort(false);
		} else {
			setShort(localStorage.getItem('navbar-short') ? localStorage.getItem('navbar-short') === 'true' : true);
		}
	};

	useLayoutEffect(() => {
		screenResize();
		window.addEventListener("resize", screenResize);

		return () => {
			window.removeEventListener("resize", screenResize);
		}
	}, []);


	function recursive(flatArray, parentId = 0) {
		let hierarchy = [];
		if (flatArray != null) {
			flatArray.forEach(item => {
				if (item.parentId == parentId) {
					let children = recursive(flatArray, item.id);
					if (children.length) {
						item.children = children;
					}

					hierarchy.push(item);
				}
			});
		}
		return hierarchy;
	}

	function handleLink() {
		setTimeout(() => {
			document.querySelectorAll('#sidenav:not(.navbar-short) a').forEach((el) => {
				el.addEventListener('click', () => {
					document.getElementById('sidenav').classList.remove('show');
				})
			})
		}, 250)
	}

	const handleToggle = () => {
		setShort(!short);
		localStorage.setItem('navbar-short', !short)
		document.querySelectorAll('#sidenav .collapse').forEach((el) => {
			let bsCollapse = bootstrap.Collapse.getInstance(el);
			bsCollapse && bsCollapse.hide();
		});
	}

	const handleHide = () => {
		document.getElementById('sidenav').classList.remove('show', 'navbar-short');
	}

	const HasResolved = () => {
		const data = useAsyncValue();
		if (items == null || items.length == 0) {
			setTimeout(() => {
				//inserisco un delay di 250ms per evitare di sovrapporre troppi aggiornamenti dei componenti nello stesso momento
				setItems(recursive(data.data));
				sessionStorage.setItem('nav', JSON.stringify(data)); //evito di ricaricare nav in continuazione
				handleLink();
			}, 250)
		}
		return true;
	}

	return (
		<>
			<nav id='sidenav' className={'navbar border-end' + (short ? ' navbar-short' : '')}>
				<div className='navbar-vertical-header'>
					<Tooltip title={process.env.REACT_APP_NAME}>
						<a className='navbar-brand' href='./'>
							<img width='40' src={process.env.REACT_APP_LOGO} alt={process.env.REACT_APP_NAME} style={{ verticalAlign: 'bottom' }} />
							<span className='lh-1'>
								<span>{process.env.REACT_APP_NAME}</span>
								<small className='fw-light small text-muted'>{process.env.REACT_APP_SUB_NAME}</small>
							</span>
						</a>
					</Tooltip>
				</div>
				<OverlayScrollbarsComponent
					options={{
						showNativeOverlaidScrollbars: false,
						overflow: (short ? { x: 'hidden' } : {}),
						scrollbars: { autoHide: 'leave', autoHideDelay: 1300 },
					}}
					defer
					style={{ height: 'calc(100vh - 102px)', width: '100%' }}
				>
					<div className='row g-0 text-start'>
						<ul className='navbar-nav flex-column' id='sidenav-menu'>
							<Suspense fallback={<SideNavPlaceHolder key={'LIP-1' + Date.now()} />}>
								<Await resolve={nav}>
									<HasResolved />
									{items?.map((item, key) => (
										<SideNavItem item={item} key={key} isShort={short} />
									))}
								</Await>
							</Suspense>
						</ul>
					</div>
				</OverlayScrollbarsComponent>
				<div className='navbar-vertical-footer'>
					<Tooltip title={<Trans i18nKey={!short ? 'components:sidenav.hide' : 'components:sidenav.show'} />}>
						<MotionComponent as={'button'} whileTap={{ scale: 0.8 }} className='border-0 btn fw-semibold text-start text-truncate w-100 d-flex' onClick={handleToggle}>
							<i className={!short ? 'mdi mdi-arrow-collapse-left me-2' : 'mdi mdi-arrow-collapse-right me-2'} />
							<span><Trans i18nKey='components:sidenav.hide' /></span>
						</MotionComponent>
					</Tooltip>
					{!short && (<>
						<Langs />
						<Themes />
					</>)}
				</div>
			</nav>
			<div className='offcanvas-backdrop fade' onClick={handleHide} />
		</>
	)
}

const SideNavItem = ({ item, isShort, isChild = false }) => {

	const matches = useMatches();

	const hasActiveChildren = !isChild && matches.some(m => item.children != null && item.children.find(i => i.url == m.pathname));

	return (
		item != null && item?.children ? (
			<li className='nav-item'>
				{isShort ? (
					<Popover placement='right'>
						<PopoverButton className={'nav-link' + (hasActiveChildren ? ' active' : '')}>
							<i className='mdi mdi-chevron-right' data-chevron />
							<i className={item.avatar.icon} data-icon />
							<span><Trans i18nKey={'components:sidenav.items.' + item.title} /></span>
							{isChild && (<i className='mdi mdi-chevron-right mdi-18px' />)}
						</PopoverButton>
						<PopoverContent className='popover sidenav-popover bs-popover-auto fade show '>
							<PopoverHeader>
								<div className='nav-item'>
									<i style={{ paddingLeft: '0.8rem' }} data-chevron />
									<i className={item.avatar.icon + ' pe-2'} data-icon />
									<span><Trans i18nKey={'components:sidenav.items.' + item.title} /></span>
								</div>
							</PopoverHeader>
							<PopoverBody>
								<OverlayScrollbarsComponent
									options={{
										showNativeOverlaidScrollbars: false,
										overflow: isShort ? { x: 'hidden' } : {},
										scrollbars: { autoHide: 'leave', autoHideDelay: 1300 },
									}}
									defer
									style={isShort ? { height: '100%', maxHeight: '200px', width: '100%' } : {}}
								>
									<ul className='navbar-nav'>
										{item.children?.map((child, key) => (
											<SideNavItem item={child} key={key} isChild={true} />
										))}
									</ul>
								</OverlayScrollbarsComponent>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				) : (
					<Collapse>
						<CollapseButton
							className={'nav-link' + (hasActiveChildren ? ' active' : '')}
							id={'sidenav-item-' + item.id}
						>
							<i className='mdi mdi-chevron-right' data-chevron />
							<i className={item.avatar.icon} data-icon />
							<span><Trans i18nKey={'components:sidenav.items.' + item.title} /></span>
							{isChild && (<i className='mdi mdi-chevron-right mdi-18px' />)}
						</CollapseButton>
						<CollapseContent id={'sidenav-item-' + item.id} parentId='#sidenav-menu'>
							<OverlayScrollbarsComponent
								options={{
									showNativeOverlaidScrollbars: false,
									overflow: isShort ? { x: 'hidden' } : {},
									scrollbars: { autoHide: 'leave', autoHideDelay: 1300 },
								}}
								defer
								style={isShort ? { height: '100%', maxHeight: '200px', width: '100%' } : {}}
							>
								<ul className='navbar-nav'>
									{item.children?.map((child, key) => (
										<SideNavItem item={child} key={key} isChild={true} />
									))}
								</ul>

							</OverlayScrollbarsComponent>
						</CollapseContent>
					</Collapse>
				)}
			</li >
		) : (
			<Tooltip title={<Trans i18nKey={'components:sidenav.items.' + item.title} />} placement='right' >
				<li className='nav-item'>
					<MotionComponent
						as={NavLink}
						whileTap={{ scale: 0.8 }}
						to={item.url}
						className='nav-link'
					>
						<i style={{ paddingLeft: '0.8rem' }} data-chevron />
						<i className={item.avatar.icon} data-icon />
						<span><Trans i18nKey={'components:sidenav.items.' + item.title} /></span>
						{item?.text && (isShort ? (
							<em className='bg-primary border border-white end-0 p-1 position-absolute rounded-circle top-0'></em>
						) : (
							<em className='badge bg-primary me-2 rounded-1'><Trans i18nKey='components:sidenav.isNew' /></em>
						))}
						{isChild && (<i className='mdi mdi-chevron-right mdi-18px' />)}
					</MotionComponent>
				</li>
			</Tooltip>
		)
	)
}

//List of placeholder items for loading
const SideNavPlaceHolder = () => {
	return <>
		<SideNavItemPlaceHolder key={1} />
		<SideNavItemPlaceHolder key={2} />
		<SideNavItemPlaceHolder key={3} />
		<SideNavItemPlaceHolder key={4} />
		<SideNavItemPlaceHolder key={5} />
		<SideNavItemPlaceHolder key={6} />
		<SideNavItemPlaceHolder key={7} />
	</>
}

//single placeholder item for loading
const SideNavItemPlaceHolder = () => {
	return (
		<div className='list-group-item position-relative px-2'>
			<div className='row p-2 g-0 flex-nowrap justify-content-between align-items-center placeholder-glow'>
				<div className='col-auto p-0 pe-2 placeholder-glow'>
					<span className='p-3 placeholder bg-secondary bg-opacity-75 rounded-4'></span>
				</div>
				<div className='col lh-1 placeholder-glow lh-1'>
					<div>
						<span className='placeholder placeholder-sm bg-secondary bg-opacity-75 rounded-1 w-75'></span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SideNav