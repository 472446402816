import React, { useState } from 'react'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async';
import Avatar from '../../Avatar/Avatar';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { t } from 'i18next';

const SelectField = ({ ...props }) => {
	props.attribute = props.attribute || {};

	const { control } = useFormContext();
	const { t } = useTranslation();


	const filterInput = (inputValue) => {
		return props.asyncOptions?.filter((i) =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	const loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(filterInput(inputValue));
		}, 1000);
	};

	// const handleChange = (selectedOption) => {
	// 	props.attribute.onChange && props.attribute.onChange(selectedOption);
	// 	return selectedOption;
	// };

	return (
		<div className={props.cssClass != null ? props.cssClass + ' p-2' : 'p-2'}>
			<div className="mb-3 position-relative">
				{props.label != null ? (<label id={props.id + '_label'} htmlFor={props.id} className="form-label fw-semibold">{props.label}</label>) : null}
				<Controller
					control={control}
					name={props.formName + (props.attribute.multiple ? '[]' : '')}
					defaultValue={props.value}
					rules={{
						required: props.attribute.required,
						min: props.attribute.min,
						minLength: props.attribute.minLength,
						max: props.attribute.max,
						maxLength: props.attribute.maxLength,
						validate: props.attribute.validate,
					}}
					render={({
						field: { onChange, onBlur, value, name, ref },
						fieldState: { invalid, isTouched, isDirty, error },
					}) => (
						<>
							{props.asyncOptions != null ? (
								<AsyncSelect
									id={props.id}
									name={name}
									ref={ref}
									defaultValue={value || null}
									onChange={(val) => {
										if (Array.isArray(val)) {
											onChange(val.map(c => c.value))
										} else {
											onChange(val.value)
										}
									}}

									className={
										(props.inputClass != null ? props.inputClass : '') +
										(invalid ? ' is-invalid' : !invalid && isDirty ? ' is-valid' : '')
									}
									placeholder={props.attribute.placeholder || t('components:formFields.select.placeholder')}
									aria-label={props.id}
									aria-describedby={props.hint != null ? (props.id + '_hint') : null}

									cacheOptions
									loadOptions={loadOptions}
									defaultOptions={props.asyncOptions}

									components={{ Option, SingleValue, MultiValueLabel }}
									styles={{
										multiValue: (base) => ({
											...base,
											border: `1px solid ${base.background}`,
										}),
										control: (base, state) => ({
											...base,
											borderRadius: 'var(--bs-border-radius) !important',
											backgroundColor: 'var(--bs-body-bg)',
											borderColor: invalid ? 'var(--bs-form-invalid-border-color)' : !invalid && isDirty ? 'var(--bs-form-valid-border-color)' : 'var(--bs-border-color)',
											"&:hover": {
												borderColor: invalid ? 'var(--bs-form-invalid-border-color)' : !invalid && isDirty ? 'var(--bs-form-valid-border-color)' : 'var(--bs-border-color)',
											}
										}),
										placeholder: (base) => ({
											...base,
											color: invalid ? 'var(--bs-form-invalid-color)' : !invalid && isDirty ? 'var(--bs-form-valid-color)' : '',
										}),
									}}

									closeMenuOnSelect
									isDisabled={props.attribute.disabled}
									isSearchable={props.attribute.searchable || true}
									required={props.attribute.required}
									isMulti={props.attribute.multiple}
								/>
							) : (
								<Select
									id={props.id}
									name={name}
									ref={ref}
									defaultValue={value}
									onChange={(val) => {
										if (Array.isArray(val)) {
											onChange(val.map(c => c.value))
										} else {
											onChange(val.value)
										}
									}}

									className={
										(props.inputClass != null ? props.inputClass : '') +
										(invalid ? ' is-invalid' : !invalid && isDirty ? ' is-valid' : '')
									}
									placeholder={props.attribute.placeholder || t('components:formFields.select.placeholder')}
									aria-label={props.id}
									aria-describedby={props.hint != null ? (props.id + '_hint') : null}
									options={props.options}

									components={{ Option, SingleValue, MultiValueLabel }}
									styles={{
										multiValue: (base) => ({
											...base,
											border: `1px solid ${base.background}`,
										}),
										control: (base, state) => ({
											...base,
											borderRadius: 'var(--bs-border-radius) !important',
											backgroundColor: 'var(--bs-body-bg)',
											borderColor: invalid ? 'var(--bs-form-invalid-border-color)' : !invalid && isDirty ? 'var(--bs-form-valid-border-color)' : 'var(--bs-border-color)',
											"&:hover": {
												borderColor: invalid ? 'var(--bs-form-invalid-border-color)' : !invalid && isDirty ? 'var(--bs-form-valid-border-color)' : 'var(--bs-border-color)',
											}
										}),
										placeholder: (base) => ({
											...base,
											color: invalid ? 'var(--bs-form-invalid-color)' : !invalid && isDirty ? 'var(--bs-form-valid-color)' : '',
										}),
									}}

									closeMenuOnSelect
									isDisabled={props.attribute.disabled}
									isSearchable={props.attribute.searchable || true}
									required={props.attribute.required}
									isMulti={props.attribute.multiple}
								/>
							)}
							<div className='invalid-feedback'>
								{error != null && <Trans i18nKey={'components:formFields.select.error.' + error.type} />}
							</div>
							{props.hint != null ? (<div id={props.id + '_hint'} className="form-text">{props.hint}</div>) : null}
						</>
					)}
				/>
			</div >
		</div>
	)
}

const MultiValueLabel = ({ children, ...props }) => {
	return (
		<components.MultiValueLabel {...props}>
			<div className="d-inline-flex flex-nowrap row g-0 mw-100 align-items-center">
				{props.data.avatar != null ? (
					<div className="col-auto avatar-sm">
						<Avatar item={props.data.avatar} className="avatar-sm" />
					</div>
				) : null}
				<div className="col lh-1 text-truncate">{children}</div>
			</div>
		</components.MultiValueLabel>
	);
};


const SingleValue = ({ children, ...props }) => (
	<components.SingleValue {...props}>
		<div className="d-inline-flex flex-nowrap row g-0 mw-100 align-items-center">
			{props.data.avatar != null ? (
				<div className="col-auto avatar-sm">
					<Avatar item={props.data.avatar} className="avatar-sm" />
				</div>
			) : null}
			<div className="col lh-1 text-truncate">{children}</div>
		</div>
	</components.SingleValue>
);

const Option = ({ children, ...props }) => {

	return (
		<components.Option {...props}>
			<div className="d-inline-flex flex-nowrap row g-0 mw-100 align-items-center">
				{props.data.avatar != null ? (
					<div className="col-auto">
						<Avatar item={props.data.avatar} className="avatar-sm" />
					</div>
				) : null}
				{props.data.icon != null ? (
					<div className="col-auto">
						<i className={props.data.icon} />
					</div>
				) : null}
				<div className="col lh-1 text-truncate">
					<div className="text-truncate">
						<span>{children}</span>
					</div>
					{props.data.subtitle != null ? (
						<div className="text-truncate lh-1">
							<small className="fst-italic">{props.data.subtitle}</small>
						</div>
					) : null}
				</div>
				{props.data.link != null ? (
					<div className="col-auto">
						<a href={props.data.link} target="_blank" className="btn btn-light btn-sm px-1 py-0 rounded-1 text-primary">
							<i className="mdi mdi-share small"></i>
						</a>
					</div>
				) : null}
			</div>
		</components.Option>
	);
};

export default SelectField