import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export const Error = ({ error }) => {
	// error = error || useRouteError();
	error = useRouteError();
	const [errorMsg, setErrorMsg] = useState('components:error.500');

	useEffect(() => {
		if (error != null && (isRouteErrorResponse(error) || error.status != null)) {
			switch (error.status) {
				case 400:
					setErrorMsg('components:error.400');
					break;
				case 401:
					setErrorMsg('components:error.401');
					break;
				case 403:
					setErrorMsg('components:error.403');
					break;
				case 404:
					setErrorMsg('components:error.404');
					break;
				case 405:
				case 501:
					setErrorMsg('components:error.501');
					break;
				case 408:
					setErrorMsg('components:error.408');
					break;
				case 503:
					setErrorMsg('components:error.503');
					break;
				case 500:
				default:
					setErrorMsg('components:error.500');
					break;
			}
		}
	}, [error])

	return (
		<div className='p-2 w-100'>
			<div className="bg-danger bg-opacity-10 text-center text-danger p-2 rounded">
				<i className="mdi mdi-alert-circle-outline mdi-72px"></i>
				<h3><Trans i18nKey="components:error.title" /></h3>
				<h6><Trans i18nKey={errorMsg} /></h6>
				{error?.data?.message != null ? (<div>{error.data.message}</div>) : null}
			</div>
		</div>
	)
}
