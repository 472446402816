import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import * as auth from '../../store/actions/auth.action';
import { useLoaderData } from 'react-router-dom';
import Nav from 'components/Nav/Nav';
import FooterNav from 'components/Nav/FooterNav';
import SideNav from 'components/Nav/SideNav';
import LegacyComponent from 'components/Legacy/Legacy';

const Legacy = () => {
	const user = useSelector((state) => state.auth.user.risorsa);
	const { uri } = useLoaderData();
	const [url, setUrl] = useState();

	useEffect(() => {
		let newUrl;

		if (uri.includes('m=')) {
			newUrl = '/legacy.php' + (uri != null ? '?' + uri + '&' : '?');
			newUrl += 'requestID=' + user.id;

		} else if (uri.includes('sections/') || uri.includes('actions/')) {
			newUrl = uri;

		} else {
			newUrl = uri;
		}

		setUrl(newUrl);

	}, [uri])

	return (
		<>
			<SideNav />
			<main>
				<header><Nav /></header>
				<section className='p-1 p-lg-2'>
					<LegacyComponent url={url} />
				</section>
				<footer>
					<FooterNav />
				</footer>
			</main>
		</>
	)
}

const mapStateToProps = state => {
	return {
		loggingIn: state.auth.loggingIn,
		user: state.auth.user,
		error: state.auth.error
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(auth.logout())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Legacy);
