import React from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import Moment from 'react-moment';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import Avatar from 'components/Avatar/Avatar';
import { initials } from 'helpers/string';

const EventInfo = ({ item }) => {
	const details = item?.extendedProps;

	const bodyFix = (html) => {
		const substringToRemove = '<div style="margin-bottom:24px; overflow:hidden; white-space:nowrap">________________________________________________________________________________</div>';
		return html.replace(new RegExp(substringToRemove, 'g'), "");
	}

	return (
		<>
			<div className='row g-0 p-2'>
				<div className='col-auto pe-1'>
					<Avatar item={{
						title: details?.organizer?.emailAddress?.name,
						initials: initials(details?.organizer?.emailAddress?.name)
					}} />
				</div>
				<div className='col'>
					<h6 className='m-0'>{details?.subject}</h6>
					<div className='text-body-secondary'><Moment date={item.start} format='LL' /></div>
				</div>
			</div>

			<OverlayScrollbarsComponent
				options={{
					showNativeOverlaidScrollbars: false,
					overflow: { x: "hidden" },
					scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
				}}
				defer
				style={{ height: 'calc(100% - 60px)' }}
			>

				<div className='row g-0 text-center align-items-center py-2'>
					<div className='col-auto'>
						<div className='text-body-secondary fw-semibold'><Trans i18nKey='views:calendars.meetings.details.start' />:</div>
						<div className='fw-semibold'><Moment date={item.start} format='HH:mm' /></div>
					</div>
					<div className='col'>
						<Moment date={item.end} to={item.start} ago />
					</div>
					<div className='col-auto'>
						<div className='text-body-secondary fw-semibold'><Trans i18nKey='views:calendars.meetings.details.end' />:</div>
						<div className='fw-semibold'><Moment date={item.end} format='HH:mm' /></div>
					</div>
				</div>
				<div className='py-2'>
					<div className='text-body-secondary fw-semibold'><Trans i18nKey='views:calendars.meetings.details.room' />:</div>
					<div>{details.location.displayName}</div>
				</div>
				<div className='py-2'>
					<div className='text-body-secondary fw-semibold'><Trans i18nKey='views:calendars.meetings.details.description' />:</div>
					<div>
						{details?.body?.content ? (
							<OverlayScrollbarsComponent
								options={{
									showNativeOverlaidScrollbars: false,
									overflow: { x: "hidden" },
									scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
								}}
								defer
								style={{ height: '100%', maxHeight: '200px' }}
								className='border rounded bg-grey bg-opacity-10'
							>
								<div dangerouslySetInnerHTML={{ __html: bodyFix(details?.body?.content) }}></div>
							</OverlayScrollbarsComponent>
						) : (
							<Trans i18nKey='views:calendars.meetings.details.no_description' />
						)}
					</div>
				</div>

				{details?.onlineMeeting?.joinUrl && moment(item.start).isSameOrAfter(moment()) && (
					<div className='py-2'>
						<div className='text-body-secondary fw-semibold'><Trans i18nKey='views:calendars.meetings.details.link' />:</div>
						<div>
							<a className='btn btn-deep-purple' href={details.onlineMeeting.joinUrl} target="_blank">
								<Trans i18nKey='views:calendars.meetings.details.linkButton' />
								<i className='mdi mdi-microsoft-teams mdi-18px ps-2' />
							</a>
						</div>
					</div>
				)}

				{details?.organizer && (
					<div className='row g-0'>
						<div className='col-12 '>
							<div className='text-body-secondary fw-semibold'><Trans i18nKey='views:calendars.meetings.details.organizer' />:</div>
						</div>
						<div className='col col-lg-4 p-2'>
							<div className='border rounded text-center align-content-center p-2 h-100 user-select-none lh-1'>
								<Avatar item={{
									title: details.organizer.emailAddress?.name,
									initials: initials(details.organizer.emailAddress?.name)
								}} />
								<div>{details.organizer.emailAddress?.name}</div>
								<div className='text-body-secondary'><small>{details.organizer.emailAddress.address}</small></div>
							</div>
						</div>
					</div>
				)}

				<div className='row g-0'>
					<div className='col-12 '>
						<div className='text-body-secondary fw-semibold'><Trans i18nKey='views:calendars.meetings.details.attendees' />:</div>
					</div>
					{details?.attendees?.map((attende, key) => (
						<div key={key} className='col col-lg-4 p-2'>
							<div className='border rounded text-center align-content-center p-2 h-100 user-select-none lh-1'>
								<Avatar item={{
									title: attende.emailAddress.name,
									initials: initials(attende.emailAddress.name)
								}} />
								<div>{attende.emailAddress.name}</div>
								<div className='text-body-secondary'><small>{attende.emailAddress.address}</small></div>
							</div>
						</div>
					))}
				</div>
			</OverlayScrollbarsComponent >
		</>
	)
}

export default EventInfo