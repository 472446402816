import { createStore, combineReducers, applyMiddleware } from 'redux';
import auth from './reducers/auth.reducer';
import badge from './reducers/badge.reducer';
import common from './reducers/common.reducer';
import { thunk } from 'redux-thunk';

export default function storeConfig() {
	return createStore(
		combineReducers({ auth, common, badge }),
		applyMiddleware(thunk)

	);
}