import React from "react";

const SortableOverlay = (props) => {

	return (
		<div id="drd_overlay" className="mt-1 p-1">
			<div className="bg-white p-2 ps-0 border rounded shadow-lg">
				<div className="row g-0 align-items-center">
					<div className="col">
						<div className="row g-0 align-items-center">
							<div className="col-auto">
								<i className="mdi mdi-24px mdi-drag-vertical-variant text-opacity-50 text-secondary"></i>
							</div>
							<div className="col lh-1">
								<h6 className="m-0">{props.item.title}</h6>
								<div className="small text-muted">{props.item.subtitle}</div>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<div className="form-check form-switch d-flex justify-content-end">
							<input className="form-check-input" type="checkbox" role="switch" id="drd_overlay_check" style={{ width: '1.5em' }} defaultChecked={props.item.emptyHidden} />
							<label className="form-check-label" htmlFor="drd_overlay"></label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SortableOverlay;