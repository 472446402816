import { urlReplace } from "helpers/url";
import Api from "./Api";

export default class DepartmentService {
	constructor(api) {
		this.api = api || new Api();
	}

	async getAll(params) {
		return this.api.get('/secure/departments', params);
	}

	async getById(id, params) {
		return this.api.get(urlReplace('/secure/department/{id}', { id: id }), params);
	}

	async getEvalutaionsById(id, params) {
		return this.api.get(urlReplace('/secure/department/{id}/evaluations', { id: id }), params);
	}
}