import React from 'react';
import Item from 'components/Item/Item';
import { useTheme } from 'providers/ThemeProvider';

const Api = () => {
	const { theme } = useTheme();
	return (
		<Item>
			<iframe src={process.env.REACT_APP_API_URL + "/documentation" + (theme ? '?theme=' + theme : '')} className="h-100" />
		</Item>
	)
}

export default Api