import Settings from "pages/settings/Settings"
import CompanyRoutes from "./settings/CompanyRoutes"
import RoleRoutes from "./settings/RoleRoutes"

const SettingsRoutes = [
	{
		path: "/settings",
		element: <Settings />,
		handle: {
			title: 'settings.title',
			crumb: null, //Breadcrumbs
		},
		future: {
			v7_normalizeFormMethod: true,
		},
	},
	...CompanyRoutes,
	...RoleRoutes
]

export default SettingsRoutes