import React from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import DetailView from 'components/DetailView/DetailView';
import Document from 'components/Document/Document';
import Avatar from 'components/Avatar/Avatar';
import ProjectTeams from 'components/ProjectTeams/ProjectTeams';
import Chart from 'components/Chart/Chart';

import { areaChart, radialBar } from './TenderInfoCharts';

const TenderInfo = () => {
	const [item] = useOutletContext();

	const mainAttributes = [
		'category',
		'gruppo',
		'dataApertura:date',
		'dataScadenza:date',
		'customer.fullname',
		'importo:currency',
	];

	const summaryAttributes = [
		'dataPrevistaInizio:date',
		'dataPrevistaFine:date',
		'dataConsegna:date',
		'durata:number',
		'durataProgettazione:number',
		'durataRealizzazione:number',
		'parteTecnica:check',
		'parteAmministrativa:check',
		'attiva:check'
	];

	const batchsAttributes = [
		"numero",
		"importoOfferto:currency",
		"importoAlcotec:currency",
		"percentRibasso:percentage",
		"percentAlcotec:percentage",
		{
			label: "note",
			value: "note",
			cssClass: "col-12 border-bottom p-2"
		},
	];

	const serviceAttributes = [
		'presaInCarico',
		'oggetto',
		'serviziOfferti',
		'toDo',
		'note'
	];

	const costAttributes = [
		'importo:currency',
		'importoLavori:currency',
		'successFee:currency',
		'importoPrimaEmissione:currency',
		'rimborso:currency',

	];

	const customerAttribute = [
		'fullname',
		'fulladdress',
		'referent.fullname',
		'referent.email',
		'referent.pec',
		'referent.telefono',
	];

	return (
		<div className='row g-0 align-items-start'>

			{/* //controllo se ci sono risorse attive
			//controllo se è attiva ma non aperta alle risorse */}


			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='tenders.item.contents.info.title' context={item?.typology} /></h6>
			</div>
			<div className='col-12 col-lg-6'>
				<div className='row g-0 align-items-center'>
					{item && <DetailView item={item} attributes={mainAttributes} options={{
						transPrefix: 'models:tender.',
						cssClass: 'col-12 col-md-6 lh-1 p-2 border-bottom'
					}} />}

					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='tenders.item.contents.info.teams' /></h6>
					</div>
					<div className='col-12 col-lg-8'>
						<ProjectTeams teams={item?.teams} />
					</div>

				</div>
			</div>
			<div className='col-12 col-lg-6'>
				<Chart {...areaChart(item?.timesheetsChart)} />
				<Chart {...radialBar(item?.tasksChart)} />
			</div>

			{/* informazioni */}
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='tenders.item.contents.info.summary' /></h6>
			</div>
			{item && <DetailView item={item} attributes={summaryAttributes} options={{
				transPrefix: 'models:tender.',
				cssClass: 'col-12 col-md-6 col-lg-4 lh-1 p-2 border-bottom'
			}} />}

			{/* servizi */}
			{item && <DetailView item={item} attributes={serviceAttributes} options={{
				transPrefix: 'models:tender.',
				cssClass: 'col-12 border-bottom p-2',
				truncate: false
			}} />}

			{/* lotti */}
			{item && item?.batches && item.batches.length != 0 && (
				<>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='tenders.item.contents.info.batches' /></h6>
					</div>
					<div className='col-12'>
						<ul className="list-group">
							{item.batches.map((batch, idx) => (
								<li key={idx} className='list-group-item'>
									<div className='row g-0 align-items-center'>
										<div className='col-auto col-lg-4 border-bottom p-2' style={{ paddingTop: '30px !important' }}>
											<Avatar item={batch.avatar} className='avatar-sm' />
										</div>
										<DetailView item={batch} attributes={batchsAttributes} options={{
											transPrefix: 'models:tender-batch.',
											cssClass: 'col-auto col-lg-4 border-bottom p-2',
											truncate: false
										}} />
									</div>
								</li>
							))}
						</ul>
					</div>
				</>
			)}

			{/* costi */}
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='tenders.item.contents.info.cost' /></h6>
			</div>
			{item && <DetailView item={item} attributes={costAttributes} options={{
				transPrefix: 'models:tender.',
				cssClass: 'col-12 col-md-6 col-lg border-bottom p-2'
			}} />}

			{/* cliente */}
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='tenders.item.contents.info.customer' /></h6>
			</div>
			{item && item?.customer && <DetailView item={item.customer} attributes={customerAttribute} options={{
				transPrefix: 'models:customer.',
				cssClass: 'col-12 col-md-6 border-bottom p-2'
			}} />}

			{/* documenti */}
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='tenders.item.contents.info.documents' /></h6>
			</div>
			<div className='col-12'>
				<ul className="list-group">
					{item?.documents != null && item.documents.length > 0 ? (item.documents?.map((document, idx) => (
						<li key={idx} className='list-group-item list-group-item-action'>
							<Document item={document} />
						</li>
					))) : (<div className='text-center text-body-tertiary small'><Trans i18nKey='tenders.item.contents.info.no_document' /></div>)}
				</ul>
			</div>

		</div>
	)
}

export default TenderInfo