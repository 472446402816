
import React, { useState } from 'react';
import { useLoaderData, useOutletContext } from 'react-router-dom';
import ItemAwait from 'components/Item/ItemAwait';
import Loader from 'components/Loader/Loader';
import { costReport, hoursReport } from './WorkReportsChart';
import { useTranslation } from 'react-i18next';
import Chart from 'components/Chart/Chart';

const WorkReports = () => {
	const [item, projectPlan] = useOutletContext();
	const { reports } = useLoaderData();
	// const [item, setItem] = useState();
	const [charts, setCharts] = useState();

	const { t } = useTranslation();

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			// setItem(response.data);

			setCharts({
				hours: hoursReport(response.data, item.fullname, projectPlan.fullname),
				cost: costReport(response.data, item.fullname, projectPlan.fullname)
			})
		}
	}

	return (
		<ItemAwait className='row g-0 align-items-center' resolvePromise={reports} hasResolved={resolvedPromise} loading={<Loader />}>
			<div className='col-12 col-lg-6'>
				{charts?.hours && <Chart {...charts.hours} />}
			</div>
			<div className='col-12 col-lg-6'>
				{charts?.cost && <Chart {...charts.cost} />}
			</div>
		</ItemAwait>
	)
}

export default WorkReports