import React, { useMemo, useState } from 'react'
import { useLoaderData, useOutletContext } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ViewMode, Gantt } from "gantt-task-react";
import "./gantt.css";

const WorkWbs = () => {
	const [item, projectPlan] = useOutletContext();
	const { wbs } = useLoaderData();
	const [view, setView] = useState(ViewMode.Day);
	const [tasks, setTasks] = useState(initTasks());

	//recuperare wbs deferer

	let columnWidth = 65;
	if (view === ViewMode.Year) {
		columnWidth = 350;
	} else if (view === ViewMode.Month) {
		columnWidth = 300;
	} else if (view === ViewMode.Week) {
		columnWidth = 250;
	}

	const handleExpanderClick = (task) => {
		setTasks(tasks.map(t => (t.id === task.id ? task : t)));
	};

	const handleAddTask = (task) => {
		return true;
	}

	return (
		<>
			<div className='bg-warning-subtle text-warning rounded p-2 align-items-center justify-content-center d-flex mb-2'>
				<i className='mdi mdi-information-variant-circle-outline mdi-24px pe-1' />
				<Trans i18nKey='views:wip_ongoing' />
			</div>

			<div className="row g-0 align-items-center justify-content-lg-end mb-2">
				<div className="col-12 col-lg-6 text-lg-end">
					<ViewSwitcher onViewModeChange={viewMode => setView(viewMode)} current={view} />
				</div>
			</div>
			<div>
				<Gantt
					tasks={tasks}
					viewMode={view}
					locale="it-IT"
					// onDateChange={handleTaskChange}
					// onDelete={handleTaskDelete}
					// onProgressChange={handleProgressChange}
					// onDoubleClick={handleDblClick}
					// onClick={handleClick}
					// onSelect={handleSelect}
					onExpanderClick={handleExpanderClick}
					listCellWidth="155px"
					// ganttHeight={300}
					columnWidth={columnWidth}
					TaskListHeader={(props) => (
						<TaskListHeader {...props} />
					)}
					TaskListTable={(props) => (
						<TaskListTable {...props} handleAddTask={handleAddTask} />
					)}
				/>
			</div>
		</>
	)
}

const ViewSwitcher = ({ onViewModeChange, current }) => {
	return (
		<div className="ViewContainer btn-group border">
			<button className={"btn " + (current == ViewMode.Day ? "btn-primary" : "")} onClick={() => onViewModeChange(ViewMode.Day)} >
				Giorno
			</button>
			<button className={"btn " + (current == ViewMode.Week ? "btn-primary" : "")} onClick={() => onViewModeChange(ViewMode.Week)} >
				Settimana
			</button>
			<button className={"btn " + (current == ViewMode.Month ? "btn-primary" : "")} onClick={() => onViewModeChange(ViewMode.Month)} >
				Mese
			</button>
			<button className={"btn " + (current == ViewMode.Year ? "btn-primary" : "")} onClick={() => onViewModeChange(ViewMode.Year)} >
				Anno
			</button>
		</div>
	);
};

function initTasks() {
	const currentDate = new Date();
	const tasks = [
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
			name: "91000.4",
			id: "ProjectSample",
			progress: 25,
			type: "project",
			hideChildren: false,
			displayOrder: 1,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
			end: new Date(
				currentDate.getFullYear(),
				currentDate.getMonth(),
				2,
				12,
				28
			),
			name: "Formazione",
			id: "Task 0",
			progress: 45,
			type: "task",
			project: "ProjectSample",
			displayOrder: 2,
		},



		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
			name: "Formazione Interna",
			id: "Task 1",
			progress: 25,
			dependencies: ["Task 0"],
			type: "task",
			project: "ProjectSample",
			displayOrder: 3,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
			name: "Sviluppo",
			id: "Task 2",
			progress: 10,
			dependencies: ["Task 1"],
			type: "task",
			project: "ProjectSample",
			displayOrder: 4,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
			name: "Formazione e Rollout",
			id: "Task 3",
			progress: 2,
			dependencies: ["Task 2"],
			type: "task",
			project: "ProjectSample",
			displayOrder: 5,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
			name: "Implementazione funzioni in Piattaforma",
			id: "Task 4",
			type: "task",
			progress: 70,
			dependencies: ["Task 2"],
			project: "ProjectSample",
			displayOrder: 6,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
			name: "Modifica e aggiornamento Piattaforma",
			id: "Task 6",
			progress: currentDate.getMonth(),
			type: "milestone",
			dependencies: ["Task 4"],
			project: "ProjectSample",
			displayOrder: 7,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
			name: "Progettazione nuove funzioni in Piattaforma",
			id: "Task 10",
			progress: 0,
			isDisabled: true,
			type: "task",
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
			name: "Test/Collaudi/Rilascio",
			id: "Task 11",
			progress: 0,
			isDisabled: true,
			type: "task",
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
			name: "Modifica e aggiornamento Sito Web",
			id: "Task 9",
			progress: 0,
			isDisabled: true,
			type: "task",
		},
	];
	return tasks;
}

export const TaskListTable = ({ rowHeight, rowWidth, tasks, fontFamily, fontSize, locale, onExpanderClick, handleAddTask }) => {
	const localeDateStringCache = {};
	const dateTimeOptions = { weekday: "short", year: "numeric", month: "long", day: "numeric" };
	const toLocaleDateStringFactory = (locale) => (date, dateTimeOptions) => {
		const key = date.toString();
		let lds = localeDateStringCache[key];
		if (!lds) {
			lds = date.toLocaleDateString(locale, dateTimeOptions);
			localeDateStringCache[key] = lds;
		}
		return lds;
	};
	const toLocaleDateString = useMemo(() => toLocaleDateStringFactory(locale), [locale]);

	return (
		<div
			className="taskListWrapper"
			style={{
				fontFamily: fontFamily,
				fontSize: fontSize,
			}}
		>
			{tasks.map(t => {
				let expanderSymbol = "";
				if (t.hideChildren === false) {
					expanderSymbol = "▼";
				} else if (t.hideChildren === true) {
					expanderSymbol = "▶";
				}
				let isProject = t.type === "project";

				return (
					<div
						className="taskListTableRow"
						style={{ height: rowHeight }}
						key={`${t.id}row`}
					>
						<div
							className="taskListCell"
							style={{
								minWidth: rowWidth,
								maxWidth: rowWidth,
							}}
							title={t.name}
						>
							<div className="taskListNameWrapper">
								<div
									className={
										expanderSymbol
											? "taskListExpander"
											: "taskListEmptyExpander"
									}
									onClick={() => onExpanderClick(t)}
								>
									{expanderSymbol}
								</div>
								<div>{t.name}</div>
							</div>
						</div>
						<div
							className="taskListCell"
							style={{
								minWidth: rowWidth,
								maxWidth: rowWidth,
							}}
						>
							&nbsp;{toLocaleDateString(t.start, dateTimeOptions)}
						</div>
						<div
							className="taskListCell"
							style={{
								minWidth: rowWidth,
								maxWidth: rowWidth,
							}}
						>
							&nbsp;{toLocaleDateString(t.end, dateTimeOptions)}
						</div>
						<div
							className="taskListCell"
							style={{
								minWidth: '80px',
								maxWidth: '80px',
							}}
						>
							<button className="border-0 btn p-0 text-success">
								<i className='mdi mdi-plus-circle-outline' />
							</button>
							<button className="border-0 btn p-0 text-primary">
								<i className='mdi mdi-pencil-circle-outline' />
							</button>
							<button className="border-0 btn p-0 text-danger" onClick={() => handleAddTask(t)}>
								<i className='mdi mdi-close-circle-outline' />
							</button>
						</div>
					</div>
				);
			})}
		</div>
	);
};

const TaskListHeader = ({ headerHeight, fontFamily, fontSize, rowWidth }) => {
	return (
		<div
			className="ganttTable"
			style={{
				fontFamily: fontFamily,
				fontSize: fontSize,
			}}
		>
			<div
				className="ganttTable_Header"
				style={{
					height: headerHeight - 2,
				}}
			>
				<div
					className="ganttTable_HeaderItem"
					style={{
						minWidth: rowWidth,
					}}
				>
					&nbsp;WBS
				</div>
				<div
					className="ganttTable_HeaderSeparator"
					style={{
						height: headerHeight * 0.5,
						marginTop: headerHeight * 0.2,
					}}
				/>
				<div
					className="ganttTable_HeaderItem"
					style={{
						minWidth: rowWidth,
					}}
				>
					&nbsp;Inizio
				</div>
				<div
					className="ganttTable_HeaderSeparator"
					style={{
						height: headerHeight * 0.5,
						marginTop: headerHeight * 0.25,
					}}
				/>
				<div
					className="ganttTable_HeaderItem"
					style={{
						minWidth: rowWidth,
					}}
				>
					&nbsp;Fine
				</div>
				<div
					className="ganttTable_HeaderSeparator"
					style={{
						height: headerHeight * 0.5,
						marginTop: headerHeight * 0.25,
					}}
				/>
				<div
					className="ganttTable_HeaderItem"
					style={{
						minWidth: '80px',
					}}
				>
					&nbsp;Opzioni
				</div>
			</div>
		</div>
	);
};


export default WorkWbs

