/**
 * redirect to url passed
 * 
 * @param {string} url 
 * @param {boolean} loading 
 * @param {boolean} newPage 
 * @param {Event} event 
 * @returns 
 */
export const goTo = (url, loading = false, newPage = false, event) => {
	event && event.preventDefault();

	if (url == null || url == "" || url.length == 0) {
		return false;
	}

	if (newPage) {
		window.open(url, "_blank");
	} else {
		if (loading) {
			//
		}

		window.location.href = url;
	}

	return true;
};

export const urlReplace = (url, params = {}) => {
	return url.replace(/{(\w+)}/g, (un, es) => {
		return params[es] != null ? params[es] : es;
	});
};

// export const isExternalURL = (url) => {
// 	let newUrl = new URL(url);
// 	return newUrl && newUrl.origin !== window.location.origin;
// }

export const checkDomain = function (url) {
	if (url.indexOf('//') === 0) { url = window.location.protocol + url; }
	return url.toLowerCase().replace(/([a-z])?:\/\//, '$1').split('/')[0];
};

export const isExternalURL = function (url) {
	return url && ((url.indexOf(':') > -1 || url.indexOf('//') > -1) && checkDomain(window.location.href) !== checkDomain(url));
};