import React from 'react'
import { Trans } from 'react-i18next'

const ListItemEmpty = () => {
	return (
		<div className="list-group-item">
			<i className="mdi mdi-information-outline me-2"></i>
			<span><Trans i18nKey="components:list.empty" /></span>
		</div>
	)
}

export default ListItemEmpty