import React from 'react';
import { Trans } from 'react-i18next';
import { isString } from 'helpers/commons';

/**
 * Generate a detail-list view with data from item
 * @param {model} item Model
 * 
 * @param {array} attributes contains string or objects 
 * you can use a shortcut indicating only the "value:formatter" es ('name:text', 'birthdate:date')
 * or alternatively you can use a object with the following properties 
 * @param {string} attributes[].label key to translate
 * @param {boolean} attributes[].noTrans if true no translate the label
 * @param {string} attributes[].value The property's name of the item
 * @param {string} attributes[].formatter type of formatting to apply to the value (date,datetime,time,number,percentage,currency,check,html,text)
 * @param {object} attributes[].options key-values set for the value es (status, options from select ecc)
 * @param {string} attributes[].cssClass class css to apply
 * @param {string} attributes[].cssStyle style css to apply
 *  
 * @param {object} options
 * @param {string} options.transPrefix prefix to apply at the key to translate
 * @param {string} options.cssClass default attribute cssClass
 * @param {string} options.cssStyle default attribute cssStyle
 * @param {bool}   options.truncate default attribute truncate
 * @param {object} options.numberFormat view: Intl.NumberFormat options
 * @param {object} options.dateFormat view: Intl.DateTimeFormat options
 * 
 * @returns 
 */
const DetailView = ({ item, attributes = [], options = {} }) => {
	let i18nextLng = localStorage.getItem('i18nextLng');

	//se non ci sono attributi mostro tutto l'oggetto
	if (attributes == null || attributes.length == 0) {
		Object.entries(item).map(v => {
			if (typeof v[1] !== 'object') {
				attributes.push(v[0]);
			}
		});
	}

	//assegno le options di default e le mergio con quelle passatogli
	options = Object.assign({
		transPrefix: '',
		cssClass: 'col-12 border-bottom',
		truncate: true,
		numberFormat: {
			locale: 'it-IT',
			currency: 'EUR'
		},
		dateFormat: {
			locale: i18nextLng || 'it-IT',
			dateStyle: 'full',
			timeStyle: 'long',
			timeZone: 'Europe/Rome'
		}
	}, options);

	function getValue(obj, path) {
		if (!path) return obj;
		if (!obj) return null;
		const properties = path.split('.');
		return getValue(obj[properties.shift()], properties.join('.'));
	}

	//formatto il value
	const formattedValue = (attribute) => {
		let formatter, value, text;

		if (item == null) {
			return false;
		}

		if (isString(attribute)) {
			let split = attribute.split(':');
			value = split[0];
			formatter = split[1] || 'text';
		} else {
			value = attribute.value;
			formatter = attribute.formatter || 'text';
		}

		value = typeof value === 'function' ? value : getValue(item, value);

		if (value == null) {
			text = 'N.D.';
		}

		switch (formatter) {

			case 'date':
			case 'date-full':
				text = !value || value.startsWith('-') ? 'N.D.' : Intl.DateTimeFormat(options.dateFormat.locale, {
					dateStyle: 'full',
					timeZone: options.dateFormat.timeZone,
				}).format(new Date(value))
				break;
			case 'date-short':
				text = !value || value.startsWith('-') ? 'N.D.' : Intl.DateTimeFormat(options.dateFormat.locale, {
					dateStyle: 'short',
					timeZone: options.dateFormat.timeZone,
				}).format(new Date(value))
				break;
			case 'datetime':
				text = !value || value.startsWith('-') ? 'N.D.' : Intl.DateTimeFormat(options.dateFormat.locale, {
					dateStyle: 'full',
					timeStyle: 'long',
					timeZone: options.dateFormat.timeZone,
				}).format(new Date(value))
				break;
			case 'time':
				text = value.startsWith('-') ? 'N.D.' : Intl.DateTimeFormat(options.dateFormat.locale, {
					hour: 'numeric',
					minute: 'numeric',
					second: 'numeric',
					timeZone: options.dateFormat.timeZone,
				}).format(new Date(value))
				break;
			case 'number':
				text = Intl.NumberFormat(options.numberFormat.locale).format(value);
				break;
			case 'currency':
				text = Intl.NumberFormat(options.numberFormat.locale, {
					style: 'currency',
					currency: options.numberFormat.currency
				}).format(value);
				break;
			case 'percentage':
				text = Intl.NumberFormat(options.numberFormat.locale, {
					style: 'percent',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				}).format(value);
				break;
			case 'check':
				text = value == '1' ? (<i className="mdi mdi-checkbox-marked-circle-outline text-success" />) : (<i className="mdi mdi-close-circle-outline text-danger" />);
				break;
			case 'html':
				text = (<div dangerouslySetInnerHTML={{ __html: value }} />);
				break;
			case 'text':
				text = attribute.options != null && attribute.options.length > 0 ? attribute.options[value] : value;
				break;
			case 'raw':
			default:
				text = typeof value === 'function' ? value() : value; //se è una funzione la eseguo altrimenti assegno semplicemente il valore
				break;
		}

		return text || 'N.D.';
	}

	//formatto la label traducendola
	const formattedLabel = (attribute) => {
		let label = isString(attribute) ? attribute.split(':')[0] : attribute.label;

		if (label != null) {
			if (attribute.noTrans) {
				return label;
			} else {
				return label = <Trans i18nKey={(attribute?.transPrefix || options.transPrefix) + label} />
			}
		}
	}

	return (
		attributes?.map((attribute, key) => (
			<div key={key} className={(attribute?.cssClass || options?.cssClass) + (options?.truncate ? ' text-truncate' : '')} style={attribute?.cssStyle || options?.cssStyle}>
				<strong className='fw-semibold'>{formattedLabel(attribute)}</strong>
				<div className={'mt-2 mb-0' + (options?.truncate ? ' text-truncate' : '')}>{formattedValue(attribute)}</div>
			</div>
		))
	)
}

export default DetailView