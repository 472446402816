import React, { useState } from 'react'
import { NavLink, Outlet, useLoaderData } from 'react-router-dom';
import ItemAwait from 'components/Item/ItemAwait';
import ItemHeader from 'components/Item/ItemHeader';
import ItemContent from 'components/Item/ItemContent';
import ItemFooter from 'components/Item/ItemFooter';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';
import { Trans, useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';

const Customer = () => {
	const { customer } = useLoaderData();
	const [item, setItem] = useState();
	const { t } = useTranslation();

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			setItem(response.data);
		}
	}

	return (
		<ItemAwait resolvePromise={customer} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
			<ItemHeader
				avatar={item?.avatar}
				title={item?.fullname}
				subtitle={item?.entita ? <Trans i18nKey={'models:customer.entita.value.' + item.entita} /> : null}
			>
				<Tooltip title={t('views:customers.item.header.edit')} >
					<NavLink to='edit' className='btn btn-outline-primary btn-sm disabled'>
						<i className='mdi mdi-pencil' />
						<Trans i18nKey='views:customers.item.header.edit' />
					</NavLink>
				</Tooltip>
			</ItemHeader>
			<ItemContent>
				<Outlet context={[item]} />
			</ItemContent>
			<ItemFooter />
		</ItemAwait>
	)
}

export default Customer