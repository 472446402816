import { Error } from "components/Error/Error";
import Protocols from "pages/protocols/Protocols";
import Protocol from "pages/protocols/item/Protocol";
import ProtocolEmpty from "pages/protocols/item/ProtocolEmpty";
import { defer } from "react-router-dom";
import ProtocolService from "services/ProtocolService";

const service = new ProtocolService();
const ProtocolRoutes = [
	{
		path: "/protocols",
		element: <Protocols />,
		loader: async ({ request }) => {
			return defer({ protocols: service.getAll(new URL(request.url).search) });
		},
		handle: {
			title: 'protocols.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				path: "",
				element: <ProtocolEmpty />,
			},
			{
				path: "/protocols/:id",
				element: <Protocol />,
				loader: ({ params }) => {
					return defer({ protocol: service.getById(params.id) });
				},
				handle: {
					crumb: null, //Breadcrumbs
				},
				errorElement: <Error />,
			},
		],
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]

export default ProtocolRoutes