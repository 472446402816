
import React from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import DetailView from 'components/DetailView/DetailView';
import Document from 'components/Document/Document';
import ProjectTeams from 'components/ProjectTeams/ProjectTeams';
import Chart from 'components/Chart/Chart';

import { areaChart, radialBar, progressChart } from './WorkInfoCharts';

const WorkInfo = () => {
	const [item] = useOutletContext();

	const mainAttributes = [
		'category',
		'azienda',
		'dataApertura:date',
		'dataChiusura:date',
		'customer.fullname',
		'importo:currency',
	];

	const summaryAttributes = [
		'dataAperturaPrevista:date',
		'dataChiusuraPrevista:date',
		'committenteFinale',
		'cdcSap',
		'luogo',
		'indirizzo',
		'environment',
		'qualification.description',
		{
			label: 'descrizione',
			value: 'descrizione',
			cssClass: 'col-12 lh-1 p-2 border-bottom'
		},
		{
			label: 'oggetto',
			value: 'oggetto',
			cssClass: 'col-12 lh-1 p-2 border-bottom'
		},
		{
			label: 'note',
			value: 'note',
			cssClass: 'col-12 lh-1 p-2 border-bottom'
		},
		{
			label: 'businessSector',
			value: 'businessSector',
			cssClass: 'col-12 col-md-6 col-lg-4 lh-1 p-2 border-bottom'
		},
		{
			label: 'businessField',
			value: 'businessField',
			cssClass: 'col-12 col-md-6 col-lg-4 lh-1 p-2 text-lg-center border-bottom'
		},
		{
			label: 'businessScope',
			value: 'businessScope',
			cssClass: 'col-12 col-md-6 col-lg-4 lh-1 p-2 text-lg-end border-bottom'
		}
	];

	const serviceAttributes = [
		'chiusuraOperativa:check',
		'chiusuraAmministrativa:check',
		'gestioneSemplificata:check',
		'attiva:check',
	];

	const costAttributes = [
		'importo:currency',
		'importoLavori:currency',

	];

	const customerAttribute = [
		'fullname',
		'fulladdress',
		'referent.fullname',
		'referent.email',
		'referent.pec',
		'referent.telefono',
	];


	return (
		<div className='row g-0 align-items-start'>

			{/* //controllo se ci sono risorse attive
			//controllo se è attiva ma non aperta alle risorse */}

			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='works.item.contents.info.title' context={item?.typology} /></h6>
			</div>
			<div className='col-12 col-lg-6'>
				<div className='row g-0 align-items-center'>
					{item && <DetailView item={item} attributes={mainAttributes} options={{
						transPrefix: 'models:work.',
						cssClass: 'col-12 col-md-6 lh-1 p-2 border-bottom'
					}} />}

					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='works.item.contents.info.teams' /></h6>
					</div>
					<div className='col-12 col-lg-8'>
						<ProjectTeams teams={item?.teams} />
					</div>

				</div>
			</div>
			<div className='col-12 col-lg-6'>
				<div className='row g-0'>
					<div className='col-12'>
						<Chart {...areaChart(item?.timesheetsChart)} />
					</div>
					<div className='col-12 col-lg-6'>
						<Chart {...radialBar(item?.tasksChart)} />
					</div>
					<div className='col-12 col-lg-6'>
						<Chart {...progressChart(item?.pal)} />
					</div>
				</div>
			</div>

			{/* informazioni */}
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='works.item.contents.info.summary' /></h6>
			</div>
			{item && <DetailView item={item} attributes={summaryAttributes} options={{
				transPrefix: 'models:work.',
				cssClass: 'col-12 col-md-6 lh-1 p-2 border-bottom'
			}} />}

			{/* servizi */}
			{item && <DetailView item={item} attributes={serviceAttributes} options={{
				transPrefix: 'models:work.',
				cssClass: 'col-12 col-md-6 col-lg text-lg-center lh-1 border-bottom p-2',
				truncate: false
			}} />}

			{/* costi */}
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='works.item.contents.info.cost' /></h6>
			</div>
			{item && <DetailView item={item} attributes={costAttributes} options={{
				transPrefix: 'models:work.',
				cssClass: 'col-12 col-md-6 border-bottom p-2'
			}} />}

			{/* cliente */}
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='works.item.contents.info.customer' /></h6>
			</div>
			{item && item?.customer && <DetailView item={item.customer} attributes={customerAttribute} options={{
				transPrefix: 'models:customer.',
				cssClass: 'col-12 col-md-6 border-bottom p-2'
			}} />}

			{/* documenti */}
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='works.item.contents.info.documents' /></h6>
			</div>
			<div className='col-12'>
				<ul className="list-group">
					{item?.documents != null && item.documents.length > 0 ? (item.documents?.map((document, idx) => (
						<li key={idx} className='list-group-item list-group-item-action'>
							<Document item={document} />
						</li>
					))) : (<div className='text-center text-body-tertiary small'><Trans i18nKey='works.item.contents.info.no_document' /></div>)}
				</ul>
			</div>

		</div>

	)
}

export default WorkInfo