import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { logout } from 'store/actions/auth.action';
import Avatar from '../Avatar/Avatar'
import DateView from 'components/DateView/DateView';
import { NavLink } from 'react-router-dom';

const ProfileMenu = ({ user, lastAccess, permissions }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const handleLogout = () => {
		setIsSubmitting(true);
		return logout().then(() => {
			setIsSubmitting(false);
		});
	}

	return (
		<div className='p-2'>
			<div className='row g-0 align-items-center mb-3 text-start text-lg-center'>
				<div className='col-auto col-lg-12 pe-2 pb-2'>
					<Avatar item={user?.avatar} className='avatar-lg' />
				</div>
				<div className='col col-lg-12'>
					<h6 className='m-0 text-body-emphasis'>{user?.fullname}</h6>
					<p className='m-0 small text-body-tertiary'>{user?.active_contract?.profile?.descrizioneProfilo}</p>
				</div>
			</div>

			<div className='bg-white rounded border shadow-sm mb-3 px-3 py-2'>
				<NavLink to='/profile' className='link-body-emphasis text-decoration-none'>
					<div className='row g-0 align-items-center'>
						<div className='col-auto pe-2'>
							<i className='mdi mdi-account-circle-outline mdi-24px' />
						</div>
						<div className='col text-start pe-2'>
							<strong className='m-0 h5'><Trans i18nKey='components:profileMenu.show' /></strong>
						</div>
						<div className='col-auto'>
							<i className='mdi mdi-chevron-right mdi-24px' />
						</div>
					</div>
				</NavLink>
			</div>

			<div className='accordion rounded bg-white shadow-sm mb-3' id='accordionProfile'>
				<div className='accordion-item'>
					<h2 className='accordion-header'>
						<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#dataProfileId' aria-expanded='false' aria-controls='dataProfileId'>
							<strong><Trans i18nKey='components:profileMenu.id' /></strong>
						</button>
					</h2>
					<div id='dataProfileId' className='accordion-collapse collapse' data-bs-parent='#accordionProfile'>
						<div className='accordion-body'>
							<p className='m-0'>{user?.id}</p>
						</div>
					</div>
				</div>
				<div className='accordion-item'>
					<h2 className='accordion-header'>
						<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#dataProfileLastAccess' aria-expanded='false' aria-controls='dataProfileLastAccess'>
							<strong><Trans i18nKey='components:profileMenu.lastAccess' /></strong>
						</button>
					</h2>
					<div id='dataProfileLastAccess' className='accordion-collapse collapse' data-bs-parent='#accordionProfile'>
						<div className='accordion-body'>
							<p className='m-0'><DateView date={lastAccess} format='DD/MM/YYYY HH:mm' /></p>
						</div>
					</div>
				</div>
				<div className='accordion-item'>
					<h2 className='accordion-header'>
						<button className='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#dataProfilePermissions' aria-expanded='false' aria-controls='dataProfilePermissions'>
							<strong><Trans i18nKey='components:profileMenu.permissions' /></strong>
						</button>
					</h2>
					<div id='dataProfilePermissions' className='accordion-collapse collapse' data-bs-parent='#accordionProfile'>
						<div className='accordion-body'>
							<p className='m-0'>{permissions.map(p => p.nome).join(', ')}</p>
						</div>
					</div>
				</div>
			</div>

			<div className='row g-0 p-0 justify-content-center align-items-center'>
				<div className='col-12 text-center'>
					<button disabled={isSubmitting} className='btn btn-dark w-100' onClick={handleLogout}>
						{isSubmitting ? (<span className="spinner-border spinner-border-sm me-1"></span>) : (<i className='mdi mdi-logout' />)}
						<Trans i18nKey='components:profileMenu.logout' />
					</button>
				</div>
				<div className='col-12 text-center mt-1'>
					<a className='text-body-tertiary' href='#!'>
						<small>{process.env.REACT_APP_NAME} <Trans i18nKey='components:profileMenu.version' /> {process.env.REACT_APP_VERSION}</small>
					</a>
				</div>
			</div>
		</div>
	)
}

export default ProfileMenu