import React, { useState } from 'react'
import { NavLink, Outlet, useLoaderData } from 'react-router-dom';
import { Trans } from 'react-i18next';
import ItemAwait from 'components/Item/ItemAwait';
import ItemHeader from 'components/Item/ItemHeader';
import ItemNavDropDown from 'components/Item/ItemNavDropDown';
import ItemContent from 'components/Item/ItemContent';
import ItemFooter from 'components/Item/ItemFooter';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';
import { DropDown, DropDownButton, DropDownContent, DropDownDivider, DropDownItem } from 'components/Bootstrap/DropDown';


const Work = () => {
    const { work } = useLoaderData();
    const [item, setItem] = useState();

    //callback function of Await react-router-dom
    const resolvedPromise = (response) => {
        if (response != null && response.data != null && response.data.length != 0) {
            setItem(response.data);
        }
    }

    return (
        <ItemAwait resolvePromise={work} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
            <ItemHeader
                avatar={item?.avatar}
                title={item?.fullname}
                subtitle={item?.category || null}
            // subtitle={'PM: ' + (item?.pm != null && item.pm.length > 0 ? (item.pm[0]?.resource?.fullname + (item.pm[1] != null ? ' & ' + item?.pm[1]?.resource?.fullname : '')) : <Trans i18nKey="works.item.header.no_subtitle" />)}
            >
                <DropDown>
                    <DropDownButton>
                        <i className='mdi mdi-dots-vertical' />
                    </DropDownButton>
                    <DropDownContent>
                        <DropDownItem>
                            <NavLink to='./edit' disabled>
                                <i className='mdi mdi-pencil mdi-18px pe-1' />
                                <Trans i18nKey='views:works.item.header.menu.edit' />
                            </NavLink>
                        </DropDownItem>
                        <DropDownDivider />
                        <DropDownItem>
                            <NavLink to='./close' className='bg-danger-subtle text-danger' disabled>
                                <i className='mdi mdi-cancel mdi-18px pe-1' />
                                <Trans i18nKey='views:works.item.header.menu.close' />
                            </NavLink>
                        </DropDownItem>
                    </DropDownContent>
                </DropDown>
            </ItemHeader>
            <ItemNavDropDown>
                <NavLink className="nav-link d-flex d-lg-block justify-content-between" aria-current="page" to="." end>
                    <span><Trans i18nKey="works.item.nav.info" /></span><i className='mdi mdi-chevron-right d-lg-none' />
                </NavLink>
                <NavLink className="nav-link d-flex d-lg-block justify-content-between" aria-current="page" to={"./schedules" + (item?.project_plans[0]?.id ? '/' + item.project_plans[0].id : '')} end>
                    <span><Trans i18nKey="works.item.nav.schedules" /></span><i className='mdi mdi-chevron-right d-lg-none' />
                </NavLink>
            </ItemNavDropDown>
            <ItemContent>
                <Outlet context={[item]} />
            </ItemContent>
            <ItemFooter />
        </ItemAwait>
    )

}

export default Work