// Generate Empty Polar Chart for Funzione Team
export const _generateFTPolarChart = () => {
	return {
		id: 'polar-chart',
		type: 'polarArea',
		series: [
			// { name: 'Omogeneità', data: [] }
		],
		options: {
			chart: {
				height: 230,
				width: '100%',
				type: 'polarArea',
			},
			fill: {
				opacity: 1
			},
			stroke: {
				width: 1,
				colors: undefined
			},
			tooltip: {
				fillSeriesColor: false,
			},
			yaxis: {
				tickAmount: 5,
				min: 0,
				max: 100,
				forceNiceScale: false,
				decimalsInFloat: 0,
				labels: {
					formatter: function (y) {
						return y + "%";
					}
				}
			},
			legend: {
				show: false,
			},
			labels: [],
			responsive: [{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
				}
			}],
			theme: {
				monochrome: {
					enabled: true,
					color: '#255aee',
					shadeTo: 'light',
					shadeIntensity: 1
				},
			}
		}
	};
};