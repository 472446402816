import React from 'react'
import { Trans } from 'react-i18next'

const WorkMilestones = () => {
	return (
		<div className='bg-warning-subtle text-warning rounded p-2 align-items-center justify-content-center d-flex'>
			<i className='mdi mdi-information-variant-circle-outline mdi-24px pe-1' />
			<Trans i18nKey='views:wip' />
		</div>
	)
}

export default WorkMilestones