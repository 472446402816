import AttendanceRoutes from "./users/AttendanceRoutes"
import CustomerRoutes from "./users/CustomerRoutes"
import ResourceRoutes from "./users/ResourceRoutes"
import SupplierRoutes from "./users/SupplierRoutes"

const UserRoutes = [
	...ResourceRoutes,
	...SupplierRoutes,
	...CustomerRoutes,
	...AttendanceRoutes,
]

export default UserRoutes