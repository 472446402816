import React from 'react';
import { defer, redirect } from 'react-router-dom';
import Protected from 'layout/protected/Protected';
import { Error } from 'components/Error/Error';
import DepartmentsRoutes from './protectedRoutes/DepartmentRoutes';
import DashboardRoutes from './protectedRoutes/DashboardRoutes';
import TimesheetRoutes from './protectedRoutes/TimesheetRoutes';
import NavService from 'services/NavService';
import AssetRoutes from './protectedRoutes/AssetRoutes';
import CalendarRoutes from './protectedRoutes/CalendarRoutes';
import DocumentationRoutes from './protectedRoutes/DocumentationRoutes';
import PlanningRoutes from './protectedRoutes/PlanningRoutes';
import SettingsRoutes from './protectedRoutes/SettingsRoutes';
import ReportRoutes from './protectedRoutes/ReportRoutes';
import ProfileRoutes from './protectedRoutes/ProfileRoutes';
import UserService from 'services/UserService';
import moment from 'moment';
import ProjectRoutes from './protectedRoutes/ProjectRoutes';
import ProtocolRoutes from './protectedRoutes/ProtocolRoutes';
import UserRoutes from './protectedRoutes/UserRoutes';
import TaskRoutes from './protectedRoutes/TaskRoutes';

const ProtectedRoutes = [
	{
		path: "/",
		element: <Protected />,

		shouldRevalidatee: () => { return false },

		loader: async ({ request }) => {
			if (!localStorage.getItem('user')) {
				return redirect("/login");
			}

			let uri = new URL(request.url).searchParams;
			if (uri.get('m')) {
				return redirect("/leg/?" + uri);
			}

			// nav request
			let nav = sessionStorage.getItem('nav');
			if (!nav) {
				let navService = new NavService();
				nav = navService.getAllMe();
			} else {
				nav = Promise.resolve(JSON.parse(nav))
			}

			//badges request
			let badges = sessionStorage.getItem('badges');
			if (!badges) {
				let userService = new UserService();
				let today = moment().format('YYYY-MM-DD');
				badges = userService.getBadges(today, today);
			} else {
				badges = Promise.resolve(JSON.parse(badges))
			}

			return defer({
				nav: nav,
				badges: badges
			});
		},
		children: [
			...AssetRoutes,
			...CalendarRoutes,
			...DashboardRoutes,
			...DepartmentsRoutes,
			...DocumentationRoutes,
			...PlanningRoutes,
			...ProfileRoutes,
			...ProjectRoutes,
			...ProtocolRoutes,
			...ReportRoutes,
			...SettingsRoutes,
			...TaskRoutes,
			...TimesheetRoutes,
			...UserRoutes,
		],
		errorElement: <Error />,
	},
]


export default ProtectedRoutes