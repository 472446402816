import React from "react";
import Tooltip from "../Tooltip/Tooltip";

const Avatar = ({ item, ...props }) => {

	const handleImageError = (e) => {
		if (item.initials != null) {
			e.target.style = 'display: none';
			e.target.nextSibling.style = null;
		} else {
			e.target.src = "/images/secure/help.png";
		}
	};


	item.image = item.image != null ? item.image.replace(' ', '') : null;

	item.cssClass = item.class != null && item.class.length > 0 ? item.class : (item.cssClass != null && item.cssClass.length > 0 ? item.cssClass : '');

	return (
		<>
			<span className="d-inline-block position-relative">
				<Tooltip title={item.title} placement='top'>
					<span className={"avatar-bg" + (props.className ? ' ' + props.className : '')}>
						{item.image != null ? (
							<>
								<img
									className={"avatar " + item.cssClass}
									src={process.env.REACT_APP_CDN_URL + (item.image.startsWith('/') ? '' : '/') + item.image.replace(' ', '')}
									loading="lazy"
									alt={item?.title}
									data-initials={item?.initials}
									onError={handleImageError}
								/>
								<span className={"avatar fw-semibold " + item.cssClass} style={{ display: 'none' }} data-initials={item.initials} alt={item.initials}>{item.initials}</span>
							</>
						) : item.icon != null ? (
							<span className={"avatar " + item.cssClass}><i className={item.icon}></i></span>
						) : item.initials != null ? (
							<span className={"avatar fw-semibold " + item.cssClass} data-initials={item.initials} alt={item.initials}>{item.initials}</span>
						) : (
							//default
							<span className={"avatar " + item.cssClass} alt={item?.title} ><i className='mdi mdi-account mdi-24px'></i></span>
						)}
					</span>
				</Tooltip>
				{item.subIcon && (
					<span className={item.subIconClass}><i className={item.subIcon}></i></span>
				)}
				{item.label != null &&
					<Tooltip title={item.label} placement='bottom'>
						<span className={'avatar-label ' + (item.labelClass ? item.labelClass : '')}>{item.label}</span>
					</Tooltip>}
			</span>
		</>
	);
};

export default Avatar;
