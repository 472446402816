import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import Item from 'components/Item/Item';
import ListAwait from 'components/List/ListAwait';
import Search from 'components/Search/Search';
import ListItem from 'components/List/ListItem';

const Procedures = () => {
	let { procedures } = useLoaderData();

	const [items, setItems] = useState();
	const [hasMore, setHasMore] = useState(false);

	const handleLoad = (response) => {
		setItems(oldItems => [...oldItems, ...response.data]);
		setHasMore(response.next_page_url);
	};

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (items == null || items.length == 0) {
			// if (response != null && response.data != null && response.data.length != 0) {
			setItems(response.data.data);
			setHasMore(response.data.next_page_url);
		}
	}

	return (
		<div className='flex-column h-100 w-100'>
			<Item>
				<div className='row g-2 align-items-center mb-2'>
					<div className='col'>
						{/* <Filters /> */}
						<Search action="/documentations/procedures" />
					</div>
					<div className='col-auto m-0'>
					</div>
				</div>

				<ListAwait resolvePromise={procedures} hasResolved={resolvedPromise} loadMore={handleLoad} hasMore={hasMore}>
					{items?.map((item, idx) => (
						<ListItem key={idx} item={item} />
						// <div>{item.fullname}</div>
					))}
				</ListAwait>
			</Item>
		</div>
	)
}

export default Procedures