import React from 'react'
import { PlainField } from './PlainField';

const FileField = ({ ...props }) => {
	props.type = 'file';
	// props.inputClass = null;
	props.fieldType = 'file';

	return (<PlainField {...props} />)
}

export default FileField