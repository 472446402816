// Generate Empty Scatter Chart for Funzione Team
export const _generateFTScatterChart = () => {
	return {
		id: 'scatter-chart',
		type: 'scatter',
		series: [],
		options: {
			tooltip: {
				x: {
					formatter: function (value, data) {
						return data.w.config.series[data.seriesIndex].name;
					},
				},
				y: {
					formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
						return 'Soft Skill : ' + w.config.series[seriesIndex].data[0][1] + '<br>' +
							'Hard Skill : ' + w.config.series[seriesIndex].data[0][0] + '<br>';
					}, title: { formatter: function () { return ''; }, }
				},
			},
			chart: {
				height: 200,
				width: '100%',
				type: 'scatter',
			},
			colors: [],
			xaxis: {
				tickAmount: 4,
				min: 0,
				max: 5,
				decimalsInFloat: 1,
				title: {
					text: "Soft Skills",
					fontWeight: '500',
					fontSize: '11px',
				}
			},
			yaxis: {
				tickAmount: 4,
				min: 1,
				max: 5,
				decimalsInFloat: 1,
				title: {
					text: "Hard Skills",
					fontWeight: '500',
					fontSize: '11px',
				}
			},
			markers: {
				size: 20
			},
			fill: {
				type: [],
				opacity: 1,
				image: {
					src: [],
					width: 40,
					height: 40
				},
			},
			legend: {
				show: false,
			}
		}
	};
}