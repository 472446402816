import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, useSearchParams } from "react-router-dom";

const Search = ({ ...props }) => {
	const [searchParams] = useSearchParams();
	const [search, setSearch] = useState();
	const { t } = useTranslation();

	props.method = props.method || "get";

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (search != null && search.length >= 3) {
				document.getElementById('search-form').submit();
			}
		}, 3000);

		return () => clearTimeout(delayDebounceFn)
	}, [search])

	return (
		<Form method={props.method} action={props.action} id="search-form">
			<div className="row align-items-center g-0 mb-2">
				<div className="col-12">
					<div className="input-group">
						<input
							type="search"
							className={"form-control bg-white" + (props.className ? ' ' + props.className : '')}
							name="search"
							id="search"
							placeholder={t('components:search.placeholder')}
							aria-label="Search..."
							onChange={(e) => setSearch(e.target.value)}
							defaultValue={searchParams.get('search')}
							autoComplete='off'
							tabIndex="-1"
							disabled={props.disabled}
						/>
					</div>
				</div>
			</div>
		</Form>
	);
};

export default Search;
