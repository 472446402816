import React from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { NavLink, useOutletContext } from 'react-router-dom';
import DetailView from 'components/DetailView/DetailView';
import Avatar from 'components/Avatar/Avatar';

const ResourceContracts = () => {
	const [item] = useOutletContext();

	const contractAttributes = [
		"societa",
		"sedeLavoro",
		"sede",
		"dataInizio:date-short",
		"dataFinePrevista:date-short",
		"dataFineEffettiva:date-short",
		"ccnl",
		"tipologia",
		"livello",
		"ruolo",
		"profile.descrizioneProfilo",
		"livelloProfessionale",
		"activateBadge:check",
		"oreSupplementari:check",
		"weekendWork:check",
		{
			label: 'orario',
			value: 'orario',
			cssClass: 'col-12 col-md-6 border-bottom p-2',
		},
		{
			label: 'totPausa',
			value: 'totPausa',
			cssClass: 'col-12 col-md-6 border-bottom p-2',
		},
		{
			label: 'ingressoMin',
			value: 'ingressoMin',
			cssClass: 'col-12 col-md-6 col-lg-3 border-bottom p-2',
		},
		{
			label: 'ingressoMax',
			value: 'ingressoMax',
			cssClass: 'col-12 col-md-6 col-lg-3 border-bottom p-2',
		},
		{
			label: 'uscitaMin',
			value: 'uscitaMin',
			cssClass: 'col-12 col-md-6 col-lg-3 border-bottom p-2',
		},
		{
			label: 'uscitaMax',
			value: 'uscitaMax',
			cssClass: 'col-12 col-md-6 col-lg-3 border-bottom p-2',
		},
		{
			label: 'note',
			value: 'note',
			cssClass: 'col-12 border-bottom p-2',
		},
		{
			label: 'proroga.label',
			value: 'proroga',
			formatter: 'check',
			cssClass: 'col-12 col-md-4 p-2',
		},
		{
			label: 'created_at',
			value: 'created_at',
			formatter: 'date',
			cssClass: 'col-12 col-md-4 p-2',
		},
		{
			label: 'lastUpdatedAt',
			value: 'lastUpdatedAt',
			formatter: 'date',
			cssClass: 'col-12 col-md-4 p-2',
		},
	];

	const expirationDate = item?.active_contract?.dataFineEffettiva && moment(item.active_contract.dataFineEffettiva).isAfter(moment()) ? moment(item.active_contract.dataFineEffettiva).diff(moment(), 'days') : null;

	return (
		<div className='row g-0 align-items-center'>
			<div className='col-12 p-2'>
				<NavLink to='./new' className='btn btn-primary-subtle text-primary disabled'>
					<i className='mdi mdi-plus mdi-18px' />
					<Trans i18nKey='resources.item.contents.contracts.new_contract' />
				</NavLink>
			</div>
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='resources.item.contents.contracts.title' /></h6>
			</div>
			{item?.active_contract == null || (item?.active_contract?.dataFineEffettiva && expirationDate) ? (
				<div className='col-12'>
					<div className='bg-warning-subtle rounded p-2 text-warning mb-2 text-center'>
						<i className='mdi mdi-alert-outline mdi-18px pe-1' />
						<Trans
							i18nKey='resources.item.contents.contracts.alert'
							values={{ count: expirationDate, context: !expirationDate ? 'expired' : null }}
							components={{ bold: <strong /> }}
						/>
					</div>
				</div>
			) : null}
			<div className='col-12'>
				<div className='accordion' id='contractsList'>
					{item?.contracts != null && item?.contracts.length > 0 ? (item.contracts?.map((contract, idx) => (
						<div className='accordion-item' key={idx}>
							<div className='accordion-header'>
								<button
									className={'accordion-button' + (contract.stato != '0' ? ' ' : ' collapsed')}
									type='button'
									data-bs-toggle='collapse'
									data-bs-target={'#contract-' + idx}
									aria-expanded={contract.stato != '0'}
									aria-controls={'contract-' + idx}
								>
									<Avatar item={{
										icon: contract.stato == '0' ? 'mdi mdi-lock-outline' : 'mdi mdi-lock-open-outline',
										cssClass: contract.stato == '0' ? 'bg-danger-subtle text-danger' : 'bg-success-subtle text-success'
									}} />
									<span className='d-flex flex-column lh-1'>
										<h6><Trans i18nKey='resources.item.contents.contracts.contract_title' values={{ date: moment(contract.dataInizio).format('DD MMMM YYYY') }} /></h6>
										<span>
											<span className={'badge d-inline ' + (contract.stato == '0' ? 'bg-danger' : 'bg-success')}>
												<Trans i18nKey={'models:contract.stato.value.' + contract.stato} />
											</span>
										</span>
									</span>
								</button>
							</div>
							<div id={'contract-' + idx} className={'accordion-collapse collapse' + (contract.stato != '0' ? ' show' : '')} data-bs-parent='#contractsList'>
								<div className='accordion-body'>
									<div className='row g-0 align-items-center'>
										<DetailView item={contract} attributes={contractAttributes} options={{
											transPrefix: 'models:contract.',
											cssClass: 'col-12 col-md-6 col-lg-4 border-bottom p-2'
										}} />
									</div>
								</div>
							</div>
						</div>
					))) : (<div className='text-center text-body-tertiary small'>
						<Trans i18nKey='resources.item.contents.contracts.no_contract' />
					</div>)}
				</div>
			</div>
		</div>
	)
}

export default ResourceContracts