import Companies from "pages/settings/companies/Companies";
import Company from "pages/settings/companies/item/Company";
import CompanyEmpty from "pages/settings/companies/item/CompanyEmpty";
import CompanyForm from "pages/settings/companies/item/CompanyForm";
import CompanyInfo from "pages/settings/companies/item/info/CompanyInfo";
import { defer } from "react-router-dom";
import { Error } from "components/Error/Error";
import CompanyService from "services/CompanyService";

const service = new CompanyService();
const CompanyRoutes = [
	{
		path: "/settings/companies",
		element: <Companies />,
		loader: async ({ request }) => {
			return defer({ companies: service.getAll(new URL(request.url).search) });
		},
		handle: {
			title: 'companies.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				path: "",
				element: <CompanyEmpty />,
			},
			{
				path: "/settings/companies/:id",
				element: <Company />,
				loader: ({ params }) => {
					return defer({ company: service.getById(params.id) });
				},
				handle: {
					crumb: null, //Breadcrumbs
				},
				children: [
					{
						index: true,
						element: <CompanyInfo />,
					},
				],
				errorElement: <Error />,
			},
			{
				path: "/settings/companies/new",
				element: <CompanyForm />,
			},
			{
				path: "/settings/companies/:id/edit",
				element: <CompanyForm />,
				loader: ({ params }) => {
					return defer({ company: service.getById(params.id) });
				},
				errorElement: <Error />,
			},
		],
		errorElement: <Error />,
	},
]

export default CompanyRoutes