import React from 'react'
import { PlainField } from './PlainField';

const CurrencyField = ({ ...props }) => {
	props.type = 'number';
	props.attribute = props.attribute || {};
	props.attribute.step = props.attribute.step || '0.01';
	props.inputGroup = props.inputGroup || { before: '€' }
	props.fieldType = 'currency';

	return (<PlainField {...props} />)
}

export default CurrencyField