import React from 'react'
import { useOutletContext } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Profiles from './fragments/profiles';

const WorkProjectPlan = () => {
	const [item] = useOutletContext();

	return (
		<div className='bg-warning-subtle text-warning rounded p-2 align-items-center justify-content-center d-flex'>
			<i className='mdi mdi-information-variant-circle-outline mdi-24px pe-1' />
			<Trans i18nKey='views:wip' />

			{/*
				<>
					{/* <nav className="nav nav-tabs flex-column flex-sm-row">
						<button className="flex-sm-fill text-sm-center nav-link active"
							id="profiles-tab" data-bs-toggle="tab" data-bs-target="#profiles" type="button" role="tab" aria-controls="profiles" aria-selected="true">
							<Trans i18nKey="works.item.contents.planning-controlling.projectplan.nav.profiles" />
						</button>
						<button className="flex-sm-fill text-sm-center nav-link"
							id="externalCosts-tab" data-bs-toggle="tab" data-bs-target="#externalCosts" type="button" role="tab" aria-controls="externalCosts">
							<Trans i18nKey="works.item.contents.planning-controlling.projectplan.nav.externalCosts" />
						</button>
						<button className="flex-sm-fill text-sm-center nav-link"
							id="revenues-tab" data-bs-toggle="tab" data-bs-target="#revenues" type="button" role="tab" aria-controls="revenues" >
							<Trans i18nKey="works.item.contents.planning-controlling.projectplan.nav.revenues" />
						</button>
						<button className="flex-sm-fill text-sm-center nav-link"
							id="billings-tab" data-bs-toggle="tab" data-bs-target="#billings" type="button" role="tab" aria-controls="billings">
							<Trans i18nKey="works.item.contents.planning-controlling.projectplan.nav.billings" />
						</button>
					</nav> 
					<div className="tab-content" id="nav-tabContent">
						<div className="tab-pane fade show active" id="profiles" role="tabpanel" aria-labelledby="profiles-tab" tabIndex="0">
							<Profiles data={[
								{ id: 1, idWbs: "0", idProfile: "0", profile: "Profilo 1", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 2, idWbs: "0", idProfile: "0", profile: "Profilo 2", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 3, idWbs: "0", idProfile: "0", profile: "Profilo 3", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 4, idWbs: "0", idProfile: "0", profile: "Profilo 1", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 5, idWbs: "0", idProfile: "0", profile: "Profilo 2", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 6, idWbs: "0", idProfile: "0", profile: "Profilo 3", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 7, idWbs: "0", idProfile: "0", profile: "Profilo 1", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 8, idWbs: "0", idProfile: "0", profile: "Profilo 2", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 9, idWbs: "0", idProfile: "0", profile: "Profilo 3", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 10, idWbs: "0", idProfile: "0", profile: "Profilo 1", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 11, idWbs: "0", idProfile: "0", profile: "Profilo 2", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 12, idWbs: "0", idProfile: "0", profile: "Profilo 3", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 13, idWbs: "0", idProfile: "0", profile: "Profilo 1", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 14, idWbs: "0", idProfile: "0", profile: "Profilo 2", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
								{ id: 15, idWbs: "0", idProfile: "0", profile: "Profilo 3", wbs: "executions", isWbsOpen: false, isProfileOpen: false },
							]} />
						</div>
						<div className="tab-pane fade" id="externalCosts" role="tabpanel" aria-labelledby="externalCosts-tab" tabIndex="0">...</div>
						<div className="tab-pane fade" id="revenues" role="tabpanel" aria-labelledby="revenues-tab" tabIndex="0">...</div>
						<div className="tab-pane fade" id="billings" role="tabpanel" aria-labelledby="billings-tab" tabIndex="0">...</div>
					</div>


				</>
			*/}


		</div>
	)
}

export default WorkProjectPlan