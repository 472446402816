import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Avatar from '../Avatar/Avatar'
import Tooltip from '../Tooltip/Tooltip'


const ItemHeader = (props) => {

	const { t } = useTranslation();

	return (
		<nav className="bg-white">
			<div className={"row g-0 align-items-center" + (!props.avatar ? ' mb-2' : '')}>
				{props.hideBackButton != true ? (
					<div className="col-auto">
						<Tooltip title={t('components:item.header.turn_back')}>
							<Link className="btn btn-sm p-0" to={props.backButton || ".."} reloadDocument={props.backButtonReload || false}>
								<i className="mdi mdi-arrow-left mdi-24px"></i>
							</Link>
						</Tooltip>
					</div>
				) : null}
				{props.avatar != null ? (
					<div className="col-auto">
						{props.avatar != null ? <Avatar item={props?.avatar} /> : null}
					</div>
				) : null}
				<div className={"col lh-1 text-truncate" + (props.hideBackButton && !props.avatar ? '' : ' ps-2')}>
					{props.title != null && props.title.html == null ? (
						<h6 className="mb-0 text-capitalize">{props.title}</h6>
					) : props.title != null && props.title.html != null ? (
						<h6 className="mb-0 text-capitalize" dangerouslySetInnerHTML={{ __html: props.title.html }}></h6>
					) : null}

					{props.subtitle != null && props.subtitle.html == null ? (
						<div className="text-muted mb-0">
							<small>{props.subtitle}</small>
						</div>
					) : props.subtitle != null && props.subtitle.html != null ? (
						<div className="text-muted mb-0">
							<small dangerouslySetInnerHTML={{ __html: props.subtitle.html }}></small>
						</div>
					) : null}
				</div>
				{props.children != null ? (<div className='col-auto'>{props.children}</div>) : null}
			</div>

		</nav>
	)
}

export default ItemHeader