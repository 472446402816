import React from 'react'
import { Trans } from 'react-i18next'

//INFO: vedere se è possibile usare useNavigation di react-router-dom v6+

const FormSubmit = ({ ...props }) => {
	props.className = props.className || 'btn-primary text-white';
	return (
		<button
			type='submit'
			disabled={props.isSubmitting || props.errors != null && props.errors.lenght > 0}
			className={props.className + ' btn w-100'}
			{...props.dataAttribute}
			{...props.attribute}
		>
			{props.isSubmitting ? (<span className="spinner-border spinner-border-sm me-1"></span>) : props.icon ? <i className={props.icon} /> : null}
			<span><Trans i18nKey={props.title} /></span>
		</button>
	)
}

export default FormSubmit