import React from 'react'
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import Persona from 'components/Persona/Persona';
import DetailView from 'components/DetailView/DetailView';


const DepartmentInfo = () => {
    const [item] = useOutletContext();

    return (
        <>
            {/* info department */}
            <div className='row g-0 align-items-center'>
                <div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
                    <h6 className='mb-0'><Trans i18nKey="departments.item.contents.info.title" /></h6>
                </div>
                <DetailView item={item} attributes={['name', 'circle', { 'label': 'company', 'formatter': 'raw', 'value': () => { return item?.company?.name } }]} options={{
                    transPrefix: 'models:department.',
                    cssClass: 'col-12 col-md-6 col-lg-4 border-bottom p-2'
                }} />
            </div>
            <div className='row g-0 align-items-center'>
                <div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
                    <h6 className='m-0'><Trans i18nKey='departments.item.contents.info.resources.title' /></h6>
                    <div className='text-muted'><Trans i18nKey='departments.item.contents.info.resources.subtitle' /></div>
                </div>
                <div className='col-12'>
                    <div className='row g-0 text-center py-2 justify-content-evenly'>
                        {item?.resources?.map((resource, index) => (
                            <div key={index} className='col-sm-12 col-md-6 col-lg-auto p-2' style={{ width: '320px' }}>
                                <div className={'animate__animated animate__zoomIn animate__delay-' + (index * 200) + 'ms'} style={{ width: '100%', maxWidth: '310px' }}>
                                    <Persona resource={resource} />
                                </div>
                            </div>
                        ))}
                        {item?.resources == null || item?.resources.length == 0 ? (<div className='col-sm-12 '>
                            <div className='bg-warning text-warning bg-opacity-10 rounded p-2'><Trans i18nKey="departments.item.contents.info.resources.empty" /></div>
                        </div>) : (<div className='bg-secondary text-secondary bg-opacity-10 rounded p-2 mt-2'><Trans i18nKey="departments.item.contents.info.resources.privacy" /></div>)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DepartmentInfo