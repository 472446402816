import { SplitScreen } from 'components/SplitScreen/SplitScreen';
import Item from 'components/Item/Item';
import Search from 'components/Search/Search';
import { Filters } from 'components/Filters/Filters';
import ListAwait from 'components/List/ListAwait';
import ListItem from 'components/List/ListItem';
import React, { useState } from 'react'
import { NavLink, Outlet, useLoaderData } from 'react-router-dom'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

const Roles = () => {
	let { roles } = useLoaderData();

	const [items, setItems] = useState();
	const [hasMore, setHasMore] = useState(false);

	const { checkPermissions } = useAuth();
	const EDIT_ROLES = checkPermissions(['EDIT_ROLES']); //PERMISSION

	const { t } = useTranslation();

	const handleLoad = (response) => {
		setItems(oldItems => [...oldItems, ...response.data]);
		setHasMore(response.next_page_url);
	};

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (items == null || items.length == 0) {
			setItems(response.data.data);
			setHasMore(response.data.next_page_url);
		}
	}

	return (
		<SplitScreen name="roles-list" leftClass="flex-column h-100 w-100">
			<Item>
				<div className='row g-2 align-items-center mb-2'>
					<div className='col'>
						<NavLink to={"./new"} end /*disabled={!EDIT_ROLES}*/ className='btn btn-info shadow-sm text-white d-block text-truncate disabled'><Trans i18nKey="roles.new" /></NavLink>
					</div>
					<div className='col-auto'>
						<button
							className="btn border shadow-sm"
							type="button" data-bs-toggle="offcanvas"
							data-bs-target="#roles-filter" aria-controls="roles-filter"
							title={t("roles.filters.title")}>
							<i className="mdi mdi-filter-variant"></i>
						</button>
					</div>
				</div>
				<div className='row g-2 align-items-center'>
					<div className='col-12'>
						<Search action="/roles" />
					</div>
				</div>
				<div className="offcanvas offcanvas-start position-absolute bg-white rounded border-0 w-100" data-bs-backdrop="false" tabIndex="-1" id="roles-filter" aria-labelledby="roles-filter-label">
					<div className="offcanvas-header p-3 text-truncate">
						<h5 className="offcanvas-title" id="roles-filter-label"><Trans i18nKey="roles.filters.title" /></h5>
						<button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive" aria-label="Close"></button>
					</div>
					<div className="offcanvas-body p-2">
						<OverlayScrollbarsComponent
							options={{
								showNativeOverlaidScrollbars: false,
								overflow: { x: "hidden" },
								scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
							}}
							defer
							style={{ height: '100%' }}
						>
							<Filters action="/roles">
								{/* //TODO:: da fare  */}
							</Filters>
						</OverlayScrollbarsComponent>
					</div>
				</div>

				<ListAwait resolvePromise={roles} hasResolved={resolvedPromise} loadMore={handleLoad} hasMore={hasMore}>
					{items?.map((item, idx) =>
						<ListItem key={idx} item={item} />
					)}
				</ListAwait>

			</Item>
			<Outlet />
		</SplitScreen>
	)
}

export default Roles