import Public from 'layout/public/Public';
import Login from 'pages/login/Login';
import { Error } from 'components/Error/Error';

const PublicRoutes = [
	{
		path: "/login",
		element: <Public />,
		handle: {
			title: 'login.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				element: <Login />,
			},
		],
		errorElement: <Error />,
	},
]

export default PublicRoutes