import { redirect } from 'react-router-dom';
import { Error } from 'components/Error/Error';
import Legacy from 'layout/legacy/Legacy';

const LegacyRoutes = [
	{
		path: "/leg",
		element: <Legacy />,
		loader: ({ request }) => {

			//fare unseAuth seguendo chatgpt

			let user = localStorage.getItem('user');
			if (!user) {
				return redirect("/login");
			}

			let uri = new URL(request.url).searchParams;

			//genereare la nuova url

			//gestire il fatto che sulle action c'è il redirect e sulle action l'url potrebbe essere con indirizzo diverso

			//fare qui la richiesta rest

			return { user: JSON.parse(user), uri: uri.toString() };
		},
		errorElement: <Error />,
	}

]

export default LegacyRoutes 