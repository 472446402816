import React from 'react'

const StarRating = ({ actual, total = 5, cssClass = "text-warning", showLabel = false, showTotal = false, justify = 'center', disableAnimation = false }) => {
	actual = parseFloat(actual).toFixed(2);

	const stars = [];

	for (let i = 0; i < total; i++) {
		if (actual >= i + 1 || actual > i + 0.75) {
			stars.push(
				<i key={i} className={"mdi mdi-star mdi-18px " + cssClass}></i>
			);
		} else if (actual > i + 0.25) {
			// Half star
			stars.push(
				<i key={i} className={"mdi mdi-star-half-full mdi-18px " + cssClass}></i>
			);
		} else {
			// Empty star
			stars.push(
				<i key={i} className={"mdi mdi-star-outline mdi-18px " + cssClass}></i>
			);
		}
	}

	return (
		<div className={disableAnimation ? '' : 'animate__animated animate__delay-2s animate__infinite animate__pulse animate__slow'}>
			<div className={'d-flex align-items-center justify-content-' + justify}>
				{stars}
				{showLabel && (showTotal ? <small className={"ps-1 " + cssClass}>{actual}/{total}</small> : <small className={"ps-1 " + cssClass}>{actual}</small>)}
			</div>
		</div>
	);
}

export default StarRating