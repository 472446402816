import React from 'react'
import { Suspense } from 'react'
import { Await, useAsyncValue } from 'react-router-dom'
import ListItemPlaceHolder from './ListItemPlaceHolder'
import List from './List'

const ListAwait = (props) => {


    const HasResolved = () => {
        const data = useAsyncValue();
        setTimeout(() => {
            //inserisco un delay di 250ms per evitare di sovrapporre troppi aggiornamenti dei componenti nello stesso momento
            props.hasResolved && props.hasResolved(data);
        }, 250)

        return true;
    }

    return (
        <Suspense fallback={
            <List loadMore={props.loadMore} hasMore={props.hasMore}>
                <ListItemPlaceHolder key={'LIP-1' + Date.now()} />
                <ListItemPlaceHolder key={'LIP-2' + Date.now()} />
                <ListItemPlaceHolder key={'LIP-3' + Date.now()} />
                <ListItemPlaceHolder key={'LIP-4' + Date.now()} />
            </List>
        }>
            <Await resolve={props.resolvePromise}>
                <HasResolved />
                <List loadMore={props.loadMore} hasMore={props.hasMore}>
                    {props.children}
                </List>
            </Await>
        </Suspense>
    )
}

export default ListAwait