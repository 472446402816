import React, { Suspense, useState } from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';
import BadgeItem from 'components/Badge/BadgeItem';
import List from 'components/List/List';
import BadgeMap from 'components/Badge/BadgeMap';
import { OffCanvas, OffCanvasContent } from 'components/Bootstrap/OffCanvas';
import Modal, { ModalContent, ModalPortal } from 'components/Bootstrap/Modal';

const ResourceBadges = () => {
	const [item] = useOutletContext();
	const [badgeItem, setBadgeItem] = useState();

	let date;
	const DateDivider = ({ badgeDate }) => {
		let currDate = moment(badgeDate).format('DD/MM/YYYY');
		if (currDate != date) {
			date = currDate;
			return (<li className='list-group-item bg-light p-1 text-center'><span className='fw-semibold'>{currDate}</span></li>)
		}
		return null;
	}

	const showMapHandle = (badge) => {
		setBadgeItem(badge);
	}

	return (
		<div className='row g-0 align-items-center'>
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='resources.item.contents.badges.title' /></h6>
				<div className='text-body-ternary'><Trans i18nKey='resources.item.contents.badges.subtitle' /></div>
			</div>
			<div className='col-12 rounded border'>
				<List>
					{item?.last_month_badges && item.last_month_badges.length > 0 ? (item.last_month_badges?.map((badge, idx) => (
						<React.Fragment key={idx}>
							<DateDivider badgeDate={badge.data} />
							<BadgeItem badge={badge} parentCallback={() => showMapHandle(badge)} />
						</React.Fragment>
					))) : (<div className='text-center text-body-tertiary small'>
						<Trans i18nKey='resources.item.contents.badges.no_badge' />
					</div>)}
				</List>
			</div>
			{badgeItem && (
				<Modal>
					<ModalPortal>
						<ModalContent
							id='badgeMap'
							initialOpen={true}
							title={<Trans i18nKey='resources.item.contents.badges.maps_title' />}
							onCloseHandle={() => setBadgeItem()}
							height={70}
						>
							<BadgeMap badge={badgeItem} />
						</ModalContent>
					</ModalPortal>
				</Modal>
			)}
		</div >
	)
}

export default ResourceBadges