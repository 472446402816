import { DropDown, DropDownButton, DropDownContent } from 'components/Bootstrap/DropDown'
import Tooltip from 'components/Tooltip/Tooltip'
import { useTheme } from 'providers/ThemeProvider'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const Themes = () => {
	const { t } = useTranslation();
	const { theme, toggleTheme } = useTheme();

	return (
		<DropDown>
			<DropDownButton>
				<Tooltip title={t('components:nav.theme.title')} >
					<i className={(theme == 'light' ? 'mdi-weather-sunny' : theme == 'dark' ? 'mdi-weather-night' : 'mdi-theme-light-dark') + ' mdi mdi-18px'} />
					<i className='mdi mdi-chevron-down' />
				</Tooltip>
			</DropDownButton>
			<DropDownContent className='dropdown-menu-end' style={{ width: '150px' }}>
				<div>
					<button className={'dropdown-item' + (theme == 'light' ? ' active' : '')} onClick={() => toggleTheme('light')}>
						<i className='mdi mdi-weather-sunny mdi-18px pe-1' />
						<Trans i18nKey='components:nav.theme.light' />
					</button>
				</div>
				<div>
					<button className={'dropdown-item' + (theme == 'dark' ? ' active' : '')} onClick={() => toggleTheme('dark')}>
						<i className='mdi mdi-weather-night mdi-18px pe-1' />
						<Trans i18nKey='components:nav.theme.dark' />
					</button>
				</div>
				<div>
					<button className={'dropdown-item' + (!theme ? ' active' : '')} onClick={() => toggleTheme(null)}>
						<i className='mdi mdi-theme-light-dark mdi-18px pe-1' />
						<Trans i18nKey='components:nav.theme.auto' />
					</button>
				</div>
			</DropDownContent>
		</DropDown>
	)
}

export default Themes