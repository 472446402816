import React, { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { datasetTypologies, generateDepartmentCharts, populateDepartmentCharts } from './charts/chartsUtils';
import ListItem from 'components/List/ListItem';
import ListItemPlaceHolder from 'components/List/ListItemPlaceHolder';
import ItemNav from 'components/Item/ItemNav';
import { Popover, PopoverBody, PopoverButton, PopoverContent } from 'components/Popover/Popover';
import { Trans, useTranslation } from 'react-i18next';
import Chart from 'components/Chart/Chart';

const DepartmentEvaluations = () => {
    const { evaluations } = useLoaderData();
    const { t } = useTranslation();
    let emptyCharts = generateDepartmentCharts();

    const [charts, setCharts] = useState(emptyCharts);
    const [resources, setResources] = useState([{ loading: true }, { loading: true }, { loading: true }, { loading: true }]);
    const [radarChart, setRadarChart] = useState(null);
    const [scatterChart, setScatterChart] = useState(null);
    const [polarChart, setPolarChart] = useState(null);
    const [linearChart, setLinearChart] = useState(null);
    const [mixedChart, setMixedChart] = useState(null);

    const [type, setType] = useState(null);
    const [typologies, setTypologies] = useState(datasetTypologies);

    useEffect(() => {
        if (evaluations != null) {
            evaluations.then((response) => {
                let newcharts = populateDepartmentCharts(charts, response.data);
                setCharts(newcharts);
                if (newcharts != null) {
                    setRadarChart(newcharts.radarChart);
                    setScatterChart(newcharts.scatterChart);

                    let res = Object.values(newcharts.resources);
                    setResources(res);

                    if (type == null) {
                        // setType('rating');
                        handleType(typologies[0]); //setto ratings come primo tab selezionato
                    }
                }
            });
        }
    }, [evaluations]);

    useEffect(() => {
        if (type != null) {
            setPolarChart(charts.polarChart[type]);
            setLinearChart(charts.linearChart[type]);
            let res = resources.find((resource) => { return resource.isActive; });
            setMixedChart(res.charts[type]);
        }
    }, [type]);

    const handleClick = (item) => {
        let newResources = [...resources];
        newResources.forEach(resource => {
            resource.isActive = resource.id == item.id;
            if (resource.id == item.id) {
                setMixedChart(resource.charts[type]);
            }
        });
        setResources(newResources);
    };

    const handleType = (item) => {
        let newTypes = [...typologies];
        newTypes.forEach(type => {
            type.isActive = type.id == item.id;
            if (type.id == item.id) {
                setType(type.id);
            }
        });
        setTypologies(newTypes);

    };

    return (
        <div className="g-0 row">
            {charts != null ? (
                <>
                    <div className='col-12 col-xl-4 p-2'>
                        {radarChart && (<>
                            <div className='text-end'>
                                <Popover placement='bottom'>
                                    <PopoverButton className='btn btn-sm btn-white m-0 p-0'>
                                        <i className='mdi mdi-information-variant-circle-outline mdi-18px'></i>
                                    </PopoverButton>
                                    <PopoverContent className='popover bs-popover-auto fade show '>
                                        <div className='p-2'><Trans i18nKey={radarChart.description} /></div>
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <Chart {...radarChart} />
                        </>)}
                    </div>
                    <div className='col-12 col-xl-8 p-2'>
                        {scatterChart && (<>
                            <div className='text-end'>
                                <Popover placement='bottom'>
                                    <PopoverButton className='btn btn-sm btn-white m-0 p-0'>
                                        <i className='mdi mdi-information-variant-circle-outline mdi-18px'></i>
                                    </PopoverButton>
                                    <PopoverContent className='popover bs-popover-auto fade show '>
                                        <div className='p-2'><Trans i18nKey={scatterChart.description} /></div>
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <Chart {...scatterChart} />
                        </>)}
                    </div>

                    <div className='col-12 text-center p-2 py-0 bg-white sticky-top'>
                        <ItemNav>
                            {typologies?.map((type, index) => (
                                <button key={index}
                                    onClick={() => handleType(type)}
                                    className={type.isActive ? 'nav-link p-2 shadow-sm border-0' : 'nav-link text-body p-2'}
                                    style={{ color: (type.isActive ? '#FFF' : null), backgroundColor: (type.isActive ? type.backgroundColor : null) }}
                                >
                                    <span>{type.name}</span>
                                </button>
                            ))}
                        </ItemNav>
                    </div>

                    <div className='col-12 col-xl-4 p-2'>
                        {polarChart && (<>
                            <div className='text-end'>
                                <Popover placement='bottom'>
                                    <PopoverButton className='btn btn-sm btn-white m-0 p-0'>
                                        <i className='mdi mdi-information-variant-circle-outline mdi-18px'></i>
                                    </PopoverButton>
                                    <PopoverContent className='popover bs-popover-auto fade show '>
                                        <div className='p-2'><Trans i18nKey={polarChart.description} /></div>
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <Chart {...polarChart} />
                        </>)}
                    </div>
                    <div className='col-12 col-xl-8 p-2'>
                        {linearChart && (<>
                            <div className='text-end'>
                                <Popover placement='bottom'>
                                    <PopoverButton className='btn btn-sm btn-white m-0 p-0'>
                                        <i className='mdi mdi-information-variant-circle-outline mdi-18px'></i>
                                    </PopoverButton>
                                    <PopoverContent className='popover bs-popover-auto fade show '>
                                        <div className='p-2'><Trans i18nKey={linearChart.description} /></div>
                                    </PopoverContent>
                                </Popover>
                            </div>
                            <Chart {...linearChart} />
                        </>)}
                    </div>

                    <div className='col-12 p-2'>
                        <div className='row p-2'>
                            <div className='col-12 col-xl-4' style={{ height: '396px' }}>
                                {resources != null && resources.length > 0 ? (
                                    <OverlayScrollbarsComponent
                                        className="border h-100 rounded m-2"
                                        options={{
                                            showNativeOverlaidScrollbars: false,
                                            overflow: { x: "hidden" },
                                            scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
                                        }}
                                        defer
                                    >
                                        <div className="list-group list-group-flush">
                                            {resources?.map((item, index) =>
                                                item.loading ? (
                                                    <ListItemPlaceHolder key={index} />
                                                ) : (
                                                    <ListItem
                                                        key={index}
                                                        item={item}
                                                        parentCallback={() => handleClick(item)}
                                                        isActive={item.isActive}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </OverlayScrollbarsComponent>
                                ) : (
                                    <div className='row g-0 align-items-center h-100 text-center p-2'>
                                        <div>
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden"><Trans i18nKey="components:loading" /></span>
                                            </div>
                                            <div><span><Trans i18nKey="components:loading" /></span></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 col-xl-8'>
                                {mixedChart && (<>
                                    <div className='text-end'>
                                        <Popover placement='bottom'>
                                            <PopoverButton className='btn btn-sm btn-white m-0 p-0'>
                                                <i className='mdi mdi-information-variant-circle-outline mdi-18px'></i>
                                            </PopoverButton>
                                            <PopoverContent className='popover bs-popover-auto fade show '>
                                                <div className='p-2'><Trans i18nKey={mixedChart.description} /></div>
                                            </PopoverContent>
                                        </Popover>
                                    </div>
                                    <Chart {...mixedChart} />
                                </>)}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className='col-12'>
                    <div className="bg-opacity-10 bg-warning lh-1 mt-2 p-4 rounded text-center text-warning">
                        <div>
                            <i className="mdi mdi-warehouse mdi-72px"></i>
                        </div>
                        <h6><Trans i18nKey="departments.item.contents.evaluations.empty" /></h6>
                    </div>
                </div>
            )}
        </div >
    )
}

export default DepartmentEvaluations