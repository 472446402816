import React, { useEffect, useState } from 'react';
import * as bootstrap from 'bootstrap'
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Form from 'components/FormFields/Form';
import FormFields from 'components/FormFields/FormFields';
import CompanyService from 'services/CompanyService';
import { listDtoToOptions } from 'components/FormFields/formUtils';
import BadgeService from 'services/BadgeService';
import BadgeCount from './BadgeCount';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import './Badge.scss';

const _getLocation = (successFn, errorFn) => {
	if (navigator.geolocation) {
		//dati non più vecchi di 10 secondi, tempo di esecuzione massima 5 secondi, precisione alta
		navigator.geolocation.getCurrentPosition((pos) => {
			successFn && successFn(pos);
		}, (error) => {
			switch (error.code) {
				case error.PERMISSION_DENIED:
					errorFn && errorFn("Hai negato l'accesso alla geolocalizzazione, potrai effettuare la timbratura solo in smart working.");
					break;
				case error.POSITION_UNAVAILABLE:
				case error.TIMEOUT:
				case error.UNKNOWN_ERROR:
				default:
					errorFn && errorFn("Non è stato possibile recuperare la tua posizione, potrai comunque effettuare la timbratura", true);
					break;
			}
		}, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true });

	} else {
		errorFn && errorFn("Il tuo dispositivo non supporta la geolocalizzazione, potrai comunque effettuare la timbratura.", true);
	}
}
const companyService = new CompanyService();
const badgeService = new BadgeService();
const BadgeModal = () => {
	const user = useSelector((state) => state.auth.user.risorsa);
	const badge = useSelector((state) => state.badge.badge);
	const badgeType = useSelector((state) => state.badge.type);
	const [form, setForm] = useState();

	useEffect(() => {
		if (badgeType == 'BADGE_END') {
			let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('badgeModal'));
			modal && modal.show();

			//recupero le sedi filtrate per geolocalizzazione
			companyService.getAllLocations()
				.then(response => response.data)
				.then(data => {

					let newForm = {
						id: {
							type: "hidden",
						},
						idRisorsa: {
							type: "hidden",
						},
						lat: {
							type: "hidden",
						},
						lng: {
							type: "hidden",
						},
						precisione: {
							type: "hidden",
						},
						timezone: {
							type: "hidden",
						},
						connessione: {
							type: "hidden",
						},
						userAgent: {
							type: "hidden",
						},

						idSede: {
							type: "select",
							label: "Seleziona la sede",
							attribute: {
								required: true,
							}
						},
						/*
						test: {
							type: 'text',
							value: 'asdfdsgsgdsgsdgdsgsd',
							cssClass: 'col-12 col-lg-6',
							attribute: {
								required: true,
								minLength: '3',
								maxLength: '10'
							},
						},
	
						test2: {
							type: 'password',
							value: 'asdfdsgsgdsgsdgdsgsd',
							cssClass: 'col-12 col-lg-6',
							attribute: {
								required: true,
								minLength: '3',
								maxLength: '10'
							},
						},
	
						test3: {
							type: 'textarea',
							value: 'asdfdsgsgdsgsdgdsgsd',
							cssClass: 'col-12 col-lg-6',
							attribute: {
								required: true,
								minLength: '3',
								maxLength: '10'
							},
						},
	
						test4: {
							type: 'date',
							cssClass: 'col-12 col-lg-6',
							// value: 'asdfdsgsgdsgsdgdsgsd',
							attribute: {
								required: true,
								// minLength: '3',
								// maxLength: '10'
							},
							dateAttribute: {
								dateType: 'WEEK',
							}
						},
	
						test45: {
							type: 'date',
							cssClass: 'col-12 col-lg-6',
							attribute: {
								required: true,
							},
							dateAttribute: {
							}
						},
	
						test5: {
							type: 'time',
							value: 'asdfdsgsgdsgsdgdsgsd',
							attribute: {
								// required: true,
								// minLength: '3',
								// maxLength: '10'
							},
						},
	
	
						test6: {
							type: 'editor',
							value: 'asdfdsgsgdsgsdgdsgsd',
							attribute: {
								required: true,
								maxLength: '1000'
							},
							hint: 'testo di prova'
						},
	
						test7: {
							type: 'switch',
							label: 'testone',
							value: 'asdfdsgsgdsgsdgdsgsd',
							attribute: {
								required: true,
								// minLength: '3',
								maxLength: '10'
							},
							hint: 'testo di prova'
						},
	
						test8: {
							type: 'radio',
							label: 'testone',
							value: 'asdfdsgsgdsgsdgdsgsd',
							attribute: {
								required: true,
								// minLength: '3',
								maxLength: '10'
							},
							hint: 'testo di prova'
						},
	
						test81: {
							type: 'checkbox',
							label: 'testone',
							value: 'asdfdsgsgdsgsdgdsgsd',
							attribute: {
								required: true,
								// minLength: '3',
								maxLength: '10'
							},
							hint: 'testo di prova'
						},
	
						test9: {
							type: 'percentage',
							attribute: {
								required: true,
							},
							hint: 'testo di prova'
						},
	
						test91: {
							type: 'currency',
							attribute: {
								required: true,
								min: '0',
								max: '100'
							},
							hint: 'testo di prova'
						},
	
						test10: {
							type: 'email',
							attribute: {
								required: true,
							},
							hint: 'testo di prova'
						},
	
						test11: {
							type: 'file',
							attribute: {
								required: true,
							},
							hint: 'testo di prova'
						},
	
						test12: {
							type: 'number',
							attribute: {
								required: true,
							},
							hint: 'testo di prova'
						},
						*/
						submit: {
							type: 'submit',
							className: 'btn-success text-white',
							title: 'components:badgeModal.buttons.badgeNow',
							icon: 'mdi mdi-play-circle-outline mdi-18px'
						},
						badgeSuccess: {
							type: 'hidden',
						},
						badgeError: {
							type: 'hidden',
						}

					}

					//recupero la posizione
					_getLocation((pos) => {
						newForm.badgeSuccess.value = 1;
						newForm.badgeError = null;

						newForm.idSede.asyncOptions = listDtoToOptions(data);

						newForm.lat.value = pos.coords.latitude;
						newForm.lng.value = pos.coords.longitude;
						newForm.precisione.value = pos.coords.accuracy;

						if (navigator) {
							//Assegno il tipo di useragent
							newForm.userAgent.value = navigator.userAgent;

							//Assegno il tipo di connessione WIFI|ETHERNET|MOBILE
							let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
							if (connection) {
								newForm.connessione.value = connection.effectiveType; //type non funziona quindi prendo se è 4g - 3g - 2g ecc.
							}

						}

						setForm(newForm);

					}, (error) => {
						newForm.badgeSuccess = null;
						newForm.badgeError.value = error;

						newForm.idSede.asyncOptions = listDtoToOptions(data.filter(item => { return item.id == '0' }));
						newForm.idSede.value = newForm.idSede.asyncOptions[0];
						setForm(newForm);
					})

				})
		}

	}, []);


	function onSubmit(formData) {
		// console.log(formData.values());
		formData.entries().forEach((value, key) => {
			console.log(key + ": " + value)
		});


		// badgeService.create(formData).then((response) => {
		// 	//if ok set redux state
		// })


	}

	const noBadge = () => {

	}

	return (
		<div id='badgeModal' className='modal fade' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='false'>
			<div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
				<div className='modal-content'>
					<OverlayScrollbarsComponent
						options={{
							showNativeOverlaidScrollbars: false,
							overflow: { x: "hidden" },
							scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
						}}
						defer
						style={{ height: '100%' }}
					>
						{badgeType != 'BADGE_END' ? (
							<div id='badge-clock-in' className='modal-body'>
								<div>
									{badge == null || badge.id == null ? (
										<div className='badge-jumbotron badge-jumbotron-success'>
											<div className='text-success'>
												<div><i className='mdi mdi-human-greeting-variant mdi-48px'></i></div>
												<h5><Trans i18nKey="components:badgeModal.header.start" values={{ name: user.nome }} /></h5>
											</div>
											<div>
												<span /><span /><span /><span /><span /><span /><span /><span /><span /><span />
												<span /><span /><span /><span /><span /><span /><span /><span /><span /><span />
											</div>
										</div>
									) : null}
									{badge != null && badge.isBreak != null && badge.isBreak == '1' ? (
										<div className='badge-jumbotron badge-jumbotron-success'>
											<div className='text-success'>
												<div><i className='mdi mdi-motion-pause mdi-48px'></i></div>
												<h5><Trans i18nKey="components:badgeModal.header.stop" values={{ name: user.nome }} /></h5>
											</div>
											<div>
												<span /><span /><span /><span /><span /><span /><span /><span /><span /><span />
												<span /><span /><span /><span /><span /><span /><span /><span /><span /><span />
											</div>
										</div>
									) : null}
									{badge != null && badge.isBreak != null && badge.isBreak == '0' ? (
										<div className='badge-jumbotron badge-jumbotron-success'>
											<div className='text-success'>
												<div><i className='mdi mdi-restart mdi-48px'></i></div>
												<h5><Trans i18nKey="components:badgeModal.header.end" values={{ name: user.nome }} /></h5>
											</div>
											<div>
												<span /><span /><span /><span /><span /><span /><span /><span /><span /><span />
												<span /><span /><span /><span /><span /><span /><span /><span /><span /><span />
											</div>
										</div>
									) : null}
									{badge != null && badge.uscita != null ? (
										<div className='col-12'>
											<div className='row rounded mt-3 g-0 bg-secondary bg-opacity-10'>
												<div className='col'>
													<div>Entrata</div>
													<div>
														<h6>{badge.entrata || '-'}</h6>
													</div>
												</div>
												<div className='col'>
													<div>Uscita</div>
													<div>
														<h6>{badge.uscita || '-'}</h6>
													</div>
												</div>
												<div className='col'>
													<div>Ore attività</div>
													<div>
														<h6><BadgeCount badges={[badge]} /></h6>
													</div>
												</div>
											</div>
										</div>
									) : null}
									<div className='col-md-6 col-sm-12 m-auto text-start'>
										<Form name='badgeForm' onSubmit={onSubmit}>
											<FormFields fields={form} items={badge} formName='' />
										</Form>
									</div>
									<div>
										{form?.badgeSuccess != null ? (
											<div id='badge-geo-success' className='bg-success bg-opacity-10 rounded text-success p-2 animate__animated animate__backInUp'>
												<i className='mdi mdi-checkbox-marked-circle-outline'></i> <span></span>
												<Trans i18nKey="components:badgeModal.geo.success" />
											</div>
										) : null}
										{form?.badgeError != null ? (
											<div id='badge-geo-error' className='bg-danger bg-opacity-10 rounded text-danger p-2 animate__animated animate__backInUp'>
												<i className='mdi mdi-alert-circle-outline'></i> <span></span>
												{form.badgeError.value}
											</div>
										) : null}
									</div>
									<div className='mt-2 bg-secondary bg-opacity-10 p-2 rounded text-start overflow-auto'>
										<div>
											<i className='mdi mdi-alert-decagram-outline mdi-18px'></i> <strong><Trans i18nKey="components:badgeModal.note.title" />:</strong>
										</div>
										<ul className='mb-0' style={{ paddingLeft: '1.3rem' }}>
											<li><span><Trans i18nKey="components:badgeModal.note.items.0" /></span></li>
											<li><span><Trans i18nKey="components:badgeModal.note.items.1" /></span></li>
											<li><span><Trans i18nKey="components:badgeModal.note.items.2" /></span></li>
										</ul>
										<div className='mt-2'>
											<i className='mdi mdi-information-outline mdi-18px'></i> <strong><Trans i18nKey="components:badgeModal.privacy.title" />:</strong>
										</div>
										<div>
											<span><Trans i18nKey="components:badgeModal.privacy.text" /></span>
											<span dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_EMAIL_PRIVACY }} />
										</div>
									</div>
								</div>
								<div className='m-auto text-center'>
									<button className='btn btn-dark mt-2 w-100' onClick={noBadge}>
										<Trans i18nKey="components:badgeModal.buttons.noBadge" />
									</button>
								</div>
							</div>
						) : (
							<div id='badge-clock-out' className='modal-body text-center d-none'>
								<div className='bg-secondary text-secondary bg-opacity-10 rounded p-3'>
									<div><i className='mdi mdi-hand-wave-outline mdi-48px'></i></div>
									<h5><Trans i18nKey="components:badgeModal.headers.goodbye" values={{ name: user.nome }} /></h5>
								</div>
								{badge != null ? (
									<div className='g-0 row'>
										<div className='col-12'>
											<div className='row rounded mt-3 g-0 bg-secondary bg-opacity-10'>
												<div className='col'>
													<div>Entrata</div>
													<div>
														<h6>{badge.entrata || '-'}</h6>
													</div>
												</div>
												<div className='col'>
													<div>Uscita</div>
													<div>
														<h6>{badge.uscita || '-'}</h6>
													</div>
												</div>
												<div className='col'>
													<div>Ore attività</div>
													<div>
														<h6><BadgeCount badges={[badge]} /></h6>
													</div>
												</div>
											</div>
										</div>
										<div className='col-12 p-2 mt-3'>
											<strong><Trans i18nKey="components:badgeModal.buttons.logout" /></strong>
										</div>
									</div>
								) : null}
							</div>
						)}
					</OverlayScrollbarsComponent>
				</div>
			</div>
		</div>
	)
}

export default BadgeModal