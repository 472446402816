import React, { useEffect, useState } from 'react'
import FormFields from 'components/FormFields/FormFields'
import { Form } from 'react-router-dom';
import Item from 'components/Item/Item';
import ItemHeader from 'components/Item/ItemHeader';
import ItemContent from 'components/Item/ItemContent';
import { listDtoToOptions } from 'components/FormFields/formUtils';
import ResourceService from 'services/ResourceService';

const resourceService = new ResourceService();
const WorkForm = ({ item }) => {
    const [resources, setResources] = useState();

    useEffect(() => {
        resourceService.getAll('?perPage=1000&filters[status]=1')
            .then((response) => {
                return response.data;
            }).then((data) => {
                setResources(listDtoToOptions(data.data));
            });
    }, []);

    function getNextLavoro(el) {
        return el;
    }

    function changeBusinessSector(el) {
        return el;
    }

    const form = {
        progetto: {
            idProgetto: {
                type: "select",
                label: "Progetto",
                cssClass: "col-sm-12",
                options: null,
                attribute: {
                    required: true,
                    onChange: () => { getNextLavoro(this) }
                }
            }
        },
        nuovo_progetto: {
            item: {
                type: "number",
                label: "ID Progetto",
                cssClass: "col-sm-12 col-md-4 col-lg-2",
                dataAttribute: {
                    // next-project: null
                },
                attribute: {
                    onkeyup: "checkProgejectExist(this);",
                    minlength: 5,
                    maxlength: 5
                }
            },
            nome: {
                type: "text",
                label: "Nome",
                cssClass: "col-sm-12 col-md-4 col-lg-6",
                attribute: {
                    placeholder: "Inserisci il Nome del Progetto"
                }
            },
            azienda: {
                type: "select",
                label: "Azienda",
                cssClass: "col-sm-12 col-md-4 col-lg-4",
                options: [
                    { label: "Alcotec", value: "alcotec" },
                    { label: "Alcocheck", value: "alcocheck" },
                    { label: "Alcosys", value: "alcosys" },
                    { label: "Altra", value: "altra" },
                    { label: "SGS", value: "sgs" },
                ]
            },
            note: {
                type: "textarea",
                label: "Note",
                cssClass: "col-sm-12",
                attribute: {
                    maxLength: "250"
                }
            }
        },
        info: {
            progetto: null,
            nome: {
                type: "text",
                cssClass: "col-sm-12 col-md-6 col-lg-9",
                label: "Nome commessa",
                attribute: {
                    required: true
                }
            },
            status: {
                type: "select",
                cssClass: "col-sm-12 col-md-6 col-lg-3",
                label: "Stato Commessa",
                options: [
                    { label: "In corso", value: "C" },
                    { label: "Sospesa", value: "S" },
                    { label: "Chiusa", value: "K" },
                ],
                attribute: {
                    required: true
                }
            },
            categoria: {
                type: "select",
                cssClass: "col-sm-12 col-md-4",
                label: "Categoria",
                options: [
                    { label: "Commessa", value: "Commessa" },
                    { label: "Studio Assett (DD)", value: "Ass" },
                    { label: "Concorso di idee", value: "Concorso" },
                ],
                attribute: {
                    required: true
                }
            },
            ambito: {
                type: "select",
                cssClass: "col-sm-12 col-md-4",
                label: "Ambito",
                options: null,
                attribute: {
                    required: true
                }

            },
            pal: {
                type: "number",
                cssClass: "col-sm-12 col-md-4",
                label: "Avanzamento Lavori (%)",
                attribute: {
                    required: true,
                    min: "0",
                    max: "100",
                    step: "1"
                }
            },
            luogo: {
                type: "text",
                cssClass: "col-sm-12 col-md-4 col-lg-4",
                label: "Luogo",
                attribute: {
                    required: true
                }
            },
            indirizzo: {
                type: "text",
                cssClass: "col-sm-12 col-md-4 col-lg-4",
                label: "Indirizzo",
                attribute: {
                    required: true
                }
            },
            azienda: {
                type: "select",
                label: "Azienda",
                cssClass: "col-sm-12 col-md-4 col-lg-4",
                options: [
                    { label: "Alcotec", value: "alcotec" },
                    { label: "Alcocheck", value: "alcocheck" },
                    { label: "Alcosys", value: "alcosys" },
                    { label: "Altra", value: "altra" },
                    { label: "SGS", value: "sgs" },
                ]
            },
            descrizione: {
                type: "textarea",
                cssClass: "col-sm-12",
                label: "Servizio Offerto",
                attribute: {
                    maxLength: "150"
                }
            }
        },
        risorse: {
            idResponsabile: {
                type: "select",
                cssClass: "col-sm-12 col-md-6",
                label: "Responsabile Principale",
                attribute: {
                    required: true
                },
                options: resources
            },
            idRisorse: {
                type: "select",
                cssClass: "col-sm-12 col-md-6",
                label: "Risorse Operative",
                attribute: {
                    required: true,
                    multiple: true
                },
                options: resources
            }
        },
        cliente: {
            idCliente: {
                type: "select",
                label: "Cliente",
                cssClass: "col-sm-12",
                options: null,
                attribute: {
                    required: true
                }
            }
        },
        nuovo_cliente: {
            ragione: {
                type: "text",
                label: "Ragione Sociale",
                cssClass: "col-sm-12 col-md-6"
            },
            piva: {
                type: "text",
                label: "Partita IVA",
                cssClass: "col-sm-12 col-md-6 col-lg-2"
            },
            tipoSoggetto: {
                type: "select",
                label: "Tipo Soggetto",
                cssClass: "col-sm-12 col-md-6 col-lg-2",
                options: [
                    { label: "Società", value: "SOC" },
                    { label: "Azienda", value: "AZ" },
                    { label: "Persona Fisica", value: "PF" },
                ]
            },
            entita: {
                type: "select",
                label: "Entità",
                cssClass: "col-sm-12 col-md-6 col-lg-2",
                options: [
                    { label: "Azienda", value: "AZ" },
                    { label: "Pubblica Amministrazione", value: "PA" },
                    { label: "Società di Persone", value: "SP" },
                ]
            },
            indirizzocliente: {
                type: "text",
                label: "Indirizzo",
                cssClass: "col-sm-12 col-md-6"
            },
            civico: {
                type: "text",
                label: "Civico",
                cssClass: "col-sm-12 col-md-3"
            },
            cap: {
                type: "text",
                label: "CAP",
                cssClass: "col-sm-12 col-md-3 "
            },
            comune: {
                type: "text",
                label: "Comune",
                cssClass: "col-sm-12 col-md-4"
            },
            provincia: {
                type: "text",
                label: "Provincia",
                cssClass: "col-sm-12 col-md-4"
            },
            nazione: {
                type: "text",
                label: "Nazione",
                cssClass: "col-sm-12 col-md-4"
            }
        },
        referente: {
            referenteNome: {
                type: "text",
                label: " Nome Referente",
                cssClass: "col-sm-12 col-md-6 col-lg-4"
            },
            referenteCognome: {
                type: "text",
                label: "Cognome Referente",
                cssClass: "col-sm-12 col-md-6 col-lg-4"
            },
            referenteTelefono: {
                type: "text",
                label: "Telefono Referente",
                cssClass: "col-sm-12 col-md-6 col-lg-4"
            },
            referenteMail: {
                type: "text",
                label: "Mail Referente",
                cssClass: "col-sm-12 col-md-6 col-lg-6"
            },
            referentePEC: {
                type: "text",
                label: "PEC Referente",
                cssClass: "col-sm-12 col-md-6 col-lg-6"
            },
            note: {
                type: "textarea",
                label: "Note",
                cssClass: "col-sm-12",
                attribute: {
                    maxLength: "200"
                }
            }
        },
        date: {
            dataAperturaPrevista: {
                type: "date",
                cssClass: "col-sm-12 col-md-6",
                label: "Data Apertura Prevista",
                attribute: {
                    required: true
                }
            },
            dataChiusuraPrevista: {
                type: "date",
                cssClass: "col-sm-12 col-md-6",
                label: "Data Fine Prevista"
            },
            dataApertura: {
                type: "date",
                cssClass: "col-sm-12 col-md-6",
                label: "Data Inizio Effettiva"
            },
            dataChiusura: {
                type: "date",
                cssClass: "col-sm-12 col-md-6",
                label: "Data Fine Effettiva"
            }
        },
        currency: {
            importo: {
                type: "currency",
                label: "Importo Commessa",
                cssClass: "col-sm-12 col-md-6",
                attribute: {
                    required: true
                }
            },
            importoLavori: {
                type: "currency",
                label: "Importo Lavori",
                cssClass: "col-sm-12 col-md-6"
            }
        },
        amount: [],
        costControl: {
            businessSector: {
                type: "select",
                label: "Business Sector",
                cssClass: "col-sm-12 col-md-6 col-lg-4",
                options: null,
                attribute: {
                    onchange: (e) => { changeBusinessSector(e.target.value) }
                }
            },
            businessField: {
                type: "select",
                label: "Business Field",
                options: null,
                cssClass: "col-sm-12 col-md-6 col-lg-4",
                attribute: {
                    disabled: true
                }
            },
            cdcSap: {
                type: "text",
                label: "Cdc SAP",
                cssClass: "col-sm-12 col-md-6 col-lg-4",
                attribute: {
                    disabled: null
                }
            }
        },
        switch: {
            chiusuraOperativa: {
                type: "switch",
                label: "Chiusura Operativa",
                cssClass: "col-sm-12 col-md-6 text-start"
            },
            chiusuraAmministrativa: {
                type: "switch",
                label: "Chiusura Amministrativa",
                cssClass: "col-sm-12 col-md-6 text-start"
            },
            gestioneSemplificata: {
                type: "switch",
                label: "Gestione Semplificata",
                cssClass: "col-sm-12 col-md-6 text-start"
            },
            attiva: {
                type: "switch",
                label: "Aperta alle risorse",
                cssClass: "col-sm-12 col-md-6 text-start"
            },
            editPreviousPP: {
                type: "switch",
                label: "Modifica ProjectPlan Pregresso",
                hint: "Questa modifica è permessa solo alla PMO",
                cssClass: "col-sm-12 col-md-6 text-start",
                attribute: {
                    disabled: null
                }
            }
        },
        note: {
            note: {
                type: "textarea",
                label: "Note",
                attribute: {
                    maxLength: "5000"
                }
            }
        },
        hidden: {
            id: {
                type: "hidden"
            },
            idProgetto: null,
            lavoro: {
                type: "hidden"
            },
            idReferente: {
                type: "hidden"
            },
            tipologia: {
                type: "hidden"
            },
            businessScope: {
                type: "hidden"
            },
            peso: {
                type: "hidden"
            },
            lastModified: {
                type: "hidden"
            },
            idCommessaPadre: {
                type: "hidden"
            },
            budgetOre: {
                type: "hidden"
            },
            createdBy: {
                type: "hidden"
            },
            updatedBy: {
                type: "hidden"
            }
        }
    };



    return (
        <Item>
            <ItemHeader
                avatar={item?.avatar}
                title={item != null ? item.fullname : 'Nuova commessa'}
                subtitle={item != null ? 'Stai modificando questa commessa' : 'Stai creando una nuova commessa'}
            />
            <ItemContent>
                <Form className="needs-validation" novalidate action="/actions/orders/orders.php?ADD=true" method="post" name="form-commessa" id="form-commessa">

                    {item == null ? (
                        <>
                            <div className="row g-0 align-items-center mb-2">
                                <div className="col-12 col-md position-relative">
                                    <FormFields fields={form.progetto} item={item} formName='form[progetto]' />
                                </div>
                                <div className="col-12 col-md-auto">
                                    <button className="btn bg-primary bg-opacity-10 text-primary mt-2" type="button" onclick="event.stopPropagation(); toggleOrderProject(this);">
                                        <span><i className="mdi mdi-plus"></i> Crea Nuovo Progetto</span>
                                    </button>
                                </div>
                                <div className="col-12">
                                    <div className="collapse px-2" id="collapseProject">
                                        <div className="bg-light border p-2 rounded">
                                            <div className="text-center">
                                                <h6>Stai registrando un nuovo Progetto</h6>
                                            </div>
                                            <FormFields fields={form.nuovo_progetto} item={item} formName='form[progetto]' />
                                        </div>
                                    </div>
                                </div>
                                <div id="next-order" className="d-none bg-primary bg-opacity-10 text-primary p-2 rounded text-center">La commessa che stai creando sarà la <strong></strong></div>
                            </div>
                            <hr />
                        </>
                    ) : null}

                    <FormFields fields={form.info} item={item} />
                    <hr />


                    <FormFields fields={form.risorse} item={item} />

                    {item?.id == null ? (
                        <>
                            <div className="bg-primary bg-opacity-10 mx-2 p-2 rounded text-primary">Potrai aggiungere e/o differenziare le risorse in gruppi differenti nella pagina gestione team.</div>
                            <hr />
                        </>
                    ) : null}

                    <div className="row g-0 align-items-center mb-2">
                        <div className="col-12 col-md">
                            <FormFields fields={form.cliente} item={item} wrap={false} />
                        </div>
                        <div className="col-12 col-md-auto">
                            <button className="btn bg-primary bg-opacity-10 text-primary mt-2" type="button" onclick="toggleOrderClient(this); return false;">
                                <span><i className="mdi mdi-plus"></i> Crea Nuovo Cliente</span>
                            </button>
                        </div>
                        <div className="col-12">
                            <div className="collapse px-2" id="collapseClient">
                                <div className="bg-light border p-2 rounded">
                                    <div className="text-center">
                                        <h6>Stai registrando un nuovo Cliente</h6>
                                    </div>
                                    <FormFields fields={form.nuovo_cliente} item={item?.cliente} formName='form[cliente]' />
                                    <div className="text-center mb-4">
                                        <h6>Dati del Referente</h6>
                                    </div>
                                    <FormFields fields={form.referente} item={item?.referente} formName='form[cliente]' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <FormFields fields={form.date} item={item} />
                    <hr />
                    <FormFields fields={form.currency} item={item} />
                    <hr />
                    <FormFields fields={form.amount} item={item} />
                    <FormFields fields={form.costControl} item={item} />
                    <hr />
                    <FormFields fields={form.switch} item={item} />
                    <FormFields fields={form.note} item={item} />
                    <FormFields fields={form.hidden} item={item} wrap={false} />


                    <div className="row g-0 p-2">
                        <div className="col-12 text-center">
                            <button className="btn btn-primary" id="save-button"><i className="mdi mdi-content-save"></i> Salva</button>
                        </div>
                    </div>
                </Form>
            </ItemContent>
        </Item>


    )
}

export default WorkForm