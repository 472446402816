import React from "react";

const ListItemPlaceHolder = () => {
	return (
		<>
			<div className="list-group-item position-relative px-2">
				<div className="row p-2 g-0 flex-nowrap justify-content-between align-items-center placeholder-glow">
					<div className="col-auto p-0 pe-2 placeholder-glow">
						<span className="p-3 placeholder bg-secondary bg-opacity-75 rounded-circle"></span>
					</div>
					<div className="col lh-1 placeholder-glow lh-1">
						<div>
							<span className="placeholder placeholder-sm bg-secondary bg-opacity-75 rounded-1 w-75"></span>
						</div>
						<div>
							<span className="placeholder placeholder-xs bg-secondary bg-opacity-75 rounded-1 w-50"></span>
						</div>
						<div>
							<span className="placeholder placeholder-xs bg-secondary bg-opacity-75 rounded-1 w-25"></span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default ListItemPlaceHolder;
