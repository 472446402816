import Roles from "pages/settings/roles/Roles";
import Role from "pages/settings/roles/item/Role";
import RoleEmpty from "pages/settings/roles/item/RoleEmpty";
import RoleForm from "pages/settings/roles/item/RoleForm";
import RoleInfo from "pages/settings/roles/item/info/RoleInfo";
import { defer } from "react-router-dom";
import { Error } from "components/Error/Error";
import RoleService from "services/RoleService";

const service = new RoleService();
const RoleRoutes = [
	{
		path: "/settings/roles",
		element: <Roles />,
		loader: async ({ request }) => {
			return defer({ roles: service.getAll(new URL(request.url).search) });
		},
		handle: {
			title: 'roles.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				path: "",
				element: <RoleEmpty />,
			},
			{
				path: "/settings/roles/:id",
				element: <Role />,
				loader: ({ params }) => {
					return defer({ role: service.getById(params.id) });
				},
				handle: {
					crumb: null, //Breadcrumbs
				},
				children: [
					{
						index: true,
						element: <RoleInfo />,
					},
				],
				errorElement: <Error />,
			},
			{
				path: "/settings/roles/new",
				element: <RoleForm />,
			},
			{
				path: "/settings/roles/:id/edit",
				element: <RoleForm />,
				loader: ({ params }) => {
					return defer({ role: service.getById(params.id) });
				},
				errorElement: <Error />,
			},
		],
		errorElement: <Error />,

		future: {
			v7_normalizeFormMethod: true,
		},
	},
]

export default RoleRoutes