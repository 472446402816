import React, { useState } from 'react'
import { PlainInput } from './PlainField';

const PasswordField = ({ ...props }) => {
	const [showPassword, setShowPassword] = useState(false);

	const showPasswordToggle = () => {
		setShowPassword(!showPassword);
		let input = document.getElementById(props.id);
		input.type = !showPassword ? 'text' : 'password';
	}

	props.type = 'password';
	props.fieldType = 'password';

	props.inputGroup = {
		after: (<button type='button' className='btn h-100 border-0 p-0' onClick={showPasswordToggle}>
			<i className={showPassword ? 'mdi mdi-eye-off-outline' : 'mdi mdi-eye-outline'} />
		</button>
		)
	};

	return (
		<div className={props.type != null && props.type != 'hidden' ? (props.cssClass != null ? props.cssClass + ' p-2' : 'p-2') : 'w-auto'}>
			<div className="mb-3">
				{props.label != null ? (<label id={props.id + '_label'} htmlFor={props.id} className="form-label fw-semibold">{props.label}</label>) : null}
				<PlainInput {...props} />
			</div>
		</div>
	)
}

export default PasswordField