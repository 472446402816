import Item from 'components/Item/Item'
import React from 'react'
import { Trans } from 'react-i18next'

const Timesheets = () => {
	return (
		<Item>
			<div className='bg-warning-subtle text-warning rounded p-2 align-items-center justify-content-center d-flex'>
				<i className='mdi mdi-information-variant-circle-outline mdi-24px pe-1' />
				<Trans i18nKey='views:wip' />
			</div>
		</Item>
	)
}
export default Timesheets