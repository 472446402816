import React from 'react'
import { Trans, useTranslation } from 'react-i18next';
import Avatar from '../Avatar/Avatar';
import Tooltip from '../Tooltip/Tooltip';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
/**
 * Questo componente prende il nome da Persona di Microsoft 
 * e contiene le informazioni necessarie per contattare l'utente passatogli
 * TODO: in futuro potrebbe diventare un popover all'apertura dell'avatar
 * 
 * @param {*} resource 
 * @param {*} width 
 * @returns 
 */
const Persona = ({ resource, showContact = true }) => {
	const { t } = useTranslation();


	return (
		<div className='bg-white border-opacity-10 border-secondary card p-2 shadow-sm'>
			<div className='row g-2 align-items-center mb-2'>
				<div className='col-12 col-md-auto'>
					{resource.avatar != null ? (<Avatar item={resource.avatar} className='avatar-lg' />) : null}
				</div>
				<div className='col-12 col-md text-truncate text-center text-md-start px-2 lh-1'>
					<h5 className='fw-semibold mb-0 text-truncate'>{resource.fullname}</h5>
					{resource.status == '1' ? (
						<>
							<div className='fw-normal text-body-secondary text-truncate'>{resource.active_contract != null && resource.active_contract.profile != null && resource.active_contract.profile.descrizioneProfilo != null ? resource.active_contract.profile.descrizioneProfilo : <Trans i18nKey='components:persona.no_profile' />}</div>
							<div className='fw-normal text-body-secondary small text-truncate'>{resource.active_contract != null && resource.active_contract.societa != null ? resource.active_contract.societa : <Trans i18nKey='components:persona.no_society' />}</div>
						</>
					) : (
						<div className='fw-normal text-body-secondary small'>Non Attivo</div>
					)}
				</div>
			</div>
			{resource.status == '1' ? (
				<>
					<div className='row g-2 align-items-center'>
						<div className='col'>
							<div className='btn-group w-100 shadow-sm' role='group'>
								{resource.cellAziendale || resource.cellPersonale ? (
									<a className='btn btn-primary text-white w-100' href={'tel:' + (resource.cellAziendale || resource.cellPersonale)} target='_blank'>
										<i className='mdi mdi-phone'></i> <Trans i18nKey='components:persona.call' />
									</a>
								) : (
									<a className='btn btn-primary text-white w-100' href={'https://teams.microsoft.com/l/call/0/0?users=' + resource.mailAziendale} target='_blank'>
										<i className='mdi mdi-phone'></i> <Trans i18nKey='components:persona.call' />
									</a>
								)}
								<button type='button' className='btn btn-primary text-white dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false' />

								<ul className='dropdown-menu dropdown-menu-end'>
									{resource.cellAziendale || resource.cellPersonale ? (
										<>
											<li>
												<a className='dropdown-item' href={'https://teams.microsoft.com/l/call/0/0?users=' + resource.mailAziendale} target='_blank'>
													<Trans i18nKey='components:persona.teams_call' />
												</a>
											</li>
											<li><hr className='dropdown-divider' /></li>
										</>
									) : null}
									<li>
										<a className='dropdown-item' href={'https://teams.microsoft.com/l/call/0/0?users=' + resource.mailAziendale + '&withVideo=true'} target='_blank'>
											<Trans i18nKey='components:persona.teams_videocall' />
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-auto'>
							<Tooltip title={t('components:persona.mail')} >
								<a href={'mailto:' + resource.mailAziendale} target='_blank' className='btn border shadow-sm'>
									<i className='mdi mdi-email-outline'></i>
								</a>
							</Tooltip>
						</div>
						<div className='col-auto'>
							<Tooltip title={t('components:persona.teams_message')} >
								<a href={'https://teams.microsoft.com/l/chat/0/0?users=' + resource.mailAziendale} target='_blank' className='btn border shadow-sm'>
									<i className='mdi mdi-chat-outline'></i>
								</a>
							</Tooltip>
						</div>
					</div>
					{showContact ? (
						<OverlayScrollbarsComponent className='border mt-2 rounded' options={{ showNativeOverlaidScrollbars: false, overflow: { x: 'hidden' }, scrollbars: { autoHide: 'leave', autoHideDelay: 1300 } }} defer style={{ height: '100px' }}>
							<ul className='list-group list-group-flush text-start'>
								<li className='list-group-item py-1'>
									<strong>Contatti:</strong>
								</li>
								<li className='list-group-item'>
									<div className='row g-0 align-items-center'>
										<div className='col-auto'>
											<i className='mdi mdi-email-outline' />
										</div>
										<div className='col ps-2'>
											<Tooltip title={t('components:persona.mail')} >
												<a href={'mailto:' + resource.mailAziendale} target='_blank'>{resource.mailAziendale}</a>
											</Tooltip>
										</div>
										<div className='col-auto'>
											<CopyToClipboard value={resource.mailAziendale} />
										</div>
									</div>
								</li>
								{resource.cellAziendale ? (
									<li className='list-group-item'>
										<div className='row g-0 align-items-center'>
											<div className='col-auto'>
												<i className='mdi mdi-cellphone' />
											</div>
											<div className='col ps-2'>
												<Tooltip title={t('components:persona.call')} >
													<a href={'tel:' + resource.cellAziendale} target='_blank'>{resource.cellAziendale}</a>
												</Tooltip>
											</div>
											<div className='col-auto'>
												<CopyToClipboard value={resource.cellAziendale} />
											</div>
										</div>
									</li>
								) : null}
								{resource.cellPersonale ? (
									<li className='list-group-item'>
										<div className='row g-0 align-items-center'>
											<div className='col-auto'>
												<i className='mdi mdi-cellphone' />
											</div>
											<div className='col ps-2'>
												<Tooltip title={t('components:persona.call')} >
													<a href={'tel:' + resource.cellPersonale} target='_blank'>{resource.cellPersonale}</a>
												</Tooltip>
											</div>
											<div className='col-auto'>
												<CopyToClipboard value={resource.cellPersonale} />
											</div>
										</div>
									</li>
								) : null}
							</ul>
						</OverlayScrollbarsComponent>
					) : null}
				</>
			) : null}
		</div>
	)
}

export default Persona