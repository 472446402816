import Suppliers from "pages/users/suppliers/Suppliers";
import Supplier from "pages/users/suppliers/item/Supplier";
import SupplierEmpty from "pages/users/suppliers/item/SupplierEmpty";
import SupplierForm from "pages/users/suppliers/item/SupplierForm";
import SupplierInfo from "pages/users/suppliers/item/info/SupplierInfo";
import { defer } from "react-router-dom";
import { Error } from "components/Error/Error";
import SupplierService from "services/SupplierService";

const service = new SupplierService();
const SupplierRoutes = [
    {
        path: "/suppliers",
        element: <Suppliers />,
        loader: async ({ request }) => {
            return defer({ suppliers: service.getAll(new URL(request.url).search) });
        },
        handle: {
            title: 'suppliers.title',
            crumb: null, //Breadcrumbs
        },
        children: [
            {
                index: true,
                path: "",
                element: <SupplierEmpty />,
            },
            {
                path: "/suppliers/:id",
                element: <Supplier />,
                loader: ({ params }) => {
                    return defer({ supplier: service.getById(params.id) });
                },
                handle: {
                    crumb: null, //Breadcrumbs
                },
                children: [
                    {
                        index: true,
                        element: <SupplierInfo />,
                    },
                ],
                errorElement: <Error />,
            },
            {
                path: "/suppliers/new",
                element: <SupplierForm />,
            },
            {
                path: "/suppliers/:id/edit",
                element: <SupplierForm />,
                loader: ({ params }) => {
                    return defer({ supplier: service.getById(params.id) });
                },
                errorElement: <Error />,
            },
        ],
        errorElement: <Error />,

        future: {
            v7_normalizeFormMethod: true,
        },
    },
]

export default SupplierRoutes