import React from 'react';
import Modal, { ModalButton, ModalContent, ModalPortal } from 'components/Bootstrap/Modal';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Form from 'components/FormFields/Form';
import FormFields from 'components/FormFields/FormFields';

const ImportProjectPlan = () => {

	const { t } = useTranslation();

	const form = {
		template: {
			type: 'file',
			attribute: {
				required: true,
			},
			hint: t('components:importProjectPlan.hint')
		},
		id: {
			type: 'hidden',
		},
		submit: {
			type: 'submit',
			className: 'btn-success text-white',
			title: 'components:importProjectPlan.submit',
			icon: 'mdi mdi-play-circle-outline mdi-18px',
			attribute: {
				disabled: true,
			},
		},
		importProgress: {
			type: 'hidden',
		},
		importSuccess: {
			type: 'hidden',
		},
		importError: {
			type: 'hidden',
		}
	};

	function onSubmit(formData) {
		formData.entries().forEach((value, key) => {
			console.log(key + ": " + value)
		});

		///salvare....

	}

	return (
		<Modal>
			<ModalButton target='import-primavera' className='btn bg-primary text-white shadow-sm'>
				<i className='mdi mdi-plus-outline me-1'></i>
				<Trans i18nKey="components:importProjectPlan.button" />
			</ModalButton>
			<ModalPortal>
				<ModalContent
					id='import-primavera'
					title={<Trans i18nKey='components:importProjectPlan.title' />}
					// onCloseHandle={() => setBadgeItem()}
					height={70}
				>
					{!form?.importProgress?.value && !form?.importSuccess?.value && !form?.importSuccess?.value ? (
						<div className='mt-3'>
							<div className="bg-primary bg-opacity-10 text-primary p-2 rounded mb-3">
								<i className='mdi mdi-information-variant-circle-outline mdi-18px me-1' />
								<Trans i18nKey='components:importProjectPlan.description' />
								&nbsp;
								<NavLink to={'/documentations/procedures'} end data-bs-dismiss="modal">
									<Trans i18nKey='components:sidenav.items.documentations.procedures' />
								</NavLink>
							</div>
							<Form name='import-primavera-form' onSubmit={onSubmit}>
								<FormFields fields={form} formName='' />
							</Form>
						</div>
					) : form?.importProgress?.value == '1' ? (
						<div>
							Stiamo analizzando il file
							Stiamo importando i dati
							Stiamo aggiornando la pianificazione
							Import eseguito con successo, la pagina si chiuderà tra 3 secondi
							<div className="progress" role="progressbar" aria-label="Example with label" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
								<div className="progress-bar" style="width: 10%">10%</div>
							</div>
						</div>
					) : form?.importSuccess?.value == '1' ? (

						<div>
							Import eseguito con successo
							<button type="button" className="btn btn-success" data-bs-dismiss="modal" aria-label="Close">Chiudi</button>
						</div>
					) : form?.importError?.value == '1' ? (
						<div>
							Si è verificato un errore durante l'import
							possibili soluzioni
						</div>
					) : null}
				</ModalContent>
			</ModalPortal>
		</Modal>
	)
}

export default ImportProjectPlan