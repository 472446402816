import {
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGOUT
} from '../../constants/action.constant';
import AuthService from '../../services/AuthService';
import Api from 'services/Api';

const authService = new AuthService(new Api());
const request = (user) => { return { type: LOGIN_REQUEST, user } };
const success = (user) => { return { type: LOGIN_SUCCESS, user } };
const failure = (error) => { return { type: LOGIN_FAILURE, error } };

const redirectTosuccess = () => {
	let referrer = window.frames.top.document.referrer;
	if (referrer && referrer.includes(process.env.REACT_APP_PUBLIC_URL) && !referrer.includes('login')) {
		window.location.href = referrer;
	} else {
		window.location.href = '/';
	}
}

export const login = (email, password, rememberme) => {
	return async (dispatch) => {
		try {
			dispatch(request(email));

			const token = await authService.login(email, password, rememberme).then(response => response.data);

			if (token && token.access_token) {
				localStorage.setItem('token', JSON.stringify(token));

				authService.api.setAuth(token.access_token);
				const user = await authService.me().then(response => response.data);

				if (user) {
					localStorage.setItem('user', JSON.stringify(user));

					if (rememberme) {
						localStorage.setItem('rememberUser', JSON.stringify({
							avatar: user.risorsa.avatar,
							name: user.risorsa.fullname,
							email: email,
						}));
					} else {
						localStorage.removeItem('rememberUser');
					}

					dispatch(success(user));
					// window.location.href = '/';
					redirectTosuccess();
				} else {
					dispatch(failure(`Couldn't login user: ${email}`));
				}
			} else {
				dispatch(failure(`Couldn't login user: ${email}`));
			}

		} catch (e) {
			console.error(`Error code: ${e.code}\nError details: ${e.message}`);
			dispatch(failure(`Couldn't login user: ${email}`));
		}
	}
}

export const logout = async () => {
	try {
		// const response = await authService.logout();

		localStorage.removeItem('token');
		localStorage.removeItem('user');
		sessionStorage.removeItem('nav');
		window.location.href = '/login';

		return { type: LOGOUT };

	} catch (e) {
		console.error(`Error code: ${e.code}\nError details: ${e.message}`);
	}
}