import React, { useEffect } from 'react';
import axios from 'axios';

const LegacyComponent = ({ url }) => {

	useEffect(() => {
		if (url != null) {
			let urls = [
				process.env.REACT_APP_LEGACY_URL + url,
				process.env.REACT_APP_LEGACY_URL + '/css/secure/assets.min.css',
				process.env.REACT_APP_LEGACY_URL + '/css/secure/app.min.css',
				process.env.REACT_APP_LEGACY_URL + '/js/secure/assets.min.js',
				process.env.REACT_APP_LEGACY_URL + '/js/secure/app.min.js',
				//vedere se aggiungere font altri css o js necessari e anche bundle
			];


			let files = [];
			let requests = urls.map(u => axios.get(u));
			axios.all(requests).then((responses) => {
				responses.forEach((resp) => {
					let type = resp.headers['content-type'];

					if (type.includes('css')) {
						files.push(styleGenerator(resp.data));
					}

					if (type.includes('javascript')) {
						let Badge = null;
						files.push(scriptGenerator(resp.data));
					}

					if (type.includes('html')) {
						files.push(htmlGenerator(resp.data));
					}
				});
			});

			window['initBootstrap'] && window['initBootstrap']();
			window['initMain'] && window['initMain']();

		}

		return () => {
			// files.forEach((file) => {
			//     document.getElementById('legacy-content').removeChild(file);
			// })
			if (document.getElementById('legacy-content')) {
				document.getElementById('legacy-content').innerHTML = '';
			}
		}

	}, [url])

	const htmlGenerator = (data) => {
		const html = document.getElementById('legacy-content');
		html.innerHTML = data;
	}

	const scriptGenerator = (data) => {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.innerHTML = data;
		document.getElementById('legacy-content').appendChild(script);
		return script;
	}

	const styleGenerator = (data) => {
		const style = document.createElement('style');
		style.type = 'text/css';
		style.rel = "stylesheet";
		style.innerHTML = data;
		document.getElementById('legacy-content').appendChild(style);
		return style;
	}

	return (<div id='legacy-content'></div>)
}

export default LegacyComponent