import { urlReplace } from "helpers/url";
import Api from "./Api";

export default class SupplierService {
	constructor(api) {
		this.api = api || new Api();
	}

	async getAll(params) {
		return this.api.get('/secure/suppliers', params);
	}

	async getById(id, params) {
		return this.api.get(urlReplace('/secure/supplier/{id}', { id: id }), params);
	}
}