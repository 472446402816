import React, { createContext, forwardRef, useContext, useMemo, useRef, useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  arrow
} from '@floating-ui/react';

function useTooltip({ initialOpen = false, placement = 'top', open: controlledOpen, onOpenChange: setControlledOpen }) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const arrowRef = useRef();
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5
      }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef })
    ]
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null
  });
  const focus = useFocus(context, {
    enabled: controlledOpen == null
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(() => ({ open, setOpen, arrowRef, ...interactions, ...data }), [open, setOpen, arrowRef, interactions, data]);
}


export const TooltipContext = createContext(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

const Tooltip = ({ children, ...options }) => {
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {
        React.Children.count(children) === 1 ? (
          <>
            <TooltipTrigger>{children}</TooltipTrigger>
            <TooltipContent>{options.title}</TooltipContent>
          </>
        ) : children
      }
    </TooltipContext.Provider>
  );
}

export const TooltipTrigger = forwardRef(({ children, asChild = false, ...props }, propRef) => {
  const context = useTooltipContext();
  const childrenRef = children.ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed'
      })
    );
  }

  return React.Children.map(children, (child =>
    React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed'
      })
    )
  ))
});

export const TooltipContent = forwardRef(({ style, ...props }, propRef) => {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  const staticSide =
    {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right'
    }[context.placement.split('-')[0]] ?? '';

  const arrowX = context.middlewareData.arrow?.x ?? null;
  const arrowY = context.middlewareData.arrow?.y ?? null;

  if (!context.open) return null;

  return (
    <FloatingPortal>
      <div ref={ref} style={{ ...context.floatingStyles, ...style }} className='tooltip bs-tooltip-auto fade show' {...context.getFloatingProps(props)} >
        <div className='tooltip-arrow' ref={context.arrowRef} style={{
          position: 'absolute',
          background: 'var(--bs-tooltip-bg)',
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : '',
          [staticSide]: staticSide == 'top' || staticSide == 'bottom' ? '0px' : '-4px',
          transform: 'rotate(45deg)'
        }} />
        <div className='tooltip-inner'>{props.children}</div>
      </div>
    </FloatingPortal>
  );
});

export default Tooltip