import React, { createContext, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const LangContext = createContext();

export const LangProvider = ({ children }) => {
	const { i18n } = useTranslation();
	const [lang, setLang] = useState(localStorage.getItem('lang') || i18n.languages[0]);
	if (lang) {
		document.documentElement.setAttribute("lang", lang);
		moment.locale(lang);
	}


	const toggleLang = (language) => {
		localStorage.setItem('lang', language);
		i18n.changeLanguage(language);
		moment.locale(language);
		setLang(language);
	};

	const value = useMemo(() => ({ lang, toggleLang }), [lang]);

	return <LangContext.Provider value={value}>{children}</LangContext.Provider>
}

export const useLang = () => {
	return useContext(LangContext);
};