import React from 'react'
import Item from 'components/Item/Item'
import { Trans, useTranslation } from 'react-i18next'

const DepartmentEmpty = () => {

    const { t } = useTranslation();

    return (
        <Item>
            <div className='d-flex flex-column h-100 justify-content-center text-body-secondary text-center'>
                <div><i className='mdi mdi-warehouse mdi-72px'></i></div>
                <h6><Trans i18nKey="departments.item.empty.title" /></h6>
                <div><Trans i18nKey="departments.item.empty.subtitle" /></div>
                <div className='mt-4'>
                    <button
                        className="btn bg-primary text-white shadow-sm"
                        type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#departmens-charts" aria-controls="departments-chart"
                        title={t("departments.charts.title")}>
                        <i className="mdi mdi-sitemap-outline me-1"></i>
                        <Trans i18nKey="departments.charts.title" />
                    </button>
                </div>
            </div>
        </Item>

    )
}

export default DepartmentEmpty