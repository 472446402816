import React from 'react'
import { DropDown, DropDownButton, DropDownContent } from 'components/Bootstrap/DropDown'
import { Trans, useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import Tooltip from 'components/Tooltip/Tooltip'
import { useLang } from 'providers/LangProvider'

const Langs = () => {
	const { t } = useTranslation();
	const { lang, toggleLang } = useLang();

	return (
		<DropDown>
			<DropDownButton>
				<Tooltip title={t('components:nav.langs.title')} >
					<Flag code={lang == 'es' ? 'es' : lang == 'en' ? 'gb' : 'it'} width='18' />
					<i className='mdi mdi-chevron-down' />
				</Tooltip>
			</DropDownButton>
			<DropDownContent className='dropdown-menu-end' style={{ width: '150px' }}>
				<div>
					<button className={'dropdown-item' + (lang == 'it' ? ' active' : '')} onClick={() => toggleLang('it')}>
						<Flag code='it' width='18' className='pe-1' />
						<Trans i18nKey='components:nav.langs.it' />
					</button>
				</div>
				<div>
					<button className={'dropdown-item' + (lang == 'en' ? ' active' : '')} onClick={() => toggleLang('en')}>
						<Flag code='gb' width='18' className='pe-1' />
						<Trans i18nKey='components:nav.langs.en' />
					</button>
				</div>
				<div>
					<button className={'dropdown-item' + (lang == 'es' ? ' active' : '')} onClick={() => toggleLang('es')}>
						<Flag code='es' width='18' className='pe-1' />
						<Trans i18nKey='components:nav.langs.es' />
					</button>
				</div>
			</DropDownContent>
		</DropDown>
	)
}

export default Langs