import React from 'react'
import { Trans } from 'react-i18next'

const ResourceSkills = () => {
	return (
		<div className='row g-0 align-items-center'>
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='resources.item.contents.skills.title' /></h6>
			</div>
			<div className='col-12'>
				<div className='bg-warning-subtle rounded p-2 text-warning mb-2 text-center'>
					<Trans i18nKey='views:wip' />
				</div>
			</div>
		</div>
	)
}

export default ResourceSkills