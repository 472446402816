import React, { useEffect, useState } from 'react';

import './BadgeCount.scss';
import Clock from 'components/Clock/Clock';
import Moment from 'react-moment';

const BadgeCount = ({ badges, format = 'hh:mm' }) => {
	const moment = require('moment');
	const [badge, setBadge] = useState();

	const sumInSeconds = badges?.reduce((acc, item) => {
		let entrata = moment(item.entrata, 'HH:mm:ss');
		let uscita = item.uscita != null ? moment(item.uscita, 'HH:mm:ss') : moment();
		return acc + uscita.diff(entrata, 'seconds');
	}, 0);

	useEffect(() => {
		const interval = setInterval(() => {
			setBadge(moment(moment.duration(sumInSeconds, 'seconds').as('milliseconds')));
		}, 1000);

		return () => clearInterval(interval);
	}, [badge])


	// return (<Clock date={badge} />)
	return (badge && <Moment
		format={format}
		subtract={badge && { hours: 1 }}
		trim>
		{badge}
	</Moment>
	)
}

export default BadgeCount