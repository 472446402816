import React, { forwardRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import { it } from 'date-fns/locale/it';
import 'assets/datepicker.scss';
import moment from 'moment';

const DateField = ({ ...props }) => {
	registerLocale('it', it);
	const { control } = useFormContext();
	const { t } = useTranslation();

	//considerare mindate, maxdate per un limitare le date
	//considerare startdate enddate per selezionare da - a
	//considerare Select Time Only per fare un timepicker
	//considerare holidays
	//disabilitare weekend

	props.inputClass = props.inputClass || 'form-control rounded-start text-center flatpickr-input';
	props.dataAttribute = props.dataAttribute || {};

	switch (props.dateAttribute.dateType) {
		case 'MONTH':
			props.dateAttribute.showWeekNumbers = null;
			props.dateAttribute.showWeekPicker = null;
			props.dateAttribute.showYearPicker = null;
			props.dateAttribute.showMonthYearPicker = true;
			props.dateFormat = 'MMMM - YYYY';
			break;
		case 'YEAR':
			props.dateAttribute.showWeekNumbers = null;
			props.dateAttribute.showWeekPicker = null;
			props.dateAttribute.showYearPicker = true;
			props.dateAttribute.showMonthYearPicker = null;
			props.dateFormat = 'YYYY';
			break;
		case 'WEEK':
			props.dateAttribute.showWeekNumbers = true;
			props.dateAttribute.showWeekPicker = true;
			props.dateAttribute.showYearPicker = null;
			props.dateAttribute.showMonthYearPicker = null;
			props.dateFormat = "dd/MM/YYYY";
			break;
		case 'DAY':
		default:
			props.dateAttribute.showWeekNumbers = null;
			props.dateAttribute.showWeekPicker = null;
			props.dateAttribute.showYearPicker = null;
			props.dateAttribute.showMonthYearPicker = null;
			props.dateFormat = 'dd/MM/YYYY';
			break;
	}

	return (
		<div className={props.cssClass != null ? props.cssClass + ' p-2' : 'p-2'}>
			<div className='mb-3'>
				{props.label != null ? (<label id={props.id + '_label'} htmlFor={props.id} className='form-label fw-semibold'>{props.label}</label>) : null}
				<Controller
					control={control}
					name={props.formName + (props.attribute.multiple ? '[]' : '')}
					defaultValue={props.value}
					rules={{
						required: props.attribute.required,
						min: props.attribute.min,
						minLength: props.attribute.minLength,
						max: props.attribute.max,
						maxLength: props.attribute.maxLength,
						validate: props.attribute.validate,
					}}
					render={({
						field: { onChange, onBlur, value, name, ref },
						fieldState: { invalid, isTouched, isDirty, error },
					}) => (
						<>
							<ReactDatePicker
								name={name}
								defaultValue={value}
								selected={value}
								onChange={onChange}
								customInput={
									props.dateAttribute.showWeekPicker ?
										<ReactDatePickerRangeInput
											name={name}
											value={value}
											onChange={onchange}
											invalid={invalid}
											isDirty={isDirty}
											{...props}
											ref={ref}
										/>
										:
										<ReactDatePickerInput
											name={name}
											value={value}
											onChange={onchange}
											invalid={invalid}
											isDirty={isDirty}
											{...props}
											ref={ref}
										/>
								}
								locale={'it'}

								dateFormat={props.dateFormat}

								filterDate={(date) => (date.getDay() !== 6) && (date.getDay() !== 0)}

								peekNextMonth
								showMonthDropdown
								showYearDropdown
								dropdownMode='select'

								{...props.dateAttribute}

							/>
							<div className={(error != null ? 'd-block' : '') + ' invalid-feedback'}>
								{error != null && <Trans i18nKey={'components:formFields.date.error.' + error.type} values={props.attribute} />}
							</div>
							{props.hint != null ? (<div id={props.id + '_hint'} className='form-text'>{props.hint}</div>) : null}
						</>
					)}
				/>
			</div>
		</div>

	)
}

const ReactDatePickerInput = forwardRef(({ value, onClick, name, invalid, isDirty, ...props }, ref) => {
	return (
		<div className='input-group has-validation z-0'>
			<input className='form-control rounded-end-0' type='text' name={name} defaultValue={value} ref={ref} onClick={onClick}  {...props.attribute} />
			<span className='input-group-text' onClick={onClick}>
				<i className='mdi mdi-calendar-outline cursor-pointer' />
			</span>
		</div>
	)
})

//per weekpicker
const ReactDatePickerRangeInput = forwardRef(({ value, onClick, name, invalid, isDirty, ...props }, ref) => {
	const start = value ? moment(value, 'DD/MM/YYYY').startOf('isoWeek').format('DD/MM/YYYY') : null;
	const end = value ? moment(value, 'DD/MM/YYYY').endOf('isoWeek').format('DD/MM/YYYY') : null;

	return (
		<div className='input-group has-validation z-0'>
			<span className={'form-control rounded-start rounded-end-0' + (invalid ? ' is-invalid' : isDirty && !invalid ? ' is-valid' : '')} onClick={onClick}>{start}{end != null ? ' - ' + end : null}</span>
			<input type='hidden' name={name} defaultValue={start} ref={ref} {...props.attribute} />
			<span className='input-group-text' onClick={onClick}>
				<i className='mdi mdi-calendar-outline cursor-pointer' />
			</span>
		</div >
	)
})

export default DateField