// Generate Empty Radar Chart for Funzione Team
export const _generateFTRadarChart = () => {
	return {
		id: 'radar-chart',
		type: 'radar',
		series: [],
		options: {
			chart: {
				height: 230,
				type: 'radar',
				dropShadow: {
					enabled: true,
					blur: 1,
					left: 1,
					top: 1
				},
			},
			legend: {
				show: false,
				height: 0
			},
			stroke: {
				colors: ['#ff9800'],
				width: 2
			},
			fill: {
				colors: ['#ff9800'],
				opacity: 0.1
			},
			markers: {
				colors: ['#ff9800']
			},
			xaxis: {
				categories: [
					'Rating',
					'Hard skills',
					'Soft skills',
					'HR',
					'Mood'
				],
				labels: {
					show: true,
					style: {
						colors: [''],
						fontSize: "14px",
						fontFamily: 'Arial',
					}
				}
			},
			yaxis: {
				min: 0,
				max: 5,
				tickAmount: 5,
			},
			plotOptions: {
				radar: {
					polygons: {
						strokeColor: '#e8e8e8',
						fill: {
							colors: ['#f8f8f8', '#fff']
						}
					}
				}
			}
		}
	};
};