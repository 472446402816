import React from "react";

const TopicPlaceHolderItem = () => {
	return (
		<div className="card shadow-sm placeholder-glow border border-opacity-25 h-100 w-100 " aria-hidden="true">
			<div className="card-header border-0 row g-0 align-items-center">
				<div className="col-auto pe-2">
					<span className="p-3 placeholder bg-secondary bg-opacity-75 rounded-circle"></span>
				</div>
				<div className="col lh-1">
					<div>
						<span className="p-1 placeholder placeholder-sm bg-secondary bg-opacity-75 rounded-1 w-75"></span>
					</div>
					<div>
						<span className="placeholder placeholder-xs bg-secondary bg-opacity-75 rounded-1 w-50"></span>
					</div>
				</div>
			</div>

			<div className="card-body lh-sm">
				<h5 className="card-title">
					<span className="placeholder placeholder-sm bg-secondary bg-opacity-75 rounded-1 col-7"></span>
				</h5>
				<div className="card-text">
					<span className="placeholder placeholder-xs bg-secondary bg-opacity-50 rounded-1 col-10"></span>
					<span className="placeholder placeholder-xs bg-secondary bg-opacity-50 rounded-1 col-6"></span>
				</div>
			</div>

			<div className="card-footer border-0 row g-0 align-items-center">
				<div className="col lh-1">
					<div>
						<span className="placeholder placeholder-xs bg-secondary bg-opacity-75 rounded-1 w-75"></span>
					</div>
					<div>
						<span className="placeholder placeholder-xs bg-secondary bg-opacity-75 rounded-1 w-50"></span>
					</div>
				</div>
				<div className="col-12 col-md-auto text-center">
					<button className="bg-opacity-75 bg-secondary border-0 btn disabled placeholder placeholder-xs px-4 py-0 rounded-1"></button>
				</div>
			</div>
		</div>
	);
};

export default TopicPlaceHolderItem;
