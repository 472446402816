import React from 'react'
import { Trans } from 'react-i18next'

const ListItemLoader = () => {
	return (
		<div className="list-group-item text-center p-2">
			<div className="spinner-border text-primary text-center" role="status" aria-hidden="true" ></div>
			<strong className="visually-hidden"><Trans i18nKey="components:loader" /></strong>
		</div>
	)
}

export default ListItemLoader