import React from 'react'
import { PlainInput } from './PlainField';

const RadioField = ({ ...props }) => {
	props.type = 'checkbox';
	props.inputClass = 'form-check-input';

	return (
		<div className={props.cssClass != null ? props.cssClass + ' p-2' : 'p-2'}>
			<div className="mb-3">
				<div className="form-check d-inline-block">
					{props.label != null ? (<label id={props.id + '_label'} htmlFor={props.id} className="form-check-label fw-semibold" dangerouslySetInnerHTML={{ __html: props.label }} />) : null}
					<PlainInput {...props} />
				</div>
			</div>
		</div>
	)
}

export default RadioField