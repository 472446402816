import React from 'react'
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import DetailView from 'components/DetailView/DetailView';
import Avatar from 'components/Avatar/Avatar';

const RoleInfo = () => {
	const [item] = useOutletContext();

	return (
		item != null ? (
			<>
				{/* info gruppo */}
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='roles.item.contents.info.title' /></h6>
						<div className='text-muted'><Trans i18nKey='roles.item.contents.info.subtitle' /></div>
					</div>
					<DetailView item={item} attributes={['nome', 'descrizione']} options={{
						transPrefix: 'models:role.',
						cssClass: 'col-12 col-md-6 border-bottom p-2'
					}} />
				</div>

				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='roles.item.contents.info.permissions.title' /></h6>
						<div className='text-muted'><Trans i18nKey='roles.item.contents.info.permissions.subtitle' /></div>
					</div>
					{item.permissions != null && item.permissions.length != 0 ? (
						item.permissions?.map((permission, idx) => (
							<div key={idx} className='col-12 col-md-6 col-lg-4'>
								<div className='row g-0 align-items-center p-2'>
									<div className="col-auto p-0 pe-2">
										<i className='mdi mdi-checkbox-marked-circle-outline mdi-18px text-success' />
									</div>
									<div className="col lh-1 text-truncate text-start">
										<div><span className='fw-semibold'>{permission.nome}</span></div>
										<div><small className='text-body-secondary'>{permission.descrizione}</small></div>
									</div>
								</div>
							</div>
						))
					) : (<div className='text-center text-body-tertiary small'><Trans i18nKey='roles.item.contents.info.permissions.empty' /></div>)}
				</div>

				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='roles.item.contents.info.resources.title' /></h6>
						<div className='text-muted'><Trans i18nKey='roles.item.contents.info.resources.subtitle' /></div>
					</div>
					{item.resources != null && item.resources.length != 0 ? (
						item.resources?.map((resource, idx) => (
							<div key={idx} className='col-12 col-md-6 col-lg-4 p-2'>
								<div className='border p-2 rounded'>
									<div className='row g-0 align-items-center'>
										<div className="col-auto p-0 pe-2">
											<Avatar item={resource.avatar} />
										</div>
										<div className="col lh-1 text-truncate text-start">
											<h6 className="m-0 text-truncate">{resource.fullname}</h6>
											<div className="text-body-secondary text-truncate">
												<small>{resource.funzioneTeam}</small>
											</div>
										</div>
									</div>
								</div>
							</div>
						))
					) : (<div className='text-center text-body-tertiary small'><Trans i18nKey='roles.item.contents.info.resources.empty' /></div>)}
				</div>
			</>
		) : null
	)
}

export default RoleInfo