import {
	BADGE_START,
	BADGE_STOP,
	BADGE_END,
} from '../../constants/action.constant';

const initialState = {
	badgeIn: false,
	badge: localStorage.getItem('badge') ? JSON.parse(localStorage.getItem('badge')) : null,
	error: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case BADGE_START:
			return {
				badgeIn: true
			};
		case BADGE_STOP:
			return {
				badgeIn: false,
				badge: action.badge,
				error: null
			};
		case BADGE_END:
			return {
				badgeIn: false,
				badge: null,
				error: action.error
			};
		default:
			return state;
	}
};

export default reducer;