import React, { useState } from 'react';
import ItemAwait from 'components/Item/ItemAwait';
import { useLoaderData } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import Loader from 'components/Loader/Loader';

const Reports = () => {
	let { report } = useLoaderData();

	const [item, setItem] = useState({
		type: 'report',
		id: undefined,
		embedUrl: undefined,
		accessToken: undefined,
	});

	// callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (item == null || item.id == null) {
			setItem({
				'id': response.data.id,
				'accessToken': response.data.accessToken,
				'embedUrl': response.data.embedUrl,
				'type': 'report',
				'visualName': response.data.name,
			});
		}
	}

	return (
		<ItemAwait resolvePromise={report} hasResolved={resolvedPromise} loading={<Loader />}>
			<PowerBIEmbed embedConfig={item} cssClassName='h-100 w-100' />
		</ItemAwait>
	)
}

export default Reports