import React from 'react'
import { PlainField } from './PlainField'

const PercentageField = ({ ...props }) => {
	props.type = 'number';
	props.attribute = props.attribute || {};
	if (props.attribute.step == null) {
		props.attribute.step = 'any';
		props.attribute.max = '100';
		props.attribute.min = '0';
	}
	props.inputGroup = props.inputGroup || { before: '%' }
	props.fieldType = 'percentage';

	return (<PlainField {...props} />)
}

export default PercentageField