import React from 'react'
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip/Tooltip';

const CopyToClipboard = ({ ref, value }) => {
	const { t } = useTranslation();

	value = value || ref.value;

	return (
		<Tooltip title={t('components:copyToClipboard')} >
			<button className='btn btn-sm shadow-sm border' onClick={() => navigator.clipboard.writeText(value)}>
				<i className='mdi mdi-content-copy' />
			</button>
		</Tooltip>
	)
}

export default CopyToClipboard