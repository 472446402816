import React, { useEffect, useState } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import ItemAwait from 'components/Item/ItemAwait';
import ItemHeader from 'components/Item/ItemHeader'
import ItemContent from 'components/Item/ItemContent';
// import { getPermissions } from '../../../../repositories/PermissionsRepository';
// import { getResources } from '../../../../repositories/ResourcesRepository';
import FormFields from 'components/FormFields/FormFields';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';
// import { listDtoToOptions } from 'components/FormFields/formUtils';
// import { updateRole } from '../../../../repositories/RolesRepository';
import Form from 'components/FormFields/Form';

// const resourceService = new ResourceService();
// const permissionService = new PermissionService();
// const roleService = new RoleService();
const CompanyForm = () => {
	const { role } = useLoaderData();
	const [item, setItem] = useState();
	const { t } = useTranslation();

	const [resources, setResources] = useState();
	const [permissions, setPermissions] = useState();

	useEffect(() => {
		// getResources('?perPage=1000&filters[status]=1')
		// 	.then((response) => {
		// 		return response.data;
		// 	}).then((data) => {
		// 		setResources(listDtoToOptions(data.data));
		// 	});

		// getPermissions('?perPage=1000')
		// 	.then((response) => {
		// 		return response.data;
		// 	}).then((data) => {
		// 		setPermissions(listDtoToOptions(data.data));
		// 	});
	}, []);

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			let newItem = Object.assign({}, response.data);
			//converto i permessi e le risorse in options per la select
			newItem.permissions = response.data.permissions.map(i => ({
				label: i.nome,
				value: i.id,
			}));
			newItem.resources = response.data.resources.map(i => ({
				label: i.fullname,
				value: i.id,
				avatar: i.avatar,
			}));

			setItem(newItem);
		}
	}

	const form = {
		id: {
			type: "hidden",
		},
		slug: {
			type: "text",
			label: "Slug",
			cssClass: "col-sm-12 col-md-6",
			attribute: {
				required: true,
			}
		},
		nome: {
			type: "text",
			label: "Nome",
			cssClass: "col-sm-12 col-md-6",
			attribute: {
				required: true,
			}
		},
		descrizione: {
			type: "textarea",
			label: "Descrizione",
			cssClass: "col-sm-12",
			attribute: {
				required: true,
			}
		},
		resources: {
			type: "select",
			label: "Risorse",
			cssClass: "col-sm-12 col-lg-6",
			asyncOptions: resources,
			attribute: {
				required: true,
				multiple: true,
				// onChange: (selectedOption) => {
				// }
			}
		},
		permissions: {
			type: "select",
			label: "Permessi",
			cssClass: "col-sm-12 col-lg-6",
			asyncOptions: permissions,
			attribute: {
				required: true,
				multiple: true,
				// onChange: (selectedOption) => {
				// }
			}
		},
	};

	const handleSubmit = (formData) => {
		// updateRole(item.id, formData).then(e => {
		// 	return redirect(`../${item.id}`)
		// })
	}

	return (
		<ItemAwait resolvePromise={role} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
			<ItemHeader
				title={item != null ? item.nome : t('views:roles.new')}
				subtitle={item != null ? item.descrizione : t('views:roles.item.header.subtitle')}
			/>
			<ItemContent>
				<Form name='form-role' enctype='multipart/form-data' onSubmit={handleSubmit} >
					<FormFields fields={form} item={item} formName='' />
					<div className="row g-0 p-2">
						<div className="col-12 text-center">
							<button className="btn btn-primary" id="save-button"><i className="mdi mdi-content-save"></i> Salva</button>
						</div>
					</div>
				</Form>
			</ItemContent>
		</ItemAwait>
	)
}

export default CompanyForm