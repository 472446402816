import React from 'react';
import Avatar from '../Avatar/Avatar';
import { motion } from 'framer-motion';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const TopicDetails = ({ item, handleCallback, rowID }) => {

	const openUrl = () => {
		if (item.content.url != null) {
			return window.open(item.content.url, '_blank');
		}
		return false;
	};

	return (<div className='topic-details'>
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.2, delay: 0.1 }}
			style={{ pointerEvents: 'auto' }}
			className='overlay'
			onClick={() => handleCallback(null)}
		>
			<motion.div className='card-wrap' layoutId={rowID + '_' + item.id} animate={{ transitionEnd: { transform: 'translate(-50%, -50%)' } }}>
				<div className={'card shadow-sm cursor-pointer bg-white border border-opacity-10 h-100 w-100' + (item.class != null && item.class.length > 0 ? item.class : '')}
					style={item?.content?.background ? { background: item.content.background, backgroundSize: 'cover' } : null}
				>
					{item?.header && (
						<div className={'card-header border-0 row g-0 align-items-center p-1 ' + (item.header.class != null ? item.header.class : '')}
							style={item.header?.background ? { background: item.header.background, backgroundSize: '100% 100%' } : null}
						>
							{item.header?.avatar && (<div className='col-auto pe-1'><Avatar item={item.header.avatar} /></div>)}
							<div className='col lh-1'>
								{item.header?.title ? (<h6 className='card-title m-0' dangerouslySetInnerHTML={{ __html: item.header.title }} />) : (<h6>&nbsp;</h6>)}
								{item.header?.subtitle && (<p className='card-text small' dangerouslySetInnerHTML={{ __html: item.header.subtitle }} />)}
							</div>
							{item.header?.button && (<div className='col-12 col-md-auto text-center' dangerouslySetInnerHTML={{ __html: item.header.button }} />)}
						</div>
					)}
					{item?.content && (
						<div className={'card-body border-0 d-block ' + (item.content?.class ? item.content.class : 'bg-transparent lh-sm')}>
							<OverlayScrollbarsComponent
								options={{
									showNativeOverlaidScrollbars: false,
									overflow: { x: 'hidden' },
									scrollbars: { autoHide: 'leave', autoHideDelay: 1300 },
								}}
								defer
								style={{ height: '100%', maxHeight: '200px', width: '100%' }}
							>
								{item.content?.image && (
									<div className='text-center'>
										<img className='img-fluid' src={item.content.image} height={124} width={item.content.width} />
									</div>
								)}
								{item.content?.avatar && (<div className='text-center'><Avatar item={item.content.avatar} /></div>)}
								{item.content?.title && (<h5 className='card-title' dangerouslySetInnerHTML={{ __html: item.content.title }} />)}
								{item.content?.subtitle && (<p className='card-text fst-italic' dangerouslySetInnerHTML={{ __html: item.content.subtitle }} />)}
								{item.content?.text && (<div className='mt-2'><p dangerouslySetInnerHTML={{ __html: item.content.text }}></p></div>)}
								{item.content?.url && (
									<button type='button' className='btn btn-primary btn-sm btn-md btn-lg  mt-1' onClick={openUrl}>
										<i className='mdi mdi-share'></i>
									</button>
								)}
							</OverlayScrollbarsComponent>
						</div>
					)}
					{item?.footer && (
						<div className={'card-footer lh-1 border-0 row g-0 align-items-center ' + (item.footer?.class ? item.footer.class : 'bg-transparent text-center justify-content-center')}
							style={item.footer?.background ? { background: item.footer.background, backgroundSize: 'cover', } : null}
						>
							{item.footer?.avatar && (<div className='col-auto pe-2'><Avatar item={item.footer.avatar} /></div>)}
							<div className='col p-md-0'>
								{item.footer?.title ? (<h6 className='card-text m-0' dangerouslySetInnerHTML={{ __html: item.footer.title }} />) : (<h6>&nbsp;</h6>)}
								{item.footer?.subtitle && (<p className='card-text small' dangerouslySetInnerHTML={{ __html: item.footer.subtitle }} />)}
							</div>
							{item.footer?.button && (<div className='col-12 col-md-auto text-center' dangerouslySetInnerHTML={{ __html: item.footer.button }} />)}
						</div>
					)}
				</div>
			</motion.div>
		</motion.div>
	</div>)
};

export default TopicDetails;
