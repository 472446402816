import { urlReplace } from "helpers/url";
import Api from "./Api";

export default class NotificationService {
	constructor(api) {
		this.api = api || new Api();
	}

	async getAll(params) {
		return this.api.get('/secure/notifications', params);
	}

	async getAllMe(params) {
		if (localStorage.getItem('token')) {
			return this.api.get('/secure/notifications/me', params);
		} else {
			return null;
		}
	}

	async getById(id, params) {
		return this.api.get(urlReplace('/secure/notifications/{id}', { id: id }), params);
	}

	//post put delete
}