import React, { useState } from 'react'
import { Outlet, useLoaderData } from 'react-router-dom';
import ItemAwait from 'components/Item/ItemAwait';
import ItemHeader from 'components/Item/ItemHeader';
import ItemContent from 'components/Item/ItemContent';
import ItemFooter from 'components/Item/ItemFooter';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';
import DetailView from 'components/DetailView/DetailView';
import { Trans } from 'react-i18next';

const Task = () => {
	const { task } = useLoaderData();
	const [item, setItem] = useState();

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			setItem(response.data);
		}
	}

	return (
		<ItemAwait resolvePromise={task} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
			<ItemHeader
				avatar={item?.avatar}
				title={item?.fullname}
			/>
			<ItemContent>
				<div className='bg-warning-subtle text-warning rounded p-2 align-items-center justify-content-center d-flex'>
					<i className='mdi mdi-information-variant-circle-outline mdi-24px pe-1' />
					<Trans i18nKey='views:wip_ongoing' />
				</div>
				{item && <DetailView item={item} attributes={[
					'descrizione',
					// {
					// 	label: 'status.label',
					// 	value: () => item?.stauts && <Trans i18nKey={'models:task.status.value.' + item.stauts} />,
					// 	formatter: 'raw'
					// }
				]} options={{
					transPrefix: 'models:task.',
					cssClass: 'col-12 border-bottom p-2'
				}} />}
			</ItemContent>
			<ItemFooter />
		</ItemAwait>
	)
}

export default Task