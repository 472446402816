import ListItem from 'components/List/ListItem';
import React from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

const ResourceProjects = () => {
	const [item] = useOutletContext();
	return (
		<>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='resources.item.contents.projects.works' /></h6>
				</div>
				<div className='col-12'>
					<ul className="list-group">
						{item?.works && item.works.length > 0 ? (item.works?.map((work, idx) => (
							<ListItem key={idx} item={{
								id: work.id,
								avatar: work.avatar,
								title: work.fullname,
								subtitle: work.descrizione,
								url: '/works/' + work.id
							}} showUrlIcon={true} />
						))) : (<div className='text-center text-body-tertiary small'>
							<Trans i18nKey='resources.item.contents.projects.no_work' />
						</div>)}
					</ul>
				</div>
			</div>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='resources.item.contents.projects.tenders' /></h6>
				</div>
				<div className='col-12'>
					<ul className="list-group">
						{item?.tenders && item.tenders.length > 0 ? (item.tenders?.map((tender, idx) => (
							<ListItem key={idx} item={{
								id: tender.id,
								avatar: tender.avatar,
								title: tender.fullname,
								subtitle: tender.descrizione,
								url: '/tenders/' + tender.id
							}} showUrlIcon={true} />
						))) : (<div className='text-center text-body-tertiary small'>
							<Trans i18nKey='resources.item.contents.projects.no_tender' />
						</div>)}
					</ul>
				</div>
			</div>
		</>
	)
}
export default ResourceProjects