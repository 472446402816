import { urlReplace } from "helpers/url";
import Api from "./Api";

export default class AssetService {
	constructor(api) {
		this.api = api || new Api();
	}

	async getAll(params) {
		return this.api.get('/secure/assets', params);
	}

	async getById(id, params) {
		return this.api.get(urlReplace('/secure/asset/{id}', { id: id }), params);
	}

	async create(data, params) {
		return this.api.post('/secure/asset/new', data, params);
	}

	async update(id, data, params) {
		return this.api.put(urlReplace('/secure/asset/{id}', { id: id }), data, params);
	}

}