import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import * as bootstrap from 'bootstrap';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';


export const ModalContent = ({ ...props }) => {
	props.id = props.id || ('m' + Date.now());
	props.className = props.className || 'modal-xl';
	props.style = props.style || {};
	props.height = props.height || 50;

	const ref = useRef();
	const { modalProps } = useModal();

	let modal;

	useEffect(() => {
		modal = bootstrap.Modal.getOrCreateInstance(ref.current);
		if ((modalProps != null && modalProps.initialOpen) || props.initialOpen) {
			modal.show();
			//on back button event
			window.addEventListener('popstate', handleHide, { passive: true });
			window.history.pushState({ modalsOpened: true }, '', window.location.href);
		}

		ref.current.addEventListener('show.bs.modal', (e) => {
			//on back button event
			window.addEventListener('popstate', handleHide, { passive: true });
			window.history.pushState({ modalsOpened: true }, '', window.location.href);
		}, false);

		ref.current.addEventListener('hidden.bs.modal', (e) => {
			props.onCloseHandle && props.onCloseHandle();
		}, false);

		return () => {
			window.removeEventListener('popstate', handleHide);
		}

	}, [props.id])

	const handleHide = (e) => {
		e.preventDefault();
		modal && modal.hide();
		window.history.replaceState({ modalsOpened: false }, null);
	}

	return (
		<div ref={ref} id={props.id} className={'modal ' + props.className} tabIndex="-1">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content" style={{ height: (props.height + 'vh'), ...props.style }}>
					<div className="modal-header">
						<h5 className="modal-title">{props.title}</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body pt-0">
						<OverlayScrollbarsComponent
							options={{
								showNativeOverlaidScrollbars: false,
								overflow: { x: "hidden" },
								scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
							}}
							defer
							style={{ height: '100%' }}
						>
							{props.children}
						</OverlayScrollbarsComponent>
					</div>
				</div>
			</div>
		</div>
	)
}

export const ModalPortal = ({ children, ...props }) => {
	return document.getElementById('portal-content') && createPortal(children, document.getElementById('portal-content'))
}

export const ModalButton = ({ target, ...props }) => {
	return (
		<motion.button whileTap={{ scale: 0.8 }} className={props?.className ? props.className : 'btn btn-sm'} type='button' data-bs-toggle='modal' data-bs-target={'#' + target} aria-controls={target}>
			{props.children}
		</motion.button>
	)
}

const ModalContext = createContext(null);

function useModal(props) {
	return useMemo(() => ({ props }), [props]);
}

export const Modal = ({ initialOpen = false, ...props }) => {
	const modal = useModal({ initialOpen: initialOpen, ...props });
	return (
		<ModalContext.Provider value={modal}>
			{props.children}
		</ModalContext.Provider>
	)
}

export default Modal