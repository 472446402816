import React from 'react';
import { Trans } from 'react-i18next';
import { useMatches } from 'react-router-dom';
import ItemNav from './ItemNav';
import { DropDown, DropDownButton, DropDownContent } from 'components/Bootstrap/DropDown';


const ItemNavDropDown = ({ children, horizontal = true, ...props }) => {
	const matches = useMatches();
	const activeMatche = matches.pop();

	return (
		<DropDown className='dropdown-center' style={{ minHeight: (horizontal ? '52px' : 'initial') }}>
			<DropDownButton className='dropdown-toggle w-100 btn btn-dark d-lg-none'>
				<Trans i18nKey={activeMatche?.handle?.title || 'components:item.nav.dropdown'} />
			</DropDownButton>
			<DropDownContent className='d-lg-block row w-100 border-0 g-0 p-1 bg-body-tertiary'>
				<ItemNav className={'nav-pills nav-fill ' + (horizontal ? ' flex-column flex-lg-row flex-lg-nowrap' : ' flex-column')}>
					{children}
				</ItemNav>
			</DropDownContent>
		</DropDown>
	)
}

export default ItemNavDropDown