
import React from 'react'
import Avatar from 'components/Avatar/Avatar'
import moment from 'moment'
import ListItem from 'components/List/ListItem';
import { Trans, useTranslation } from 'react-i18next';
import { goTo } from 'helpers/url';

const BadgeItem = ({ badge, linkable = true, parentCallback = null }) => {

	const { t } = useTranslation();

	let context = !badge.uscita && moment().isSame(moment(badge.data), 'd') ? 'ongoing' : (!badge.uscita ? 'noend' : null);

	const item = {
		avatar: {
			icon: badge.idSede == '0' ? 'mdi mdi-map-marker-off-outline mdi-18px' : 'mdi mdi-map-marker-outline mdi-18px',
			title: moment(badge.data).format('DD/MM/YYYY'),
			cssClass: badge.idSede == '0' ? 'bg-body-secondary text-body-tertiary' : (context == 'noend' ? 'bg-warning-subtle text-warning' : 'bg-primary-subtle text-primary')
		},
		title: <Trans i18nKey='components:badgeItem.title' values={{
			start: badge.entrata,
			end: badge.uscita,
		}} context={context} />,
		subtitle: badge.idSede == '0' ? <Trans i18nKey='components:badgeItem.smartworking' /> : (badge.location?.nome ? (badge.location.nome + ' - ' + badge?.location?.fulladdress) : badge.location.nome),
		url: linkable && !parentCallback && badge.idSede != '0' ? 'https://www.google.com/maps/place/' + badge.lat + ',' + badge.lng : null
	};

	return (
		<ListItem item={item} disabled={linkable && badge.idSede == '0'} showUrlIcon={linkable && badge.idSede != '0'} parentCallback={parentCallback} />
	)
}

export default BadgeItem