import { Error } from "components/Error/Error";
import Tasks from "pages/tasks/Tasks";
import Task from "pages/tasks/item/Task";
import TaskEmpty from "pages/tasks/item/TaskEmpty";
import { defer } from "react-router-dom";
import TaskService from "services/TaskService";

const service = new TaskService();
const TaskRoutes = [
	{
		path: "/tasks",
		element: <Tasks />,
		loader: async ({ request }) => {
			return defer({ tasks: service.getAllMe(new URL(request.url).search) });
		},
		handle: {
			title: 'tasks.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				path: "",
				element: <TaskEmpty />,
			},
			{
				path: "/tasks/:id",
				element: <Task />,
				loader: ({ params }) => {
					return defer({ task: service.getById(params.id) });
				},
				handle: {
					crumb: null, //Breadcrumbs
				},
				errorElement: <Error />,
			},
		],
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]


export default TaskRoutes