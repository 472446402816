
import React from 'react'
import Item from 'components/Item/Item'
import { NavLink } from 'react-router-dom'

const Settings = () => {
	return (
		<Item>
			<NavLink to='./companies'>
				Gestione Aziende
			</NavLink>
			<NavLink to='./roles'>
				Gestione Ruoli
			</NavLink>
		</Item>
	)
}

export default Settings