import React, { createContext, useEffect, useMemo, useRef } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import * as bootstrap from 'bootstrap';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';


export const OffCanvasContent = ({ ...props }) => {
	props.id = props.id || ('oc' + Date.now());
	props.className = props.className || 'offcanvas-bottom';
	props.style = props.style || {};
	props.height = props.height || 50;
	props.isDraggable = props.isDraggable || true;

	const ref = useRef();
	const { offCanvasProps } = useOffcanvas();

	let offCanvas, offCanvasDraggable, isDragging = false, startY, startHeight;

	useEffect(() => {
		offCanvas = bootstrap.Offcanvas.getOrCreateInstance(ref.current);
		if ((offCanvasProps != null && offCanvasProps.initialOpen) || props.initialOpen) {
			offCanvas.show();
			//on back button event
			window.addEventListener('popstate', handleHide, { passive: true });
			window.history.pushState({ offcanvasOpened: true }, '', window.location.href);
		}

		ref.current.addEventListener('hidden.bs.offcanvas', e => {
			updateHeight(props.height);
			props.onCloseHandle && props.onCloseHandle();
		});

		ref.current.addEventListener('shown.bs.offcanvas', e => {
			//on back button event
			window.addEventListener('popstate', handleHide, { passive: true });
			window.history.pushState({ offcanvasOpened: true }, '', window.location.href);
		});

		if (props.isDraggable) {
			offCanvasDraggable = document.getElementById(props.id + 'Drag');
			offCanvasDraggable.addEventListener("mousedown", dragStart, { passive: true });
			offCanvasDraggable.addEventListener("touchstart", dragStart, { passive: true });

			document.addEventListener("mousemove", dragging, { passive: true });
			document.addEventListener("mouseup", dragStop, { passive: true });
			document.addEventListener("touchmove", dragging, { passive: true });
			document.addEventListener("touchend", dragStop, { passive: true });
		}

		return () => {

			// offCanvas.hide();
			offCanvasDraggable.removeEventListener("mousedown", dragStart);
			offCanvasDraggable.removeEventListener("touchstart", dragStart);

			document.removeEventListener("mousemove", dragging);
			document.removeEventListener("mouseup", dragStop);
			document.removeEventListener("touchmove", dragging);
			document.removeEventListener("touchend", dragStop);

			window.removeEventListener('popstate', handleHide);
		}

	}, [props.id])

	const handleHide = (e) => {
		e.preventDefault();
		offCanvas && offCanvas.hide();
		window.history.replaceState({ offcanvasOpened: false }, null);
	}

	const updateHeight = (height) => {
		ref.current.style.height = height + 'vh';
		ref.current.classList.toggle("rounded-top", height !== 100);
	}

	const dragStart = (e) => {
		isDragging = true;
		startY = e.pageY || e.touches?.[0].pageY;
		startHeight = parseInt(ref.current.style.height);
		ref.current.classList.add("dragging");
	}

	const dragging = (e) => {
		if (!isDragging) return;
		const delta = startY - (e.pageY || e.touches?.[0].pageY);
		const newHeight = startHeight + delta / window.innerHeight * 100;
		updateHeight(newHeight);
	}

	const dragStop = () => {
		isDragging = false;
		ref.current.classList.remove("dragging");
		let sheetHeight = parseInt(ref.current.style.height);

		if (sheetHeight < (props.height - 10)) {
			offCanvas && offCanvas.hide();
		} else {
			updateHeight((sheetHeight > props.height + 10 || sheetHeight >= 100) ? 100 : props.height);
		}
	}

	return (
		<div ref={ref} id={props.id} className={'rounded-top offcanvas ' + props.className} tabIndex='-1' aria-labelledby={props.id + 'Drag'} style={{ height: (props.height + 'vh'), ...props.style }}>
			<div className='offcanvas-header justify-content-center position-relative'>
				{props.isDraggable && (<div className='bg-opacity-10 bg-secondary lh-1 rounded cursor-grab' id={props.id + 'Drag'} style={{ width: '72px', height: '8px' }}>&nbsp;</div>)}
				<button type='button' className='btn-close position-absolute' style={{ right: '16px' }} data-bs-dismiss='offcanvas' aria-label='Close'></button>
			</div>
			<div className='offcanvas-body pt-0 small'>
				<OverlayScrollbarsComponent
					options={{
						showNativeOverlaidScrollbars: false,
						overflow: { x: "hidden" },
						scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
					}}
					defer
					style={{ height: '100%' }}
				>
					{props.children}
				</OverlayScrollbarsComponent>
			</div>
		</div>
	)
}

export const OffCanvasPortal = ({ children, ...props }) => {
	return document.getElementById('portal-content') && createPortal(children, document.getElementById('portal-content'))
}

export const OffCanvasButton = ({ target, ...props }) => {
	return (
		<motion.button whileTap={{ scale: 0.8 }} className='btn btn-sm' type='button' data-bs-toggle='offcanvas' data-bs-target={'#' + target} aria-controls={target}>
			{props.children}
		</motion.button>
	)
}

const OffCanvasContext = createContext(null);

function useOffcanvas(props) {
	return useMemo(() => ({ props }), [props]);
}

export const OffCanvas = ({ initialOpen = false, ...props }) => {
	const offcanvas = useOffcanvas({ initialOpen: initialOpen, ...props });
	return (
		<OffCanvasContext.Provider value={offcanvas}>
			{props.children}
		</OffCanvasContext.Provider>
	)
}

