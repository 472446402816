import React, { forwardRef } from 'react'
import { Trans } from 'react-i18next';

const Loader = forwardRef((props, ref) => {

    return (
        <div className='row g-0 align-items-center h-100 text-center p-2' style={props.style}>
            <div>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden"><Trans i18nKey="components:loader" /></span>
                </div>
                <div><span>{props.title || <Trans i18nKey="components:loader" />}</span></div>
            </div>
        </div>
    )
});

export default Loader