import React from 'react'

const ItemNav = ({ children, ...props }) => {
	props.className = props.className || 'nav-pills nav-fill bg-body-tertiary align-items-center mb-2';
	return (
		<ul className={'nav ' + props.className}>
			{React.Children.map(children, (child) => (
				<li className="nav-item p-1">
					{React.cloneElement(child, { style: { ...child.props.style } })}
				</li>
			))}
		</ul>
	)
}

export default ItemNav