import React, { useEffect, useState } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Trans } from 'react-i18next';
import Loader from 'components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import Avatar from 'components/Avatar/Avatar';
import { Offcanvas } from 'bootstrap';
import DepartmentService from 'services/DepartmentService';

const departmentService = new DepartmentService();
const DepartmentsChart = () => {
	const [departments, setDepartments] = useState([]);

	const navigate = useNavigate();

	useEffect(() => {
		departmentService.getAll('?perPage=1000')
			.then((response) => response.data)
			.then((data) => {

				function buildTree(list, parent = 0) {
					let tree = [];
					list.forEach((item) => {
						if (item.parentId == parent) {
							let children = buildTree(list, item.id);
							if (children != null && children.length > 0) {
								item.children = children;
							}
							tree.push(item);
						}
					})
					return tree;
				}

				setDepartments(buildTree(data.data));
			})

	}, [])

	const getLabel = (item) => {

		const onclick = () => {
			navigate(item.url);
			let off = Offcanvas.getInstance(document.getElementById('departmens-charts'));
			off.hide();
		}

		return (
			<div className='position-relative'>
				<div className="card d-inline-block py-2 px-4">
					<Avatar item={item.avatar} />
					<h6 className='text-capitalize m-0'>{item.title}</h6>
					<div className='text-body-tertiary'>{item.description}</div>
				</div>
				<a className='position-absolute top-0 start-0 bottom-0 end-0 z-1 cursor-pointer' onClick={onclick}></a>
			</div>

		);
	};

	const getTreenode = (list) => {
		return list?.map((item, idx) => {
			return (
				<TreeNode key={idx} label={getLabel(item)}>
					{item.children && getTreenode(item.children)}
				</TreeNode>
			);
		});
	};


	return (
		<div className="offcanvas offcanvas-bottom bg-white w-100 h-100" data-bs-backdrop="false" tabIndex="-1" id="departmens-charts" aria-labelledby="departmens-charts-label">
			<div className="offcanvas-header p-3 text-truncate">
				<h5 className="offcanvas-title" id="departmens-charts-label"><Trans i18nKey="departments.charts.title" /></h5>
				<button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#departmens-charts" aria-label="Close"></button>
			</div>
			<div className="offcanvas-body p-2">
				<OverlayScrollbarsComponent
					options={{
						showNativeOverlaidScrollbars: false,
						overflow: { x: "scroll" },
						scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
					}}
					defer
					style={{ height: '100%' }}
				>
					{departments != null && departments.length > 0 ?
						departments.map((department, idx) => (
							<div key={idx} style={{ width: '100vw' }}>
								<div style={{ transform: 'scale(50%)', transformOrigin: '0 0', width: '200%' }}>
									<Tree
										lineWidth={'2px'}
										lineColor={'var(--bs-primary)'}
										lineBorderRadius={'10px'}
										label={<div className="card d-inline-block py-2 px-4"><h2>{department.info.company.name}</h2></div>}>
										<TreeNode label={getLabel(department)}>
											{department.children && getTreenode(department.children)}
										</TreeNode>
									</Tree>
								</div>
							</div>
						)) : (<Loader />)}
				</OverlayScrollbarsComponent>
			</div>
			<div className='p-2'>
				<div className='bg-secondary bg-opacity-10 text-secondary p-2 rounded'>
					<Trans i18nKey="departments.charts.subtitle" />
				</div>
			</div>


		</div>
	)
}

export default DepartmentsChart