import React from "react";
import Avatar from "../Avatar/Avatar";
import { NavLink } from "react-router-dom";
import { isExternalURL } from "helpers/url";
import { isObject, isString } from "helpers/commons";

const ListItem = ({ item, showUrlIcon = false, ...props }) => {

	const isExternal = item?.url ? isExternalURL(item.url) : null;

	return (
		<div className={[
			"list-group-item bg-opacity-10 position-relative px-2",
			(item.url || props.parentCallback) && 'list-group-item-action',
			(props.disabled && 'disabled'),
			props.className,
		].join(' ')
		} >
			<div className="row g-0 flex-nowrap justify-content-between align-items-center">
				{item.avatar && <div className="col-auto p-0 pe-2">
					<Avatar item={item.avatar} />
				</div>}
				<div className="col lh-1 text-truncate text-start">
					<h6 className="m-0 text-truncate">{item.title}</h6>
					<div className="text-body text-truncate">
						<small>{item.subtitle} </small>
					</div>
					{item.text != null && item.text.html != null ? (
						<div className="text-body-secondary text-truncate" dangerouslySetInnerHTML={{ __html: item.text.html }} />
					) : (
						<div className="text-body-secondary text-truncate"><small>{item.text}</small></div>
					)}
				</div>
				<div className="col-auto">
					{props.children ? (
						<div className="position-relative z-2">{props.children}</div>
					) : showUrlIcon ? (
						<i className="mdi mdi mdi-24px mdi-chevron-right text-body-tertiary" />
					) :
						null}
				</div>
			</div>
			{item.url != null ? (
				isExternal ?
					(<a className='position-absolute top-0 start-0 bottom-0 end-0 cursor-pointer z-1' href={item.url} rel='nofollow' target="_BLANK" ></a>)
					:
					(<NavLink className="position-absolute top-0 start-0 bottom-0 end-0 cursor-pointer z-1" aria-current="page" to={item.url}></NavLink>)
			) : props.parentCallback ? (
				<a className={(item.isActive ? "active " : "") + "position-absolute top-0 start-0 bottom-0 end-0 cursor-pointer z-1"} onClick={() => { props.parentCallback(item.id) }}></a>
			) : null}
		</div>
	);
};

export default ListItem;
