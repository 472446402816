import React, { useState } from 'react'
import ListAwait from 'components/List/ListAwait';
import ListItem from 'components/List/ListItem';
import { Trans } from 'react-i18next'
import NotificationService from 'services/NotificationService'
import Item from 'components/Item/Item';
import { Link } from 'react-router-dom';

const service = new NotificationService();
const notifications = service.getAllMe();
const NotificationsMenu = (props) => {
	const [items, setItems] = useState();
	// const [hasMore, setHasMore] = useState(false);

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (items == null || items.length == 0) {
			setItems(response?.data?.data);
		}
	}

	return (
		<div className='p-2'>
			<div className="row g-0 p-2 align-items-center">
				<div className='col text-start'>
					<h5 className="mb-0">
						<Trans i18nKey='components:notifcationsMenu.title' />
					</h5>
				</div>
				<div className='col-auto  text-end'>
					<h5 className="mb-0">{props.count}</h5>
				</div>
			</div>
			<div className='text-start'>
				<Item>
					<ListAwait resolvePromise={notifications} hasResolved={resolvedPromise} loadMore={null} hasMore={null}>
						{items?.map((item, idx) =>
							<ListItem key={idx} item={{ ...item, url: null }} showUrlIcon={true} />
						)}
					</ListAwait>
					<div className="border-top p-2 text-center">
						<Link className="btn btn-dark text-white w-100" to="/notifications">
							<Trans i18nKey='components:notifcationsMenu.all' />
						</Link>
					</div>
				</Item>
			</div>
		</div>
	)
}

export default NotificationsMenu