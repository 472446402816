import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import MotionComponent from 'components/Motion/MotionComponent';
import Avatar from 'components/Avatar/Avatar';

const ResourceSecure = () => {
	const [item] = useOutletContext();
	const [permissions, setPermissions] = useState([]);

	useEffect(() => {
		if (item?.roles) {
			setPermissions((oldPermissions) => {
				let newPermissions = [];
				let permissionMap = new Map();

				item?.roles.forEach(role => {
					newPermissions.push(role.permissions)
				});
				newPermissions.flat(Infinity).forEach(item => {
					if (!permissionMap.has(item['id'])) {
						permissionMap.set(item['id'], item);
					}
				});

				return oldPermissions.concat(Array.from(permissionMap.values()));
			});
		}
	}, [item])


	return (
		<>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='resources.item.contents.secure.edit_password' /></h6>
				</div>
				<div className='col-12'>
					<div className='bg-warning-subtle rounded p-2 text-warning mb-2 text-center'>
						<Trans i18nKey='views:wip' />
					</div>
				</div>
			</div>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='resources.item.contents.secure.edit_avatar' /></h6>
				</div>
				<div className='col-12'>
					<div className='bg-warning-subtle rounded p-2 text-warning mb-2 text-center'>
						<Trans i18nKey='views:wip' />
					</div>
				</div>
			</div>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='resources.item.contents.secure.roles' /></h6>
				</div>
				<div className='col-12'>
					<div className='row g-0 align-items-center'>
						{item?.roles != null && item.roles.length != 0 ? item.roles.map((role, key) => (
							<div key={key} className='col-12 col-md-6 col-lg-4 p-2'>
								<div className='border p-2 rounded'>
									<div className='row g-0 align-items-center'>
										<div className="col-auto p-0 pe-2">
											<Avatar item={role.avatar} />
										</div>
										<div className="col lh-1 text-truncate text-start">
											<h6 className="m-0 text-truncate">{role.fullname}</h6>
										</div>
									</div>
								</div>
							</div>
						)) : (<div className='text-center text-body-tertiary small'>
							<Trans i18nKey='resources.item.contents.secure.no_roles' />
						</div>)}
						<div className='col-12 col-md-6 col-lg-4 p-2'>
							<MotionComponent as='button' whileTap={{ scale: 0.8 }} className='btn btn-primary-subtle text-primary w-100' disabled>
								<i className='mdi mdi-plus mdi-24px' />
								<Trans i18nKey='resources.item.contents.secure.add_role' />
							</MotionComponent>
						</div>
					</div>
				</div>
			</div>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='resources.item.contents.secure.permissions' /></h6>
				</div>
				<div className='col-12'>
					<div className='row g-0 align-items-center'>
						{permissions != null && permissions.length != 0 ? permissions.map((permission, key) => (
							<div key={key} className='col-12 col-md-6 col-lg-4'>
								<div className='row g-0 align-items-center p-2'>
									<div className="col-auto p-0 pe-2">
										<i className='mdi mdi-checkbox-marked-circle-outline mdi-18px text-success' />
									</div>
									<div className="col lh-1 text-truncate text-start">
										<div><span className='fw-semibold'>{permission.nome}</span></div>
										<div><small className='text-body-secondary'>{permission.descrizione}</small></div>
									</div>
								</div>
							</div>
						)) : (<div className='text-center text-body-tertiary small'>
							<Trans i18nKey='resources.item.contents.secure.no_permissions' />
						</div>)}
					</div>
				</div>
			</div>
			{item?.status == '1' ? (
				<div className='row g-0 align-items-center text-center bg-danger text-danger bg-opacity-10 mt-3 rounded p-2'>
					<div className='col-12'>
						<h6 className='m-0'><Trans i18nKey='resources.item.contents.secure.disable.title' /></h6>
						<div className='text-body-ternary'><Trans i18nKey='resources.item.contents.secure.disable.subtitle' /></div>
					</div>
					<div className='col-12 pt-3'>
						<MotionComponent as='button' whileTap={{ scale: 0.8 }} className='btn btn-danger text-white' disabled>
							<i className='mdi mdi-account-off-outline mdi-18px' />
							<Trans i18nKey='resources.item.contents.secure.disable.button' />
						</MotionComponent>
					</div>
				</div>
			) : (
				<div className='row g-0 align-items-center text-center bg-danger text-danger bg-opacity-10 mt-3 rounded p-2'>
					<div className='col-12'>
						<h6 className='m-0'><Trans i18nKey='resources.item.contents.secure.disable.title' /></h6>
						<div className='text-body-ternary'><Trans i18nKey='resources.item.contents.secure.disable.subtitle' /></div>
					</div>
					<div className='col-12 pt-3'>
						<MotionComponent as='button' whileTap={{ scale: 0.8 }} className='btn btn-danger text-white' disabled>
							<i className='mdi mdi-account-off-outline mdi-18px' />
							<Trans i18nKey='resources.item.contents.secure.disable.button' />
						</MotionComponent>
					</div>
				</div>
			)}
		</>
	)
}

export default ResourceSecure