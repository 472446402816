import { Resizable } from 're-resizable';
import React, { useState, createContext, useLayoutEffect } from 'react'
import { useMatches } from 'react-router-dom';

export const SplitScreenContext = createContext();

export const SplitScreen = ({ ...props }) => {
    const [left, right] = props.children;
    const [layout, setLayout] = useState({
        leftStyle: {
            maxWidth: props.maxWidth || "90%",
            minWidth: props.minWidth || "10%",
            width: localStorage.getItem(props.name) || "16%",
        },
        rightStyle: {
            maxWidth: "100%",
            minWidth: "10%",
            width: 'calc(100% - ' + (localStorage.getItem(props.name) || "16%") + ')',
        }
    });

    let matches = useMatches();
    let hasChild = matches != null && matches.length > 0 ? Boolean(matches[0].params?.id || matches.find(m => m.pathname.includes('new'))) : false;

    props.name = props.name || Date.now();
    props.hasResize = props.hasResize || true;
    props.showLeft = props.showLeft || !hasChild;
    props.showRight = props.showRight || hasChild;

    const screenResize = () => {
        if (window.innerWidth <= 1200) {
            setLayout({
                leftStyle: {
                    maxWidth: null,
                    minWidth: null,
                    width: "100%",
                },
                rightStyle: {
                    maxWidth: "100%",
                    minWidth: "10%",
                    width: "100%",
                }
            })
        } else {
            setLayout({
                leftStyle: {
                    maxWidth: props.maxWidth || "90%",
                    minWidth: props.minWidth || "10%",
                    width: localStorage.getItem(props.name) || "16%",
                },
                rightStyle: {
                    maxWidth: "100%",
                    minWidth: "10%",
                    width: 'calc(100% - ' + (localStorage.getItem(props.name) || "16%") + ')',
                }
            });
        }
    };

    useLayoutEffect(() => {
        screenResize();
        window.addEventListener("resize", screenResize);

        return () => {
            window.removeEventListener("resize", screenResize);
        }
    }, []);

    //storage the last resize width
    const handleResize = (event, direction, ref, delta) => {
        localStorage.setItem(props.name, ref.style.width);
        setLayout({
            leftStyle: {
                maxWidth: props.maxWidth || "90%",
                minWidth: props.minWidth || "10%",
                width: ref.style.width,
            },
            rightStyle: {
                maxWidth: "100%",
                minWidth: "10%",
                width: 'calc(100% - ' + (ref.style.width != '0' && ref.style.width != '100%' ? ref.style.width : '0') + ')',
            }
        });
    };

    return (
        <div className="w-100 d-flex flex-row g-0 align-items-top overflow-hidden flex-fill p-2" style={{ height: 'calc(100vh - 120px)' }}>
            <Resizable className={(!props.showLeft ? "d-none d-xl-flex" : "d-flex") + " flex-column "}
                defaultSize={{
                    width: layout.leftStyle.width,
                    height: "100%",
                }}
                size={{
                    width: layout.leftStyle.width,
                }}
                maxWidth={layout.leftStyle.maxWidth}
                minWidth={layout.leftStyle.minWidth}
                enable={props.hasResize ? {
                    top: false,
                    right: true,
                    bottom: false,
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                } : false}
                handleClasses={{ right: layout.leftStyle.minWidth ? "resizable-bar" : "d-none" }}
                handleComponent={{ right: <div></div> }}
                onResizeStop={handleResize}
            >
                <LeftScreen leftClass={props.leftClass}>{left}</LeftScreen>
            </Resizable>
            <RightScreen showRight={props.showRight} rightClass={props.rightClass} style={layout.rightStyle}>{right}</RightScreen>
        </div>
    )
}

export const LeftScreen = (props) => {
    return (
        <div className={props.leftClass != null ? props.leftClass : ''}>{props.children}</div>
    )
}

export const RightScreen = (props) => {
    return (
        <div className={(!props.showRight ? "d-none d-xl-flex" : "d-flex") + " flex-column ps-xl-4 h-100" + (props.rightClass != null ? ' ' + props.rightClass : '')} style={props.style}>{props.children}</div>
    )
}