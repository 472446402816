import React, { useEffect, useState } from "react";
import TopicPlaceHolderItem from "./TopicPlaceHolderItem";

const TopicPlaceHolderList = ({ sizes }) => {
	const [children, setChildren] = useState([]);

	useEffect(() => {
		function calculateChildren() {
			if (sizes != null) {
				Object.entries(sizes).forEach((size) => {
					if (size[0] <= window.innerWidth) {
						setChildren([...Array(size[1].slidesPerView).keys()]);
					}
				});
			}
		}
		calculateChildren();

		window.addEventListener('resize', calculateChildren)
	}, []);

	return (
		<div className="row flex-nowrap g-0 align-items-center" style={{ minHeight: '150px' }}>
			{children?.map((child, index) => (
				<div className={"col p-1 animate__animated animate__fadeInRight " + 'animate__delay-' + (200 * index) + "ms"} key={index} style={{ width: (100 / children.length) + '%' }}>
					<TopicPlaceHolderItem item={child} />
				</div>
			))}
		</div>
	);
};

export default TopicPlaceHolderList;
