import Avatar from 'components/Avatar/Avatar';
import { DropDown, DropDownButton, DropDownContent } from 'components/Bootstrap/DropDown';
import Item from 'components/Item/Item'
import ItemContent from 'components/Item/ItemContent';
import ItemNav from 'components/Item/ItemNav';
import ItemNavDropDown from 'components/Item/ItemNavDropDown';
import { useAuth } from 'hooks/useAuth'
import React from 'react'
import { Trans } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';

const Profile = () => {
	const { user } = useAuth();

	return (
		<Item>
			<ItemContent height={{ height: '100%' }}>
				<div className='row g-0 h-100'>
					<div className='col-12 col-lg-4 text-center pe-lg-2'>
						<div className='sticky-lg-top py-2 p-lg-2'>
							<div>
								<Avatar item={user.avatar} className='avatar-xl' />
							</div>
							<div className='my-2'>
								<h6 className='m-0 text-body-emphasis'>{user?.fullname}</h6>
								<p className='m-0 small text-body-tertiary'>{user?.active_contract?.profile?.descrizioneProfilo}</p>
							</div>
							<div className='my-2'>
								<ItemNavDropDown horizontal={false}>
									<NavLink className="nav-link d-flex justify-content-between" aria-current="page" to="." end><span><Trans i18nKey="profile.item.nav.info" /></span><i className='mdi mdi-chevron-right' /></NavLink>
									<NavLink className="nav-link d-flex justify-content-between" aria-current="page" to={"./documents"} end><span><Trans i18nKey="profile.item.nav.documents" /></span><i className='mdi mdi-chevron-right' /></NavLink>
									<NavLink className="nav-link d-flex justify-content-between" aria-current="page" to={"./badges"} end><span><Trans i18nKey="profile.item.nav.badges" /></span><i className='mdi mdi-chevron-right' /></NavLink>
									<NavLink className="nav-link d-flex justify-content-between" aria-current="page" to={"./projects"} end><span><Trans i18nKey="profile.item.nav.projects" /></span><i className='mdi mdi-chevron-right' /></NavLink>
									<NavLink className="nav-link d-flex justify-content-between" aria-current="page" to={"./assets"} end><span><Trans i18nKey="profile.item.nav.assets" /></span><i className='mdi mdi-chevron-right' /></NavLink>
									<NavLink className="nav-link d-flex justify-content-between" aria-current="page" to={"./skills"} end><span><Trans i18nKey="profile.item.nav.skills" /></span><i className='mdi mdi-chevron-right' /></NavLink>
									<NavLink className="nav-link d-flex justify-content-between" aria-current="page" to={"./secure"} end><span><Trans i18nKey="profile.item.nav.secure" /></span><i className='mdi mdi-chevron-right' /></NavLink>
								</ItemNavDropDown>
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-8'>
						<Outlet context={[user]} />
					</div>
				</div>
			</ItemContent>
		</Item>
	)
}

export default Profile