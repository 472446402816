import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next';
import { Outlet, useLoaderData } from 'react-router-dom';
import Item from 'components/Item/Item';
import ListAwait from 'components/List/ListAwait';
import ListItem from 'components/List/ListItem';
import Search from 'components/Search/Search';
import { SplitScreen } from 'components/SplitScreen/SplitScreen';

const Customers = () => {
	let { customers } = useLoaderData();

	const [items, setItems] = useState();
	const [hasMore, setHasMore] = useState(false);

	const { t } = useTranslation();

	const handleLoad = (response) => {
		setItems(oldItems => [...oldItems, ...response.data]);
		setHasMore(response.next_page_url);
	};

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (items == null || items.length == 0) {
			setItems(response.data.data);
			setHasMore(response.data.next_page_url);
		}
	}

	return (
		<SplitScreen name="customers-list" leftClass="flex-column h-100 w-100">
			<Item>
				<div className='row g-2 align-items-center'>
					<div className='col-12'>
						<Search action="/customers" />
					</div>
				</div>
				<ListAwait resolvePromise={customers} hasResolved={resolvedPromise} loadMore={handleLoad} hasMore={hasMore}>
					{items?.map((item, idx) =>
						<ListItem key={idx} item={{
							...item,
							subtitle: item?.subtitle ? <Trans i18nKey={'models:customer.entita.value.' + item.subtitle} /> : null
						}} />
					)}
				</ListAwait>
			</Item>
			<Outlet />
		</SplitScreen>
	)
}

export default Customers