import React from 'react'
import Loader from '../Loader/Loader'

const ItemPlaceHolder = (props) => {
	return (
		<>
			<nav className="bg-white">
				<div className="row p-2 g-0 flex-nowrap justify-content-between align-items-center placeholder-glow">
					<div className="col-auto">
						&nbsp;
					</div>
					<div className="col-auto p-0 pe-2 placeholder-glow">
						<span className="p-4 placeholder bg-secondary bg-opacity-75 rounded-circle"></span>
					</div>
					<div className="col lh-1 placeholder-glow lh-1">
						<div>
							<h6 className="placeholder placeholder-sm bg-secondary bg-opacity-75 rounded-1 w-75"></h6>
						</div>
						<div>
							<small className="placeholder placeholder-xs bg-secondary bg-opacity-75 rounded-1 w-50"></small>
						</div>
					</div>
				</div>
			</nav>
			{props.children != null ? props.children : <Loader />}
		</>
	)
}

export default ItemPlaceHolder