import React, { useState } from 'react';
import { NavLink, Outlet, useLoaderData } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ItemAwait from 'components/Item/ItemAwait';
import ItemHeader from 'components/Item/ItemHeader';
import ItemContent from 'components/Item/ItemContent';
import ItemFooter from 'components/Item/ItemFooter';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';
import ItemNavDropDown from 'components/Item/ItemNavDropDown';
import Tooltip from 'components/Tooltip/Tooltip';

const Resource = () => {
	const { resource } = useLoaderData();
	const [item, setItem] = useState();

	const { t } = useTranslation();

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			setItem(response.data);
		}
	}

	return (
		<ItemAwait resolvePromise={resource} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
			<ItemHeader
				avatar={item?.avatar}
				title={item?.fullname}
				subtitle={item?.active_contract?.profile?.descrizioneProfilo + ' - ' + item?.active_contract?.societa}
			>
				<Tooltip title={t('views:roles.item.header.edit')} >
					<NavLink to='edit' className='btn btn-outline-primary btn-sm disabled'>
						<i className='mdi mdi-pencil' />
						<Trans i18nKey='views:roles.item.header.edit' />
					</NavLink>
				</Tooltip>
			</ItemHeader>
			<ItemNavDropDown>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between text-nowrap" aria-current="page" to="." end>
					<span><Trans i18nKey="resources.item.nav.info" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between text-nowrap" aria-current="page" to={"./contracts"} end>
					<span><Trans i18nKey="resources.item.nav.contracts" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between text-nowrap" aria-current="page" to={"./documents"} end>
					<span><Trans i18nKey="resources.item.nav.documents" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between text-nowrap" aria-current="page" to={"./projects"} end>
					<span><Trans i18nKey="resources.item.nav.projects" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between text-nowrap" aria-current="page" to={"./assets"} end>
					<span><Trans i18nKey="resources.item.nav.assets" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between text-nowrap" aria-current="page" to={"./evaluations"} end>
					<span><Trans i18nKey="resources.item.nav.evaluations" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between text-nowrap" aria-current="page" to={"./badges"} end>
					<span><Trans i18nKey="resources.item.nav.badges" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between text-nowrap" aria-current="page" to={"./skills"} end>
					<span><Trans i18nKey="resources.item.nav.skills" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
				<NavLink className="nav-link d-flex d-lg-block justify-content-between text-nowrap" aria-current="page" to={"./secure"} end>
					<span><Trans i18nKey="resources.item.nav.secure" /></span><i className='mdi mdi-chevron-right d-lg-none' />
				</NavLink>
			</ItemNavDropDown>
			<ItemContent>
				<Outlet context={[item]} />
			</ItemContent>
			<ItemFooter />
		</ItemAwait>
	)
}

export default Resource