
import {
	BADGE_START,
	BADGE_STOP,
	BADGE_END,
} from '../../constants/action.constant';
import BadgeService from 'services/BadgeService';
import Api from 'services/Api';

const service = new BadgeService(new Api());
const start = (badge) => { return { type: BADGE_START, badge } };
const stop = (badge) => { return { type: BADGE_STOP, badge } };
const end = (badge) => { return { type: BADGE_END, badge } };

export const badgeStart = async (data) => {
	return async (dispatch) => {
		try {
			const badge = await service.create(badge).then(response => response.data);

			if (badge && badge.access_token) {
				localStorage.setItem('badge', JSON.stringify(badge));
				dispatch(start(badge));
			}

		} catch (e) {
			console.error(`Error code: ${e.code}\nError details: ${e.message}`);
		}
	}
};

export const badgeStop = async (data) => {
	return async (dispatch) => {
		try {
			const badge = await service.create(badge).then(response => response.data);

			if (badge && badge.access_token) {
				localStorage.setItem('badge', JSON.stringify(badge));
				dispatch(stop(badge));
			}

		} catch (e) {
			console.error(`Error code: ${e.code}\nError details: ${e.message}`);
		}
	}
};

export const badgeEnd = async () => {
	return async (dispatch) => {
		try {
			const badge = await service.create(badge).then(response => response.data);

			if (badge && badge.access_token) {
				localStorage.setItem('badge', JSON.stringify(badge));
				dispatch(end(badge));
			}
		} catch (e) {
			console.error(`Error code: ${e.code}\nError details: ${e.message}`);
		}
	}
};