import React, { createContext, useContext, useMemo } from 'react'
import { Error } from 'components/Error/Error';
import { useAuth } from 'hooks/useAuth';

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children, permissions }) => {
	const { user, checkPermissions } = useAuth();

	const value = useMemo(() => ({ checkPermissions }), [user]);

	if (user != null && !checkPermissions(permissions)) {
		// user is not authenticated
		return <Error error={{ status: 403 }} />;
	}

	return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>
}

export const usePermissions = () => {
	return useContext(PermissionsContext);
};