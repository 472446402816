
import Dashboard from "pages/dashboard/Dashboard";

const DashboardRoutes = [
    {
        path: "/",
        element: <Dashboard />,
        handle: {
            title: 'dashboard.title',
            crumb: null, //Breadcrumbs
        },
        future: {
            v7_normalizeFormMethod: true,
        },
    },
]

export default DashboardRoutes