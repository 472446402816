import MeetingsCalendars from "pages/calendars/MeetingsCalendars"
import MeCalendar from "pages/calendars/MeCalendar"

const CalendarRoutes = [
	{
		path: "/calendars/me/:date?",
		handle: {
			title: 'calendars.me.title',
			crumb: null, //Breadcrumbs
		},
		element: <MeCalendar />,
		future: {
			v7_normalizeFormMethod: true,
		},
	},
	{
		path: "/calendars/meetings/:date?",
		handle: {
			title: 'calendars.meetings.title',
			crumb: null, //Breadcrumbs
		},
		element: <MeetingsCalendars />,
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]

export default CalendarRoutes