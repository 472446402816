import React, { useState } from 'react'
import { Outlet, useLoaderData } from 'react-router-dom';
import ItemAwait from 'components/Item/ItemAwait';
import ItemHeader from 'components/Item/ItemHeader';
import ItemContent from 'components/Item/ItemContent';
import ItemFooter from 'components/Item/ItemFooter';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';

const Protocol = () => {
	const { protocol } = useLoaderData();
	const [item, setItem] = useState();

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			setItem(response.data);
		}
	}

	return (
		<ItemAwait resolvePromise={protocol} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
			<ItemHeader
				avatar={item?.avatar}
				title={item?.fullname}
			/>
			<ItemContent>
				{/* <Outlet context={[item]} /> */}
			</ItemContent>
			<ItemFooter />
		</ItemAwait>
	)
}
export default Protocol