
import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';

const MotionComponent = forwardRef(({ as, children, ...props }, ref) => {
	const ChildrenComponent = motion(as, {
		// forwardMotionProps: true,
	});

	return <ChildrenComponent {...props}>{children}</ChildrenComponent>;
});

export default MotionComponent