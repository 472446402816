import { urlReplace } from "helpers/url";
import Api from "./Api";

export default class CompanyService {
	constructor(api) {
		this.api = api || new Api();
	}

	async getAll(params) {
		return this.api.get('/secure/companies', params);
	}

	async getAllLocations(params) {
		return this.api.get('/secure/companies/locations', params);
	}


	async getById(id, params) {
		return this.api.get(urlReplace('/secure/company/{id}', { id: id }), params);
	}

}