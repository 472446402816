import Api from "./Api";

/**
 * Tutte le richieste dell'utente in sessione
 * anche se nelle api poi si richiama risources/me
 */
export default class UserService {
	constructor(api) {
		this.api = api || new Api();
	}

	async getPlannings(start, end, params) {
		return this.api.get('/secure/me/plannings', { start, end, ...params });
	}

	async getBadges(start, end, params) {
		return this.api.get('/secure/me/badges', { start, end, ...params });
	}

	async getTimesheets(start, end, params) {
		return this.api.get('/secure/me/timesheets', { start, end, ...params });
	}

	async getNotifications(params) {
		return this.api.get('/secure/me/notifications', params);
	}

	async getWorks(params) {
		return this.api.get('/secure/me/works', params);
	}

	async getTenders(params) {
		return this.api.get('/secure/me/tenders', params);
	}

	async getAssets(params) {
		return this.api.get('/secure/me/assets', params);
	}

	async getDocuments(params) {
		return this.api.get('/secure/me/documents', params);
	}

	async getContracts(params) {
		return this.api.get('/secure/me/contracts', params);
	}

	async setBadge(data, params) {
		return this.api.post('/secure/me/badges/new', data, params);
	}
}