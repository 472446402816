import axios from "axios";
import { logout } from "store/actions/auth.action";

export default class Api {
	static url = process.env.REACT_APP_API_URL;

	constructor() {
		this.request = axios.create({
			baseURL: Api.url, headers: {
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With, X-Requested-Type',
				'Access-Control-Allow-Credentials': 'true',
				'X-Requested-With': 'XMLHttpRequest',
				'X-Requested-Type': 'DTO',
				'credentials': 'same-origin',
				'Accept': 'application/json',
				// 'Content-Type': 'application/json',
				// 'Content-Type': 'text/plain',
				'Content-Type': 'application/x-www-form-urlencoded',
			}
		});

		this.request.interceptors.response.use((response) => response, (error) => {
			if (error?.response?.status === 401) { //se le richieste mostrano errore 401 faccio il logout
				return logout();
			}

			// if (error.response.status === 403) {
			// 	//no permissions
			// }

			//aggiungere altri controlli in caso

			return Promise.reject(error);
		});

		if (localStorage.getItem('token')) {
			const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
			if (token && token.access_token) {
				this.setAuth(token.access_token);
			}
		}
	}

	setAuth(token) {
		if (token && token.length > 0) {
			this.request.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		}

	}

	async get(url, params = null, config = null) {
		return await this.request.get(this.createUrlRequest(url, params), config);
	}


	async post(url, body = '', params = null, config = null) {
		return await this.request.postForm(this.createUrlRequest(url, params), body, config);
	}

	async postJSON(url, body = '', params = null, config = null) {
		return await this.request.post(this.createUrlRequest(url, params), body, config);
	}

	async put(url, body = '', params = null, config = null) {
		return await this.request.putForm(this.createUrlRequest(url, params), body, config);
	}

	async putJSON(url, body = '', params = null, config = null) {
		return await this.request.put(this.createUrlRequest(url, params), body, config);
	}

	async patch(url, body = '', params = null, config = null) {
		return await this.request.patchForm(this.createUrlRequest(url, params), body, config);
	}

	async patchJSON(url, body = '', params = null, config = null) {
		return await this.request.patch(this.createUrlRequest(url, params), body, config);
	}


	async delete(url, params = null, config = null) {
		return await this.request.delete(this.createUrlRequest(url, params), config);
	}

	async options(url, params = null, config = null) {
		return await this.request.options(this.createUrlRequest(url, params), config);
	}

	async head(url, params = null, config = null) {
		return await this.request.head(this.createUrlRequest(url, params), config);
	}


	createUrlRequest(url, params = null) {
		if (url == null) {
			return null;
		}

		url = new URL(url.includes(Api.url) ? url : Api.url + url);

		if (params != null) {
			if (params instanceof Array || typeof params === 'object') {
				url.search = (new URLSearchParams(params)).toString();
			} else if (typeof params === 'string' || params instanceof String) {
				url.search = params;
			} else {
				url.search = params.toString();
			}
		}
		return url;
	}

}

