
/**
 * Check if is a String
 * @param {*} value 
 * @returns {boolean}
 */
export const isString = (value) => {
	return typeof value === 'string' || value instanceof String;
}

/**
 * Check if is an Object
 * @param {*} value 
 * @returns {boolean}
 */
export const isObject = (value) => {
	return typeof value === 'object' && !Array.isArray(value) && value !== null
}

/**
 * Check if is an Array
 * @param {*} value 
 * @returns {boolean}
 */
export const isArray = (value) => {
	return Array.isArray(value);
}