/**
 * Truncate string passed
 * @param {string} value 
 * @param {int} maxChar 
 * @returns {string}
 */
export const truncate = (value, maxChar = 200) => {
	return /<\/?[a-z][\s\S]*>/i.test(value) ? value : ((value.length > maxChar) ? (value.slice(0, maxChar - 1) + '&hellip;') : value);
};

export const initials = (value) => {
	let init = value ? value.split(" ").map((n) => n[0]) : [];
	return init.length == 1 ? init[0] : init[0] + init[init.length - 1];
}