import React from 'react'
import Item from './Item';
import { Suspense } from 'react';
import { Await, useAsyncError, useAsyncValue } from 'react-router-dom';
import { Error } from '../Error/Error';

const ItemAwait = (props) => {

    const HasResolved = () => {
        const data = useAsyncValue();
        setTimeout(() => {
            //inserisco un delay di 250ms per evitare di sovrapporre troppi aggiornamenti dei componenti nello stesso momento
            props.hasResolved && props.hasResolved(data);
        }, 250)

        return true;
    }

    return (
        <Item {...props}>
            <Suspense fallback={props.loading}>
                <Await resolve={props.resolvePromise} errorElement={<Error error={useAsyncError()} />}>
                    <HasResolved />
                    {props.children}
                </Await>
            </Suspense>
        </Item>

    )
}

export default ItemAwait