import React, { useEffect, useState } from 'react';
import { DndContext, DragOverlay, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, verticalListSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import SortableItem from "./SortableItem";
import SortableOverlay from "./SortableOverlay";
import Loader from 'components/Loader/Loader';
import { Offcanvas } from 'bootstrap';
import { Trans } from 'react-i18next';
import MotionComponent from 'components/Motion/MotionComponent';
import Tooltip from 'components/Tooltip/Tooltip';

const TopicSettings = ({ items, handleCallBack }) => {
	const [isDragging, setIsDragging] = useState(null);
	const [sorted, setSorted, sortedRef] = useState([]);
	const [loading, setLoading] = useState(false);
	let newSort = [];
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	useEffect(() => {
		// setSorted(items);
		let sortedItems = [];
		items.forEach(item => {
			sortedItems[item.id] = item;
		});

		sortedItems.sort((a, b) => {
			return a.displayOrder > b.displayOrder ? 1 : (a.displayOrder < b.displayOrder ? -1 : 0);
		});

		setSorted(sortedItems);
	}, [items]);

	const handleSave = () => {
		let el = document.getElementById('topic_settings');
		setLoading(true);

		//chiudo l'offcanvas
		if (el != null) {
			let offcanvas = Offcanvas.getOrCreateInstance('#topic_settings'); //new bootstrap.Offcanvas(el);
			offcanvas.hide();
			setLoading(false);
		}

		//aggiorno la lista attuale
		handleCallBack(sorted);

	}

	function handleDragStart(event) {
		const { active, over } = event;
		let newItem = sorted.find(obj => { return obj != null && obj.id == active.id });
		setIsDragging(newItem);
	}

	function handleDragEnd(event) {
		const { active, over } = event;
		if (active != null && over != null && active.id !== over.id) {

			let oldItem = sorted.findIndex(obj => { return obj != null && obj.id == active.id });
			let newItem = sorted.findIndex(obj => { return obj != null && obj.id == over.id });

			newSort = arrayMove(sorted, oldItem, newItem);

			newSort.forEach((item, idx) => {
				item.displayOrder = idx;
			});

			setSorted(newSort);
		}
		setIsDragging(null);
	}

	return (
		<>
			<div>
				<Tooltip title={<Trans i18nKey='views:dashboard.topics.settings.title' />} >
					<MotionComponent
						as='button'
						whileTap={{ scale: 0.8 }}
						className="btn btn-icon"
						type="button" data-bs-toggle="offcanvas" data-bs-target="#topic_settings" aria-controls="topic_settings">
						<i className="mdi mdi-view-dashboard-edit-outline mdi-24px"></i>
					</MotionComponent>
				</Tooltip>
			</div>

			<div className="offcanvas offcanvas-end text-body" tabIndex="-1" id="topic_settings" aria-labelledby="topic_settings_label">
				<div className="offcanvas-header lh-1 text-start align-items-center pb-0">
					<div className="col">
						<h5 className="offcanvas-title" id="topic_settings_label"><Trans i18nKey='views:dashboard.topics.settings.title' /></h5>
						<div className="text-muted"><Trans i18nKey='views:dashboard.topics.settings.subtitle' /></div>
					</div>
					<div className="col-auto">
						<button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
					</div>
				</div>
				<div className="offcanvas-body text-start">
					{loading ? (<Loader />) : (
						<>
							<div className='bg-secondary bg-opacity-10 text-secondary rounded p-2 mt-2'>
								<Trans i18nKey='views:dashboard.topics.settings.hint' />
							</div>
							<DndContext sensors={sensors}
								collisionDetection={closestCenter}
								modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
								onDragStart={handleDragStart}
								onDragEnd={handleDragEnd}>
								<SortableContext
									id={'topics_settings_list'}
									items={sorted}
									strategy={verticalListSortingStrategy}>
									{sorted?.map((item) => (
										<SortableItem key={item.id} id={item.id} item={item} handle={true} />
									))}
									<DragOverlay>{isDragging != null ? (<SortableOverlay key="drdovrl" item={isDragging} />) : null}</DragOverlay>
								</SortableContext>
							</DndContext>
						</>
					)}
				</div>
				<div className="offcanvas-footer px-2">
					<div className="p-2 text-center">
						<button className="bg-success btn text-white w-100" data-bs-dismiss="offcanvas" onClick={handleSave}>
							<Trans i18nKey='views:dashboard.topics.settings.save' />
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default TopicSettings;