import { SplitScreen } from 'components/SplitScreen/SplitScreen';
import Item from 'components/Item/Item';
import Search from 'components/Search/Search';
import { Filters } from 'components/Filters/Filters';
import ListAwait from 'components/List/ListAwait';
import ListItem from 'components/List/ListItem';
import React, { useState } from 'react'
import { Outlet, useLoaderData } from 'react-router-dom'
import DepartmentsChart from './organization-chart/DepartmentsChart';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';


const Departments = () => {
	let { departments } = useLoaderData();

	const [items, setItems] = useState();
	const [hasMore, setHasMore] = useState(false);

	const { checkPermissions } = useAuth();
	const VIEW_EVALUATIONS = checkPermissions(['VIEW_EVALUATIONS']); //PERMISSION

	const { t } = useTranslation();

	const handleLoad = (response) => {
		setItems(oldItems => [...oldItems, ...response.data]);
		setHasMore(response.next_page_url);
	};

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		// if (items == null || items.length == 0) {
		if (response != null && response.data != null && response.data.length != 0) {
			setItems(response.data.data);
			setHasMore(response.data.next_page_url);
		}
	}

	return (
		<>
			<SplitScreen name="departments-list" leftClass="flex-column h-100 w-100">
				<Item>
					<div className='row g-2 align-items-center mb-2'>
						<div className='col'>
							{/* <Filters /> */}
							<Search action="/departments" />
						</div>
						<div className='col-auto m-0'>
							<button
								className="btn border shadow-sm"
								type="button" data-bs-toggle="offcanvas"
								data-bs-target="#departmens-charts" aria-controls="departments-chart"
								title={t("departments.charts.title")}>
								<i className="mdi mdi-sitemap-outline"></i>
							</button>
						</div>
					</div>

					<ListAwait resolvePromise={departments} hasResolved={resolvedPromise} loadMore={handleLoad} hasMore={hasMore}>
						{items?.map((item, idx) => (
							<ListItem key={idx} item={{ ...item, text: !VIEW_EVALUATIONS ? null : item.text }} />
						))}
					</ListAwait>

				</Item>
				<Outlet />
			</SplitScreen>
			<DepartmentsChart />
		</>
	)
}

export default Departments