import { Error } from "components/Error/Error";
import Tenders from "pages/projects/tenders/Tenders";
import Tender from "pages/projects/tenders/item/Tender";
import TenderEmpty from "pages/projects/tenders/item/TenderEmpty";
import TenderInfo from "pages/projects/tenders/item/info/TenderInfo";
import { defer } from "react-router-dom";
import TenderService from "services/TenderService";

const service = new TenderService();
const TenderRoutes = [
	{
		path: "/tenders",
		element: <Tenders />,
		loader: async ({ request }) => {
			return defer({ tenders: service.getAll(new URL(request.url).search) });
		},
		handle: {
			title: 'tenders.title',
			crumb: null, //Breadcrumbs
		},
		children: [
			{
				index: true,
				path: "",
				element: <TenderEmpty />,
			},
			{
				path: "/tenders/:id",
				element: <Tender />,
				loader: ({ params }) => {
					return defer({ tender: service.getById(params.id) });
				},
				handle: {
					crumb: null, //Breadcrumbs
				},
				children: [
					{
						index: true,
						element: <TenderInfo />,
					},
				],
				errorElement: <Error />,
			},
		],
		future: {
			v7_normalizeFormMethod: true,
		},
	},
]


export default TenderRoutes