import React from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import DetailView from 'components/DetailView/DetailView';
import ListItem from 'components/List/ListItem';

const ProjectInfo = () => {
	const [item] = useOutletContext();

	const attributes = [
		'azienda',
		'data:date',
		{
			label: 'note',
			value: 'note',
			cssClass: 'col-12 border-bottom p-2'
		}
	];

	return (
		<div className='row g-0 align-items-center'>
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='projects.item.contents.info.title' /></h6>
			</div>
			{item && <DetailView item={item} attributes={attributes} options={{
				transPrefix: 'models:project.',
				cssClass: 'col-12 col-md-6 border-bottom p-2'
			}} />}

			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='projects.item.contents.info.works' /></h6>
			</div>
			<div className='col-12'>
				<ul className="list-group">
					{item?.works && item.works.length > 0 ? (item.works?.map((work, idx) => (
						<ListItem key={idx} item={{
							id: work.id,
							avatar: work.avatar,
							title: work.fullname,
							subtitle: work.descrizione,
							url: '/works/' + work.id
						}} showUrlIcon={true} />
					))) : (<div className='text-center text-body-tertiary small'>
						<Trans i18nKey='projects.item.contents.info.no_work' />
					</div>)}
				</ul>
			</div>
			<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
				<h6 className='m-0'><Trans i18nKey='projects.item.contents.info.tenders' /></h6>
			</div>
			<div className='col-12'>
				<ul className="list-group">
					{item?.tenders && item.tenders.length > 0 ? (item.tenders?.map((tender, idx) => (
						<ListItem key={idx} item={{
							id: tender.id,
							avatar: tender.avatar,
							title: tender.fullname,
							subtitle: tender.descrizione,
							url: '/tenders/' + tender.id
						}} showUrlIcon={true} />
					))) : (<div className='text-center text-body-tertiary small'>
						<Trans i18nKey='projects.item.contents.info.no_tender' />
					</div>)}
				</ul>
			</div>

		</div>
	)
}

export default ProjectInfo