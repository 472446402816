import React from 'react';
import './Item.scss';

const Item = ({ ...props }) => {
	props.className = props.className || "d-flex flex-column shadow-sm bg-white p-2 rounded w-100 h-100";

	return (
		<div className={'item ' + props.className}>
			{props.children}
		</div>
	)
}

export default Item