import { useSelector, useDispatch } from 'react-redux';
import * as auth from 'store/actions/auth.action';

export const useAuth = () => {
	const user = useSelector(state => state.auth.user.risorsa);
	const lastAccess = useSelector(state => state.auth.user.lastAccess);
	const permissions = useSelector(state => state.auth.user.permissions);
	const dispatch = useDispatch();

	const login = (email, password) => {
		dispatch(auth.login(email, password));
	};

	const logout = () => {
		dispatch(auth.logout());
	};

	const checkPermissions = (toCheck) => {
		if (toCheck == null) {
			return true; //se non ci sono permessi settati vuol dire che la pagina non ne richiede
		}

		toCheck = !Array.isArray(toCheck) ? toCheck.split(',') : toCheck;

		if (user != null && permissions != null) {
			if (!Array.isArray(permissions)) {
				return Object.values(permissions).some(p => toCheck.includes(p.slug));
			} else if (permissions.length > 0) {
				return permissions.some(p => toCheck.includes(p.slug));
			} else {
				return false;
			}
		}

		return false;
	}

	const checkBadge = () => {
		return true;
	}

	return { user, lastAccess, permissions, checkPermissions, checkBadge, login, logout };
};