import React, { useLayoutEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import { Outlet, useLocation, useMatches } from 'react-router-dom';
import * as auth from '../../store/actions/auth.action';
import * as badge from '../../store/actions/badge.action';
import Nav from 'components/Nav/Nav';
import FooterNav from 'components/Nav/FooterNav';
import SideNav from 'components/Nav/SideNav';
import { useTranslation } from 'react-i18next';
import BadgeModal from 'components/Badge/BadgeModal';
import * as bootstrap from 'bootstrap';

const Protected = () => {
	const { t } = useTranslation();
	let location = useLocation();
	let matches = useMatches();
	let handle = matches.filter((match) => Boolean(match.handle?.title))[0];

	//aggiorno il titolo
	let title = handle.handle.title && t('views:' + handle.handle.title);
	document.title = title == handle.handle.title ? process.env.REACT_APP_NAME : title;

	const badgeIn = useSelector((state) => state.badge.badgeIn);

	useLayoutEffect(() => {
		//chiudo la sidenav da mobile al cambio pagina
		let sidenav = document.getElementById('sidenav');
		sidenav && sidenav.classList.remove('show');

		//chiudo eventuali modal e offcanvas
		//TODO: da sistemare in caso decido di gestire il tutto con le hinstory
		document.querySelectorAll('.offcanvas.show, .modal.show').forEach(el => {
			if (el.classList.contains('modal')) {
				(bootstrap.Modal.getInstance(el)).hide();
			}

			if (el.classList.contains('offcanvas')) {
				(bootstrap.Offcanvas.getInstance(el)).hide();
			}
		})
	}, [location])


	return (
		<>
			<SideNav />
			<main>
				<header><Nav /></header>
				<section className='p-1 p-lg-2'>
					<Outlet />
				</section>
				<footer>
					<FooterNav />
				</footer>
			</main>
			{!badgeIn && <BadgeModal />}
		</>
	)
}

const mapStateToProps = state => {
	return {
		loggingIn: state.auth.loggingIn,
		user: state.auth.user,
		error: state.auth.error,

		badgeIn: state.badge.badgeIn,
		badge: state.badge.badge,
		badgeError: state.badge.error,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(auth.logout()),

		badgeStart: () => dispatch(badge.badgeStart()),
		badgeStop: () => dispatch(badge.badgeStop()),
		badgeEnd: () => dispatch(badge.badgeEnd()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Protected);
