import React from 'react'
import { Trans, useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import DetailView from 'components/DetailView/DetailView';
import Avatar from 'components/Avatar/Avatar';

const CompanyInfo = () => {
	const [item] = useOutletContext();

	const { t } = useTranslation();

	return (
		item != null ? (
			<>
				{/* info company */}
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='companies.item.contents.info.title' /></h6>
						<div className='text-muted'><Trans i18nKey='companies.item.contents.info.subtitle' /></div>
					</div>
					<DetailView item={item} attributes={['name', 'description']} options={{
						transPrefix: 'models:company.',
						cssClass: 'col-12 col-md-6 border-bottom p-2'
					}} />
				</div>

				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='companies.item.contents.info.locations.title' /></h6>
						<div className='text-muted'><Trans i18nKey='companies.item.contents.info.locations.subtitle' /></div>
					</div>
					{item.locations != null ? (
						item.locations?.map(location => (
							<div className='col-12 col-md-6 col-lg-4'>
								<div className='row g-0 align-items-center p-2'>
									<div className="col-auto p-0 pe-2">
										<i className={'mdi mdi-18px ' + (location.stato == 0 ? ' mdi-close-circle-outline text-danger' : 'mdi-checkbox-marked-circle-outline text-success')} />
									</div>
									<div className="col lh-1 text-truncate text-start">
										<div><span className='fw-semibold'>{location.nome}</span></div>
										<div><small className='text-body-secondary'>{location.fulladdress}</small></div>
									</div>
								</div>
							</div>
						))
					) : (<div className='col-12'>No data</div>)}
				</div>

				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='companies.item.contents.info.departments.title' /></h6>
						<div className='text-muted'><Trans i18nKey='companies.item.contents.info.departments.subtitle' /></div>
					</div>
					{item.departments != null ? (
						item.departments?.map(department => (
							<div className='col-12 col-md-6 col-lg-4 p-2'>
								<div className='border p-2 rounded position-relative'>
									<div className='row g-0 align-items-center'>
										<div className="col-auto p-0 pe-2">
											<Avatar item={{ initials: department.initials }} />
										</div>
										<div className="col lh-1 text-truncate text-start">
											<h6 className="m-0 text-truncate">{department.name}</h6>
											<div className="text-body-secondary text-truncate" style={{ maxWidth: '250px' }}>
												<small>{department.activeResources}</small>
											</div>
										</div>
									</div>
									<Link to={'/departments/' + department.id} reloadDocument className='position-absolute z-1 top-0 bottom-0 start-0 end-0' />
								</div>
							</div>
						))
					) : (<div className='col-12'>No data</div>)}
				</div>
			</>
		) : null
	)
}

export default CompanyInfo