import { urlReplace } from "helpers/url";
import Api from "./Api";

export default class BadgeService {
	constructor(api) {
		this.api = api || new Api();
	}

	async getAll(params) {
		return this.api.get('/secure/badges', params);
	}

	// async getAllMe(params) {
	// 	if (localStorage.getItem('token')) {
	// 		return this.api.get('/secure/badges/me', params);
	// 	} else {
	// 		return null;
	// 	}
	// }

	async getById(id, params) {
		return this.api.get(urlReplace('/secure/badges/{id}', { id: id }), params);
	}

	async create(data, params) {
		return this.api.post('/secure/badges/new', data, params);
	}

	async update(id, data, params) {
		return this.api.put(urlReplace('/secure/badges/{id}', { id: id }), data, params);
	}

}