import React from 'react'
import ListItemLoader from './ListItemLoader'
import ListItemEmpty from './ListItemEmpty'
import InfiniteScroll from 'react-infinite-scroller'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import Api from 'services/Api';

const API = new Api();
const List = (props) => {
	const loadMore = props.loadMore;
	const hasMore = props.hasMore;

	const handleLoad = (hasMore) => {
		if (hasMore) {
			API.get(hasMore)
				.then(response => {
					loadMore && loadMore(response.data)
				});
		}
	};

	return (
		<div className="align-items-start g-0 overflow-hidden row">
			{props.children == null || props.children.length == 0 ? (
				<div className={"list-group" + (props.className ? ' ' + props.className : ' list-group-flush')}>
					<ListItemEmpty />
				</div>
			) : (
				<OverlayScrollbarsComponent
					className="h-100 rounded"
					options={{
						showNativeOverlaidScrollbars: false,
						overflow: { x: "hidden" },
						scrollbars: { autoHide: "leave", autoHideDelay: 1300 },
					}}
					defer
				>
					<InfiniteScroll
						className={"list-group" + (props.className ? ' ' + props.className : ' list-group-flush')}
						loadMore={() => handleLoad(hasMore)}
						hasMore={!!hasMore}
						loader={<ListItemLoader key={'l-' + Date.now()} />}
						useWindow={false}
					>
						{props.children}
					</InfiniteScroll>
				</OverlayScrollbarsComponent>
			)}
		</div>
	)
}

export default List