import React from 'react'
import { PlainField } from './PlainField';

const NumberField = ({ ...props }) => {
	props.type = 'number';
	props.fieldType = 'number';

	return (<PlainField {...props} />)
}

export default NumberField