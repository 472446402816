export const listDtoToOptions = (list) => {
	let options = [];
	list?.map((item, idx) => {
		options.push({
			label: item.title,
			value: item.id,
			avatar: item.avatar,
			subtitle: item.subtitle
		});
	});

	return options;
}

export const isPromise = (p) => {
	return p && Object.prototype.toString.call(p) === "[object Promise]";
}
