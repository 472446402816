import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import ItemAwait from '../../../../components/Item/ItemAwait';
import ItemHeader from '../../../../components/Item/ItemHeader';
import ItemContent from '../../../../components/Item/ItemContent';
import ItemFooter from '../../../../components/Item/ItemFooter';
import ItemPlaceHolder from '../../../../components/Item/ItemPlaceHolder';
import DetailView from '../../../../components/DetailView/DetailView';
import { Trans } from 'react-i18next';

const Furniture = () => {
	const { furniture } = useLoaderData();
	const [item, setItem] = useState();

	//callback function of Await react-router-dom
	const resolvedPromise = (response) => {
		if (response != null && response.data != null && response.data.length != 0) {
			if (response.data?.description) {
				response.data.description.replace(/\\r\\n/g, '<br/>').replace(/\r\n/g, '<br/>');
			}
			setItem(response.data);
		}
	}

	const attributes = [
		{
			label: 'description',
			value: 'description',
			formatter: 'html',
			cssClass: 'col-12 border-bottom p-2'
		},
		'resource.fullname',
		'status:check',
		'location.fulladdress',
		'room',
		'start:date',
		'end:date',
	];

	return (
		<ItemAwait resolvePromise={furniture} hasResolved={resolvedPromise} loading={<ItemPlaceHolder />}>
			<ItemHeader avatar={item?.avatar} title={item?.fullname} subtitle={item?.typology?.name}>
				{(item?.qrCode || item?.barCode) && (
					<div className='d-flex align-items-center'>
						<span className="badge bg-success-subtle text-success">
							<i className="mdi mdi-barcode mdi-24px" />
							{item?.qrCode || item?.barCode}
						</span>
					</div>
				)}
			</ItemHeader>
			<ItemContent>
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='assets.furnishings.item.title' /></h6>
					</div>
					<DetailView
						item={item}
						attributes={[
							...attributes,
							{
								label: 'quality.label',
								value: () => item.quality && <Trans i18nKey={'models:asset.quality.value.' + item.quality} />,
								formatter: 'raw',
								cssClass: 'col-12 col-lg-4 border-bottom p-2'
							},
							{
								label: 'assignment.label',
								value: () => item.assignment && <Trans i18nKey={'models:asset.assignment.value.' + item.assignment} />,
								formatter: 'raw',
								cssClass: 'col-12 col-lg-4 text-lg-center border-bottom p-2'
							},
							{
								label: 'cluster.label',
								value: () => item.cluster && <Trans i18nKey={'models:asset.cluster.value.' + item.cluster} />,
								formatter: 'raw',
								cssClass: 'col-12 col-lg-4 text-lg-end border-bottom p-2'
							},
							{
								label: 'note',
								value: 'note',
								cssClass: 'col-12 border-bottom p-2'
							},
						]}
						options={{
							transPrefix: 'models:asset.',
							cssClass: 'col-12 col-md-6 border-bottom p-2'
						}} />
				</div>
			</ItemContent>
			<ItemFooter />
		</ItemAwait>
	)

}

export default Furniture