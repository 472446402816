import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableItem = (props) => {
	const {
		attributes,
		listeners,
		isDragging,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: props.item.id });

	const itemStyle = {
		transform: CSS.Transform.toString(transform),
		transition,
		userSelect: "none",
		cursor: "grab",
		boxSizing: "border-box",
		zIndex: isDragging ? "100" : "auto",
		opacity: isDragging ? 0.3 : 1
	};

	return (
		<div id={"topic-sort-item-" + props.id} className="mt-1 p-1" style={itemStyle} ref={setNodeRef}>
			<div className="bg-white p-2 ps-0 border rounded shadow-sm ">
				<div className="row g-0 align-items-center">
					<div className="col" {...attributes} {...listeners}>
						<div className="row g-0 align-items-center">
							<div className="col-auto">
								<i className="mdi mdi-24px mdi-drag-vertical-variant text-opacity-50 text-secondary"></i>
							</div>
							<div className="col lh-1">
								<h6 className="m-0">{props.item.title}</h6>
								<div className="small text-muted">{props.item.subtitle}</div>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<div className="form-check form-switch d-flex justify-content-end" data-bs-target="tooltip" title="Mostra sempre la Storia nella Dashboard">
							<input className="form-check-input" type="checkbox" role="switch" id={"topicSort" + props.id} style={{ width: '1.5em' }} value="1" onChange={
								() => {
									props.item.emptyHidden = props.item.emptyHidden ? 0 : 1;
								}
							} defaultChecked={!props.item.emptyHidden} />
							<label className="form-check-label" htmlFor={"topicSort" + props.id}></label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default SortableItem;
