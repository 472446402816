import Avatar from 'components/Avatar/Avatar';
import DetailView from 'components/DetailView/DetailView';
import Document from 'components/Document/Document';
import React from 'react'
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

const ProfileInfo = () => {
	const [item] = useOutletContext();

	const profileAttributes = [
		"fullname",
		"id",
		"dataNascita:date",
		"codiceFiscale:text",
		"cellAziendale",
		"cellPersonale",
		"mailAziendale",
		"mailPersonale",
	];

	const contractAttributes = [
		"societa",
		"sede",
		"tipologia",
		"ruolo",
		"profile.descrizioneProfilo",
		"livelloProfessionale",
		"dataInizio:date",
		"ccnl",
	];

	return (
		<>
			<div className='row g-0 align-items-center'>
				<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
					<h6 className='m-0'><Trans i18nKey='profile.item.contents.info.title' /></h6>
				</div>
				<DetailView item={item} attributes={profileAttributes} options={{
					transPrefix: 'models:resource.',
					cssClass: 'col-12 col-md-6 border-bottom p-2'
				}} />
			</div >

			{/* contratto */}
			{item?.active_contract ? (
				<div className='row g-0 align-items-center'>
					<div className='col-12 bg-body-tertiary my-2 p-2 rounded text-center'>
						<h6 className='m-0'><Trans i18nKey='profile.item.contents.info.contract' /></h6>
					</div>
					<DetailView item={item.active_contract} attributes={contractAttributes} options={{
						transPrefix: 'models:contract.',
						cssClass: 'col-12 col-md-6 border-bottom p-2'
					}} />
				</div >
			) : (
				<div className='text-center text-body-tertiary small'>
					<Trans i18nKey='profile.item.contents.info.no_contract' />
				</div>
			)}


		</>
	)
}

export default ProfileInfo