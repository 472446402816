import React from 'react';
import { Link } from 'react-router-dom';
import ItemNav from '../../../components/Item/ItemNav';

export const WikiNav = () => {
	return (
		<ItemNav className='flex-column nav-pills'>
			<div className='d-flex justify-content-between'>
				<Link className="nav-link py-1" target="_BLANK" to={process.env.REACT_APP_LEGACY_URL + "/documenti/howto/Presentazione Alcotec.pdf"}>Presentazione Alcotec <i className='mdi mdi-open-in-new mdi-18px' /></Link>
				<Link className="nav-link py-1 active" target="_BLANK" to={process.env.REACT_APP_LEGACY_URL + "/documenti/howto/media/Presentazione Alcotec.mp4"}>Video <i className='mdi mdi-youtube mdi-18px' /></Link>
			</div>
			<div className='d-flex justify-content-between'>
				<Link className="nav-link py-1" target="_BLANK" to={process.env.REACT_APP_LEGACY_URL + "/documenti/howto/Presentazione Alcademy.pdf"}>Presentazione Alcademy <i className='mdi mdi-open-in-new mdi-18px' /></Link>
				<Link className="nav-link py-1 active" target="_BLANK" to={process.env.REACT_APP_LEGACY_URL + "/documenti/howto/media/Presentazione Alcademy.mp4"}>Video <i className='mdi mdi-youtube mdi-18px' /></Link>
			</div>
			<div className='d-flex justify-content-between'>
				<Link className="nav-link py-1" target="_BLANK" to={process.env.REACT_APP_LEGACY_URL + "/documenti/howto/Dipartimenti aziendali.pdf"}>Dipartimenti aziendali <i className='mdi mdi-open-in-new mdi-18px' /></Link>
				<Link className="nav-link py-1 active" target="_BLANK" to={process.env.REACT_APP_LEGACY_URL + "/documenti/howto/media/Dipartimenti aziendali.mp4"}>Video <i className='mdi mdi-youtube mdi-18px' /></Link>
			</div>
			<div className='d-flex justify-content-between'>
				<Link className="nav-link py-1" target="_BLANK" to={process.env.REACT_APP_LEGACY_URL + "/documenti/howto/Mentori.pdf"}>Mentori <i className='mdi mdi-open-in-new mdi-18px' /></Link>
				<Link className="nav-link py-1 active" target="_BLANK" to={process.env.REACT_APP_LEGACY_URL + "/documenti/howto/media/Mentori.mp4"}>Video <i className='mdi mdi-youtube mdi-18px' /></Link>
			</div>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-793381553"><span>Login</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-106149083"><span>Timbratura in ingresso, pausa e chiusura giornata</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-78064135"><span>Dashboard Alcodesk e le diverse sezioni</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-1049079397"><span>Consultazione Procedure, Istruzioni, Templates</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-1548444575"><span>Compilazione TS</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-976292144"><span>Visualizzazione TS mensile</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-1544994859"><span>Visualizzazione pianificazione mensile e giornaliera</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-1348955861"><span>I task e la loro gestione</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-1412633480"><span>Pianificazione mensile delle risorse su cdc</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-183833078"><span>Aggiornamento delle commesse (a cura dei PM)</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-1923274593"><span>Gestione della wbs di progetto e relative attività/milestones</span><i className='mdi mdi-chevron-right' /></Link>
			<Link className="nav-link d-flex justify-content-between" aria-current="page" to="./#w-1414928208"><span>Valutazione settimanale commessa</span><i className='mdi mdi-chevron-right' /></Link>
		</ItemNav>
	)
}

export const WikiContent = () => {
	return (
		<>
			<div id='w-793381553'>
				<h3 className='text-center'>Login in</h3><br />
				<p className='text-jusitfy m-auto'>Per accedere alla piattaforma Alcodesk sarà necessario inserire la propria mail aziendale (es. m.rossi@alcotec.it) e la propria password fornita via mail al momento dell&rsquo;ingresso in azienda.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/002-min.png' /></p><br />
			</div>
			<div id='w-106149083'>
				<h3 className='text-center'>Timbratura in ingresso, pausa e chiusura giornata</h3><br />
				<p className='text-jusitfy m-auto'>
					Ogni giorno la prima attività da svolgere sulla piattaforma sarà quella di accedere e timbrare la propria presenza. La tua posizione sarà geolocalizzata rispetto alla sede lavorativa della giornata (es. sede di Roma = Via Adelaide Ristori 38).
					<br />L’orario di lavoro del personale presso le sedi in indirizzo sarà il seguente: <br /><br />
					Dal Lunedi al venerdì <br />
					&emsp; ✓ Ingresso: dalle ore 08.30 alle ore 09.30 (1 ora di flessibilità) <br />
					&emsp; ✓ Uscita: dalle ore 17.30 alle ore 18:30 <br /><br />
					Il personale direttivo (Quadri) sarà tenuto ad effettuare 2 timbrature, una in entrata ed una in uscita. <br />
					I collaboratori che prestano l’attività lavorativa nei cantieri hanno l’obbligo di timbrare solamente in entrata e l’orario di lavoro è in funzione all’esigenza di commessa. <br />
					In caso di lavoro in modalità smart working bisognerà effettuare la timbratura sulla piattaforma Alcodesk, opzionando la voce <em>'Smart working'</em> <strong>(PRO_HR GESTIONE_SMART WORKING_00_G)</strong><br />
				</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/004-min.png' /></p><br /><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/006-min.png' /></p><br /><br />
				<p className='text-jusitfy m-auto'>Ogni qualvolta ti allontanerai dalla sede di lavoro (per una pausa o pausa pranzo) sarà obbligatorio timbrare “pausa” e badgiare nuovamente l’ingresso al tuo ritorno in azienda, confermando la sede lavorativa.<br /><br />
					La durata complessiva delle pause è di 1 ore distribuite in: <br />
					&emsp; ✓ intervallo meridiano di 1 ora da fruire tra le ore 13.00 e le ore 14.30 <br /><br />
					Eventuali ritardi in ingresso così come eventuali uscite anticipate rispetto l’orario previsto sono consentiti 2 volte al mese, fino ad un massimo di 2 ore mese. <br />
					Questi ritardi devono essere recuperati entro il mese e distribuiti in massimo 2 giornate lavorative. <br />
					In caso di superamento delle 2 volte mensili, o in caso di mancato recupero, ogni assenza e/o ritardo rispetto a quanto sopra riportato devono essere giustificati con permessi individuali <strong>(MOD_HR_REGOLAMENTO AZIENDALE_00_D)</strong>
				</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/008-min.png' /></p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/010-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Per poter timbrare la chiusura della giornata occorre compilare il TimeSheet giornaliero. Vedi sezione compilazione TS</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/012-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Una volta conclusa la giornata lavorativa e compilato il timesheet ti apparirà la seguente schermata.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/014-min.png' /></p>
			</div>
			<div id='w-78064135'>
				<h3 className='text-center'>Dashboard Alcodesk e le diverse sezioni</h3><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/016-min.png' /></p><br />
				<div style={{ 'width': '90%', 'margin': 'auto' }}>
					<p style={{ 'textAlign': ' justify' }}>L&rsquo;home page di Alcodesk è strutturata nel modo seguente:</p>
					<ul style={{ 'margin': 'auto' }}>
						<li>Il menù a sinistra rappresenta l&rsquo;elenco delle funzioni disponibili sulla piattaforma.</li>
						<li>La parte centrale è relativa alle commesse attive.</li>
						<li>Il menù a destra rappresenta la pianificazione settimanale.</li>
						<li>Il banner in alto fa riferimento ai task che ti vedono coinvolto.</li>
					</ul><br />
					<em>N.B. Qualora non ti apparissero commesse, ti chiediamo di farlo presente al PM di riferimento.</em>
					<br />
				</div>
			</div>
			<div id='w-1049079397'>
				<h3 className='text-center'>Consultazione Procedure, Istruzioni, Templates</h3><br />
				<p className='text-jusitfy m-auto'>Nell&rsquo;apposita sezione è possibile visionare tutte le procedure, istruzioni e moduli aziendali.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/018-min.png' /></p><br />
			</div>
			<div id='w-1548444575'>
				<h3 className='text-center'>Compilazione TS</h3><br />
				<p className='text-jusitfy m-auto'>Il TS si compone di due sezioni: Planned e Unplanned.<br /><br />
					La sezione Planned andrà compilata relativamente alle attività svolte nella commessa di riferimento per cui è presente la pianificazione. Il TS andrà compilato per un totale di 8 ore giornaliere e sarà possibile imputare le singole attività a partire da un minimo di 15 min (0.25), inserendo una breve descrizione delle attività.<br /><br />
					La sezione Unplanned andrà compilata relativamente alle attività svolte in una commessa per cui non è presente la pianificazione.<br /><br />
					Nel caso di inserimento di ore/giornate nella commessa 90000.1 Assenza/Non Disponibile fare riferimento al <strong>REGOLAMENTO AZIENDALE (MOD_HR_REGOLAMENTO AZIENDALE_00_D)</strong>.<br /><br />
					<strong>ASSENZA PER MALATTIA</strong><br />
					L’assenza per malattia deve essere comunicata all’inizio dell’orario di lavoro del giorno (max 9:30) in cui si verifica l’assenza all’ufficio HR Gestione <strong><em>hrgestione@alcotec.it</em></strong> e al proprio Responsabile.
					Il dipendente deve richiedere il certificato medico comprovante la malattia, anche per un solo giorno di assenza, ed inviare agli indirizzi sopra citati il numero di protocollo del certificato rilasciato dal medico oltre ad inserirlo su Alcodesk.<br />
					Sulla piattaforma Alcodesk alla voce Unplanned bisognerà selezionare:<br />
					&emsp;• CdC: Assenza/Non disponibile; -&gt; Ambito: Malattia ed inserire il numero di protocollo del certificato medico<br /><br />
					Tutte le altre assenze devono essere comunicate tramite task, che sarà compilato nel seguente modo: <br /><br />
					&emsp;• <strong>ALCODESK -&gt; NUOVO TASK -&gt; RICHIESTE UFFICIO HR GESTIONE -&gt; RICHIESTA FERIE/ ROL/EXF/BANCA ORE </strong><br />
					&emsp; - RESPONSIBLE: il proprio Responsabile di Dipartimento<br />
					&emsp; - CONSULTED: Ida Colucci<br />
					&emsp; - INFORMED: Maria Rosa Gambuto e Giulia Fabrizi<br />
					&emsp; - DESCRIZIONE: richiesta<br /><br />
					La fruizione delle FERIE non potrà essere inferiore a 4 ore giornaliere, mentre la fruizione delle ore di ROL, EXF e BANCA ORE non potrà essere inferiore a 15 minuti e di multipli corrispondenti.<br /><br />
					I collaboratori con contratto autonomo e gli stagisti dovranno informare, previa comunicazione al proprio responsabile, la loro assenza nel seguente modo: <br />
					&emsp;• <strong>ALCODESK -&gt; NUOVO TASK -&gt; RICHIESTE UFFICIO HR GESTIONE -&gt; SEGNALAZIONE GENERICA</strong><br />
					&emsp; - RESPONSIBLE: Ida Colucci, Maria Rosa Gambuto e Giulia Fabrizi<br />
					&emsp; - CONSULTED: il proprio Responsabile di Dipartimento<br />
					&emsp; - DESCRIZIONE: richiesta<br /><br />

					Sulla piattaforma Alcodesk alla voce Unplanned i collaboratori con contratto autonomo dovranno inserire la propria assenza nel seguente modo: <br />
					&emsp; - <span className='fw-semibold'>CdC: Assenza/Non disponibile; Ambito: PERMESSO; Attività: ASSENZA P.IVA; Ore: ore di assenza</span><br /><br />
					&emsp; Mentre gli stagisti imputeranno la propria assenza come segue:<br />
					&emsp; - <span className='fw-semibold'>CdC: Assenza/Non disponibile; Ambito: PERMESSO; Attività: ASSENZA STAGE; Ore: ore di assenza</span><br /><br />
					I dipendenti con contratto part-time imputeranno le restanti ore, oltre quelle da contratto, come a seguire:<br />
					&emsp; - <span className='fw-semibold'>CdC: Assenza/Non disponibile; Ambito: PERMESSO; Attività: ASSENZA PART-TIME; Ore: ore di assenza</span><br /><br />
					Per tutti gli altri permessi presenti su Alcodesk a cui imputare la propria assenza, fare riferimento alla procedura <strong>(PRO_HR GESTIONE_AMMINISTRAZIONE DEL PERSONALE_00_C).</strong><br /><br />
				</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/020-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Per completare correttamente il Timesheet, è necessario inserire la tipologia di attività svolta (o livello della wbs) per la commessa di interesse.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/022-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Utilizzando l&rsquo;apposito menu a tendina, selezionare (se presente) il task a cui fa riferimento l&rsquo;attività svolta.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/024-min.png' /></p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/026-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Una volta completato il TS con le 8 ore lavorative, dovrai cliccare il tasto&rdquo; Completa Timesheet&rdquo;.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/028-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Ogni TS andrà in approvazione al PM/HR.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/030-min.png' /></p><br />
			</div>
			<div id='w-976292144'>
				<h3 className='text-center'>Visualizzazione TS mensile</h3><br />
				<p className='text-jusitfy m-auto'>Nella sezione &ldquo;Timesheet Mensile&rdquo; sarà possibile visionare un riepilogo mensile delle attività svolte per ogni commessa. Inoltre sarà possibile visionare lo stato di avanzamento dell&rsquo;approvazione dei TS da parte dei PM.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/032-min.png' /></p>
			</div>
			<div id='w-1544994859'>
				<h3 className='text-center'>Visualizzazione pianificazione mensile e giornaliera</h3><br />
				<p className='text-jusitfy m-auto'>Nella seguente sezione, è possibile vedere la propria pianificazione rispetto alle diverse commesse. Qualora non ti risultasse nessuna pianificazione, chiedi al tuo PM.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/034-min.png' /></p>
			</div>
			<div id='w-1348955861'>
				<h3 className='text-center'>I task e la loro gestione</h3><br />
				<div style={{ 'width': '90%', 'margin': 'auto' }}>
					<p style={{ 'textAlign': ' justify' }}>Nella sezione a sinistra sarà possibile vedere tutti i task, i quali vengono utilizzati per l&rsquo;assegnazione dell&rsquo;attività lavorative. Selezionando il pulsante &ldquo;i miei task&rdquo; sarà possibile visualizzare:</p>
					<ul style={{ 'margin': 'auto' }}>
						<li>Task aperti per cui risulto responsible, ovvero da accettare e prendere in carico l&rsquo;attività;</li>
						<li>Task in corso o in revisione per cui risulto responsible, ovvero per cui devo completare l&rsquo;attività;</li>
						<li>Task completati per cui risulto accountable, ovvero per cui occorre dare feedback di accettazione o richiesta revisione dell&rsquo;attività;</li>
						<li>Task aperti per cui risulto informed, non è necessario svolgere alcuna attività.</li>
					</ul><br />
				</div><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/036-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Per ogni task, sarà necessario completarlo per chiudere l&rsquo;attività assegnata.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/038-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Per ogni task, da te aperto (Accountable), al completamento dell&rsquo;attività sarà necessario approvarlo o chiedere una revisione.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/040-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Per aprire un nuovo Task sarà necessario andare sulla sezione &ldquo;nuovi&rdquo;, per i task specifici fare riferimento alle procedure degli uffici di competenza.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/042-min.png' /></p><br />
			</div>
			<div id='w-1412633480'>
				<h3 className='text-center'>Pianificazione mensile delle risorse su cdc</h3><br />
				<p className='text-jusitfy m-auto'>Tutti i PM devono pianificare le risorse sulle commesse di propria competenza, imputando per ogni commessa e giorno del mese le ore necessarie per svolgere le attività.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/044-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Una volta inserite le ore, sarà necessario &ldquo;Salvare pianificazione&rdquo; per confermare le ore inserite.</p><br />
			</div>
			<div id='w-183833078'>
				<h3 className='text-center'>Aggiornamento delle commesse (a cura dei PM)</h3><br />
				<p className='text-jusitfy m-auto'>Ogni PM, con una cadenza stabilita, deve aggiornare le commesse e i documenti di commessa.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/046-min.png' /></p>
				<div style={{ 'width': '90%', 'margin': 'auto' }}>
					<p style={{ 'textAlign': ' justify' }}>Nello specifico:</p>
					<ul>
						<li>Dati economici della commessa</li>
						<li>Stato di avanzamento</li>
						<li>Cliente </li>
						<li>Servizi erogati</li>
						<li>Documenti</li>
					</ul><br />
					<br />
				</div>
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/048-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Aprendo la commessa è possibile modificare l&rsquo;assegnazione delle risorse al team di lavoro, in modo tale da abilitare o disattivare la possibilità di caricare le ore sulla commessa.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/050-min.png' /></p><br />
			</div>
			<div id='w-1923274593'>
				<h3 className='text-center'>Gestione della wbs di progetto e relative attività/milestones</h3><br />
				<p className='text-jusitfy m-auto'>All&rsquo;interno della sezione Project Plan, è possibile creare una WBS scomponendola in livelli e sottolivelli a seconda delle esigenze. Le risorse che completeranno il TS potranno imputare le ore ad una specifica sezione del progetto.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/052-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Nella presente sezione è possibile vedere tutti i task attivi per la commessa di riferimento.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/054-min.png' /></p><br />
			</div>
			<div id='w-1414928208'>
				<h3 className='text-center'>Valutazione settimanale commessa</h3><br />
				<p className='text-jusitfy m-auto'>Per ogni commessa il PM dovrà caricare con cadenza stabilita i documenti di commessa aggiornati, effettuare la valutazione delle risorse e approvare i Timesheet.</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/056-min.png' /></p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/058-min.png' /></p><br />
				<p className='text-jusitfy m-auto'>Approvazione TS risorse</p><br />
				<p className='text-center'><img width='90%' className='border border-light rounded shadow' src='/images/secure/wiki/060-min.png' /></p><br />
			</div>

		</>
	)
}