import Avatar from 'components/Avatar/Avatar';
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next';

const ProjectTeams = ({ teams, ...props }) => {

	const [teamsGroup, setTeamGroup] = useState({ "PM": [], "CO_PM": [], "COORDINATOR": [], "ASS_PM": [], "OPERATING": [], "SUPPORT": [], "REF_INTERNO": [] });

	useEffect(() => {
		let tsGroup = { "PM": [], "CO_PM": [], "COORDINATOR": [], "ASS_PM": [], "OPERATING": [], "SUPPORT": [], "REF_INTERNO": [] };
		teams?.forEach(team => {
			if (!tsGroup[team.ruolo]) {
				tsGroup[team.ruolo] = [];
			}

			if (team?.resource && team.resource?.status && team.resource.status == '1') {
				tsGroup[team.ruolo].push(team.resource);
			}
		});

		Object.entries(tsGroup).forEach(([key, value]) => {
			if (['CO_PM', 'ASS_PM', 'SUPPORT', 'REF_INTERNO'].includes(key) && value.length == 0) {
				delete tsGroup[key];
			}
		});

		setTeamGroup(tsGroup);
	}, [teams])

	return (
		<div className='row g-0 align-items-center'>
			{teamsGroup && Object.entries(teamsGroup)?.map(([key, value]) => (
				<div key={key} className='col-12 col-lg-auto lh-1 p-2' style={{ minWidth: '200px' }}>
					<div>
						<strong className="fw-semibold"><Trans i18nKey='components:projectTeams.role' context={key} /></strong>
					</div>
					<div className='mt-2'>
						{value && value.length != 0 ? value.map((resource, idx) => (
							<Avatar key={idx} item={resource.avatar} />
						)) : (
							<div className='bg-warning-subtle text-warning p-2 rounded'>
								<i className='mdi mdi-alert-outline mdi-18px' />
								<Trans i18nKey='components:projectTeams.empty' context={key} />
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	)
}

export default ProjectTeams