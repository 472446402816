import React from 'react'

const LabelField = ({ ...props }) => {
	return (
		<div className="mb-3">
			{props.label != null ? (<label id={props.id + '_label'} htmlFor={props.id} className="form-label fw-semibold" dangerouslySetInnerHTML={{ __html: props.label }} />) : null}
		</div>
	)
}

export default LabelField