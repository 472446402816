import i18next from "i18next";

export const areaChart = (data) => {

	let dataset = [], labels = [];

	if (data != null && data.length > 0) {
		dataset = data.map(i => i.totalHours);
		labels = data.map(i => new Date(i.date).getTime());
	}

	return {
		id: 'area-chart-1',
		type: 'area',
		series: [{
			name: i18next.t('views:tenders.item.contents.info.charts.area.series1'),
			data: dataset
		}],
		options: {
			title: {
				text: i18next.t('views:tenders.item.contents.info.charts.area.title'),
				align: 'center',
				style: {
					fontSize: '11px',
					fontWeight: '600',
					color: '#212529',
				}
			},
			chart: {
				id: 'area-chart-1',
				type: 'area',
				height: 180,
				toolbar: {
					autoSelected: "pan",
					show: false
				},
			},
			colors: ["var(--bs-primary)"],
			stroke: {
				width: 2
			},
			grid: {
				borderColor: "#555",
				clipMarkers: false,
				yaxis: {
					lines: {
						show: false
					}
				}
			},
			dataLabels: {
				enabled: false
			},
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					inverseColors: false,
					gradientToColors: ['#fff'],
					opacityFrom: 0.45,
					opacityTo: 0.05,
				},
			},
			markers: {
				size: 0,
			},
			xaxis: {
				type: 'datetime',
			},
			labels: labels,
		}
	}
};

export const radialBar = (data) => {
	let dataset = [], labels = [];

	if (data != null && data.length > 0) {
		dataset = data.map(i => i.count);
		labels = data.map(i => i18next.t('models:task.status.value.' + i.status, { count: 100 }));
	}

	return {
		id: 'radial-bar-chart-1',
		type: 'radialBar',
		series: dataset,
		options: {
			title: {
				text: i18next.t('views:tenders.item.contents.info.charts.radialBar.title'),
				align: 'center',
				style: {
					fontSize: '11px',
					fontWeight: '600',
					color: '#212529',
				}
			},
			chart: {
				height: 200,
				type: 'radialBar',
			},
			plotOptions: {
				radialBar: {
					offsetY: 0,
					startAngle: 0,
					endAngle: 270,
					hollow: {
						margin: 5,
						size: '40%',
						background: 'transparent',
						image: undefined,
					},
					dataLabels: {
						name: {
							show: false,
						},
						value: {
							show: false,
						}
					},
					barLabels: {
						enabled: true,
						useSeriesColors: true,
						offsetX: -8,
						fontSize: 'var(--bs-body-font-size)',
						formatter: function (seriesName, opts) {
							return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
						},
					},
				}
			},
			colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
			labels: labels,
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						show: false
					}
				}
			}]
		}
	}
};