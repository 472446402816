import Search from 'components/Search/Search';
import React from 'react';
import { Trans } from 'react-i18next';

const SearchMenu = () => {
	return (
		<div className='p-2'>
			<div className="row g-0 p-2 align-items-center">
				<div className='col text-start'>
					<h5 className="mb-0">
						<Trans i18nKey='components:searchMenu.title' />
					</h5>
					<div className='text-body-secondary'><small><Trans i18nKey='components:searchMenu.subtitle' /></small></div>
				</div>
			</div>
			<div>
				<Search disabled={true} className='form-control-lg' />
			</div>

			<div className='bg-warning-subtle text-warning rounded p-2 align-items-center justify-content-center d-flex mt-4'>
				<i className='mdi mdi-information-variant-circle-outline mdi-24px pe-1' />
				<Trans i18nKey='views:wip_ongoing' />
			</div>

		</div>
	)
}

export default SearchMenu