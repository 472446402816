import React, { Suspense, useState } from 'react'
import { Await, useAsyncError, useAsyncValue, useLoaderData, useParams } from 'react-router-dom';
import Item from 'components/Item/Item';
import ItemPlaceHolder from 'components/Item/ItemPlaceHolder';
import ItemHeader from 'components/Item/ItemHeader';
import Timesheet from 'components/Timesheet/Timesheet';
import Avatar from 'components/Avatar/Avatar';
import { Trans } from 'react-i18next';
import ItemContent from 'components/Item/ItemContent';
import Search from 'components/Search/Search';
// import DatePicker from 'components/DatePicker/DatePicker';
import { Error } from 'components/Error/Error';

const ApprovalTimesheets = () => {
	let { timesheets } = useLoaderData();
	const { date } = useParams();
	const [items, setItems] = useState([]);

	//callback function of Await react-router-dom
	const HasResolved = () => {
		const response = useAsyncValue();
		if (response != null && response.data != null && (items == null || items != response.data)) {
			setItems(sortByResources(response.data));
		}
	}

	const sortByResources = (data) => {
		let newItems = [];
		data?.forEach(element => {
			//se non esiste creo la risorsa
			if (newItems[element.idRisorsa] == null) {
				newItems[element.idRisorsa] = {
					resource: element.resource,
					projects: []
				};
			}

			//se non esiste creo il progetto
			if (newItems[element.idRisorsa].projects[element.tabId + element.tabName] == null) {
				newItems[element.idRisorsa].projects[element.tabId + element.tabName] = {
					project: element.project,
					timesheets: []
				};
			}

			//aggiungo il timesheet
			newItems[element.idRisorsa].projects[element.tabId + element.tabName].timesheets.push(element);
		});
		return newItems;
	}

	//on change calendar date
	const onChangeDate = (date) => {
		if (date != null) {
			// navigate('./approval-timesheets/' + date, { reload: true });
			window.location.href = '/approval-timesheets/' + date;
		}
	}

	return (
		<Item>
			<div className='bg-warning-subtle text-warning rounded p-2 align-items-center justify-content-center d-flex mx-2'>
				<i className='mdi mdi-information-variant-circle-outline mdi-24px pe-1' />
				<Trans i18nKey='views:wip' />
			</div>
			{/* <div className='row g-0 align-items-center justify-content-between'>
				<div className='col-12 col-md-6 col-lg-4 col-xl-2'>
					<span className="fw-semibold"><Trans i18nKey={'components:search.placeholder'} /></span>
					<Search />
				</div>
				<div className='col-12 col-md-6 col-lg-4'>
				</div>
			</div>

			<ItemContent>
				<Suspense fallback={[
					<Item className="d-flex flex-column shadow-sm bg-white p-2 rounded w-100 border mb-2">
						<ItemPlaceHolder>
							<div className='placeholder-glow p-2'><div className='p-4 placeholder bg-secondary bg-opacity-75 rounded w-100'></div></div>
						</ItemPlaceHolder>
					</Item>,
					<Item className="d-flex flex-column shadow-sm bg-white p-2 rounded w-100 border mb-2">
						<ItemPlaceHolder>
							<div className='placeholder-glow p-2'><div className='p-4 placeholder bg-secondary bg-opacity-75 rounded w-100'></div></div>
						</ItemPlaceHolder>
					</Item>,
				]}>
					<Await resolve={timesheets} errorElement={<Error error={useAsyncError()} />}>
						<HasResolved />
						{items?.map((item, key) => (
							<Item className="d-flex flex-column shadow-sm bg-white p-2 rounded w-100 border mb-2">
								<ItemHeader
									avatar={item.resource?.avatar}
									title={item.resource?.fullname}
									subtitle={item.resource.active_contract != null && item.resource.active_contract.profile != null && item.resource.active_contract.profile.descrizioneProfilo != null ? item.resource.active_contract.profile.descrizioneProfilo : <Trans i18nKey='components:persona.no_profile' />}
									hideBackButton={true}
								/>
								<div className="accordion" id={"approval-timesheet-accordion-" + key}>
									{Object.entries(item?.projects).map(([idx, project], i) => (
										<div className="accordion-item">
											<div className="accordion-header">
												<button className="accordion-button p-1 pe-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#r-" + idx + "-" + key} aria-expanded="false" aria-controls={"r-" + idx + "-" + key}>
													<div className='row g-0 align-items-center lh-1'>
														<div className='col-auto pe-2'>
															<Avatar item={project.project?.avatar} />
														</div>
														<div className='col lh-1 text-truncate'>
															<h6 className='m-0'>{project.project?.fullname}</h6>
															<div className='text-muted'><small>{project.project?.pm[0]?.resource?.fullname}</small></div>
														</div>
													</div>
												</button>
											</div>
											<div id={"r-" + idx + "-" + key} className="accordion-collapse collapse" data-bs-parent={"#approval-timesheet-accordion-" + key}>
												<div className="accordion-body p-2">
													{project.timesheets?.map((timesheet) => (
														<Timesheet item={timesheet} isApprovable={true} />
													))}
												</div>
											</div>
										</div>
									))}
								</div>
							</Item>
						))}
						{items == null || items.length == 0 ? (
							<div className='bg-warning rounded p-2 bg-opacity-10 text-warning mb-2 text-center'>
								<i className='mdi mdi-information-variant-circle-outline' />
								<Trans i18nKey='views:approvalTimesheet.empty' />
							</div>
						) : null}
						<div className='bg-secondary rounded p-2 bg-opacity-10 text-secondary mb-2 text-center'>
							<i className='mdi mdi-information-variant-circle-outline' />
							<Trans i18nKey='views:approvalTimesheet.info' />
						</div>
					</Await>
				</Suspense>
			</ItemContent> */}
		</Item>
	)
}

export default ApprovalTimesheets