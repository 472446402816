import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { LangProvider } from 'providers/LangProvider';
import { ThemeProvider } from 'providers/ThemeProvider';
import App from './App';
import storeConfig from './store/storeConfig';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

import "@mdi/font/css/materialdesignicons.min.css";

const store = storeConfig();

// //set theme if present
// const theme = localStorage.getItem('theme');
// theme && document.documentElement.setAttribute("data-bs-theme", theme);

// //set lang
// const lang = localStorage.getItem('lang');
// lang && document.documentElement.setAttribute("lang", lang);

ReactDOM.createRoot(document.getElementById('root')).render(
	<Provider store={store}>
		<I18nextProvider value={i18n}>
			<LangProvider>
				<ThemeProvider>
					<App />
				</ThemeProvider>
			</LangProvider>
		</I18nextProvider>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
